
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";
import isEmpty from "lodash/isEmpty";
import action from "../middleware";

export const SET_GUARDRAILS_DATA = "SET_GUARDRAILS_DATA";
export const SET_GUARDRAILS_NOTIFICATIONS_DATA = "SET_GUARDRAILS_NOTIFICATIONS_DATA";
export const GET_GUARDRAILS_AGGS = "GET_GUARDRAILS_AGGS";
export const SET_GUARDRAILS_FILTERS = "SET_GUARDRAILS_FILTERS";
export const SET_GUARDRAILE_RULE = "SET_GUARDRAILE_RULE";
export const EDIT_GUARDRAIL_RULE = "EDIT_GUARDRAIL_RULE";
export const DELETE_GUARDRAIL_RULE = "DELETE_GUARDRAIL_RULE";
export const SET_WORKSPACE_GUARDRAILS_VIOLATIONS_IGNORES_DATA = "SET_WORKSPACE_GUARDRAILS_VIOLATIONS_IGNORES_DATA";

const getListGuardrails = (filters, searchValue, sorting = { createdAt: -1 }, pageNumber, pageSize, projection) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    let payload = { searchValue, offset: pageNumber * pageSize || 0, size: pageSize };
    const hasProjection = !isEmpty(projection);
    if (!isEmpty(sorting)) {
      payload.sort = sorting;
    }
    if (!isEmpty(filters)) {
        const nonEmptyFilters = {};
        Object.keys(filters).forEach((key) => { !isEmpty(filters[key]) && (nonEmptyFilters[key] = filters[key]) });
        payload.filters = { andClauses: nonEmptyFilters };
    }
    if (hasProjection) {
        payload = { projection, offset: 0, filters: { andClauses: {}}}
    }
    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/ci/guardrails/list`,
        "POST",
        payload,
        undefined,
        undefined,
        false,
        null,
    );
    let data = null;
    if (req?.ok) {
        data = await req.json();
    }
    dispatch({ type: hasProjection ? SET_GUARDRAILS_NOTIFICATIONS_DATA : SET_GUARDRAILS_DATA, payload: data });
  });
}

const getGuardrailsAggs = (filters, searchValue) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const payload = { filters, searchValue };
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/guardrails/aggregations`,
            "POST",
            payload,
            undefined,
            undefined,
            true,
            null,
        );
        let data = null;
        if (req?.ok) {
            data = await req.json();
        }
        dispatch({ type: GET_GUARDRAILS_AGGS, payload: data });
    });
};
const setGuardrailsFilters = (filters) => {
    return { type: SET_GUARDRAILS_FILTERS, payload: filters };
}

const setGuardrailRule = (rule = {}, isEditMode) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/guardrails${isEditMode ? `/${rule.id}` : ""}`,
            isEditMode ? "PATCH": "POST",
            rule,
            undefined,
            undefined,
            true,
            null,
        );
        let data = null;
        if (req?.ok) {
            data = await req.json();
            const payload = isEditMode ? rule : { ...rule, id: data?.id }
            dispatch({ type: isEditMode ? EDIT_GUARDRAIL_RULE : SET_GUARDRAILE_RULE, payload });
        }
    });
}
const deleteGuardrailRule = (ruleId) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/guardrails/${ruleId}`,
            "DELETE",
            undefined,
            undefined,
            undefined,
            true,
            null,
        );
        if (req?.ok) {
            dispatch({type: DELETE_GUARDRAIL_RULE, payload: ruleId});
        }
        return req?.ok;
    });
};

const ignoreTaskGuardrailViolations = (ignoreData = {}) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/tasks/${ignoreData.taskId}/guardrails/ignore`,
            "POST",
            ignoreData,
            undefined,
            undefined,
            true,
            null,
        );
        let data = {
            success: req?.ok,
        };
        if (req?.ok) {
            const runUrl = await req.json();
            data = { ...data, runUrl };
        }
        return data;
    });
}

const deleteGuardrailViolationIgnore = (ignoreId = "") => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/guardrails/ignores/${ignoreId}`,
            "DELETE",
            undefined,
            undefined,
            undefined,
            true,
            null,
        );
        return req?.ok;
    });
}

const listWorkspaceGuardrailsViolationsIgnores = (workspaceId = "") => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/guardrails/ignores/workspace/${workspaceId}`,
            "POST",
            undefined,
            undefined,
            undefined,
            false,
            null,
        );
        let data = null;
        if (req?.ok) {
            data = await req.json();
        }
        dispatch({ type: SET_WORKSPACE_GUARDRAILS_VIOLATIONS_IGNORES_DATA, payload: data });
    });
}

const getGuardrailsViolationsByTaskId = (taskId) => {
    const payload = { filters: { andClauses: { taskId } } };
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/guardrails/status/list`,
            "POST",
            payload,
            true,
            null,
            true,
            null,
        );
        let data = null;
        if (req?.ok) {
            data = await req.json();
        }
        return data;
    });
}

export {
    getListGuardrails,
    getGuardrailsAggs,
    setGuardrailsFilters,
    setGuardrailRule,
    deleteGuardrailRule,
    getGuardrailsViolationsByTaskId,
    ignoreTaskGuardrailViolations,
    deleteGuardrailViolationIgnore,
    listWorkspaceGuardrailsViolationsIgnores,
}