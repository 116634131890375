import React, { useEffect, useState } from "react";
import { Collapse, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ciDataSourceIcons } from "../../utils/icons";
import CiStepsTable from "./ciStepsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from "lodash/isEmpty";
import { sendEvent } from "../../utils/amplitude";
import { CiEvents } from "../../utils/amplitudeEvents";
import { ReactComponent as BlockedIcon } from "../../Images/ci/icons/blocked.svg";


import { getTasksByWorkspaceId } from "../../redux/actions/ciWorkflowsActions";
import { WORKFLOWS_STATUSES_TITLES, formatRunTimeDate, formatWorkspaceName } from "../../utils/workflowsHelper";
import WorkspaceMenu from "./workspaceMenu/workspaceMenu";
import { IAC_TYPES } from "../../consts/general";
import TagsDisplay from "./tagsDisplay/tagsDisplay";
import { setGuardrailsFilters } from "../../redux/actions/workflowsGuardrailsActions";
import { useHistory } from "react-router-dom";
import GuardrailsDisplay from "./guardrailsDisplay/guardrailsDisplay";
import { onClickAddGuardrail } from "../guardrails/guardrailsModal/guardrailsHelper";

const { Panel } = Collapse;

const CI_STATUSES_ICON = {
    FINISHED: "check-double",
    QUEUED: "spinner",
    RUNNING: "ellipsis-h",
    FAILED: "times-circle",
    planning: "ellipsis-h",
    applying: "ellipsis-h",
    "plan_success": "check-circle",
    "plan_error": "times-circle",
    "apply_error": "times-circle",
    "apply_success": "check-double",
    "init_error": "times-circle",
    "blocked": "blocked",
    "apply_no_changes": "check-double",
    "plan_no_changes": "check-circle",
}

const CI_RUNS_INTERVAL = 7000;
const CiStepsCollapse = ({ data = {}, ignoreDefaultParams, setIgnoreDefaultParams, handleOnDeleteWorkflowClick = () => null,
     handleOpenWorkflowManagement = () => null, params = {}, modalVisible, setModalVisible, setSelectedRun, setOpenDefaultPolicyCostDrawer,
     handleOnLabelsClick, handleOnGuardrailIgnoresClick }) => {
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collapseIsOpen, setCollapseIsOpen] = useState(false);
    const [runSort, setRunSort] = useState({ updatedAt: -1 });

    const workflowParam = params.get("workflow");
    const WorkflowRunPageParam = params.get("page");
    const runParam = params.get("run");
    const stepParam = params.get("step");
    const tabParam = params.get("tab");
    
    const { workspaceId = "" , workspaceName = '', lastRunStatus: status = '', runnerType = '', lastRunTime = "",
            repo: vcsRepo = '', repoUrl: vcsRepoUrl = '', vcsProvider: provider = '', vcsType, lastApplyTime = '',
            terraformVersion = '', runsCount = 0, backendSummary = {}, isWorkflowManaged, iacType = IAC_TYPES.terraform, labels = [],
        guardrails = [] } = data;

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const dispatch = useDispatch();
    const history = useHistory();

    const isDefaultWorkflowOpen = workflowParam === workspaceId && !ignoreDefaultParams;
    const ciStatusIcon = CI_STATUSES_ICON[status];
    const hasGuardrails = guardrails.length > 0;

    const vcsProvider = provider || vcsType;

    const fetchTasksByWorkspaceId = async (firstFetch = false) => {
        if (!collapseIsOpen) return;
        if (firstFetch) {
            setLoading(true);
        }
        const pageNum = parseInt(WorkflowRunPageParam) > 0  && !ignoreDefaultParams ? parseInt(WorkflowRunPageParam) : pageNumber;
        const newOffset = (pageNum - 1) * pageSize;
        const response = await dispatch(getTasksByWorkspaceId(workspaceId, { offset: newOffset > runsCount ? 0 : newOffset ,size: pageSize, sort: runSort}));
        if (isEmpty(response)) return setLoading(false);
        setTasks(response);
        setLoading(false);
    };

    useEffect(() => {
        if (isDefaultWorkflowOpen) {
            setCollapseIsOpen(true);
        }
    }, []);
    
    useEffect(() => {
        let interval;
        if (collapseIsOpen && !modalVisible) {
            fetchTasksByWorkspaceId(true);
            interval = setInterval(fetchTasksByWorkspaceId, CI_RUNS_INTERVAL);
         }
         else {
            clearInterval(interval);
         }
        return () => clearInterval(interval);
    }, [collapseIsOpen, pageNumber, pageSize, modalVisible, runSort ]);

    const onCollapseChange = (openCollapses) => {
        const isCollapseOpened = !isEmpty(openCollapses);
        setCollapseIsOpen(isCollapseOpened);
        sendEvent(CiEvents.workflowCollapseClick, { workspaceID: workspaceId ,CItool: runnerType, lastApply: lastApplyTime,
            workspaceLastStatus: status, isFireflyManaged: !!isWorkflowManaged, collapseType: isCollapseOpened ? "opened" : "closed", 
            repo: vcsRepo, vcs: vcsProvider, workspaceName });
        if (!isCollapseOpened) return;
    }

    const handleOnEditWorkflowClick = (e) => {
        e.stopPropagation();
        if (!isWorkflowManaged) return;
        handleOpenWorkflowManagement(true, workspaceId);
    }
    const onGuardrailsClick = (e) => {
        e.stopPropagation();
        if (hasGuardrails) {
            dispatch(setGuardrailsFilters({ "scope.workspaces.include": [workspaceName], "scope.repositories.include": [vcsRepo] }))
            history.push({ pathname: "workflows/guardrails" });
        } else {
            onClickAddGuardrail(e, history, workspaceName, vcsRepo);
        }
    }
    return (
        <Collapse
            className="CiStepsCollapse"
            onChange={onCollapseChange}
            activeKey={collapseIsOpen ? workspaceId : ""}
            >
            <Panel key={workspaceId} header={
                <div className="CiStepsCollapse__header">
                    <div className="row g10">
                        {runnerType && <img className="CiStepsCollapse__header-vcs-icon" src={ciDataSourceIcons(runnerType, themeDark)} alt={runnerType}/>}
                        <Tooltip title={workspaceName}>
                            <span className="CiStepsCollapse__header-vcs-text row g5">{formatWorkspaceName(workspaceName)}</span>
                        </Tooltip>
                    </div>
                <span className={`CiStepsCollapse__header__name`}>{formatRunTimeDate(lastRunTime)}</span>
                    {vcsRepo ? <span className="row CiStepsCollapse__header-vcs">
                    {vcsProvider && <img className="CiStepsCollapse__header-vcs-icon" src={ciDataSourceIcons(vcsProvider, themeDark)} alt={vcsProvider} onClick={() =>vcsRepoUrl && window.open(vcsRepoUrl, "_blank")}/>}
                        <Tooltip title={vcsRepo}>
                            <span className={`CiStepsCollapse__header-vcs-text ${vcsRepoUrl ? 'ModuleCall__select-link' : ''}`} onClick={() => vcsRepoUrl && window.open(vcsRepoUrl, "_blank")}>{vcsRepo}</span>
                        </Tooltip>
                    </span> : <span className=""></span>}
                    <div className="CiStepsCollapse__header-vcs row g5">
                        <img className="CiStepsCollapse__header-vcs-icon tf" src={ciDataSourceIcons(iacType)}/>
                        <span className="CiStepsCollapse__header-vcs-text">{terraformVersion}</span>
                    </div>
                    <div className="row CiStepsCollapse__header-status">
                        {ciStatusIcon ? ciStatusIcon === CI_STATUSES_ICON.blocked ? <BlockedIcon className={`CiStepsCollapse__header-status-icon ${status}`} />
                        : <FontAwesomeIcon className={`CiStepsCollapse__header-status-icon ${status}`} icon={["fas", ciStatusIcon]}
                         spin={status === "planning" || status === "applying"}/> : null}
                        <span className={`CiStepsCollapse__header-status-text ${status}`}>{WORKFLOWS_STATUSES_TITLES[status]}</span>
                    </div>
                    <div className= "row g10">
                        <GuardrailsDisplay guardrails={guardrails} onGuardrailsClick={onGuardrailsClick}/>
                        <TagsDisplay tags={labels} />
                        <WorkspaceMenu data={{ workspaceId, workspaceName, isWorkflowManaged, iacStackId: backendSummary.iacStackId, labels, ...data }}
                        handleOnEditWorkflowClick={handleOnEditWorkflowClick}
                        handleOnDeleteWorkflowClick={handleOnDeleteWorkflowClick}
                        onLabelsClick={handleOnLabelsClick}
                        handleOnGuardrailIgnoresClick={handleOnGuardrailIgnoresClick}/>
                    </div>
                </div>
            }
            >
            {<CiStepsTable tasks={tasks} statusTitles={WORKFLOWS_STATUSES_TITLES} workspaceName={workspaceName}
                runsCount={runsCount} loading={loading} setPageNumber={setPageNumber} setPageSize={setPageSize}
                iacStackId={backendSummary.iacStackId} defaultParams={tasks?.length > 0 && isDefaultWorkflowOpen ? { stepParam, runParam, tabParam } : {}}
                ignoreDefaultParams={ignoreDefaultParams} setIgnoreDefaultParams={setIgnoreDefaultParams}
                modalVisible={modalVisible} setModalVisible={setModalVisible} statusesIcons={CI_STATUSES_ICON} pageNumber={pageNumber} workspaceData={data}
                setOpenDefaultPolicyCostDrawer={setOpenDefaultPolicyCostDrawer} setSelectedRow={setSelectedRun}
                runSort={runSort} setRunSort={setRunSort} hasGuardrails={hasGuardrails}
                />}
            </Panel>
        </Collapse>
    )
}

export default CiStepsCollapse;