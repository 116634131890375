import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { nameWithSpaceRegex, validate } from "../../../utils/validations";

const ServiceNowCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
    const { t } = useTranslation("integrations", { keyPrefix: "servicenow" });
    const [form] = Form.useForm();
    const layout = {};

    const checkValidation = (formValues) => {
        let formValid = true;
        const { name, username, password, url } = formValues;

        if (_.isEmpty(name) || _.isEmpty(username) || _.isEmpty(password) || _.isEmpty(url)) {
            formValid = false;
        }

        if (!_.isEmpty(name) && !validate(nameWithSpaceRegex.regex, name)) {
            formValid = false;
        }
        return setSubmitDisabled(!formValid);
    };

    return (
        <div className="ServiceNowCreation col">
            <div className="ServiceNowCreation__intro col">
                <span className="dark-blue bold">Procedure</span>
                <ol className="dark-blue ServiceNowCreation__intro-orderedlist">
                    <li key="1">Create a Service Now account.</li>
                    <li key="2">Enter the Username and Password.</li>
                    <li key="3">Enter the URL.</li>
                    <li key="4">
                        Select <span className="bold">Next</span>.
                    </li>
                </ol>
            </div>
            <Form
                {...layout}
                name="serviceNow-wizard-form"
                form={form}
                initialValues={{
                    name: wizardState.name || "ServiceNow Integration",
                }}
                className="AppWizard__content-body-form"
                onValuesChange={(changedValues, allValues) => {
                    setWizardState(Object.assign(wizardState, allValues));
                    checkValidation(allValues);
                }}
            >
                <div className="serviceNowCreation__form">
                    <Form.Item
                        label={t("form.name")}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: `${t("form.name")} is required`,
                            },
                            {
                                pattern: name.regex,
                                message: name.msg,
                            },
                        ]}
                        style={{ marginBottom: "1.5rem", flexDirection: "column", width: "70%" }}
                    >
                        <Input placeholder={`Please fill ${t("form.name")}`} />
                    </Form.Item>
                    <div className="row" style={{ gap: "5px" }}>
                        <Form.Item
                            label={t("form.username")}
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: `${t("form.username")} is required`,
                                },
                            ]}
                            style={{
                                marginBottom: "1.5rem",
                                flexDirection: "column",
                                width: "70%",
                            }}
                        >
                            <Input placeholder={`Please fill ${t("form.username")}`} />
                        </Form.Item>
                    </div>
                    <div className="row" style={{ gap: "5px" }}>
                        <Form.Item
                            label={t("form.password")}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: `${t("form.password")} is required`,
                                },
                            ]}
                            style={{
                                marginBottom: "1.5rem",
                                flexDirection: "column",
                                width: "70%",
                            }}
                        >
                            <Input.Password />
                        </Form.Item>
                    </div>
                    <Form.Item
                        label={t("form.url")}
                        name="url"
                        rules={[
                            {
                                required: true,
                                message: `${t("form.url")} is required`,
                            },
                        ]}
                        style={{ marginBottom: "1.5rem", flexDirection: "column", width: "70%" }}
                    >
                        <Input placeholder={`Please fill ${t("form.url")}`} />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default ServiceNowCreation;
