import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SavingsIcon } from "../../../Images/dashboard/savings.svg";
import { ReactComponent as ClickOpsIcon } from "../../../Images/general_icons/governance/clickOps.svg";
import { ReactComponent as EOLIcon } from "../../../Images/general_icons/governance/eol.svg";
import { ReactComponent as WorkflowsIcon } from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { setInsightsFilters } from "../../../redux/actions/insightsV2Actions";
import { getAbbreviateNumber } from "../../../utils/helpers";
import { numberWithCommas } from "../../../utils/formatting";
import { useTranslation } from "react-i18next";
import {
    getClickOpsSum,
    getDriftCost,
    getEOLData,
    getWorkflowsDashboardData,
} from "../../../redux/actions/dashboardActions";
import { setDefaultInventorySorting } from "../../../redux/actions/inventoryv3Actions";
import { getTotalCost } from "../../../redux/actions/classificationsActions";
import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";
import { setFilter } from "../../../redux/actions/eventCenterActions";
import "./cloudWorkflows.scss";

const CloudWorkflows = ({ projectedSavingsFunc }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("dashboard");

    const account = useSelector((state) => state.onbordingReducer.account);
    const onlyProd = useSelector((state) => state.dashboardReducer.onlyProd);
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const totalCost = useSelector((state) => state.classificationsReducer.totalCost);
    const driftCost = useSelector((state) => state.dashboardReducer.driftCost);
    const workflowsAssetAffected = useSelector(
        (state) => state.dashboardReducer?.workflowsAssetAffected,
    );
    const insightsFilters = useSelector((state) => state.insightsV2Reducer?.filters);
    const clickOpsSum = useSelector((state) => state.dashboardReducer?.clickOpsSum);
    const eolSum = useSelector((state) => state.dashboardReducer?.eolSum);

    const costInYear = totalCost * 12;
    const savingsTotalCost =
        costInYear == 0
            ? 0
            : costInYear > 10000
            ? getAbbreviateNumber(costInYear, 1)
            : numberWithCommas(costInYear.toFixed(0));
    const driftCostTotal =
        driftCost == 0
            ? 0
            : driftCost > 10000
            ? getAbbreviateNumber(driftCost, 1)
            : numberWithCommas(driftCost.toFixed(0));

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchBlocksData();
    }, []);

    const fetchBlocksData = async () => {
        setLoading(true);
        await Promise.all([
            dispatch(getWorkflowsDashboardData()),
            dispatch(getTotalCost(onlyProd)),
            dispatch(getDriftCost()),
            dispatch(getClickOpsSum()),
            dispatch(getEOLData()),
        ]);
        setLoading(false);
    };

    const handleTotalSavingsClick = () => {
        if (loading) return;
        if (!account?.skus?.includes("CG")) {
            projectedSavingsFunc();
            return;
        }
        const payload = { ...insightsFilters, frameworks: ["Cloud Waste"] };
        dispatch(setInsightsFilters(payload));
        history.push({ pathname: `/governance` });
    };
    const handleClickOpsClick = () => {
        if (loading) return;
        dispatch(setFilter("eventType", ["click_ops"]));
        history.push({ pathname: `/event-center` });
    };

    const handleWorkflowsClick = () => {
        if (loading) return;
        history.push({ pathname: `/workflows` });
    };
    const handleCostDriftClick = async () => {
        if (loading) return;
        const sorting = [
            {
                field: "costDelta",
                sort: "desc",
            },
        ];
        await dispatch(setDefaultInventorySorting(sorting));
        history.push({ pathname: "/inventory" });
    };

    const handleEOLClick = () => {
        if (loading) return;
        const payload = { ...insightsFilters, frameworks: ["EOL"] };
        dispatch(setInsightsFilters(payload));
        history.push({ pathname: `/governance` });
    };

    const isWorkflowAffected = workflowsAssetAffected > 0;
    return (
        <div className="CloudWorkflows">
            <div className="CloudWorkflows__cloudWaste">
                <span className="muted">Cloud Waste</span>
                <div
                    className={`basic-card CloudWorkflows__card savings ${
                        !themeDark ? "light" : ""
                    } col g8 center`}
                    onClick={handleTotalSavingsClick}
                >
                    <SavingsIcon />
                    <span className="CloudWorkflows__card-cost">
                        {loading ? (
                            <SpinnerLoader customStyle={{ color: "#63B773", fontSize: "32px" }} />
                        ) : (
                            <>
                                <span className="CloudWorkflows__card-cost-sum">
                                    ${savingsTotalCost}
                                </span>
                                <span className="CloudWorkflows__card-cost-slash">/yr</span>
                            </>
                        )}
                    </span>
                    <span className="CloudWorkflows__card-title">Projected Savings</span>
                </div>
            </div>
            <div className="CloudWorkflows__costDrift">
                <span className="muted">Drift Impact</span>
                <div
                    className="basic-card CloudWorkflows__card drift col g8 center"
                    onClick={handleCostDriftClick}
                >
                    <SavingsIcon className="CloudWorkflows__card-cost-drift" />
                    <span className="CloudWorkflows__card-cost purple-text">
                        {loading ? (
                            <SpinnerLoader customStyle={{ color: "#9872FE", fontSize: "32px" }} />
                        ) : (
                            <>
                                <span className="CloudWorkflows__card-cost-sum">
                                    ${driftCostTotal}
                                </span>
                                <span className="CloudWorkflows__card-cost-slash">/yr</span>
                            </>
                        )}
                    </span>
                    <span className="CloudWorkflows__card-title">Cost Leakage</span>
                </div>
            </div>
            <div className="CloudWorkflows__clickOps col g5">
                <span className="muted">ClickOps Detection</span>
                <div
                    className="basic-card CloudWorkflows__card clickOps col g5 center"
                    onClick={handleClickOpsClick}
                >
                    <ClickOpsIcon className="CloudWorkflows__clickOps-icon" />
                    {loading ? (
                        <SpinnerLoader customStyle={{ color: "#D76089", fontSize: "32px" }} />
                    ) : (
                        <>
                            <span className="CloudWorkflows__card-cost-sum CloudWorkflows__clickOps-pink">
                                {clickOpsSum}
                            </span>
                            <span className="CloudWorkflows__card-title">ClickOps Events</span>
                            <span className="CloudWorkflows__clickOps-pink font-12">{`(last week)`}</span>
                        </>
                    )}
                </div>
            </div>
            <div>
                <span className="muted">Workflows</span>
                <div
                    className="basic-card CloudWorkflows__card workflows col g8 center"
                    onClick={handleWorkflowsClick}
                >
                    <WorkflowsIcon className="CloudWorkflows__icon" />
                    <span className="CloudWorkflows__card-cost workflows">
                        {loading ? (
                            <SpinnerLoader customStyle={{ color: "#AFA1D6", fontSize: "32px" }} />
                        ) : (
                            isWorkflowAffected && (
                                <span className="CloudWorkflows__card-cost-sum">
                                    {workflowsAssetAffected}
                                </span>
                            )
                        )}
                    </span>
                    <div className="col g5 center">
                        <span className="CloudWorkflows__card-title">
                            {isWorkflowAffected ? (
                                workflowsAssetAffected === 1 ? (
                                    t("workflowsCube.titleOne")
                                ) : (
                                    t("workflowsCube.titleMany")
                                )
                            ) : (
                                <div className="col g5">
                                    Workflows
                                    <span className="purple-flag">{t("workflowsCube.noData")}</span>
                                </div>
                            )}
                        </span>
                        {isWorkflowAffected && (
                            <span className="CloudWorkflows__card-cost workflows font-12">
                                {t("workflowsCube.time")}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="CloudWorkflows__eol col g5">
                <span className="muted">EOL & Service Lifecycle</span>
                <div
                    className="basic-card CloudWorkflows__card eol col g5 center"
                    onClick={handleEOLClick}
                >
                    <EOLIcon className="CloudWorkflows__eol-icon" />
                    {loading ? (
                        <SpinnerLoader customStyle={{ color: "#578ac8", fontSize: "32px" }} />
                    ) : (
                        <>
                            <span className="CloudWorkflows__card-cost-sum CloudWorkflows__eol eol-text">
                                {eolSum}
                            </span>
                            <span className="CloudWorkflows__card-title">Outdated assets</span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CloudWorkflows;
