import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clouds } from "../../../utils/icons";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";
import _ from "lodash";
import { getControllerSources, getIac, getIacStack } from "../../../redux/actions/iacStacksActions";
import { getInventoryResource } from "../../../redux/actions/inventoryActions";
import { resetHistoryState, getDeletedConfig } from "../../../redux/actions/historyActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import whiteGcp from "../../../Images/clouds/whiteGcp.svg"
import whiteAzure from "../../../Images/clouds/whiteAzure.svg"
import whiteAws from "../../../Images/clouds/whiteAws.svg"
import { ReactComponent as CloudMigrationWhiteIcon } from "../../../Images/clouds/cloud_migration_white.svg"
import { ReactComponent as IacIgnoredIcon } from "../../../Images/states/iacIgnored.svg";

import { searchInventory } from "../../../redux/actions/inventoryv3Actions";

import NewAppModal from "../../../shared/newAppModal/newAppModal";
import CodifyButton from "./codifyButton/codifyButton";
import AssetTabs from "./assetTabs/assetTabs";
import AssetComments from "./assetComments/assetComments";
import Configuration from "./assetConfig/configuration";
import AppBtn from "../../../shared/appBtn/appBtn";
import AssetInfo from "./assetInfo/assetInfo";
import {TABS_KEYS, PROVIDERS_SUPPORT_EVENT_VIEWER, PROVIDERS, CLOUD_PROVIDERS, RESOURCE_STATE_TYPES, IAC_TYPES, CODIFY_TYPES} from "../../../consts/general";
import EventsViewer from "./eventsViewer/eventsViewer";
import { getAssetEventsCount, resetAssetEvents } from "../../../redux/actions/assetEventsActions";
import AssetRelationship from "./assetRelationship/assetRelationship";
import Loading from "../../../shared/loading/loading";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { appToast } from "../../../shared/appToast/appToast";
import IacStatusFlag from "../../../shared/iacStatusFlag/iacStatusFlag";
import { getInventoryAmpliData } from "../inventoryTable/inventoryHelper";

import AssetHistoryV2 from "./assetHistory/assetHistoryV2";
import "./assetDataModal.scss";
import ConfirmationModal from "../../../shared/confirmationModal/confirmationModal";
import { createExclusion } from "../../../redux/actions/exclusionsActions";
import { useAuth0 } from "@auth0/auth0-react";
import Base64 from "../../../utils/base64";
import { useTranslation } from "react-i18next";
import { setRevisionId } from "../../../redux/actions/historyActionsV2";
import Diagram from "../../iacStacks/diagramModal/diagram";

const AssetDataModal = ({
  visible,
  handleClose,
  data,
  handleRemoveAsset,
  handleCodifyClick,
  handleCreateJiraIssue,
  handleOpenAnalysis,
  onClickTag,
  onParentClick,
  onClickResourceGroup,
  defaultTab,
  parentData,
  innerSectionTab,
  handleAssetModalFromChildren,
  handleNextAsset,
  nextAssetDisabled,
  handlePrevAsset,
  prevAssetDisabled,
  fetchingAssetLoading,
  handleChangeTab,
  handleResetParentTab,
  pageAssetHistory,
  setPageAssetHistory,
  skipAssetHistory,
  setSkipAssetHistory,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { t } = useTranslation("inventory", { keyPrefix: "assetDataModal" });
  const [activeTab, setActiveTab] = useState("general");
  const [infoActiveTab, setInfoActiveTab] = useState("info");
  const [assetData, setAssetData] = useState({});
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingIac, setLoadingIac] = useState(false);
  const [cftStacksData, setCftStacksData] = useState([]);
  const [stacksData, setStacksData] = useState([]);
  const [childParentData, setChildParentData] = useState([]);
  const [loadingEventsCount, setLoadingEventsCount] = useState(false);
  const [showIacIgnoredAddModal, setShowIacIgnoredAddModal] = useState(false);
  const [loadingIacIgnoreAdd, setLoadingIacIgnoreAdd] = useState(false);

  const requestedRevisionId = useSelector((state) => state.historyReducerV2.requestedRevisionId);
  const isRemidiateButton = data?.state === RESOURCE_STATE_TYPES.ghost && data?.iacType === CODIFY_TYPES.terraform ||
      data?.iacType === CODIFY_TYPES.cloudformation;
  const assetType = data?.assetType;

  useEffect(() => {
    if (visible && data) {
      setActiveTab(defaultTab);
      setInfoActiveTab(innerSectionTab);
      setAssetData(data);
      handleEventsCount(data);
      fetchControllerSources(data);
      handleFetchConfiguration();
      handleFetchIac();
      handleFetchParentAsset(data);
    }
  }, [data, visible]);

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const handleEventsCount = (data) => {
    if (PROVIDERS_SUPPORT_EVENT_VIEWER.includes(data.provider)) {
      setLoadingEventsCount(true)
      dispatch(getAssetEventsCount(data))
      setTimeout(() => setLoadingEventsCount(false), 1000)
    }
  }
  const handleCloseModal = () => {
    setCftStacksData([]);
    setStacksData([]);
    dispatch(resetHistoryState());
    dispatch(resetAssetEvents())
    dispatch(setRevisionId(null));
    return handleClose();
  };

  const handleFetchParentAsset = async (data) => {

    if(data?.state === RESOURCE_STATE_TYPES.child && data?.ownerSourcesList?.length){
        const frns = []
        const integrationIds = []
        for (const ffrn of data.ownerSourcesList) {
            const splittedFfrn = ffrn.split(":")
            if(splittedFfrn.length < 2){
                continue;
            }
            const integrationId = splittedFfrn[0];
            const source = splittedFfrn.slice(1).join(':');
            integrationIds.push(integrationId);
            frns.push(source);
        }
        const pageNumber = 1
        const pageSize = data?.ownerSourcesList?.length

        if (!frns.length) return;
        const results = await searchInventory(
          {
            frns: frns,
            integrationIds: integrationIds
          },
          pageNumber,
          pageSize
        );

        if (results?.responseObjects?.length) {
            const parentData = [];
            for (const asset of results?.responseObjects) {
                if (asset?.name) {
                  parentData.push({
                        key: asset?.frn,
                        value: asset?.name,
                        assetType: asset?.assetType,
                        hasOwnerTargets: asset?.hasOwnerTargets,
                    });
                }
            }
            setChildParentData(parentData)
        }

    }
  }

  const fetchControllerSources = async (data = {}) => {
    const { frn: targetFrn, integrationId: targetIntegrationId, hasControllerSources } = data;
    if (!hasControllerSources) return;
    const payload = {
        targetFrn,
        targetIntegrationId,
    }
    await dispatch(getControllerSources(payload))
  }

  const handleFetchConfiguration = async () => {
    if (!_.isEmpty(data?.inventoryItem)) {
      return setLoadingConfig(false);
    }
    setLoadingConfig(true);
    let newAsset = data;

    if(data?.state === RESOURCE_STATE_TYPES.deleted) {
      if(_.isEmpty(data?.metadata) || data?.metadata === RESOURCE_STATE_TYPES.deleted) {
      const deletedConfig = await dispatch(getDeletedConfig(data?.frn, assetType))
      newAsset.metadata = deletedConfig;
    }
    setAssetData(newAsset);
    setLoadingConfig(false);
    return;
   } 

    const providerIdKey = newAsset?.state === RESOURCE_STATE_TYPES.ghost ? "stackId" : null;
    const shouldGetGhostData = [RESOURCE_STATE_TYPES.ghost, RESOURCE_STATE_TYPES.undetermined].includes(newAsset?.state) ||
    (newAsset?.state === RESOURCE_STATE_TYPES.pending && newAsset?.pendingTo === RESOURCE_STATE_TYPES.ghost);
    let inventory = await dispatch(
      shouldGetGhostData ? getIac(newAsset?.frn)
        : getInventoryResource(
            [newAsset?.arn],
            [newAsset?.assetType],
            "inv",
            null,
            null,
            true
          ),
      providerIdKey,
      newAsset?.stackId
    );
    inventory =
    shouldGetGhostData
        ? inventory
        : _.first(inventory?.responseObjects || []);
    newAsset.inventoryItem =
      (shouldGetGhostData
        ? inventory?.attributes
        : inventory?.inventoryItem) || {};
    newAsset.originalProviderId = inventory?.providerId;
  
    setAssetData(newAsset);
    setLoadingConfig(false);
  };

  const handleFetchIac = async () => {
    setLoadingIac(true);

    if (!_.isEmpty(data?.stackIds)) {
      let stacksData = [];
      for (const stackId of data?.stackIds) {
        const stack = await dispatch(getIacStack(stackId));
        if (!_.isEmpty(stack)) {
          stacksData.push(stack);
        }
      }
      setStacksData(stacksData);
    } else {
      if (!_.isEmpty(data?.stackId) && data?.stackId !== "unassigned") {
        const stack = await dispatch(getIacStack(data?.stackId));
        if (!_.isEmpty(stack)) {
          setStacksData([stack]);
        }
      } else {
        if (!_.isEmpty(data?.cftStackIds)) {
          let cftStacksData = [];
          for (const stackId of data?.cftStackIds) {
            const stack = await dispatch(getIacStack(stackId));
            if (!_.isEmpty(stack)) {
              cftStacksData.push(stack);
            }
          }
          setCftStacksData(cftStacksData);
        } else {
          if (
            !_.isEmpty(data?.cftStackId) &&
            data?.cftStackId !== "unassigned"
          ) {
            const stack = await dispatch(getIacStack(data?.cftStackId));
            if (!_.isEmpty(stack)) {
              setCftStacksData([stack]);
            }
          }
        }
      }
    }
    setLoadingIac(false);
  };

  const handleResetDefaultTab = () => {
    setActiveTab(TABS_KEYS.general);
    handleResetParentTab()
  }
  const sendDriftClickedEvent = () => {
    sendEvent(inventoryEvents.clickedDriftDetails, {
      ...getInventoryAmpliData(assetData),
      accessedVia: 'fix drift button'
    });
  };

  const renderTabsContent = () => {
    switch (activeTab) {
      case TABS_KEYS.general:
        return (
          <AssetInfo
            asset={assetData}
            onClickTag={onClickTag}
            onParentClick={onParentClick}
            onClickResourceGroup={onClickResourceGroup}
            loadingIac={loadingIac}
            stacksData={stacksData}
            cftStacksData={cftStacksData}
            handleOpenAnalysis={() => {
              handleOpenAnalysis(
                !_.isEmpty(parentData) && data?.isChild ? parentData : data,
                false,
                stacksData || cftStacksData,
              );
              sendDriftClickedEvent();
            }}
            parentData={parentData}
            childParentData={childParentData}
            infoActiveTab={infoActiveTab}
            handleAssetModalFromChildren={handleAssetModalFromChildren}
            handleClose={handleClose}
          />
        );
      case TABS_KEYS.config:
        return (
          <Configuration
            item={assetData?.state === RESOURCE_STATE_TYPES.deleted ? assetData?.metadata : assetData?.inventoryItem}
            loading={loadingConfig}
          />
        );
      case TABS_KEYS.history:
        return <AssetHistoryV2 
        asset={assetData}
        pageAssetHistory={pageAssetHistory}
        setPageAssetHistory={setPageAssetHistory}
        skipAssetHistory={skipAssetHistory}
        setSkipAssetHistory={setSkipAssetHistory}
         />;
      case TABS_KEYS.comments:
        return (
          <AssetComments
            asset={assetData}
            setNewCount={(comments) => setAssetData({ ...assetData, comments })}
          />
        );
      case TABS_KEYS.eventViewer: 
        return <EventsViewer asset={assetData} />
      case TABS_KEYS.relationship: 
        return <AssetRelationship asset={assetData} />;
      case TABS_KEYS.diagram:
        return <Diagram stacksData={stacksData?.[0]} />;
      default:
        return (
          <AssetInfo
            asset={assetData}
            onClickTag={onClickTag}
            loadingIac={loadingIac}
            stacksData={stacksData}
            cftStacksData={cftStacksData}
            handleOpenAnalysis={() => {
              sendDriftClickedEvent();
              handleOpenAnalysis(
                !_.isEmpty(parentData) && data?.isChild ? parentData : data,
                false,
                stacksData || cftStacksData
              );
            }}
            parentData={parentData}
            infoActiveTab={infoActiveTab}
            handleAssetModalFromChildren={handleAssetModalFromChildren}
          />
        );
    }
  };

  const handleCreateIacIgnoredRule = async () => {
    setLoadingIacIgnoreAdd(true);
    const formattedAssetType = formatAwsStringWithUnderscore(assetType, true);
    const response = await dispatch(createExclusion(formattedAssetType, `IaC Ignore all ${formattedAssetType} resources`,
    Base64.encode("firefly = true"), [assetType], [], [] , user?.name));
    appToast(response?.ok ? "success" : "error",  response?.ok ? t("toasts.ignoreSuccess") : "", response?.ok ? t("toasts.ignoreSuccessSubTitle") : t("toasts.ignoreError"));
    setLoadingIacIgnoreAdd(false);
    setShowIacIgnoredAddModal(false);
  }

  const getJumpToConsoleProviderIcon = () =>{
    switch(data.provider){
      case CLOUD_PROVIDERS.aws:
        return <img src={whiteAws} alt="Gcp icon" width="22px"/>;
      case CLOUD_PROVIDERS.gcp:
        return <img src={whiteGcp} alt="Gcp icon" width="16px"/>;
      case CLOUD_PROVIDERS.azure:
      case CLOUD_PROVIDERS.azurerm:
          return <img src={whiteAzure} alt="Azure icon" width="16px"/>
      default:
        return <FontAwesomeIcon icon="external-link-alt" />;

    }
  }

  const renderFooter = () => {
    const isHistoryTab = activeTab === TABS_KEYS.history;
    return (
      <div className="row between">
        <div className="row" style={{ gap: "10px" }}>
          {(isRemidiateButton ||
            data?.state === RESOURCE_STATE_TYPES.modified ||
            (!_.isEmpty(parentData) &&
              data?.isChild &&
              parentData?.state === "modified")) && (
            <AppBtn
              text={t("buttons.driftDetails")}
              onClick={() => {
                handleOpenAnalysis(
                  !_.isEmpty(parentData) && data?.isChild ? parentData : data,
                  false,
                  stacksData || cftStacksData
                );
                sendDriftClickedEvent();
              }}
              disabled={data?.isLocked}
              icon={<FontAwesomeIcon icon="code-branch" />}
            />
          )}
          {
            <CodifyButton
              text={t("buttons.codify")}
              data={data}
              handleCodifyClick={handleCodifyClick}
              parentData={parentData}
              disabled={isHistoryTab}
              icon={<span>{`{ }`}</span>}
            />
          }
          {_.includes([PROVIDERS.aws, PROVIDERS.gcp, PROVIDERS.azurerm], data?.provider) && (
            <CodifyButton
                text={t("buttons.migrate")}
                data={{
                  isCloudMigration: true,
                  ...data
                }}
                handleCodifyClick={handleCodifyClick}
                parentData={parentData}
                disabled={isHistoryTab}
                icon={<CloudMigrationWhiteIcon className="AssetDataModal__footer-action-migrateIcon"/>}
            />
          )}
          {isHistoryTab && (            
            <CodifyButton
              text="Codify Revision"
              data={{
                revisionId: requestedRevisionId,
                ...assetData,
              }}
              handleCodifyClick={handleCodifyClick}
              disabled={
                data?.isLocked || !requestedRevisionId
              }
              icon={<span>{`{ }`}</span>}
            />
          )}
        </div>
        <div
          className="row AssetDataModal__footer-action-right"
          style={{ gap: "10px" }}
        >
          {data?.state === RESOURCE_STATE_TYPES.unmanaged && 
            <AppBtn
              text={t("buttons.addIacIgnore")}
              icon={<IacIgnoredIcon className="AssetDataModal__footer-action-addIacIgnored" />}
              onClick={() => setShowIacIgnoredAddModal(true)}
            />
          }
          <AppBtn
            icon={<FontAwesomeIcon icon={faShareAlt} />}
            tooltipText={t("buttons.shareAsset")}
            onClick={() => {
              const assetId = data?.id || `${data?.integrationId}-${assetType}-${data?.frn}`;
              navigator.clipboard.writeText(`${window.location.origin}/inventory?asset=${assetId}`);
              appToast(
                "success",
                "Sharing is Caring!",
                "Shareable Link copied to clipboard"
              )
            }}

          />
          <AppBtn
            tooltipText={t("buttons.deleteAsset")}
            onClick={() => {
              sendEvent(inventoryEvents.removeAssetButton, {
                action: "user clicked delete asset from asset info modal",
              });
              handleRemoveAsset(data);
            }}
            disabled={isViewer || !(data?.deleteCommand ||
              ([RESOURCE_STATE_TYPES.ghost, RESOURCE_STATE_TYPES.managed, RESOURCE_STATE_TYPES.modified, RESOURCE_STATE_TYPES.undetermined].includes(data?.state) 
              && data?.iacType === IAC_TYPES.terraform ))}
            icon={<FontAwesomeIcon icon="trash" />}
          />
          <AppBtn
            onClick={() => {
              sendEvent(inventoryEvents.createIssue, {
                action: "user clicked Create Jira Issue from asset info modal",
              });
              handleCreateJiraIssue(data);
            }}
            disabled={data?.isLocked}
            icon={<FontAwesomeIcon icon={["fab", "jira"]} />}
          />
          <AppBtn
            tooltipText={t("buttons.jumpTo", {location: "Code"})}
            onClick={() => {
              sendEvent(inventoryEvents.jumpToCode, {
                action: "user clicked jump to code from asset info modal",
              });
              return window.open(data?.vcsCodeLink, "_blank");
            }}
            disabled={!data?.vcsCodeLink || _.isEmpty(data?.vcsCodeLink)}
            icon={
              <FontAwesomeIcon
                icon={["fab", "git-alt"]}
                style={{ fontSize: "17px", transform: "translateY(2px)" }}
              />
            }
          />
          {data?.consoleURL && !_.isEmpty(data?.consoleURL) && (
            <AppBtn
            tooltipText={t("buttons.jumpTo", {location: "Console"})}
              onClick={() => {
                sendEvent(inventoryEvents.jumpToConsole, {
                  action: "user clicked jump to console from asset info modal",
                });
                return window.open(data?.consoleURL, "_blank");
              }}
              disabled={false}
              icon={getJumpToConsoleProviderIcon()}
              />
            )}
        </div>
      </div>
    );
  };
  const renderHeaderSuffix = () => {
    return <div className="AssetDataModal_suffix_actions">
    <AppBtn
      text="Prev"
      tooltipText="Previous Asset"
      onClick={handlePrevAsset}
      disabled={prevAssetDisabled}
      icon={<FontAwesomeIcon icon="arrow-left" />} />
    <span className="vertical-line"></span>
    <AppBtn
      text="Next"
      tooltipText="Next Asset"
      onClick={handleNextAsset}
      disabled={nextAssetDisabled}
      suffixIcon={<FontAwesomeIcon icon="arrow-right" />} />
  </div>
  };

 if (fetchingAssetLoading)
   return (
     <NewAppModal visible={true} centered>
       <Loading />
     </NewAppModal>
   );
  
  const renderAssetTitle = () => {
    return <div className="AssetDataModal_title">
      <span>{data?.name}</span>
      <IacStatusFlag
        state={!_.isEmpty(parentData) && assetData?.isChild ? parentData?.state : assetData?.state}
        iacType={!_.isEmpty(parentData) && assetData?.isChild ? parentData?.iacType : assetData?.iacType}
        hasControllerSources={!_.isEmpty(parentData) && assetData?.hasControllerSources ? parentData?.hasControllerSources : assetData?.hasControllerSources}
        rowData={assetData}
        handleOpenMulti={() => false}
        isLocked={false} />
    </div>;
  };
  
  return (
   <>
    <NewAppModal
      visible={visible}
      handleClose={handleCloseModal}
      destroyOnClose
      centered
      width={"85vw"}
      bodyClassName="AssetDataModal"
      title={renderAssetTitle() }
      subtitle={formatAwsStringWithUnderscore(assetType, true)}
      iconSrc={clouds(data?.provider, themeDark)}
      footer={renderFooter()}
      headerSuffix={renderHeaderSuffix()}
    >
      <AssetTabs
        activeTab={activeTab}
        handleSetTab={(tab) => {
          setActiveTab(tab);
          handleChangeTab(tab)
        }
      }
        assetData={assetData}
        loadingEvents={loadingEventsCount}
        handleResetTab={handleResetDefaultTab}
      />
      <div className="AssetDataModal__tab">{renderTabsContent()}</div>
    </NewAppModal>
     {showIacIgnoredAddModal && <ConfirmationModal visible={showIacIgnoredAddModal} handleClose={() => setShowIacIgnoredAddModal(false)}  title={<div className="row g10"><IacIgnoredIcon/> {t("iacIgnoreModalTitle")}</div>} 
     description={t("descriptions.iacIgnore", { assetType: formatAwsStringWithUnderscore(assetType, true)})} confirmBtnText="Create" noDelete onConfirm={handleCreateIacIgnoredRule} loadingConfirm={loadingIacIgnoreAdd} />}
    </>
  );
};

export default AssetDataModal;
