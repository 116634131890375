import "./assetTypeCell.scss";
import { formatAwsStringWithUnderscore } from "../../../../utils/formatting";
import AssetTypeImage from "../../../../shared/assetTypeImage/assetTypeImage";

const AssetTypeCell = ({ assetType }) => {
    const assetFormattedName = formatAwsStringWithUnderscore(assetType, true);
    return (
        <div className="AssetTypeCell">
            <AssetTypeImage
                assetType={assetType}
                customStyle={{ height: "100%", maxHeight: "23px", borderRadius: "4px" }}
            />
            {assetFormattedName}
        </div>
    );
};

export default AssetTypeCell;
