import React from "react";
import CodificationFilterBtnGroup from "../../../shared/codificationFilterBtnGroup/codificationFilterBtnGroup";

import "./statusFilters.scss";
import { RESOURCE_STATE_TYPES } from "../../../consts/general";

const StatusFilters = ({
  handleSetFilterManaged,
  filterManaged,
  hideManagedBtn,
  unmanagedSum,
  managedSum,
  modifiedSum,
  ghostSum,
}) => {
  return (
    <div className="no-padding-card StatusFilters col">
      <CodificationFilterBtnGroup
        text="Drifted"
        description="Drifted assets are assets that exist both in an available IaC state file and in an integrated provider, with differences in some properties and their values."
        active={filterManaged?.includes(RESOURCE_STATE_TYPES.modified) || filterManaged?.includes(RESOURCE_STATE_TYPES.ghost)}
        onClick={() => handleSetFilterManaged(RESOURCE_STATE_TYPES.modified, true)}
        type="modified"
        sum={modifiedSum + (ghostSum || 0)}
      />
      <CodificationFilterBtnGroup
        text="Unmanaged"
        description="Unmanaged assets are assets that exist in an integrated provider, but not in an available IaC state file"
        active={filterManaged?.includes("unmanaged")}
        onClick={() => handleSetFilterManaged("unmanaged")}
        type="unmanaged"
        sum={unmanagedSum}
      />
      {/* <CodificationFilterBtnGroup
            text="Ghost"
            description="Ghost assets are assets that exist in an available IaC state file but don’t exist in any integrated provider (e.g., an AWS account)"
            active={filterManaged?.includes("ghost")}
            onClick={() => handleSetFilterManaged("ghost")}
            type="ghost"
            sum={ghostSum}
        /> */}
      {!hideManagedBtn && (
        <CodificationFilterBtnGroup
          text="Codified"
          description="Codified assets are assets that exist both in an available IaC state file and in an integrated provider, with identical properties and values"
          active={filterManaged?.includes("managed")}
          onClick={() => handleSetFilterManaged("managed")}
          type="managed"
          sum={managedSum}
        />
      )}
    </div>
  );
};

export default StatusFilters;
