import React, { useState, useEffect } from "react";
import {
    DataGridPro,
    GridOverlay,
    GridFooter,
    useGridApiContext,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
} from "@mui/x-data-grid-pro";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LicenseInfo } from "@mui/x-license-pro";
import map from "lodash/map";
import toLower from "lodash/toLower";
import AppEmpty from "../appEmpty/appEmpty";
import ViewSelectedBox from "./viewSelectedBox";
import { CaretDownOutlined } from "@ant-design/icons";
import AssetTypeCell from "../../components/inventory/inventoryTable/assetTypeCell";
import { MUI_X_LICENSE_KEY } from "../../consts/config";
import TableContextMenu from "./components/tableContextMenu/TableContextMenu";

import "./tableWrapperV2.scss";
import { useSelector } from "react-redux";

const TableWrapperV2 = ({
    apiRef,
    height,
    rowKey,
    tableData,
    columns,
    loading,
    isCheckBox,
    disableSelectionOnClick,
    pageSize,
    serverSide,
    handleFilterChange,
    handleSortChange,
    rowCount,
    handleSelectedRowsArr,
    handlePageChange,
    movePagination,
    refresh,
    onRowClick,
    insights,
    selectedInsight,
    onPageSizeChange,
    getDetailPanelContent,
    getDetailPanelHeight,
    autoHeight,
    treeData,
    getTreeDataPath,
    disablePagination,
    hideRowCount,
    biggerRows,
    iacTable,
    initialSortingField,
    rowsPerPageOptions,
    keepSelectedRows,
    selectedRows,
    handleToggleSelectedView,
    selectedAssetsView,
    treeColumnTitle,
    externalPage,
    ColumnMenu,
    columnVisibilityModel,
    onColumnVisibilityModelChange,
    rowBuffer = 2,
    screenType = "",
    ...props
}) => {
    const [page, setPage] = React.useState(0);
    const [selectionModel, setSelectionModel] = useState([]);
    const [initialExpansionDone, setInitialExpansionDone] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);

    // holding the columns in a state allows the save width a location when table re-renders
    const [tableColumns, setTableColumns] = useState(columns);

    const isDarkTheme = useSelector((state) => state.userPreferencesReducer.themeDark);

    useEffect(() => {
        handleSetSelectionArr();
    }, [selectedRows]);

    useEffect(() => {
        if (refresh) {
            setTableColumns(columns);
        }
    }, [refresh]);

    const customPage = externalPage ?? page;

    // activate the library license
    LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

    // set an array of ids for the table to handle selected items
    const handleSetSelectionArr = () => {
        const idsArr = map(selectedRows || [], (i) => i[rowKey]);
        return setSelectionModel(idsArr);
    };

    // custom spinner for a loading state
    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div className="TableWrapperV2__loader">
                    <SkeletonTheme
                        baseColor={`${isDarkTheme ? "#151526" : "#e0e0e0"}`}
                        highlightColor="#444"
                        height={40}
                        borderRadius={40}
                    >
                        <p>
                            <Skeleton count={25} />
                        </p>
                    </SkeletonTheme>
                </div>
            </GridOverlay>
        );
    }

    // custom no data component
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div className="TableWrapperV2__empty center">
                    <AppEmpty text="No Data" />
                </div>
            </GridOverlay>
        );
    }

    // custom no results after a filter component
    function CustomNoResultsOverlay() {
        return (
            <GridOverlay className="center">
                <AppEmpty customStyle="results" />
            </GridOverlay>
        );
    }

    function CustomGridTreeDataGroupingCell(props) {
        const { id, field, rowNode, row } = props;
        const apiRef = useGridApiContext();
        const filteredDescendantCountLookup = useGridSelector(
            apiRef,
            gridFilteredDescendantCountLookupSelector,
        );
        const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

        const handleClick = (event) => {
            apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
            apiRef.current.setCellFocus(id, field);
            event.stopPropagation();
        };

        // open all rows at start
        if (rowNode.children && !initialExpansionDone.includes(id)) {
            apiRef.current.setRowChildrenExpansion(id, true);
            setInitialExpansionDone([...initialExpansionDone, id]);
        }

        return (
            <div style={{ marginLeft: rowNode.depth * 12 }}>
                <div
                    onClick={
                        filteredDescendantCount > 0
                            ? handleClick
                            : () => {
                                  return;
                              }
                    }
                    tabIndex={-1}
                    size="small"
                    className="row g5"
                >
                    <CaretDownOutlined
                        style={{
                            transform: rowNode?.childrenExpanded ? "" : "rotate(-90deg)",
                            opacity: filteredDescendantCount > 0 ? 1 : 0,
                        }}
                    />
                    <AssetTypeCell row={row} showAssetTypeIcon />
                    {filteredDescendantCount > 0 && <span>{`(${filteredDescendantCount})`}</span>}
                </div>
            </div>
        );
    }

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handleContextMenu = (event, rowData = null) => {
        if (screenType !== "inventory") {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (rowData) {
            const id = event.currentTarget.getAttribute("data-id");
            const row = tableData.find((r) => r[rowKey] === id);
            setContextMenu({
                mouseX: event.clientX,
                mouseY: event.clientY,
                row: row,
            });
        } else {
            setContextMenu({
                mouseX: event.clientX,
                mouseY: event.clientY,
                row: null,
            });
        }
    };

    const groupingColDef = {
        headerName: treeColumnTitle,
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
    };

    return (
        <div
            style={{ height, width: "100%" }}
            className={`TableWrapperV2 ${movePagination ? "movePagination" : ""}`}
            onContextMenu={handleContextMenu}
        >
            <DataGridPro
                disableColumnPinning
                rowBuffer={rowBuffer}
                onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                columnVisibilityModel={columnVisibilityModel}
                rows={tableData}
                columns={tableColumns}
                loading={loading}
                components={{
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    NoResultsOverlay: CustomNoResultsOverlay,
                    Footer: () => (
                        <div className="row between" style={{ width: "100%" }}>
                            <ViewSelectedBox
                                onClick={handleToggleSelectedView}
                                selectedArr={selectionModel}
                                active={selectedAssetsView}
                            />
                            <GridFooter />
                        </div>
                    ),
                    ...(ColumnMenu && { ColumnMenu }),
                }}
                componentsProps={{
                    row: {
                        onContextMenu: (event) => {
                            if (screenType === "inventory") {
                                event.preventDefault();
                                handleContextMenu(event, true);
                            }
                        },
                    },
                }}
                checkboxSelection={isCheckBox}
                getRowClassName={(params) =>
                    insights
                        ? `TableWrapperV2__table-row ${
                              params.row?.isNew ? `new ${toLower(selectedInsight?.labels[0])}` : ""
                          }`
                        : ""
                }
                headerHeight={36}
                rowHeight={32}
                className={`TableWrapperV2__table ${biggerRows ? "bigger" : ""} ${
                    iacTable ? "iacTable" : ""
                } ${onRowClick ? "selection-sign" : ""}`}
                disableSelectionOnClick={disableSelectionOnClick}
                filterMode={!serverSide ? "client" : "server"}
                paginationMode={!serverSide ? "client" : "server"}
                sortingMode={!serverSide ? "client" : "server"}
                onFilterModelChange={handleFilterChange}
                onSortModelChange={handleSortChange}
                // pagination
                pagination={disablePagination ? false : true}
                page={customPage}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [50, 100, 150]}
                onPageSizeChange={onPageSizeChange}
                rowCount={rowCount}
                onPageChange={(newPage) => {
                    setPage(newPage);
                    if (serverSide) {
                        handlePageChange(newPage + 1);
                    }
                }}
                onSelectionModelChange={(ids) => handleSelectedRowsArr(ids, true)}
                selectionModel={selectionModel}
                onRowClick={onRowClick}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                autoHeight={autoHeight}
                hideFooterRowCount={hideRowCount}
                initialState={
                    initialSortingField
                        ? {
                              sorting: {
                                  sortModel: [{ field: initialSortingField, sort: "desc" }],
                              },
                          }
                        : null
                }
                keepNonExistentRowsSelected={keepSelectedRows ? keepSelectedRows : false}
                treeData={treeData}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                {...props}
            />
            <TableContextMenu
                contextMenu={contextMenu}
                handleCloseContextMenu={handleCloseContextMenu}
                isDarkTheme={isDarkTheme}
            />
        </div>
    );
};

export default TableWrapperV2;
