import React, { useState, useMemo, useCallback } from "react";
import _ from "lodash";
import { Checkbox, Spin } from "antd";
import { Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import "./facetContent/facetContent.scss";
import SearchBox from "../../../../shared/searchBox/searchBox";
import FacetContent from "./facetContent/facetContent";
import { formatAwsStringWithUnderscore } from "../../../../utils/formatting";
import { eventCenterEvents } from "../../../../utils/amplitudeEvents";
import { sendEvent } from "../../../../utils/amplitude";

const FacetContainer = ({
    facetKey,
    allTypes = [],
    selectedTypes,
    checkedList = [],
    onLoadMore,
    isNeedToLoadMore,
    facetLoading,
    onSearchFacets,
    allowSearch = true,
}) => {
    const CheckboxGroup = Checkbox.Group;
    const isDynmaicSearch = true;
    const [searchVal, setSearchVal] = useState("");

    const handleSearch = async (searchVal) => {
        sendEvent(eventCenterEvents.eventSearchFilter, { title: searchVal });
        onSearchFacets(facetKey, searchVal);
    };

    const handleScroll = useCallback(
        async (event) => {
            const { scrollTop, clientHeight, scrollHeight } = event.target;
            if (
                scrollTop + clientHeight >= scrollHeight - 10 &&
                isNeedToLoadMore &&
                !facetLoading
            ) {
                await onLoadMore(facetKey);
            }
        },
        [onLoadMore],
    );

    const filteredTypes = useMemo(() => {
        if (!_.isEmpty(allTypes)) {
            let filteredTypes = allTypes;
            if (searchVal && !isDynmaicSearch) {
                filteredTypes = _.filter(
                    filteredTypes,
                    (item) =>
                        (item.formattedName || "")
                            .toLowerCase()
                            .includes(searchVal.toLowerCase()) || checkedList?.includes(item?.name),
                );
            }
            return filteredTypes;
        }
    }, [searchVal, selectedTypes, allTypes]);

    const renderTypes = () => {
        if (!_.isEmpty(allTypes)) {
            return _.map(filteredTypes, (item, idx) => {
                let formatName = item?.value;
                switch (facetKey) {
                    case "assetType":
                        formatName = formatAwsStringWithUnderscore(item?.value, false);
                        break;
                    case "dataSource":
                        formatName = item?.name;
                        break;
                    default:
                        break;
                }

                return (
                    <FacetContent
                        key={uuidv4()}
                        name={item?.value}
                        count={item?.count}
                        isProdFlag={item?.isProdFlag}
                        element={item?.element}
                        formattedName={formatName}
                        checkedList={checkedList}
                        provider={item?.provider}
                        tooltip={item?.tooltip}
                    />
                );
            });
        }
    };

    const onChange = (list) => {
        sendEvent(eventCenterEvents.eventFacetFilter, { title: facetKey });
        selectedTypes(facetKey, list);
    };

    if (_.isEmpty(allTypes) && !isDynmaicSearch) {
        return (
            <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
            </div>
        );
    }
    return (
        <div className="SingelFilter">
            {allowSearch && (
                <div className="SingelFilter__title row between">
                    <SearchBox
                        placeholder={"Search"}
                        value={(val) => handleSearch(val)}
                        resetSearch={(val) => setSearchVal(val)}
                        grayMode
                        noBorder
                    />
                </div>
            )}

            <div className="SingelFilter__types col">
                {_.isEmpty(filteredTypes) && !_.isEmpty(searchVal) ? (
                    <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Results" />
                    </div>
                ) : (
                    <div className="col g5">
                        <div className="SingelFilter__types__container" onScroll={handleScroll}>
                            <CheckboxGroup value={checkedList} onChange={onChange}>
                                {renderTypes()}
                                {isDynmaicSearch && facetLoading ? (
                                    <Spin indicator={<LoadingOutlined />} />
                                ) : (
                                    ""
                                )}
                            </CheckboxGroup>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FacetContainer;
