import React from "react";
import setIn from "lodash/set";
import { guardrailsEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IacExplorerIcon } from "../../../Images/general_icons/yak.svg";
import { ReactComponent as Tags } from "../../../Images/general_icons/tags.svg";
import { ReactComponent as GuardrailsIcon } from "../../../Images/general_icons/sidebar/guardrails.svg";
import Popover from "antd/lib/popover";
import DropMenu from "../../../shared/dropMenu/dropMenu";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import { setFilters as setIacFilters } from "../../../redux/actions/iacStacksActions";

import { IAC_TYPES } from "../../../consts/general";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";

import { setGuardrailsFilters } from "../../../redux/actions/workflowsGuardrailsActions";
import { onClickAddGuardrail } from "../../guardrails/guardrailsModal/guardrailsHelper";

import './workspaceMenu.scss';


const WorkspaceMenu = ({ data = {}, handleOnEditWorkflowClick, handleOnDeleteWorkflowClick, onLabelsClick, handleOnGuardrailIgnoresClick }) => {
  const { t } = useTranslation("ci-pipeline");
  const history = useHistory();
  const dispatch = useDispatch();
  const inventoryFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  
  const { isWorkflowManaged = false, iacStackId = "", workspaceName = "", workspaceId = "", repo = "", guardrails = [] } = data;
  const hasGuardrails = guardrails.length > 0;

  const handleViewAssetsInInventory = (e) => {
    e.stopPropagation();
    const stacks = [
        {
          id: iacStackId,
          name: workspaceName,
          iacType: IAC_TYPES.terraform
        }
    ]
    dispatch(setFilters({ ...inventoryFilters, stacks}));
    return history.push({
        pathname: "/inventory",
        search: `?stack=${stacks[0].id}`,
    });
  }
  const handleViewIacStack = (e) => {
    e.stopPropagation();
    dispatch(setIacFilters({
        ...emptyIacScreenFilters,
        stackId: [iacStackId],
        workspaceName,
    }));
    return history.push({
        pathname: `/iac`,
        search: `?view=${IAC_TYPES.terraform}`,
    });
  };

  const links = [
    {
      text: t("columns.workspacesMenu.inventory"),
      icon: <FontAwesomeIcon icon="layer-group" />,
      hide: !iacStackId,
      onClick: handleViewAssetsInInventory
    },
    {
      text: t("columns.workspacesMenu.iacExplorer"),
      icon: <IacExplorerIcon className="WorkspaceMenu-iacIcon" />,
      hide: !iacStackId,
      onClick: handleViewIacStack
    },
    {
      text: t("columns.workspacesMenu.editWorkflow"),
      icon: <FontAwesomeIcon icon="pen" />,
      disabled: !isWorkflowManaged || isViewer,
      onClick: handleOnEditWorkflowClick,
      tooltip: !isWorkflowManaged ? "Not managed by Firefly" : "",
    },
    {
      text: t("columns.workspacesMenu.setLabels"),
      icon: <Tags className="workspaceMenu-iacIcon"/>,
      disabled: isViewer,
      onClick: (event) => {
        event.stopPropagation();
        onLabelsClick(true, data);
      }
    },
    {
      text: t("columns.workspacesMenu.addGuardrail"),
      icon: <GuardrailsIcon className="WorkspaceMenu-iacIcon-guardrail"/>,
      disabled: isViewer,
      onClick: (event) => onClickAddGuardrail(event, history, workspaceName, repo),
    },
    {
      text: t("columns.workspacesMenu.viewGuardrails"),
      icon: <GuardrailsIcon className="WorkspaceMenu-iacIcon-guardrail"/>,
      disabled: !hasGuardrails,
      tooltip: hasGuardrails ? "" : t("tooltips.noGuardrailsOnWorkspace"),
      onClick: (event) => {
        if (!hasGuardrails) return;
        event.stopPropagation(); 
        dispatch(setGuardrailsFilters({ "scope.workspaces.include": [workspaceName], "scope.repositories.include": [repo] }))
        history.push({ pathname: "workflows/guardrails" });
      },
    },
    {
      text: t("columns.workspacesMenu.viewGuardrailsOverridden"),
      icon: <FontAwesomeIcon icon="eye-slash" className="WorkspaceMenu-iacIcon-guardrail"/>,
      disabled: !hasGuardrails,
      tooltip: hasGuardrails ? "" : t("tooltips.noGuardrailsOnWorkspace"),
      onClick: (event) => {
        if (!hasGuardrails) return;
        event.stopPropagation();
        handleOnGuardrailIgnoresClick(true, data);
      },
    },
    {
      text: t("columns.workspacesMenu.addNotifications"),
      icon: <FontAwesomeIcon icon="bell" />,
      disabled: isViewer,
      onClick: (event) => {
        event.stopPropagation();
        const initialData = {eventType: "WorkflowRunLifecycle"};
        setIn(initialData, "scope.workspaceNames", [workspaceName]);
        setIn(initialData, "scope.workspaceRepositories", [repo]);
        history.push({
          pathname: "notifications",
          state: { openCreation: true, initialData }
        })
      }
    },
    {
      text: t("columns.workspacesMenu.deleteWorkflow"),
      icon: <FontAwesomeIcon icon="trash-alt" className="WorkspaceMenu-iacIcon-delete"/>,
      disabled: isViewer,
      onClick: (event) => {
        event.stopPropagation();
        handleOnDeleteWorkflowClick(true, { workspaceName, workspaceId, isWorkflowManaged })
      }
    }
  ];
  
  return (
    <Popover
      content={<DropMenu links={links} tooltipPlacement="left"/>}
      title={null}
      placement="left"
      overlayClassName="InsightMenuCell"
    >
      <span className="WorkspaceMenu-popover">
        <FontAwesomeIcon icon="ellipsis-v" className="WorkspaceMenu-icon" />
      </span>
    </Popover>
  );
};

export default WorkspaceMenu;
