import isEmpty from "lodash/isEmpty";

export const checkCommandsListNotEmpty = (providerGroupsData) => {
    const providerGroupsDataKeys = Object.keys(providerGroupsData);
    let isCommandsListNotEmpty = false;
    if (!isEmpty(providerGroupsDataKeys)) {
        isCommandsListNotEmpty = !isEmpty(
            providerGroupsData[providerGroupsDataKeys[0]].commandsList,
        );
    }
    return isCommandsListNotEmpty;
};

export const checkRemediationCommandsNotEmpty = (providersGroupData, classificationId) => {
    if (
        !isEmpty(providersGroupData[0]) &&
        !isEmpty(providersGroupData[0].classificationsDetails) &&
        !isEmpty(
            providersGroupData[0].classificationsDetails[classificationId]?.remediationCommands,
        )
    ) {
        if (
            !isEmpty(
                providersGroupData[0]?.classificationsDetails[classificationId]
                    ?.remediationCommands[0],
            )
        ) {
            return true;
        }
    }
    return false;
};

export const getCommandGcp = (providersGroupData, classificationId) => {
    const commands = providersGroupData
        .map(
            (provider) =>
                provider?.classificationsDetails[classificationId]?.remediationCommands || [],
        )
        .flat();
    return commands;
};

export const translateProviderName = (providerKey) => {
    if (providerKey == "awsobjects") {
        providerKey = "aws";
    } else if (providerKey == "gcpobjects" || providerKey == "googleobjects") {
        providerKey = "gcp";
    } else if (providerKey == "azurermobjects") {
        providerKey = "azure";
    }
    return providerKey;
};

export const addProviderNames = (providers) => {
    const result = [];
    providers.forEach((provider) => {
        if (provider === "google") {
            result.push("gcpobjects");
        } else if (provider === "kubernetes") {
            result.push("k8sobjects");
        } else {
            result.push(`${provider}objects`);
        }
    });
    result.push(...providers);
    return result;
};

export const formattedFrameworkName = (name) => {
    if (name === "CIS") {
        return "CIS AWS Foundations";
    } else if (name === "EOL") {
        return "EOL & Serice Lifecycle";
    }
    return name;
};
