import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../../utils/formatting";
import AppBtn from "../../../shared/appBtn/appBtn";
import { PROVIDERS } from "../../../consts/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./singleIntegrationHeader.scss";

const SingleIntegrationHeader = ({ data, count }) => {
    const { t } = useTranslation("integrations");
    const { t: tCommon } = useTranslation('common');
    const history = useHistory();
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    const handleNavigate = () => {
        return history.push(data?.link);
    };
    const handleBack = () => {
        return history.push("/integrations");
    };

    const generateAddButton = () => {
        let isDisabled = isViewer;
        if (!isDisabled && data?.server_name === PROVIDERS.servicenow && count > 0) {
            isDisabled = true;
        }
        return (
            <AppBtn
                text={t("header.add-btn")}
                onClick={handleNavigate}
                icon={<FontAwesomeIcon icon="plus" />}
                disabled={isDisabled}
                tooltipText={isDisabled ? tCommon("servicenow.disable") : ""}
            />
        );
    };

    return (
        <div className="SingleIntegrationHeader row between">
            <div className="row g10">
                <button className="btn-icon" onClick={handleBack}>
                    <FontAwesomeIcon icon="arrow-left" />
                </button>
                <img src={data?.icon} alt="icon" className={`${data?.name}-icon`} />
                <span className="title bold">{`${data?.title} (${numberWithCommas(count)})`}</span>
            </div>
            {generateAddButton()}
        </div>
    );
};

export default memo(SingleIntegrationHeader);
