import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  iacStatusIconByType,
  numberWithCommas,
  formatIacStatusToState,
} from "../../../utils/formatting";
import { setFilters } from "../../../redux/actions/inventoryActions";

import "./state.scss";
import { RESOURCE_STATE_TYPES } from "../../../consts/general";

const State = ({
  name,
  percentage,
  total,
  datesData,
  onlyProd,
  prodAccounts,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "assetsCube.states" });

  const getSevenDaysCount = () => {
    let obj = {
      Drifted: "modified",
      Codified: "managed",
      Ghost: "ghost",
      Unmanaged: "unmanaged",
    };

    if (!_.isEmpty(datesData)) {
      let stateDaysPastWeek =
        _.find(datesData || [], (item) => item?.key === obj?.[name])
          ?.doc_count || 0;
      return numberWithCommas(stateDaysPastWeek);
    }
    return 0;
  };

  const counterClick = () => {
    const dataType = formatIacStatusToState(name);
    const isDrifted = dataType === RESOURCE_STATE_TYPES.modified;
    const newState = isDrifted ? [dataType, RESOURCE_STATE_TYPES.ghost] : [dataType];
    dispatch(
      setFilters({
        state: newState,
        years: [],
        types: [],
        severity: [],
        regions: [],
        classifications: [],
        exclusions: [],
        iacTypes: [],
        stacks: [],
        arns: [],
        providerTypes: {
          providerType: [],
          providerSubType: [],
          provider: [],
          providerId: onlyProd ? prodAccounts : [],
        },
      })
    );
    history.push({
      pathname: `/inventory`,
      search: `?state=${dataType}`,
    });
  };

  return (
    <div
      className="State-col col g5 pointer"
      onClick={counterClick}
    >
      <div className={`State ${name} ${total > 0 ? "hovered " : ""} row g8`}>
        <div className={`State__container-icon ${name}`}>
          <FontAwesomeIcon icon={iacStatusIconByType(name)} />
        </div>
        <span className={`State__container-data-title row ${name}`}>
          <span>{`${percentage}%`}</span>
          <span>{name}</span>
        </span>
      </div>
      <div className="col centered">
        <span className="State__container-data-text-total">
          {`${numberWithCommas(total)} ${t("total")}`}
        </span>
        <span className="State__container-data-text-pastWeek"> +{getSevenDaysCount()} {t("pastWeek")}</span>
      </div>

    </div>
  );
};
export default State;
