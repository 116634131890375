export const buildEventCenterQueryBody = (size, continuationToken, filters = {}) => {
    const query = {
        filters: [],
    };

    if (filters.selectedTime) {
        const { startTimeFormat, endTimeFormat } = getFormatTime(
            filters.selectedTime,
            filters.dateRange,
        );
        query.startTime = startTimeFormat;
        query.endTime = endTimeFormat;
    }

    if (filters.location?.length) {
        query.filters.push({
            key: "location",
            values: filters.location,
        });
    }

    if (filters.dataSource?.length) {
        query.filters.push({
            key: "integrationId",
            values: filters.dataSource,
        });
    }

    if (filters.assetType?.length) {
        query.filters.push({
            key: "assetType",
            values: filters.assetType,
        });
    }

    if (filters.owner?.length) {
        query.filters.push({
            key: "owner",
            values: filters.owner,
        });
    }

    if (filters.eventType?.length) {
        query.filters.push({
            key: "eventType",
            values: filters.eventType,
        });
    }

    if (filters.searchText) {
        query.searchText = filters.searchText;
    }

    return {
        query: query,
        facets: [
            { field: "integrationId", size: 100 },
            { field: "location", size: 100 },
            { field: "assetType", size: 10 },
            { field: "owner", size: 10 },
            { field: "providerType", size: 10 },
        ],
        size,
        ...(continuationToken ? { continuationToken } : {}),
    };
};

export const buildEventCenterFacetsQueryBody = (
    field,
    continuationToken,
    size,
    filters,
    searchFacet = "",
) => {
    const query = {
        filters: [],
    };
    if (filters.selectedTime) {
        const { startTimeFormat, endTimeFormat } = getFormatTime(
            filters.selectedTime,
            filters.dateRange,
        );
        query.startTime = startTimeFormat;
        query.endTime = endTimeFormat;
    }

    if (filters.eventType?.length) {
        query.filters.push({
            key: "eventType",
            values: filters.eventType,
        });
    }

    if (filters.search?.length) {
        query.filters.push({
            key: "fieldSearchText",
            values: filters.search,
        });
    }

    return {
        query: query,
        field: field,
        size: 10,
        continuationToken: continuationToken,
        fieldSearchText: searchFacet,
    };
};

const getFormatTime = (selectedTime, customDates) => {
    const now = new Date();

    switch (selectedTime) {
        case "day": {
            const startOfDay = new Date(now);
            startOfDay.setHours(0, 0, 0, 0);
            return {
                startTimeFormat: startOfDay.toISOString(),
                endTimeFormat: now.toISOString(),
            };
        }
        case "week": {
            const startTime = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            return {
                startTimeFormat: startTime.toISOString(),
                endTimeFormat: now.toISOString(),
            };
        }
        case "month": {
            const startTime = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
            return {
                startTimeFormat: startTime.toISOString(),
                endTimeFormat: now.toISOString(),
            };
        }
        case "custom": {
            if (customDates && customDates.length >= 2) {
                const startTime = new Date(customDates[0]);
                const endTime = new Date(customDates[1]);
                return {
                    startTimeFormat: startTime.toISOString(),
                    endTimeFormat: endTime.toISOString(),
                };
            }
        }
    }
};
