import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_OKTA_INTEGRATIONS = "GET_OKTA_INTEGRATIONS";
export const CREATE_OKTA_INTEGRATION = "CREATE_OKTA_INTEGRATION";
export const DELETE_OKTA_INTEGRATION = "DELETE_OKTA_INTEGRATION";
export const EDIT_OKTA_INTEGRATION = "EDIT_OKTA_INTEGRATION";

const getOktaIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/okta`,
      "GET"
    );

    const integrations = await integrationsReq.json();
    dispatch({
      type: GET_OKTA_INTEGRATIONS,
      payload: { integrations },
    });
    return integrationsReq.ok;
  });
};

const createOktaIntegration = (name, orgName, apiToken, baseUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/okta/`,
      "POST",
      {
        name,
        orgName,
        apiToken,
        baseUrl,
      }
    );

    dispatch({
      type: CREATE_OKTA_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteOktaIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/okta/${id}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_OKTA_INTEGRATION,
      payload: {},
    });
  });
};

const editOktaIntegration = (id, name, token) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const payload = { name };
    if (token) {
      payload.token = token;
    }
    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/okta/${id}`,
      "PUT",
      payload
    );

    dispatch({
      type: EDIT_OKTA_INTEGRATION,
      payload: {},
    });
    return res.ok;
  });
};

export {
  getOktaIntegrations,
  deleteOktaIntegration,
  editOktaIntegration,
  createOktaIntegration,
};
