import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PROVIDERS } from "../../consts/general";
import { getAllIntegrationCounts } from "../../redux/actions/integrationsActions";
import { Tooltip } from "antd";
import {
  cloud_integrations,
  notification_integrations,
  git_integrations,
  remote_state_integrations,
  project_integrations,
} from "./integrations_index";
import "./integrationsCatalog.scss";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../shared/confirmationModal/confirmationModal";

const IntegrationsCatalog = () => {
  const dispatch = useDispatch();
    
  const GITHUB_APP_URL = "https://github.com/apps/firefly-app";
  const [githubIntegration, setGithubIntegration] = useState(null);
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const integrationsCount = useSelector((state) => state.integrationsReducer.counts); 
  const { t } = useTranslation('integrations', { keyPrefix: 'new-integration.catalog'})
  const { t: tCommon } = useTranslation('common');
  const history = useHistory();

  useEffect(() => {
    fetchIntegrationsCount();
  }, []);

  const fetchIntegrationsCount = async () => {
    await dispatch(getAllIntegrationCounts());
  };

  useEffect(() => {
    const needsScrollToVCS = history.location.state?.scrollTo === 'vcs';
    if (needsScrollToVCS) {
      const vcsDiv = document.getElementById('version-control');
      if (vcsDiv) {
        vcsDiv.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  
  }, [history?.location?.state])

  const integrationBlockTypes = [
    {
      title: t('dataSources.title'),
      subtitle: t('dataSources.subtitle'),
      items: cloud_integrations(themeDark),
    },
    {
      title: t('iacRemoteState.title'),
      subtitle: t('iacRemoteState.subtitle'),
      items: remote_state_integrations(themeDark),
    },
    {
      title: t('vcs.title'),
      subtitle: t('vcs.subtitle'),
      note: t('vcs.note'),
      items: git_integrations(themeDark),
      id: 'version-control'
    },
    {
      title: t('notifications.title'),
      subtitle: t('notifications.subtitle'),
      items: notification_integrations(themeDark),
    },
    {
      title: t('projectManagement.title'),
      subtitle: t('projectManagement.subtitle'),
      items: project_integrations(themeDark),
    },
  ];

  const handleOnClickLink = (event, item) => {
    if(item.server_name == 'githubvcs'){
      event.preventDefault();
      window.location.replace(GITHUB_APP_URL);
    }

    if (item?.comingSoon || isServiceNowDisabled(item)) {
      return event.preventDefault();
    }
    if(item?.name == 'github' && item?.link?.includes('provider')){
      event.preventDefault();
      setGithubIntegration(item);
      return;
    }
    return null;
  };

  const renderItems = (arr) => {
    if (isEmpty(arr)) {
      return;
    }
    return arr.map((item) => {
      return (
        <Tooltip title={isServiceNowDisabled(item) && tCommon("servicenow.disable")} key={item.name}>
            <Link
            className={`IntegrationsCatalog__item row between ${
                (item.comingSoon || isServiceNowDisabled(item)) && "comingSoon"
            }`}
            key={item.name}
            to={{
                pathname: item.link,
                state: { type: item.name },
            }}
            onClick={(event) => handleOnClickLink(event, item)}
            >
            <div className="IntegrationsCatalog__item-text row">
                <img
                src={item.icon}
                alt={item.name}
                className={`${item.name}-icon`}
                />
                <div className="bold">{item.title}</div>
            </div>
            {item?.comingSoon ? (
                <span className="IntegrationsCatalog__item-soon bold col center">
                <span>COMING</span>
                <span>SOON</span>
                </span>
            ) : (
                <div className="IntegrationsCatalog__item-plus center">
                <FontAwesomeIcon icon="plus" />
                </div>
            )}
            </Link>
        </Tooltip>
      );
    });
  };

  const isServiceNowDisabled = (item) => {
    if(item?.server_name === PROVIDERS.servicenow && integrationsCount?.project?.servicenow === 1){
        return true;
    }
    return false;
 }

  const handleContinueGithubIntegration = () => {
    history.push(githubIntegration?.link,{type: githubIntegration?.name})
  }
  const renderConfirmationDescription = () => {
    const githubVCS = git_integrations().find(integration => integration.name == 'github');
    return (
      <span>
        This integration is for users who manage GitHub with IaC/Terraform, or looking to do that (it will fetch GitHub entities into the asset inventory).&nbsp;
        <b>
           If you are looking to integrate your GitHub as a Version Control System (to access your Code),
          click here: <Link to={{ pathname: githubVCS?.link, state: { type: githubVCS?.name } }}>Integrate GitHub as VCS</Link>. Otherwise,
          click Continue to integrate GitHub as an Inventory Data Source.
        </b>
      </span>
    )
  }

  return ( integrationsCount &&
    <>
    <div className="IntegrationsCatalog col">
      {_.map(integrationBlockTypes || [], (item) => {
        return (
          <div className="IntegrationsCatalog__block col" key={item?.title} id={item?.id || ''}>
            <div className="IntegrationsCatalog__block-header col">
              <span className="IntegrationsCatalog__block-header-title bold">
                {item?.title}
              </span>
              <span className="IntegrationsCatalog__block-header-sub">
                {item?.subtitle}
              </span>
              <span className="purple-text bold">
                {item?.note}
              </span>
            </div>
            <div className="IntegrationsCatalog__block-items">
              {renderItems(item?.items)}
            </div>
          </div>
        );
      })}
    </div>
      <ConfirmationModal
        visible={githubIntegration}
        confirmBtnText="Continue"
        onConfirm={handleContinueGithubIntegration}
        handleClose={() => setGithubIntegration(false)}
        title="Attention" 
        description={renderConfirmationDescription()}
      />
    </>
  );
};

export default IntegrationsCatalog;
