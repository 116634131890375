import getIn from 'lodash/get';
import setIn from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { v4 as uuidv4 } from 'uuid';
import { WORKFLOWS_ACTIONS } from '../../../consts/ci-workflows';
import { sendEvent } from '../../../utils/amplitude';
import { guardrailsEvents } from '../../../utils/amplitudeEvents';

export const GUARDRAILS_TYPES = {
    cost: 'cost',
    policy: 'policy',
    resource: 'resource',
    tag: 'tag',
};

export const GUARDRAILS_SEVERITIES = {2: "Strict Block", 1:"Flexible Block"};

export const ACTIONS = [WORKFLOWS_ACTIONS.delete, WORKFLOWS_ACTIONS.create, WORKFLOWS_ACTIONS.update, WORKFLOWS_ACTIONS.import, WORKFLOWS_ACTIONS.createdelete, WORKFLOWS_ACTIONS.deletecreate];

const toIncExc = (key) => ['include', 'exclude'].map((incExc) => `${key}.${incExc}`);

export const NOTIFICATIONS_PROVIDERS = {
    email: 'email',
    slackApp: 'slackApp',
    webex: 'webex',
    pagerduty: 'pagerduty',
};

export const ANY_TAGS = "anyTags";
export const REQUIRED_TAGS = "requiredTags";
export const REQUIRED_VALUES = "requiredValues";

export const TAG_ENFORCEMENTS_MODES = {
    [ANY_TAGS]: "Tags Missing Entirely", 
    [REQUIRED_TAGS]: "Specific Tag Missing",
    [REQUIRED_VALUES]: "Specific Tag Values",
};

export const checkNotificationValidation = (notification = {}) => {
    const { id = "", service = "", channels = []} = notification;
    if (!id) return true;
    let notificationIsValid = false;
    switch (service) {
        case NOTIFICATIONS_PROVIDERS.slackApp: case NOTIFICATIONS_PROVIDERS.webex: case NOTIFICATIONS_PROVIDERS.email:
            notificationIsValid = channels.length > 0;
            break;
        default: return true;
    }
    return notificationIsValid;
}
export const checkTagValidation = (tag, selectedType) => {
    if (selectedType !== GUARDRAILS_TYPES.tag || isEmpty(tag) || tag.tagEnforcementMode === ANY_TAGS) return true;
    if (tag.tagEnforcementMode === REQUIRED_TAGS) return tag.requiredTags?.length > 0;
    if (tag.tagEnforcementMode === REQUIRED_VALUES) return tag.requiredValues?.length > 0;
    return false;
}
export const formatGuardrailBeforeResponse = (data = {}, isPercentage) => {
    const { type, criteria = {}, scope = {}, name, severity = 2, createdBy, id, notification = {}, notificationId = "" } = data;
    const { resource = {}, cost = {}, policy = {}, tag = {} } = criteria;
    const formattedScope = formatEmptyFullArrays(scope, [...toIncExc('branches'),...toIncExc('workspaces'), ...toIncExc('labels'), ...toIncExc('repositories')]);
    const obj = { type, scope: formattedScope, name, severity: Number(severity), createdBy, isEnabled: true, criteria: {}, id, notification, notificationId };
    switch (type) {
        case GUARDRAILS_TYPES.cost:
        {
            if (isPercentage) {
                cost.thresholdPercentage = cost.thresholdPercentage || 0;
                delete cost.thresholdAmount;
            } else {
                cost.thresholdAmount = cost.thresholdAmount || 0;
                delete cost.thresholdPercentage;
            }
            obj.criteria.cost = cost;
            break;
        }
        case GUARDRAILS_TYPES.policy:
            const formattedPolicy = formatEmptyFullArrays(policy, [...toIncExc('policies')]);
            formattedPolicy.severity = policy.severity;
            obj.criteria.policy = formattedPolicy;
            break;
        case GUARDRAILS_TYPES.resource:
            const formattedResource = formatEmptyFullArrays(resource, ['specificResources',...toIncExc('regions'), ...toIncExc('assetTypes')]);
            formattedResource.actions = (isEmpty(resource.actions) || resource.actions[0]?.toLowerCase() === 'all') ? ACTIONS : resource.actions;
            obj.criteria.resource = formattedResource;
            break;
        case GUARDRAILS_TYPES.tag:
            const formattedTag = formatEmptyFullArrays(tag, ['requiredTags', ...toIncExc('regions'), ...toIncExc('assetTypes')]);

            switch (tag.tagEnforcementMode) {
                case ANY_TAGS:
                    delete formattedTag.requiredTags;
                    delete formattedTag.requiredValues;
                    delete formattedTag.invalidTagValue;
                    break;
                case REQUIRED_TAGS:
                    delete formattedTag.requiredValues;
                    delete formattedTag.invalidTagValue;
                    break;
                case REQUIRED_VALUES:
                    delete formattedTag.requiredTags;
                    const formattedRequiredValues = tag.requiredValues?.reduce((acc, value) => {
                        acc[value.name] = value.value === "" ? "*" : value.value;
                        return acc;
                    }, {});
                    formattedTag.requiredValues = formattedRequiredValues;
                    break;
                default: 
                    tag.tagEnforcementMode = ANY_TAGS;
                    delete formattedTag?.requiredTags;
                    delete formattedTag?.requiredValues;
                    delete formattedTag?.invalidTagValue;
                    break;
            }
            formattedTag.tagEnforcementMode = tag.tagEnforcementMode;
            obj.criteria.tag = formattedTag;
        default: break;
    }
    return obj;
}

export const getCostToggleValue = (isEditMode, costObj = {}) => {
    if (!isEditMode) return 0;
    if (isNumber(costObj.thresholdAmount)) return 0;
    return 1;
}

export const formatEditData = (data = {}) => {
    const { type, criteria = {}, scope = {}, name, severity, createdBy, isEnabled, id, notification = {}, notificationId = "" } = data;
    const { resource = {}, policy = {}, cost = {}, tag = {} } = criteria;
    const formattedScope = initialiazeSection(scope, [...toIncExc('branches'),...toIncExc('workspaces'), ...toIncExc('labels'), ...toIncExc('repositories')]);
    const obj = { type, scope: formattedScope, name, severity, createdBy, isEnabled, criteria: {}, id, notification, notificationId };
    switch (type) {
        case GUARDRAILS_TYPES.cost:
            obj.criteria.cost = cost;
            break;
        case GUARDRAILS_TYPES.policy:
            const formattedPolicy = initialiazeSection(policy, [...toIncExc('policies')]);
            formattedPolicy.severity = policy.severity;
            obj.criteria.policy = formattedPolicy;
            break;
        case GUARDRAILS_TYPES.resource:
            const formattedResource = initialiazeSection(resource, ['actions', 'specificResources',...toIncExc('regions'), ...toIncExc('assetTypes')]);
            obj.criteria.resource = formattedResource;
            obj.type = GUARDRAILS_TYPES.resource;
            break;
        case GUARDRAILS_TYPES.tag:
            const formattedTag = initialiazeSection(tag, ['requiredTags', ...toIncExc('regions'), ...toIncExc('assetTypes')]);
            switch (tag.tagEnforcementMode) {
                case ANY_TAGS:
                    delete formattedTag.requiredTags;
                    delete formattedTag.requiredValues;
                    delete formattedTag.invalidTagValue;
                    break;
                case REQUIRED_TAGS:
                    delete formattedTag.requiredValues;
                    delete formattedTag.invalidTagValue;
                    break;
                case REQUIRED_VALUES:
                    delete formattedTag.requiredTags;
                    const formattedRequiredValues = Object.entries(tag?.requiredValues).map(([key, value]) => ({ name: key, value: value === "*" ? "" : value, key: uuidv4() }));
                    formattedTag.requiredValues = formattedRequiredValues;
                    break;
            }
            formattedTag.tagEnforcementMode = tag.tagEnforcementMode;
            obj.criteria.tag = formattedTag;
            break;
        default: break;
    }
    return obj;
};

export const formatEmptyFullArrays = (section, keys = []) => {
    const formattedSection = {};
    for (const key of keys) {
        const isExcludeKey = key?.toLowerCase()?.includes('exclude');
        const value = getIn(section, key, []);
        const shouldBeAll = (!isExcludeKey && !value.length) || value[0]?.toLowerCase() === 'all'
        setIn(formattedSection, key, shouldBeAll ? ['*'] : value);
    }
    return formattedSection;
}
export const initialiazeSection = (section, keys = []) => {
    const formattedSection = {};
    for (const key of keys) {
        const value = getIn(section, key, []);
        const shouldBeEmptyArray = value?.[0] === '*';
        setIn(formattedSection, key, shouldBeEmptyArray ? [] : value);
    }
    return formattedSection;
};

const arrayWithAll = ['All'];
export const formatAsetrixToAll = (element, isExcludeIncludeObj = false) => {
    if (!isExcludeIncludeObj) return element?.length === 1 && element?.[0] === '*' ? arrayWithAll : element;
    const obj = { include: [], exclude: [] };
    const { include, exclude } = element;
    obj.include = include?.length === 1 && include?.[0] === '*' ? arrayWithAll : include;
    obj.exclude = exclude?.length === 1 && exclude?.[0] === '*' ? arrayWithAll : exclude;
    return obj;

}
export const formatCostAmount = (value) => value > 0 ? `+$${value}` : value < 0 ? `-$${Math.abs(value)}` : `$${value}`;

export const onClickAddGuardrail = (e, history, workspaceName, repo, ampliSource = "") => {
    e.stopPropagation();
    const initialData = {  };
    setIn(initialData, "scope.workspaces.include", [workspaceName]);
    setIn(initialData, "scope.repositories.include", [repo]);
    history.push({
        pathname: "workflows/guardrails",
        state: { openCreation: true, initialData }
    });
    sendEvent(guardrailsEvents.clickedAddGuradrail, { accessedVia: ampliSource || "workflows page" });
}