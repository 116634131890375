import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, AutoComplete } from 'antd';
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './navigationSearch.scss';
import { sendEvent } from '../../utils/amplitude';

const NavigationSearch = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const options = [
        //General Screens
		{"label":'Dashboard', value: '/dashboard'},
		{"label":'Inventory', value: '/inventory'},
		{"label":'Iac Explorer', value: '/iac'},
		{"label":'Iac Explorer (Terraform)', value: '/iac/terraform'},
		{"label":'Iac Explorer (Cloudformation)', value: '/iac/cloudformation'},
		{"label":'Iac Explorer (helm)', value: '/iac/helm'},
		{"label":'Iac Explorer (kustomize)', value: '/iac/kustomize'},
		{"label":'Iac Explorer (k8s)', value: '/iac/k8s'},
		{"label":'Workflows/Workspaces', value: '/workflows'},
		{"label":'Workflows/Guardrails', value: '/workflows/guardrails'},
		{"label":'Governance', value: '/governance'},
		{"label":'Create custom policy (Governance)', value: '/create-custom-control'},
		{"label":'Event Center', value: '/event-center'},
		{"label":'Notifications', value: '/notifications'},
		{"label":'Settings/IaC-Ignored', value: '/iac-ignored-rules'},
		{"label":'Create IaC-Ignored', value: '/create-iac-ignored-rules'},
		{"label":'Settings/Excluded Drifts', value: '/excluded-drifts'},
		{"label":'Settings/Users', value: '/users'},

        //Integrations
		{"label":'Settings/Integrations', value: '/integrations'},
		{"label":'Settings/Integrations/AWS', value: '/integrations/aws'},
		{"label":'Settings/Integrations/GCP', value: '/integrations/gcp'},
		{"label":'Settings/Integrations/Azure', value: '/integrations/azurerm'},
		{"label":'Settings/Integrations/Pager Duty', value: '/integrations/pagerduty'},
		{"label":'Settings/Integrations/Mongo DB Atlas', value: '/integrations/mongodbatlas'},
		{"label":'Settings/Integrations/Data Dog', value: '/integrations/datadog'},
		{"label":'Settings/Integrations/New Relic', value: '/integrations/newrelic'},
		{"label":'Settings/Integrations/Okta', value: '/integrations/okta'},
		{"label":'Settings/Integrations/Akamai', value: '/integrations/akamai'},
		{"label":'Settings/Integrations/Github', value: '/integrations/github'},
		{"label":'Settings/Integrations/Cloudflare', value: '/integrations/cloudflare'},
		{"label":'Settings/Integrations/NS1', value: '/integrations/ns1'},
		{"label":'Settings/Integrations/K8s', value: '/integrations/k8s'},
        {"label":'Settings/Integrations/vault', value: '/integrations/vault'},
        
        //Create New Integration
		{"label":'Create New integration', value: '/integrations/new-integration'},
		{"label":'Create New AWS integration (Cloudformation)', value: '/integrations/aws-integration/cloud-formation'},
		{"label":'Create New AWS integration (Terraform)', value: '/integrations/aws-integration/terraform'},
		{"label":'Create New Azure integration', value: '/integrations/azure-integration'},
		{"label":'Create New GCP integration (Terraform)', value: '/integrations/gcp-provider-integration/terraform'},
		{"label":'Create New GCP integration (GCP)', value: '/integrations/gcp-provider-integration/gcp'},
		{"label":'Create New Akamai integration', value: '/integrations/akamai-integration'},
		{"label":'Create New Datadog integration', value: '/integrations/datadog-integration'},
		{"label":'Create New NS1 integration', value: '/integrations/ns1-integration'},
		{"label":'Create New Mongo DB Atlas integration', value: '/integrations/mongodbatlas-integration'},
		{"label":'Create New Newrelic integration', value: '/integrations/newrelic-integration'},
		{"label":'Create New Cloudflare integration', value: '/integrations/cloudflare-integration'},
		{"label":'Create New PagerDuty integration', value: '/integrations/pagerduty-integration'},
		{"label":'Create New Okta integration', value: '/integrations/okta-integration'},
		{"label":'Create New Github integration', value: '/integrations/github-integration'},
		{"label":'Create New K8s integration', value: '/integrations/k8s-integration'},
        {"label":'Create New Vault integration', value: '/integrations/vault-integration'},

        //Integrations Notifications
        {"label":'Settings/Integrations/Slack', value: '/integrations/slack'},
        {"label":'Settings/Integrations/Teams', value: '/integrations/teams'},
        {"label":'Settings/Integrations/Opsgenie', value: '/integrations/opsgenie'},
        {"label":'Settings/Integrations/Google Chat', value: '/integrations/googleChat'},
        {"label":'Settings/Integrations/Webhook', value: '/integrations/webhook'},
        {"label":'Settings/Integrations/Webex', value: '/integrations/webex'},
        {"label":'Settings/Integrations/Torq', value: '/integrations/torq'},
        {"label":'Settings/Integrations/Pager Duty (Notification)', value: '/integrations/pagerduty-notification'},


        //Create new Notifications Integrations
        {"label":'Create New TFC integration', value: '/integrations/tfc-integration'},
        {"label":'Create New Slack integration', value: '/integrations/slack-integration'},
        {"label":'Create New Teams integration', value: '/integrations/teams-integration'},
        {"label":'Create New Google chat integration', value: '/integrations/google-chat-integration'},
        {"label":'Create New Webex integration', value: '/integrations/webex-integration'},

        //Integrations VCS
        {"label":'Settings/Integrations/Github', value: '/integrations/githubvcs'},
        {"label":'Settings/Integrations/Azure Devops', value: '/integrations/azuredevops'},
        {"label":'Settings/Integrations/Gitlab', value: '/integrations/gitlab'},
        {"label":'Settings/Integrations/BitBucket', value: '/integrations/bitbucket'},
        {"label":'Settings/Integrations/CodeCommit', value: '/integrations/codecommit'},

        //Create new VCS Integrations
        {"label":'Create New Github integration', value: '/integrations/github-integration'},
        {"label":'Create New Gitlab integration', value: '/integrations/gitlab-integration'},
        {"label":'Create New Bitbucket integration', value: '/integrations/bitbucket-integration'},
        {"label":'Create New CodeCommit chat integration', value: '/integrations/codecommit-integration'},
        {"label":'Create New Azure Devops integration', value: '/integrations/azuredevops-integration'},

        //Integrations IaC
        {"label":'Settings/Integrations/TFC', value: '/integrations/tfc'},
        {"label":'Settings/Integrations/Consul', value: '/integrations/consul'},
        {"label":'Settings/Integrations/GCS', value: '/integrations/gcs'},
        {"label":'Settings/Integrations/Env0', value: '/integrations/env0'},
        {"label":'Settings/Integrations/CodeCommit', value: '/integrations/codecommit'},

        //Create new IaC Integrations
        {"label":'Create New TFC integration', value: '/integrations/tfc-integration'},
        {"label":'Create New Consul integration', value: '/integrations/consul-integration'},
        {"label":'Create New GCS integration', value: '/integrations/gcs-integration'},
        {"label":'Create New Env0 chat integration', value: '/integrations/env0-integration'},
        {"label":'Create New Azure Devops integration', value: '/integrations/azuredevops-integration'},

        {"label":'Settings/Integrations/Jira', value: '/integrations/jira'},
        {"label":'Create New Jira integration', value: '/integrations/jira-integration'},
        {"label":'Create New ServiceNow integration', value: '/integrations/servicenow-integration'},

    ];

	useEffect(async () => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const handleKeyDown = (event) => {
		if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event?.preventDefault();
            !isPopupOpen && sendEvent('opened spotlight');
			setIsPopupOpen(true);
		}
	};

    const handleClose = () => {
        setIsPopupOpen(false);
    }

	const onSelect = (item) => {
        sendEvent('navigated via spotlight', { destination: item });
        navigateToPage(item)
	}

    const onEnterKeyword = (event) => {
        if(event.key !== 'Enter'){
            return
        }
        
        const value = event?.target?.value;

        if(value.toLowerCase().includes('show ')){
            const showValue = value.replace('show ', '')
            handleShowKeyword(showValue)
            return
        }else if(value.toLowerCase().startsWith('/')){
            navigateToPage(value)
            return
        }else{
            sendEvent('searched free text via spotlight', { text: value });
            handleSearchKeyword(value)
            return
        }
    }

    const handleSearchKeyword = (value) => {
        dispatch({
            type: "NAVIGATION_SEARCH",
            payload: { searchValue: value},
        });
        navigateToPage('/inventory')
    }

    const handleShowKeyword = (value) => {
        dispatch({
            type: "NAVIGATION_SHOW",
            payload: { showValue: value},
        });
        navigateToPage('/governance')
    }

    const navigateToPage = (url) => {
		handleClose();
        history.push(url);
    }

    return (
        <Modal
            title={null}
            visible={isPopupOpen}
            onCancel={handleClose}
            footer={null}
            className="popup-cmd-k"
            closable={false}
            centered={true}
            destroyOnClose={true}
            width={700}
            zIndex={1000}
        >
            <FontAwesomeIcon
                icon="search"
                className="purple-text search-icon-style"
            />
            <AutoComplete
                className='popup-cmd-k__autocomplete'
                options={options}
                autoFocus
                placeholder="Search..."
                filterOption={(inputValue, option) =>
                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onKeyUp={(event) => onEnterKeyword(event)}
                onSelect={onSelect}
            />
        </Modal>
    );
}

export default NavigationSearch;