import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Drawer, Tabs } from "antd";
import compact from "lodash/compact";
import { ReactComponent as TagIcon } from "../../../../../Images/ci/guardrails/tag.svg";
import { ReactComponent as ResourcePlanIcon } from "../../../../../Images/ci/plan.svg";
import { ReactComponent as CostAnalysisIcon } from "../../../../../Images/ci/cost.svg";
import { ReactComponent as PolicyScaningIcon } from "../../../../../Images/ci/policy.svg";
import ResourcePlan from "./resourcePlan";
import PolicyScaning from "./policyScaning";
import { WORKFLOWS_ACTIONS } from "../../../../../consts/ci-workflows";

import { formatAwsStringWithUnderscore, renderEllipsis } from "../../../../../utils/formatting";
import { CiEvents } from "../../../../../utils/amplitudeEvents";
import { sendEvent } from "../../../../../utils/amplitude";
import CostAnalysis from "./costAnalysis";
import { getPolicySeveritiesObject } from "../../../../../utils/workflowsHelper";

import "./postPlanConfig.scss";
import TagsTable from "./tagsTable";

const { TabPane } = Tabs;
const TABS = {
  plan : {
    title: "Resource Plan",
    key: "plan",
    icon: <ResourcePlanIcon />,
    getIsDisabled: () => false,
  },
  cost: {
    title: "Cost Analysis",
    key: "cost",
    icon: <CostAnalysisIcon />,
    getIsDisabled: (data) => !data?.isCostResults
  },
  policy: {
    title: "Policy Violations",
    key: "policy",
    icon: <PolicyScaningIcon />,
    getIsDisabled: (data) => isEmpty(data?.scanResults)
  },
  tag: {
    title: "Tags",
    key: "tag",
    icon: <TagIcon />,
    getIsDisabled: (data) => isEmpty(data?.tags)
  }
}

const PostPlanConfigHeader = ({ selectedTab, onSelectTab, title = "", subtitle = "", data }) => {
  const renderTabContent = (tab = {}) => {
    const { title: tabTitle = '', icon, key: tabKey = "" } = tab;
    return ( 
      <span className="PostPlanConfig__header-tab row">
        {<div className={`PostPlanConfig__header-tab-icon ${tabKey}`}>{icon}</div>}
        {tabTitle}
      </span>
  )}
  return (
      <div className="PostPlanConfig__header">
        <span className="title PostPlanConfig__header-title">
          {title}
        </span>
        <span className="sub-title PostPlanConfig__header-title sub">
          {subtitle}
        </span>
        <Tabs
          defaultActiveKey={TABS.plan.key}
          onChange={onSelectTab}
          activeKey={selectedTab}
          tabBarStyle={{ padding: 0 }}
        >
          {Object.keys(TABS).map((key) => (
            <TabPane tab={renderTabContent(TABS[key])} key={TABS[key].key} disabled={TABS[key].getIsDisabled(data)} />
          ))}
        </Tabs>
    </div>)
}

const AFTER_ACTIONS = [WORKFLOWS_ACTIONS.create, WORKFLOWS_ACTIONS["no-op"], WORKFLOWS_ACTIONS.import];

const PostPlanConfig = ({ visible, onClose, data = {}, defaultTab = "", workspaceData = {}}) => {
  const [selectedTab, setSelectedTab] = useState(TABS.plan.key);
  const { before = [], after = [], diffs = [], action = "", scanResults = [], type: assetType, resourceId, defaultRemediationOpen = false, specificReason = "" } = data;
  const code = AFTER_ACTIONS.includes(action) ? after : (action === WORKFLOWS_ACTIONS.delete ? before : diffs);

  useEffect(() => {
    return () => setSelectedTab(TABS.plan.key);
  }, [])

  useEffect(() => {
    if (defaultTab) {
      setSelectedTab(defaultTab);
    }
  },[defaultTab])

  const onCloseDrawer = () => {
    onClose();
    setSelectedTab(TABS.plan.key);
  };

  const renderByActiveTab = () => {
    switch (selectedTab) {
      case TABS.plan.key:
        return <ResourcePlan item={code} action={action} workspaceId={workspaceData?.workspaceId} />;
      case TABS.cost.key:
        return <CostAnalysis data={data}/>
      case TABS.policy.key:
        return <PolicyScaning scanResults={scanResults} taskId={workspaceData.planId} defaultRemediationOpen={defaultRemediationOpen} specificReason={specificReason}/>
      case TABS.tag.key:
        return <TagsTable tags={data?.tags} />;
      default:
        return null;
    }
  }

  const onChangeTab = (tab) => {
    const chosenTab = TABS[tab] || {};
    setSelectedTab(chosenTab.key);
    const horizontalTabAvailability = compact(["resource plan", data?.isCostResults ? "cost analysis" : "", scanResults?.length ? "policy violations" : ""]);
    const policyViolations = getPolicySeveritiesObject(workspaceData?.plan || [], true);
    sendEvent(CiEvents.ciRunResourceTabClick, { 
       tabName: chosenTab.key, horizontalTabAvailability, workspaceID: workspaceData?.workspaceId,
       workspaceRunID: workspaceData?.runId, assetType, actionType: action, 
       policyViolations
    });
  }

  return (
    <Drawer
      title={<PostPlanConfigHeader title={formatAwsStringWithUnderscore(assetType)} subtitle={renderEllipsis(resourceId, 80)} selectedTab={selectedTab} onSelectTab={onChangeTab} data={data}/>}
      placement="right"
      onClose={onCloseDrawer}
      visible={visible}
      getContainer={false}
      style={{ position: "absolute" }}
      className="PostPlanConfig"
      width="75%"
    >
      {renderByActiveTab()}
    </Drawer>
  );
};

export default PostPlanConfig;
