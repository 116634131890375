import isEmpty from "lodash/isEmpty";

import { eventCenter } from "../../../../utils/icons";
import OwnerCell from "../ownerCell/ownerCell";
import ProviderIcon from "../../../../shared/providerIcon/providerIcon";
import AssetTypeCell from "../assetTypeCell/assetTypeCell";
import AssetNameCell from "../assetNameCell/assetNameCell";

const EventCenterColumns = () => {
    const DataSourceCell = ({ row }) => {
        return (
            <div className="DataSourceCell row g10">
                <div className="row g10">
                    {!isEmpty(row?.providerType) && (
                        <ProviderIcon provider={row?.providerType} customStyle="inventory" />
                    )}
                    <span>{row?.integrationName}</span>
                </div>
            </div>
        );
    };

    const DateCell = ({ dateString }) => {
        const date = new Date(dateString);
        const options = {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
        };
        const formatted = new Intl.DateTimeFormat("en-US", options).format(date);
        const formattedDate = formatted.replace(",", "");
        return <div>{formattedDate}</div>;
    };

    const EventType = ({ type }) => {
        const Icon = eventCenter(type);
        return <div className="EventCenter__table__eventType">{Icon && <Icon />}</div>;
    };

    return [
        {
            headerName: "Type",
            field: "type",
            width: 70,
            minWidth: 70,
            renderCell: ({ row }) => {
                return <EventType type={row.eventType} />;
            },
        },
        {
            headerName: "Date",
            field: "date",
            width: 160,
            minWidth: 50,
            renderCell: ({ row }) => {
                return <DateCell dateString={row["@timestamp"]} />;
            },
        },
        {
            headerName: "Data Source",
            field: "dataSource",
            width: 150,
            minWidth: 50,
            renderCell: ({ row }) => {
                return <DataSourceCell row={row} />;
            },
        },
        {
            headerName: "Location",
            field: "location",
            width: 130,
            minWidth: 50,
        },

        {
            headerName: "Asset Type",
            field: "assetType",
            width: 110,
            minWidth: 100,
            renderCell: ({ row }) => {
                return <AssetTypeCell assetType={row.assetType} />;
            },
        },
        {
            headerName: "Event Name",
            field: "eventName",
            minWidth: 75,
            width: 100,
            flex: 1,
        },
        {
            headerName: "Asset Name",
            field: "assetId",
            minWidth: 100,
            width: 200,
            flex: 2,
            renderCell: ({ row }) => {
                let assetKey = row?.assetKey;
                if (row?.eventType === "mutation") {
                    const assetKeySplitted = row?.assetKey.split(":");
                    if (assetKeySplitted.length >= 2) {
                        assetKey = `${assetKeySplitted[0]}-${row?.assetType}-${assetKeySplitted
                            .slice(1)
                            .join(":")}`;
                    }
                }
                return <AssetNameCell assetName={row?.assetName} assetKey={assetKey} />;
            },
        },
        {
            headerName: "Owner",
            field: "owner",
            width: 110,
            minWidth: 50,
            renderCell: ({ row }) => {
                return <OwnerCell row={row} />;
            },
            flex: 1,
        },
    ];
};

export default EventCenterColumns;
