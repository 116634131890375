import React, { useState, useEffect, useRef } from "react";
import { Collapse } from "antd";
import FacetContainer from "../facetContainer";
import "./facetPanel.scss";
import { CaretDownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getAllProvidersArray } from "../../../../../utils/formatting";

const { Panel } = Collapse;

const FacetPanel = ({
    facets = [],
    updateFilter,
    selectedFacets,
    onLoadMore,
    loadMorefacets,
    facetLoading,
    onSearchFacets,
}) => {
    const providerIntegrations = useSelector((state) => state.globalAppReducer.clouds);
    const [activeKey, setActiveKey] = useState(null);
    const collapseRef = useRef(null);

    const parseFacets = (facetsArray = []) => {
        const parsed = {
            dataSource: null,
            location: null,
            assetType: null,
            owner: null,
        };

        facetsArray.forEach((facet) => {
            const { field } = facet;
            switch (field) {
                case "integrationId":
                    parsed.integrationId = facet;
                    break;
                case "location":
                    parsed.location = facet;
                    break;
                case "assetType":
                    parsed.assetType = facet;
                    break;
                case "owner":
                    parsed.owner = facet;
                    break;

                default:
                    break;
            }
        });

        return parsed;
    };

    const { integrationId, location, assetType, owner } = parseFacets(facets);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (collapseRef.current && !collapseRef.current.contains(event.target)) {
                setActiveKey(null);
            }
        };

        document.addEventListener("click", handleDocumentClick, true);
        return () => {
            document.removeEventListener("click", handleDocumentClick, true);
        };
    }, []);

    const handleCollapseChange = (key) => {
        setActiveKey(key);
    };

    useEffect(() => {
        if (!integrationId?.Values) {
            return;
        }

        const allPorviders = getAllProvidersArray(providerIntegrations);
        for (const values of integrationId.Values) {
            for (const provider of allPorviders) {
                if (values.value === provider?.id) {
                    values.name = provider?.name;
                    values.provider = provider?.provider;
                    break;
                }
            }
        }
    }, [integrationId]);

    const facetPanels = [
        {
            key: "dataSource",
            title: "Data Source",
            values: integrationId?.Values,
        },
        {
            key: "location",
            title: "Location",
            values: location?.Values,
        },
        {
            key: "owner",
            title: "Owner",
            values: owner?.Values,
        },
        {
            key: "assetType",
            title: "Asset Types",
            values: assetType?.Values,
        },
    ];

    return (
        <div className="facet-container" ref={collapseRef}>
            <Collapse
                expandIconPosition="right"
                accordion
                activeKey={activeKey}
                onChange={handleCollapseChange}
            >
                {facetPanels.map((panel) => (
                    <Panel
                        header={
                            <div className="facet-item-header">
                                <span>
                                    {panel.title} ({panel.values?.length ?? 0})
                                </span>
                                <CaretDownOutlined />
                            </div>
                        }
                        key={panel.key}
                    >
                        <FacetContainer
                            allTypes={panel.values}
                            checkedList={selectedFacets[panel.key]}
                            selectedTypes={updateFilter}
                            facetKey={panel.key}
                            onLoadMore={onLoadMore}
                            isNeedToLoadMore={loadMorefacets}
                            facetLoading={facetLoading}
                            onSearchFacets={onSearchFacets}
                        />
                    </Panel>
                ))}
            </Collapse>
        </div>
    );
};

export default FacetPanel;
