import _, { isEmpty } from "lodash";
import {
    GET_IAC_STACKS_HITS,
    QUERY_BACKENDS_DATA,
    QUERY_REPOSITORIES_DATA,
    SET_IAC_FILTERS,
    GET_IAC_STACKS_BASE_AGGS,
    GET_IAC_STACK,
    GET_IAC,
    GET_STACK_STATE_FILE_PREVIEW,
    GET_STACK_STATE_FILE_DOWNLOAD,
    GET_MODULES_BASE_AGGS,
    QUERY_MODULES,
    QUERY_MODULES_AGGS,
    QUERY_MODULE_BY_ID,
    QUERY_PROVIDERS,
    SET_MODULES_FILTERS,
    RESET_IAC_STACKS,
    GET_CONTROLLER_SOURCES,
} from "../actions/iacStacksActions";
import { emptyIacScreenFilters, emptyModulesScreenFilters } from "../../consts/iacStacks";
import { getMisconfigurationsAggs, getVulnerabilityData } from "../../utils/helpers";

const initialState = {
    responseObjects: [],
    totalObjects: 0,
    iacStacksFilters: emptyIacScreenFilters,
    backends: {
        hits: [],
        aggs: {},
        total: 0,
    },
    modules: {},
    modulesAggs: 0,
    providers: {
        types: {},
        counts: {},
        urls: {},
    },
    aggregationsIacTypes: [],
    aggregationsMissingAccountNumbers: [],
    aggregationsStateTypeStatus: [],
    aggregationsIacStatusesAgg: [],
    aggregationsProviderTypes: [],
    aggregationsNotIntegrated: [],
    aggregationsNotSupported: [],
    aggregationsIntegrated: [],
    aggregationsIntegrationsStateTypes: [],
    aggregationsBackends: [],
    aggregationsProviderIntegrations: [],
    aggregationsAssetTypes: [],
    aggregationsIacState: [],
    aggregationsProviderAccountId: [],
    aggregationsNamespaces: [],
    modulesFilters: emptyModulesScreenFilters,
    aggregationsRepositories: [],
    aggregationsStackRepositories: [],
    aggregationsModuleSourceTypes: [],
    aggregationsMisconfigurations: [],
    controllerSources: [],
};

export default (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case GET_IAC_STACKS_BASE_AGGS:
            return {
                ...state,
                aggregationsIacTypes: payload?.stateTypeAgg?.buckets,
                aggregationsMissingAccountNumbers: payload?.missingAccountNumbers?.value,
                aggregationsStateTypeStatus: payload?.stateTypeStatus?.buckets,
                aggregationsIacStatusesAgg: payload?.statusAgg?.buckets,
                aggregationsProviderTypes: !isEmpty(
                    payload["stackProviders.providerTypeAgg"]?.buckets,
                )
                    ? payload["stackProviders.providerTypeAgg"]?.buckets
                    : [],
                aggregationsNotIntegrated: payload?.hasNotIntegratedProvidersAgg?.buckets,
                aggregationsNotSupported: payload?.hasNotSupportedAgg?.buckets,
                aggregationsIntegrated: payload?.hasIntegratedProvidersAgg?.buckets,
                aggregationsIntegrationsStateTypes: payload?.integrationsStateTypes?.buckets,
                aggregationsBackends: payload?.stateMainRemoteLocationStringAgg?.buckets,
                aggregationsProviderIntegrations: !isEmpty(
                    payload["stackProviders.providerIntegrationIdAgg"]?.buckets,
                )
                    ? payload["stackProviders.providerIntegrationIdAgg"]?.buckets
                    : [],
                aggregationsAssetTypes: payload?.stackAssetTypesAgg?.buckets,
                aggregationsIacState: payload?.staticStatusAgg,
                aggregationsProviderAccountId: !isEmpty(
                    payload["stackProviders.providerAccountIdAgg"]?.buckets,
                )
                    ? payload["stackProviders.providerAccountIdAgg"]?.buckets
                    : [],
                aggregationsNamespaces: payload.metadataNamespacesAgg?.k8s?.buckets || [],
                aggregationsTfVersions:
                    payload["metadata.terraformMetadata.terraformVersionAgg"]?.buckets || [],
                aggregationsProviderType: payload["stackProviders.providerTypeAgg"]?.buckets,
                aggregationsProvidersFullType:
                    payload["stackProviders.providerFullTypeAgg"]?.buckets ?? [],
                aggregationsSourceModuleFullName:
                    payload[
                        "metadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullNameAgg"
                    ]?.buckets ?? [],
                aggregationsCftRuntime:
                    payload["metadata.cloudFormationMetadata.cdkMetadata.languageWithVersionAgg"]
                        ?.buckets,
                aggregationsCftStateStatus: payload["metadata.stateStatusAgg"]?.buckets ?? [],
                aggregationsCftRegion:
                    payload["stateRemoteLocation.cloudFormationLocation.regionAgg"]?.buckets,
                aggregationsStackRepositories: payload["metadata.vcsRepoAgg"]?.buckets,
            };
        case GET_IAC_STACKS_HITS:
            return {
                ...state,
                responseObjects: payload.responseObjects,
                totalObjects: payload.totalObjects,
            };

        case QUERY_BACKENDS_DATA:
            return {
                ...state,
                backends: payload,
            };
        case QUERY_REPOSITORIES_DATA:
            return {
                ...state,
                repositories: payload,
            };

        case SET_IAC_FILTERS:
            const { workspaceName = "", ...filters } = payload || {};
            return {
                ...state,
                workspaceName,
                iacStacksFilters: filters,
            };

        case QUERY_MODULE_BY_ID:
        case GET_IAC_STACK:
        case GET_IAC:
        case GET_STACK_STATE_FILE_PREVIEW:
        case GET_STACK_STATE_FILE_DOWNLOAD:
            return {
                ...state,
            };

        case GET_MODULES_BASE_AGGS:
            return {
                ...state,
                aggregationsRepositories: payload?.moduleOriginAgg?.buckets,
                aggregationsModuleSourceTypes: payload?.moduleOriginTypeAgg?.buckets,
                aggregationsMisconfigurations: getMisconfigurationsAggs(payload),
                aggregationsIsBeingUsed: payload.isBeingUsedAgg?.buckets ?? [],
            };

        case QUERY_MODULES:
            let modules = [];
            if (!_.isEmpty(payload?.modules)) {
                modules = _.map(payload?.modules || [], (mod) => {
                    return { ...mod, vulnerabilities: getVulnerabilityData(mod) };
                });
            }

            return {
                ...state,
                modules: { ...payload, modules },
            };
        case QUERY_MODULES_AGGS:
            return {
                ...state,
                modulesAggs: payload,
            };

        case QUERY_PROVIDERS:
            return {
                ...state,
                providers: {
                    types: payload?.types?.value,
                    urls: payload?.urls?.buckets,
                },
            };

        case SET_MODULES_FILTERS:
            return {
                ...state,
                modulesFilters: payload,
            };
        case GET_CONTROLLER_SOURCES:
            return {
                ...state,
                controllerSources: payload,
            };
        case RESET_IAC_STACKS:
            return { ...state, responseObjects: [], totalObjects: 0 };

        default:
            return state;
    }
};
