import { useSelector } from "react-redux";
import { Popover } from "antd";
import { frameworkCellDark, frameworkCellLight } from "../../../utils/icons";
import "./frameworkCell.scss";
import { formattedFrameworkName } from "../../../utils/insightsHelper";

const FrameworkCell = ({ frameworks = [] }) => {
  const isDarkTheme = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const uniqueFrameworks = [...new Set(frameworks)];

  return (
    <div className="FrameworkCell row">
      {uniqueFrameworks.map((framework) => {
        const IconComponent = isDarkTheme ? frameworkCellDark(framework, false) : frameworkCellLight(framework, false);
        const HoveredIconComponent = isDarkTheme ? frameworkCellDark(framework, true) : frameworkCellLight(framework, true);

        return (
          <Popover
            overlayClassName="FrameworkCell"
            key={framework}
            content={formattedFrameworkName(framework)}
          >
            <span className="icon-wrapper">
              <span className="icon-default">
                {IconComponent && <IconComponent />}
              </span>
              <span className="icon-hover">
                {HoveredIconComponent && <HoveredIconComponent />}
              </span>
            </span>
          </Popover>
        );
      })}
    </div>
  );
};

export default FrameworkCell;
