import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { sendEvent } from "../../utils/amplitude";
import { iacEvents } from "../../utils/amplitudeEvents";
import { triggerFileDownload } from '../../utils/helpers';

export const GET_IAC_STACKS_BASE_AGGS = "GET_IAC_STACKS_BASE_AGGS";
export const GET_IAC_STACKS_HITS = "GET_IAC_STACKS_HITS";
export const QUERY_BACKENDS_DATA = "QUERY_BACKENDS_DATA";
export const QUERY_REPOSITORIES_DATA = "QUERY_REPOSITORIES_DATA";
export const SET_IAC_FILTERS = "SET_IAC_FILTERS";
export const GET_IAC_STACK = "GET_IAC_STACK";
export const GET_IAC = "GET_IAC";
export const GET_STACK_STATE_FILE_PREVIEW = "GET_STACK_STATE_FILE_PREVIEW";
export const GET_STACK_STATE_FILE_DOWNLOAD = "GET_STACK_STATE_FILE_DOWNLOAD";
export const GET_STACK_MAP = "GET_STACK_MAP";
export const GET_STACK_DIAGRAM = "GET_STACK_DIAGRAM";
export const GET_MODULES_BASE_AGGS = "GET_MODULES_BASE_AGGS";
export const QUERY_MODULES = "QUERY_MODULES";
export const QUERY_MODULES_AGGS = "QUERY_MODULES_AGGS";
export const QUERY_MODULE_BY_ID = "QUERY_MODULE_BY_ID";
export const QUERY_PROVIDERS = "QUERY_PROVIDERS";
export const SET_MODULES_FILTERS = "SET_MODULES_FILTERS";
export const RESET_IAC_STACKS = "RESET_IAC_STACKS";
export const GET_CONTROLLER_SOURCES = "GET_CONTROLLER_SOURCES";

const getIacStacksBaseAggs = (
    screenFilters,
    freeTextSearch,
    onlyProd = false,
    dashboardScreenRequest,
) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/base-aggs`, "POST", {
            screenFilters,
            freeTextSearch,
            onlyProd,
            dashboardScreenRequest,
        });

        if (req?.ok) {
            const data = await req.json();
            dispatch({
                type: GET_IAC_STACKS_BASE_AGGS,
                payload: data,
            });
            return data;
        }
        return;
    });
};

const getIacStacksHits = (screenFilters, freeTextSearch, pageNumber, sorting, tablePageSize) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks`, "POST", {
            screenFilters,
            freeTextSearch,
            pageNumber,
            sorting,
            tablePageSize,
        });

        if (req?.ok) {
            const data = await req.json();
            dispatch({
                type: GET_IAC_STACKS_HITS,
                payload: data,
            });
        }
        return;
    });
};

const exportIacStacks = (format, screenFilters, freeTextSearch, sorting, iacType) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/export`, "POST", {
            format,
            screenFilters,
            freeTextSearch,
            sorting,
            iacType,
        });

        // product event
        sendEvent(iacEvents.export, { format });

        if (format === "csv") {
            return { req, result: await req.blob() };
        }
        return { req, result: await req.json() };
    });
};

const queryBackendsData = (screenFilters, pageSize, pageNumber, freeTextSearch = "") => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/backends`, "POST", {
            screenFilters,
            pageNumber,
            pageSize,
            freeTextSearch,
        });

        if (req?.ok) {
            const data = await req.json();
            dispatch({
                type: QUERY_BACKENDS_DATA,
                payload: data,
            });
            return data;
        }
        return;
    });
};
const queryRepositoriesData = (freeTextSearch, screenFilters) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/repositories`,
            "POST",
            { freeTextSearch, screenFilters },
        );

        if (req?.ok) {
            const data = await req.json();
            dispatch({
                type: QUERY_REPOSITORIES_DATA,
                payload: data,
            });
            return data;
        }
        return;
    });
};
const triggerVcsScan = (params) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/vcs-code/scan`,
            "POST",
            params,
        );

        if (req?.ok) {
            return true;
        }
        return false;
    });
};

const triggerScanNowLambda = (crawlerId) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/scan-now`, "POST", {
            crawlerId,
            forceUpdate: true,
        });
        return;
    });
};

const setFilters = (filters) => {
    return action(async (dispatch) => {
        dispatch({
            type: SET_IAC_FILTERS,
            payload: filters,
        });
    });
};

const getIacStack = (stackId) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/getStack`, "POST", {
            stackId,
        });
        let data = null;
        if (req?.ok) {
            data = await req.json();
            dispatch({
                type: GET_IAC_STACK,
                payload: data,
            });
        }
        return data;
    });
};

const getIac = (frn) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/getIac`, "POST", {
            frn,
        });
        let data = null;
        if (req?.ok) {
            data = await req.json();
            dispatch({
                type: GET_IAC,
                payload: data,
            });
        }
        return data;
    });
};

const getStackStateFilePreview = (stateLocalPath) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/getStackStateFilePreview`,
            "POST",
            { stateLocalPath },
            undefined,
            undefined,
            false,
        );
        let data = {};
        if (req?.ok) {
            data = await req.json();
            dispatch({
                type: GET_STACK_STATE_FILE_PREVIEW,
                payload: data,
            });
        }
        return data;
    });
};

const getStackMap = (payloadBody) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const res = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/map`,
            "POST",
            payloadBody,
            undefined,
            undefined,
            false,
        );
        dispatch({
            type: GET_STACK_MAP,
            payload: {},
        });
        const reqData = await res.json();
        return { ok: res.ok, imageUrl: reqData?.imageUrl };
    });
};

const getStackDiagram = (stateLocalPath, themeDark) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const res = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/diagram`,
            "POST",
            {
                stateLocalPath,
                theme: themeDark ? "dark" : "light",
            },
            undefined,
            undefined,
            false,
        );
        dispatch({
            type: GET_STACK_DIAGRAM,
            payload: {},
        });
        const reqData = await res.json();
        return { ok: res.ok, imageUrl: reqData?.imageUrl, message: reqData?.message };
    });
};

const setBackendRules = (crawlerId, rule, keys, shouldDelete = false) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/set-backends`,
            "POST",
            { crawlerId, rule, keys, shouldDelete },
        );
        return;
    });
};

const setGcsEncryption = (crawlerId, encryptionKey) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/set-backends/gcs`,
            "POST",
            { crawlerId, encryptionKey },
        );
        return req?.ok;
    });
};

const getEventDrivenCode = (crawlerId) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/getEventDrivenCode`,
            "POST",
            { crawlerId },
        );
        const data = await req.json();
        return data?.code || "";
    });
};

const getModulesBaseAggs = (screenFilters, freeTextSearch) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/modules-aggs`,
            "POST",
            { screenFilters, freeTextSearch },
        );

        if (req?.ok) {
            const data = await req.json();
            dispatch({
                type: GET_MODULES_BASE_AGGS,
                payload: data,
            });
            return data;
        }
        return;
    });
};

const queryModules = (size, freeTextSearch, screenFilters, page = 1, sortArr) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/modules`, "POST", {
            size,
            freeTextSearch,
            screenFilters,
            page,
            sortArr,
        });
        const data = await req.json();
        if (!size) {
            return dispatch({
                type: QUERY_MODULES_AGGS,
                payload: data,
            });
        }
        return dispatch({
            type: QUERY_MODULES,
            payload: data,
        });
    });
};

const queryModuleById = (moduleId) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/iacStacks/moduleById`,
            "POST",
            { moduleId },
        );
        const data = await req.json();
        dispatch({
            type: QUERY_MODULE_BY_ID,
            payload: data,
        });
        return data;
    });
};

const queryProviders = (screenFilters) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/iacStacks/providers`, "POST", {
            screenFilters,
        });

        if (req?.ok) {
            const data = await req.json();
            dispatch({
                type: QUERY_PROVIDERS,
                payload: data,
            });
            return data;
        }
        return;
    });
};

const setModulesFilters = (filters) => {
    return action(async (dispatch) => {
        dispatch({
            type: SET_MODULES_FILTERS,
            payload: filters,
        });
    });
};

const resetIacStacks = () => {
    return action(async (dispatch) => dispatch({ type: RESET_IAC_STACKS }));
};

const getControllerSources = (payload) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        let result = [];
        try {
            const res = await requestWrapper.sendRequest(
                `${BACKEND_URL}/relationship/getManagingController`,
                "POST",
                payload,
                undefined,
                undefined,
                false,
            );
            const reqData = await res.json();
            result = reqData?.responseObjects;
        } catch (err) {
            result = [];
        }
        dispatch({
            type: GET_CONTROLLER_SOURCES,
            payload: result,
        });
    });
};

const getStackStateFileDownload = (stateLocalPath) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        let data = null;
        try {
            const response = await requestWrapper.sendRequest(
                `${BACKEND_URL}/iacStacks/getStackStateFileDownload`,
                "POST",
                { stateLocalPath }
            );

            if (response?.ok) {
                data = await response.json();
                dispatch({
                    type: GET_STACK_STATE_FILE_DOWNLOAD,
                    payload: data
                });

                if (data?.downloadUrl) {
                    triggerFileDownload(data.downloadUrl, stateLocalPath.split('/').pop());
                }
            }
            return data;
        } catch (error) {
            return data;
        }
    });
};

export {
    getIacStacksBaseAggs,
    getIacStacksHits,
    exportIacStacks,
    queryBackendsData,
    queryRepositoriesData,
    setFilters,
    triggerScanNowLambda,
    getIacStack,
    getIac,
    getStackStateFilePreview,
    getStackMap,
    getStackDiagram,
    setBackendRules,
    setGcsEncryption,
    getEventDrivenCode,
    getModulesBaseAggs,
    queryModules,
    queryModuleById,
    queryProviders,
    setModulesFilters,
    resetIacStacks,
    getControllerSources,
    triggerVcsScan,
    getStackStateFileDownload,
};
