import React, { useEffect } from "react";
import auth0 from "auth0-js";
import { useAuth0 } from "@auth0/auth0-react";
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from "../../consts/config";
import { getSubdomain } from "../../utils/helpers";
import SplashScreen from "../../shared/splashScreen/splashScreen";
import { oktaOrganizations, azureOrganizations, pingOrganizations } from "../../consts/config";
import "./ssoLogin.scss";

const SsoLogin = () => {
  const { loginWithRedirect } = useAuth0();

  const auth = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    responseType: "token",
  });

  const ssoAuth = () => {
    const ssoSubDomain = getSubdomain();
    const redirect_uri = window.location.origin?.includes("stag") ? "https://app.stag.gofirefly.io" :
                         window.location.origin?.includes("env1") ? "https://app-env1.dev.firefly.ai" :
                         window.location.origin?.includes("env2") ? "https://app-env2.dev.firefly.ai" :
                         window.location.origin?.includes("env3") ? "https://app-env3.dev.firefly.ai" :
                         "https://app.firefly.ai";
    let authPayload = {};
    // OKTA AUTH PAYLOAD
    if (oktaOrganizations?.includes(ssoSubDomain)) {
      authPayload = {
        connection: `okta-${ssoSubDomain}`,
        redirect_uri,
      };
    }

    // AZURE AUTH PAYLOAD
    if (azureOrganizations?.includes(ssoSubDomain)) {
      authPayload = {
        connection: `azure-${ssoSubDomain}`,
        redirect_uri, //adition for Azure
      };
    }

    // PING AUTH PAYLOAD
    if (pingOrganizations?.includes(ssoSubDomain)) {
      authPayload = {
        connection: `ping-${ssoSubDomain}`,
        redirect_uri, //adition for Ping
      };
    }

    // if no match for subdomain in the any of the SSO array's
    if(![...oktaOrganizations, ...azureOrganizations, ...pingOrganizations]?.includes(ssoSubDomain)) {
      return loginWithRedirect();
    }

    auth.authorize(authPayload, function (err) {
      if (err) {
        return loginWithRedirect();
      }
    });
  };

  useEffect(() => {
    ssoAuth();
  }, []);

  return <SplashScreen />;
};

export default SsoLogin;
