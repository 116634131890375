import { isEmpty, isObject } from "lodash";
import CodeDiff from "../../../../shared/codeDiff/codeDiff";
import "./mutation.scss";
import { v4 as uuidv4 } from "uuid";

const Mutation = ({ mutations = {} }) => {
    const attributes = mutations?.eventPayload?.attributes;

    const handleSetComapreBlock = (obj) => {
        if (isObject(obj)) {
            const str = JSON.stringify(obj, null, 1);
            return str.slice(1, str.length - 1);
        }
        if (isEmpty(obj)) {
            return "";
        }
        return obj;
    };

    return (
        <div className="Mutation">
            <div className="Mutation__header row between">
                <span className="Mutation__before">Before</span>
                <span className="Mutation__after">After</span>
            </div>
            <div className="Mutation__attributes">
                {attributes.map((attribute) => {
                    if (attribute?.before === attribute?.after) {
                        return;
                    }
                    return (
                        <CodeDiff
                            key={uuidv4}
                            lang="text"
                            oldValue={handleSetComapreBlock(
                                `${attribute?.key}: ${attribute?.before}`,
                            )}
                            newValue={handleSetComapreBlock(
                                `${attribute?.key}: ${attribute?.after}`,
                            )}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Mutation;
