import React, { useState } from 'react';
import AppBtn from '../../shared/appBtn/appBtn';
import WorkflowManagement from '../../components/ciSteps/workflowManagement/workflowManagement';
import { ReactComponent as WorkflowIconImg } from '../../Images/ci/workflow-icon-img.svg';
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import ModalVideo from 'react-modal-video';
import tvIcon from "../../Images/general_icons/welcome-block-users/tv.svg";
import ReactPlayer from 'react-player';


const EmptyWorkflows = ({ showCreateWorkflowModal, setShowCreateWorkflowModal, handleOpenWorkflowManagement }) => {
    const videoId = "M4SgPTQ4Bgk";
    const { t } = useTranslation("ci-pipeline");

    const [isOpen, setOpen] = useState(false);
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    return (
        <div className="basic-card CiSteps__empty">
             <div className="CiSteps__empty-container col">
                <WorkflowIconImg className="CiSteps__empty-img"/>
                <span className="font-16 bold">{t("empty.title")}</span>
                <span className="CiSteps__empty-description centered">{t("empty.description")}</span>
                <div className="CiSteps__empty-divider"/>
                <div className="row CiSteps__empty-whyFirefly">
                    <Trans i18nKey="ci-pipeline:empty.whyFirefly"
                    components={[<span className="purple-text" key="1"/>,
                        <span className="CiSteps__empty-whyFirefly-gradientText" key="2"/>,
                        <span className="purple-text" key="3"/>,
                    ]}/>
                </div>
                <div className="col g10 center">
                      <ReactPlayer
                        url={`https://www.youtube.com/watch?v=${videoId}`}
                        muted={false}
                        controls
                        light={true}
                        width="450px"
                        height="225px"
                        config={{
                            youtube: {
                                playerVars: { showinfo: 2 },
                            },
                        }}
                    />
                    <div className="CiSteps__link-container">
                        <div>
                            <img src={tvIcon} />
                        </div>
                        <div className="CiSteps__text-link" onClick={()=>setOpen(true)}>
                            {t("empty.video-text")}
                        </div>
                    </div>
                </div>
                <div className="CiSteps__empty-bullets row g20">
                    <div className="CiSteps__empty-bullets-item col g10">
                        <div className="CiSteps__empty-bullets-item-point purple"/>
                        <span className="bold">{t("empty.bullets.1.title")}</span>
                        <span className="centered">{t("empty.bullets.1.description")}</span>
                    </div>
                    <div className="CiSteps__empty-bullets-item col g10">
                        <div className="CiSteps__empty-bullets-item-point blue"/>
                        <span className="bold centered">{t("empty.bullets.2.title")}</span>
                        <span className="centered">{t("empty.bullets.2.description")}</span>
                    </div>
                    <div className="CiSteps__empty-bullets-item col g10">
                        <div className="CiSteps__empty-bullets-item-point green"/>
                        <span className="bold">{t("empty.bullets.3.title")}</span>
                        <span className="centered">{t("empty.bullets.3.description")}</span>
                    </div>
                </div>
                <AppBtn text={t("empty.create-btn")} onClick={() => handleOpenWorkflowManagement()} icon={<FontAwesomeIcon icon="plus" />} disabled={isViewer}/>
                {showCreateWorkflowModal && <WorkflowManagement modalVisible={showCreateWorkflowModal} setModalVisible={setShowCreateWorkflowModal} isEditMode={false} workflowId={""}/>}
              </div>
                <ModalVideo
                    channel="youtube"
                    youtube={{ mute: 0, autoplay: 0 }}
                    isOpen={isOpen}
                    videoId={videoId}
                    onClose={() => setOpen(false)} 
                />  
            </div>  
    );
};

export default EmptyWorkflows;