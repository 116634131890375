import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, clearAllFilters } from "../redux/actions/eventCenterActions";

export const useEventCenterFilters = () => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.eventCenterReducer.filters);

  const updateFilter = useCallback((filterKey, value, dateRange) => {
    dispatch(setFilter(filterKey, value, dateRange));
  }, []);

  const resetFilters = useCallback(() => {
    dispatch(clearAllFilters());
  }, []);

  return {
    filters,
    updateFilter,
    resetFilters
  };
}
