import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Typography } from "antd";
import AssetCountFlag from "../../../shared/assetCountFlag/assetCountFlag";
import AssetIacLine from "../../../shared/assetIacLine/assetIacLine";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./serviceListItem.scss";

const ServiceListItem = ({ name, count, iacCoverage, onClick, onClear }) => {
  const { Text } = Typography;

  const selectedService = useSelector(
    (state) => state.inventoryReducer.selectedService
  );

  const handleClickArrow = (event) => {
    if (selectedService?.key !== name) {
      return;
    }
    event.stopPropagation();
    return onClear();
  };

  return (
    <div
      className={`ServiceListItem ${
        selectedService?.key !== name ? "notActive" : "active"
      }`}
      onClick={onClick}
    >
      <div className="ServiceListItem__nameCol row">
        <FontAwesomeIcon
          icon="chevron-down"
          className="ServiceListItem__nameCol-arrow"
          style={{
            transform: selectedService?.key !== name ? "rotate(-90deg)" : "",
          }}
          onClick={(e) => handleClickArrow(e)}
        />
        <div className="ServiceListItem__nameCol-imgWrapper"><AssetTypeImage assetType={name} /></div>
        <Text ellipsis>{formatAwsStringWithUnderscore(name)}</Text>
      </div>
      <AssetCountFlag sum={count} type="asset" />
      <AssetIacLine data={iacCoverage || []} />
    </div>
  );
};

export default memo(ServiceListItem);
