import React from "react";
import capitalize from "lodash/capitalize";
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FreeTierMask from "../../components/inventory/freeTierMask/freeTierMask";
import { purpleIacProviders, greenIacProviders, states } from "../../utils/icons";
import { capitalizeFirst, formatCodifyTabName, formatIacStatus } from "../../utils/formatting";
import Tooltip from "antd/lib/tooltip";
import { RESOURCE_STATE_TYPES, IAC_TYPES } from "../../consts/general";
import { useTranslation } from "react-i18next";
import { ReactComponent as IncreaseCostIcon } from "../../Images/general_icons/integration-filter-icons/dollar_increase.svg";
import { ReactComponent as GhostNewIcon } from "../../Images/general_icons/ghost_new_version.svg";
import { ReactComponent as ClickOpsIcon } from "../../Images/general_icons/event_center/clickOps.svg";
import "./iacStatusFlag.scss";
import isUndefined from "lodash/isUndefined";

const PENDING_CODIFIED_SUPPORT = ['externalDns', 'crossPlane', 'eksctl', 'gke', 'goog-dm', 'composer', 'airflow', 'flux'];

const IacStatusFlag = ({
  state,
  iacType,
  handleOpenAnalysis,
  rowData = {},
  iacTab,
  handleOpenMulti,
  modal,
  dashboard,
  isLocked,
  hasControllerSources,
  isAssetDataModal,
}) => {
  const { t } = useTranslation('inventory');
  const { provider = "", isBetaProvider = false } = rowData;
  const isPendingGhost = rowData?.pendingTo === 'ghost';
  
  const childState = () => {
    return (
      <Tooltip title={t('tooltips.child')}>
      <div className={`IacStatusFlag ${state}Flag row ${ 
              modal
              ? "modal"
              : dashboard
              ? "dashboardFlag"
              : "inventory-table-flag"
          }`}
        >
            <img
              src={states['child']}
              className="IacStatusFlag-icon"
              alt="IaC icon"
            />
          <span>Child</span>
        </div>
      </Tooltip>
    );
  };

  const iacIgnoredState = () => {
    return (
      <Tooltip title={`IaC-Ignored rule: ${rowData?.exclusionRule}`}>
      <div className={`IacStatusFlag ${state}Flag row ${ 
              modal
              ? "modal"
              : dashboard
              ? "dashboardFlag"
              : "inventory-table-flag"
          }`}
        >
            <img
              src={states['iacIgnored']}
              className="IacStatusFlag-icon"
              alt="IaC icon"
            />
          <span>IaC-Ignored</span>
        </div>
      </Tooltip>
    );
  };

  let output = "";
  switch (state) {
    case RESOURCE_STATE_TYPES.unmanaged:
        output = (
            <div className="row IacStatusFlag__unmanagedFlag">
                {rowData?.eventType === "click_ops" && <ClickOpsIcon className="IacStatusFlag__clickOps" />}
                <div className={`IacStatusFlag ${state}Flag row ${ 
                        iacTab
                        ? "iacTab"
                        : modal
                        ? "modal"
                        : dashboard
                        ? "dashboardFlag"
                        : rowData?.eventType !== "click_ops" ? "inventory-table-flag" : ""
                    }`}
                    >
                    <FontAwesomeIcon icon={"exclamation-circle"} />
                    <span>{capitalize(state)}</span>
                </div>
            </div>
        );
        break;
    case RESOURCE_STATE_TYPES.undetermined: 
      const isUndertermined = state === RESOURCE_STATE_TYPES.undetermined;
      output = (
        <Tooltip title={t('tooltips.undetermined')}>
        <div className={`IacStatusFlag ${state}Flag row ${ 
              iacTab && !isUndertermined
                ? "iacTab"
                : modal
                ? "modal"
                : dashboard
                ? "dashboardFlag"
                : "inventory-table-flag"
            }`}
          >
            <FontAwesomeIcon icon={"question"} />
            <span>{capitalize(state)}</span>
          </div>
        </Tooltip>
      );
      break;

    case RESOURCE_STATE_TYPES.child: 
      output = childState();
      break;
    
    case RESOURCE_STATE_TYPES.iacIgnored: 
      output = iacIgnoredState();
      break;

    case RESOURCE_STATE_TYPES.managed:
      const isTerraformPartialSupport = IAC_TYPES.terraform === iacType && isBetaProvider;
      const isPendingCodified = (PENDING_CODIFIED_SUPPORT.includes(iacType) && !hasControllerSources) || isTerraformPartialSupport;
      const tooltipTitle = isPendingCodified ? t(`tooltips.pendingCodified${isTerraformPartialSupport ? capitalizeFirst(provider) : ''}`): "";
      output = (
        <Tooltip
          placement="top"
          title={tooltipTitle}
         >
          <div
            className={`IacStatusFlag managedFlag${isPendingCodified ? ' pending' : ''} row ${
              iacTab
                ? "iacTab"
                : modal
                ? "modal-managedFlag"
                : dashboard
                ? "dashboardFlag dashboardFlag-managedFlag"
                : "inventory-table-flag"
            }`}
          >
            {!isEmpty(iacType) && (
              <img
                src={greenIacProviders[iacType]}
                className="IacStatusFlag-icon"
                alt="IaC icon"
              />
            )}
            {dashboard && <FontAwesomeIcon icon="code" />}
            <span>Codified</span>
          </div>
        </Tooltip>
      );
      break;
    case RESOURCE_STATE_TYPES.ghost:
      if (handleOpenAnalysis && (!isEmpty(rowData) || modal)) {
        return (
          <div className="row" style={{ gap: "10px", cursor: "pointer" }}>
            <GhostNewIcon className="IacStatusFlag-icon ghost" />
            <div className={`IacStatusFlag driftFlag row ${iacTab ? "iacTab" : modal ? "modal" : ""}`}>
              {!isEmpty(iacType) ? (
                  <img
                      src={purpleIacProviders[iacType]}
                      className="IacStatusFlag-icon"
                      alt="IaC icon"
                  />
              ) : (
                  <FontAwesomeIcon icon="code-branch"/>
              )}
              <span>Drifted</span>
            </div>
            {isAssetDataModal && <span className="font-12 bold">Ghost resource: found in {formatCodifyTabName(iacType)} state but missing from cloud infrastructure</span>}
          </div>
        )
      }
      break;
    case RESOURCE_STATE_TYPES.modified:
      if (handleOpenAnalysis && (!isEmpty(rowData) || modal)) {
        output = isLocked ? (
          <FreeTierMask noBlur>
            <div
              className="row"
              style={{ gap: "10px", cursor: "pointer" }}
            >
                {!isUndefined(rowData?.costDelta) && parseFloat(rowData?.costDelta).toFixed(2) > 0 ? (
                    <div className="row g5">
                        <Tooltip title={
                            <div className="IacStatusFlag__cost-tooltip-header col g10">
                                <span className="IacStatusFlag__cost-tooltip-header-title">Cost Impact (per month)</span>
                                <span className='IacStatusFlag__cost-tooltip-header-content col g8'>
                        <span>Desired: ${parseFloat(rowData?.monthlyStateCostUSD).toFixed(2)}</span>
                        <span>Actual: ${parseFloat(rowData?.monthlyCloudCostUSD).toFixed(2)}</span>
                        <span>Delta: ${parseFloat(rowData?.costDelta).toFixed(2)}</span>
                      </span>
                            </div>} overlayClassName="IacStatusFlag__cost-tooltip">
                            <div className="row g5">
                                <IncreaseCostIcon className="IacStatusFlag-analyzer" />
                            </div>
                        </Tooltip>
                    </div>
                ):
                (<FontAwesomeIcon
                    icon="search"
                    className="purple-text IacStatusFlag-analyzer"
                />)}
              <div
                className={`IacStatusFlag driftFlag row ${
                  iacTab ? "iacTab" : modal ? "modal" : ""
                }`}
              >
                {!isEmpty(iacType) ? (
                  <img
                    src={purpleIacProviders[iacType]}
                    className="IacStatusFlag-icon"
                    alt="IaC icon"
                  />
                ) : (
                  <FontAwesomeIcon icon="code-branch" />
                )}
                <span>Drifted</span>
              </div>
            </div>
          </FreeTierMask>
        ) : (
            <div
                className="row"
                style={{gap: "10px", cursor: "pointer"}}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isLocked) {
                    return;
                  }
                  handleOpenAnalysis(rowData, true);
                }}
            >
                {!isUndefined(rowData?.costDelta) && parseFloat(rowData?.costDelta).toFixed(2) > 0 ? (
                    <div className="row g5">
                        <Tooltip title={
                            <div className="IacStatusFlag__cost-tooltip-header col g10">
                                <span className="IacStatusFlag__cost-tooltip-header-title">Cost Impact (per month)</span>
                                <span className='IacStatusFlag__cost-tooltip-header-content col g8'>
                            <span>Desired: ${parseFloat(rowData?.monthlyStateCostUSD).toFixed(2)}</span>
                            <span>Actual: ${parseFloat(rowData?.monthlyCloudCostUSD).toFixed(2)}</span>
                            <span>Delta: ${parseFloat(rowData?.costDelta).toFixed(2)}</span>
                          </span>
                            </div>} overlayClassName="IacStatusFlag__cost-tooltip">
                            <div className="row g5">
                                <IncreaseCostIcon className="IacStatusFlag-analyzer" />
                            </div>
                        </Tooltip>
                    </div>
                ):
                    (<FontAwesomeIcon
                  icon="search"
                  className="purple-text IacStatusFlag-analyzer"
              />)}
              <div
                  className={`IacStatusFlag driftFlag row ${
                      iacTab ? "iacTab" : modal ? "modal" : ""
                  }`}
              >
                {!isEmpty(iacType) ? (
                    <img
                        src={purpleIacProviders[iacType]}
                        className="IacStatusFlag-icon"
                        alt="IaC icon"
                    />
                ) : (
                    <FontAwesomeIcon icon="code-branch"/>
                )}
                <span>Drifted</span>
              </div>
            </div>
        );
      } else {
        output = (
            <div
                className={`IacStatusFlag driftFlag row ${
                    iacTab
                        ? "iacTab"
                        : modal
                            ? "modal"
                            : dashboard
                                ? "dashboardFlag"
                                : "inventory-table-flag"
                }`}
            >
            {!isEmpty(iacType) ? (
              <img
                src={purpleIacProviders[iacType]}
                className="IacStatusFlag-icon"
                alt="IaC icon"
              />
            ) : (
              <FontAwesomeIcon icon="code-branch" />
            )}
            <span>Drifted</span>
          </div>
        );
      }
      break;
      case RESOURCE_STATE_TYPES.deleted:
        output = (
          <div
            className={`IacStatusFlag deletedFlag row`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon icon={["far", "trash-alt"]} />
            <span>{has(rowData, 'prevState') && !isEmpty(rowData?.prevState) ? formatIacStatus(rowData?.prevState) : capitalize(state)}</span>
          </div>
        );
      break;

    case RESOURCE_STATE_TYPES.pending:
      output = (
        <Tooltip
        placement="top"
        title={t('tooltips.pending')}
      >
        <div className="row g8">
        {isPendingGhost && <GhostNewIcon className="IacStatusFlag-icon ghost" /> }
          <div
            className={`IacStatusFlag row 
            ${isPendingGhost ? 'driftFlag pending' : ''} 
            ${rowData?.pendingTo === 'unmanaged' ? 'unmanagedPendingFlag' : ''} 
            ${iacTab ? "iacTab" : modal ? "modal" : dashboard ? "dashboard" : "inventory-table-flag"}`}
          >
            <FontAwesomeIcon icon="hourglass" />
            <span>{isPendingGhost ? "Drifted" : (capitalize(rowData?.pendingTo) || 'Pending')}</span>
          </div>
        </div>

        </Tooltip>
      );
      break;
    default:
      break;
  }
  return output;
};

export default React.memo(IacStatusFlag);
