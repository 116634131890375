import { useEffect, useState } from "react";
import { getTerraformModule } from "./azureFormData";
import { createKeyPair } from "../../../redux/actions/keyPairsActions";
import { useAuth0 } from "@auth0/auth0-react";
import DarkCopyBox from "../../../shared/darkCopyBox/darkCopyBox";
import { useDispatch } from "react-redux";
import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";
import { ReactComponent as NoteBulb } from "../../../Images/integrations/azure/noteBulb.svg";

const AzureTerraformModule = ({ wizardState = {} }) => {
    const { user } = useAuth0();
    const [terraformModuleScript, setTerraformModuleScript] = useState("");
    const [keyPair, setKeyPair] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchKeyPair = async () => {
            setLoading(true);
            const keyPair = await dispatch(createKeyPair(user?.name));
            setKeyPair(keyPair);
        };
        fetchKeyPair();
    }, []);

    useEffect(() => {
        const fetchTerraformModule = async () => {
            const script = await getTerraformModule(wizardState, keyPair);
            setTerraformModuleScript(script);
            setLoading(false);
        };

        if (keyPair) fetchTerraformModule();
        if (!keyPair && loading) setLoading(false);
    }, [keyPair]);

    return loading ? (
        <div className="center mgTtm10">
            <SpinnerLoader />
        </div>
    ) : terraformModuleScript ? (
        <div>
            <h1>Azure Terraform Module</h1>
            <p>Copy the provided code to a Terraform file inside a new directory</p>
            <div className="azureTerraformModule__note">
                <div className="azureTerraformModule__content">
                    <NoteBulb />
                    <div className="azureTerraformModule__content-text">
                        Note: Add your own{" "}
                        <a
                            href="https://registry.terraform.io/providers/hashicorp/azurerm/latest/docs#example-usage"
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                        >
                            azurerm
                        </a>{" "}
                        provider version in the config. For usage details, check out the{" "}
                        <a
                            href="https://github.com/gofireflyio/terraform-firefly-azure-onboarding?tab=readme-ov-file#firefly-azure-integration"
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                        >
                            README
                        </a>
                        .
                    </div>
                </div>
            </div>
            <DarkCopyBox text={terraformModuleScript} />
        </div>
    ) : (
        <div>We encountered an issue while generating the Terraform. Please try again.</div>
    );
};

export default AzureTerraformModule;
