import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableWrapperV2 from "../../../shared/tableWrapperV2/tableWrapperV2";
import IacStatusFlag from "../../../shared/iacStatusFlag/iacStatusFlag";
import OwnerIndication from "../ownerIndication/ownerIndication";
import AssetNameCell from "./assetNameCell";
import AssetDateCell from "./assetDateCell";
import AssetPropertiesCell from "./assetPropertiesCell";
import AssetDataSourceCell from "./assetDataSourceCell";
import AssetFlagsCell from "./assetFlagsCell";
import filter from "lodash/filter";
import AssetTypeCell from "./assetTypeCell";
import AssetStacksCell from "./assetStacksCell";
import CustomColumnVisibilityPanel from "../../../shared/tableWrapperV2/components/columnVisibilityPanel/columnVisibilityPanel";
import { useDispatch, useSelector } from "react-redux";
import { updateInventoryColumnVisibilityModel } from "../../../redux/actions/userPreferencesActions";
import { defaultInventoryColumnVisibilityModel } from '../../../consts/inventory'
import isUndefined from "lodash/isUndefined";
import Tooltip from "antd/lib/tooltip";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";
import { ReactComponent as GhostNewIcon } from "../../../Images/general_icons/ghost_new_version.svg";

import { ReactComponent as IncreaseCostIcon } from "../../../Images/general_icons/integration-filter-icons/dollar_increase.svg";
import { setDefaultInventorySorting, setFilters } from "../../../redux/actions/inventoryv3Actions";
import { RESOURCE_STATE_TYPES } from "../../../consts/general";

const inventoryTable = ({
  tableData,
  loading,
  tablePageSize,
  serverSide,
  rowCount,
  handleSelectedRowsArr,
  handlePageChange,
  handleSortChange,
  onPageSizeChange,
  onRowClick,
  disableSelectionOnClick,
  refresh,
  selectedRows,
  handleToggleSelectedView,
  selectedAssetsView,
  isDeleteMode,
  isFreeTier,
  excludedAssets,
  classifications,
  height,
  providerIntegrations,
  handleOpenAnalysis,
  handleOpenMulti,
  handleOpenInsights,
  handleTagClicked,
  handleOpenCommentsTab,
  handleClickOwner,
  handleOpenHistoryTab,
  handleOpenGit,
  handleOpenConnectionTab,
  movePagination,
  isCheckBox,
  getTreeDataPath,
  treeData,
  showRelationFlag,
  setLoading
}) => {
  const { t } = useTranslation("inventory");
  const dispatch = useDispatch();
  const [didMount, setDidMount] = useState(false);

  const inventory_columnVisibilityModel = useSelector(
    (state) => state.userPreferencesReducer.inventory_columnVisibilityModel
  );
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const inventoryFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);
  const inventoryDefaultSorting = useSelector((state) => state.inventoryReducer.inventoryDefaultSorting); 
  const isCostDeltaVisible = inventoryDefaultSorting?.[0]?.field === "costDelta";

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(inventory_columnVisibilityModel);

  useEffect(() => {
    if(didMount){
      dispatch(updateInventoryColumnVisibilityModel(columnVisibilityModel));
    }else{
      setDidMount(true)
    }
    return () => {
      isCostDeltaVisible && dispatch(setDefaultInventorySorting([]));
    }
  }, [columnVisibilityModel])

  const columns = [
    {
      headerName: t("table.columns.dataSource"),
      field: "integrationId",
      filterable: false,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <AssetDataSourceCell
            row={params?.row}
            providerIntegrations={providerIntegrations}
          />
        );
      },
      hideFromTree: true,
    },
    {
      headerName: t("table.columns.assetType"),
      field: "assetType",
      filterable: false,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => (
        <AssetTypeCell row={params?.row} />
      ),
      hideFromTree: true,
    },
    {
      headerName: t("table.columns.name"),
      field: "name",
      minWidth: 50,
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        <AssetNameCell
          value={params.value}
          freeTier={params.row?.isLocked && isFreeTier}
        />
      ),
    },
    {
      headerName: t("table.columns.id"),
      field: "arn",
      minWidth: 50,
      flex: 1,
      valueGetter: ({ row }) => {
        if (row?.state === 'ghost' || row?.state === 'pending') {
          return row?.frn;
        }
        return row?.arn;
      },
      filterable: false,
    },
    {
      headerName: t("table.columns.state"),
      field: "state",
      filterable: false,
      minWidth: 125,
      flex: 1,
      renderCell: (params) => (
        <IacStatusFlag
          state={params.row?.state}
          iacType={params.row?.iacType}
          hasControllerSources={params.row?.hasControllerSources}
          handleOpenAnalysis={handleOpenAnalysis}
          rowData={params.row}
          handleOpenMulti={handleOpenMulti}
          isLocked={params.row?.isLocked && isFreeTier}
        />
      ),
      hideFromTree: true,
    },
    {
      headerName: t("table.columns.driftCost"),
      field: "costDelta",
      filterable: true,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => (
          !isUndefined(params.row?.costDelta) && parseFloat(params.row?.costDelta).toFixed(2) > 0 && (
            <div className="row g5">
              <Tooltip title={
                <div className="IacStatusFlag__cost-tooltip-header col g10">
                  <span className="IacStatusFlag__cost-tooltip-header-title">Cost Impact (per month)</span>
                  <span className='IacStatusFlag__cost-tooltip-header-content col g8'>
                            <span>Desired: ${parseFloat(params.row?.monthlyStateCostUSD).toFixed(2)}</span>
                            <span>Actual: ${parseFloat(params.row?.monthlyCloudCostUSD).toFixed(2)}</span>
                            <span>Delta: ${parseFloat(params.row?.costDelta).toFixed(2)}</span>
                          </span>
                </div>} overlayClassName="IacStatusFlag__cost-tooltip">
                <div className="row g5">
                  <span>${parseFloat(params.row?.costDelta).toFixed(2)}</span>
                  <IncreaseCostIcon/>
                </div>
              </Tooltip>
            </div>
        )
      ),
      hideFromTree: true,
    },
    {
      headerName: t("table.columns.stacks"),
      field: "stacks",
      renderCell: (params) => (
        <AssetStacksCell
          data={params?.row}
        />
      ),
      filterable: false,
      sortable: false,
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: t("table.columns.location"),
      field: "region",
      filterable: false,
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: t("table.columns.properties"),
      field: "assetId",
      sortable: false,
      filterable: false,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        const isGhost = params.row?.state === RESOURCE_STATE_TYPES.ghost;
        return (
          <div className="row g5">
            {isGhost && 
            <span className="purple-flag row g5 InventoryTableWrapper__row-ghostProperty" onClick={(e) => {
              e.stopPropagation();
              dispatch(setFilters({ ...inventoryFilters, state: [RESOURCE_STATE_TYPES.ghost] }));
            }}>
                <GhostNewIcon/>
                ghost
            </span>
            }
            <AssetPropertiesCell
              value={params.value}
              row={params?.row}
              onClickTag={handleTagClicked}
              isFreeTier={params.row?.isLocked && isFreeTier}/>
          </div>
      )},
    },
    {
      headerName: t("table.columns.owner"),
      field: "ownerData",
      type: "boolean",
      filterable: false,
      sortable: false,
      minWidth: 50,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <OwnerIndication
          data={params?.value}
          handleClickOwner={handleClickOwner}
        />
      ),
    },
    {
      headerName: t("table.columns.updated"),
      field: "lastConfigurationChangeTime",
      filterable: false,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => (
        <AssetDateCell
          value={params?.value}
          isFreeTier={isFreeTier}
          isDeleteMode={true}
        />
      ),
    },
    {
      headerName: isDeleteMode
        ? t("table.columns.deleted")
        : t("table.columns.created"),
      field: isDeleteMode ? "deletedAt" : "resourceCreationDate",
      filterable: false,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => (
        <AssetDateCell
          value={params?.value}
          isFreeTier={isFreeTier}
          isDeleteMode={isDeleteMode}
        />
      ),
    },
    {
      headerName: t("table.columns.flags"),
      field: "resourceStatus",
      minWidth: 50,
      width: 230,
      filterable: false,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <AssetFlagsCell
          value={params.value}
          row={params?.row}
          isFreeTier={params.row?.isLocked && isFreeTier}
          classifications={classifications}
          handleOpenInsights={(e) => {
            e.stopPropagation();
            sendEvent(inventoryEvents.clickedInsightsRowFlagFilter, { });
            handleOpenInsights(params?.row);
          }}
          handleOpenGit={(e) => {
            e.stopPropagation();
            sendEvent(inventoryEvents.clickedVcsRowFlagFilter, { });
            handleOpenGit(params?.row);
          }}
          handleOpenHistoryTab={(e) => {
            e.stopPropagation();
            sendEvent(inventoryEvents.clickedMutationsRowFlagFilter, { });
            handleOpenHistoryTab(params?.row);
          }}
          handleOpenCommentsTab={(e) => {
            e.stopPropagation();
            sendEvent(inventoryEvents.clickedCommentsRowFlagFilter, { });
            handleOpenCommentsTab(params?.row);
          }}
          handleOpenConnectionTab={(e) => {
            e.stopPropagation();
            sendEvent(inventoryEvents.clickedRelationshipsRowFlagFilter, { });
            handleOpenConnectionTab(params?.row);
          }}
          excludedAssets={excludedAssets}
          showRelationFlag={showRelationFlag}
        />
      ),
    },
  ];

    useEffect(() => {
        if(setLoading){
            setLoading(false);
        }
    }, [tableData]);


    useEffect(() => {
        if(searchVal !== "" && setLoading){
            setLoading(true);
        }
    }, [searchVal]);


  const tableColumns = treeData ? filter(columns, (i) => !i?.hideFromTree) : columns;

  return (
    <TableWrapperV2
      rowKey="id"
      screenType="inventory"
      tableData={tableData}
      height={height}
      loading={loading}
      isCheckBox={isCheckBox}
      pageSize={tablePageSize}
      serverSide={serverSide}
      movePagination={movePagination}
      rowCount={rowCount}
      handleSelectedRowsArr={handleSelectedRowsArr}
      handlePageChange={handlePageChange}
      handleSortChange={handleSortChange}
      onPageSizeChange={onPageSizeChange}
      initialSortingField = {isCostDeltaVisible ? "costDelta" : false}
      onRowClick={onRowClick}
      disableSelectionOnClick={disableSelectionOnClick}
      refresh={refresh}
      keepSelectedRows
      selectedRows={selectedRows}
      handleToggleSelectedView={handleToggleSelectedView}
      selectedAssetsView={selectedAssetsView}
      getTreeDataPath={getTreeDataPath}
      treeData={treeData}
      columns={tableColumns}
      treeColumnTitle={t("table.columns.assetType")}
      ColumnMenu={(menuProps)=>  {
        return (
          <CustomColumnVisibilityPanel
            defaultColumnVisibilityModel={defaultInventoryColumnVisibilityModel}
            menuProps={menuProps}
            tableColumns={tableColumns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityChange={updatedValues => setColumnVisibilityModel(updatedValues)}
          />
        )
      }}
      onColumnVisibilityModelChange={values => setColumnVisibilityModel({...columnVisibilityModel, ...values})}
      columnVisibilityModel={isCostDeltaVisible ? { ...columnVisibilityModel, costDelta: true } : columnVisibilityModel}
    />
  );
};

export default inventoryTable;
