import { ciDataSourceIcons } from "../../../../utils/icons";

export const CI_TOOLS = {
  azure: "azure-pipelines",
  github: "github-actions",
  gitlab: "gitlab-pipelines",
  bitbucket: "bitbucket-pipelines",
  codecommit: "codecommit-pipelines",
  jenkins: "jenkins",
}
export const AZURE_PROVIDER_TYPES = {
  aws: "AWS",
  azure: "Azure",
}

export const CI_TOOLS_OPTIONS =  [
  {
    name: CI_TOOLS.github,
    tooltip: "GitHub Actions",
    icon: ciDataSourceIcons("github-actions"),
  },
  {
    name: CI_TOOLS.azure,
    tooltip: "Azure Pipelines",
    icon: ciDataSourceIcons("azure-pipelines"),
  },
  {
    name: CI_TOOLS.gitlab,
    tooltip: "GitLab Pipelines",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("gitlab-pipelines"),
  },
  {
    name: CI_TOOLS.bitbucket,
    tooltip: "Bitbucket Pipelines",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("bitbucket-pipelines"),
  },
  {
    name: CI_TOOLS.codecommit,
    tooltip: "CodeCommit Pipelines",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("codecommit-pipelines"),
  },
  {
    name: CI_TOOLS.jenkins,
    tooltip: "Jenkins",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("jenkins"),
  },
];

export const CI_TOOLS_OPTIONS_FOR_INTEGRATE_STEP = [
  CI_TOOLS_OPTIONS[0],
  CI_TOOLS_OPTIONS[1],
  { ...CI_TOOLS_OPTIONS[2], disabled: false, comingSoon: false },
  { ...CI_TOOLS_OPTIONS[3], disabled: false, comingSoon: false },
  {
    name: "atlantis",
    tooltip: "Atlantis",
    icon: ciDataSourceIcons("atlantis"),
  },
  { ...CI_TOOLS_OPTIONS[5], disabled: false, comingSoon: false },
  {
    name: "semaphore",
    tooltip: "Semaphore",
    icon: ciDataSourceIcons("semaphore"),
  },
  {
    name: "env0",
    tooltip: "env0",
    icon: ciDataSourceIcons("env0"),
  }
];

export const CI_TOOLS_INTEGRATE_LINKS = {
  [CI_TOOLS.github]: "https://github.com/gofireflyio/workflows-examples/tree/main/github",
  [CI_TOOLS.azure]: "https://github.com/gofireflyio/workflows-examples/tree/main/azure%20devops",
  [CI_TOOLS.gitlab]: "https://github.com/gofireflyio/workflows-examples/tree/main/gitlab",
  [CI_TOOLS.bitbucket]: "https://github.com/gofireflyio/workflows-examples/blob/main/bitbucket-pipelines.yml",
  "atlantis": "https://github.com/gofireflyio/workflows-examples/blob/main/atlantis.yaml",
  [CI_TOOLS.jenkins]: "https://github.com/gofireflyio/workflows-examples/blob/main/Jenkinsfile",
  "semaphore": "https://github.com/gofireflyio/workflows-examples/blob/main/semaphore.yml",
  "env0": "https://github.com/gofireflyio/workflows-examples/blob/main/env0.yml",
}
