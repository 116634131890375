import React from "react";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import compact from "lodash/compact";

import { WORKFLOWS_ACTIONS_MAP } from "../../../../consts/ci-workflows";
import { ReactComponent as CreateDeleteIcon } from "../../../../Images/ci/icons/createdelete.svg";
import { ReactComponent as DeleteCreateIcon } from "../../../../Images/ci/icons/deletecreate.svg";
import { ReactComponent as TagsIcon } from '../../../../Images/ci/guardrails/tag.svg';
import { ReactComponent as NoTagsIcon } from "../../../../Images/ci/no-tags.svg";
import { ReactComponent as DecreaseCostIcon } from "../../../../Images/general_icons/integration-filter-icons/dollar_decrease.svg";
import { ReactComponent as IncreaseCostIcon } from "../../../../Images/general_icons/integration-filter-icons/dollar_increase.svg";
import { ReactComponent as RemediationIcon } from "../../../../Images/general_icons/remediation.svg";
import AssetTypeImage from "../../../../shared/assetTypeImage/assetTypeImage";
import { GUARDRAILS_TYPES, formatCostAmount, TAG_ENFORCEMENTS_MODES, REQUIRED_TAGS, REQUIRED_VALUES } from "../../../guardrails/guardrailsModal/guardrailsHelper";
import { capitalizeFirst, formatAwsStringWithUnderscore } from "../../../../utils/formatting";

const actionsIcons = {
    create: <FontAwesomeIcon className="PostPlanTable__action-icon create" icon="plus-circle" />,
    delete: <FontAwesomeIcon className="PostPlanTable__action-icon delete" icon="minus-circle" />,
    update: <FontAwesomeIcon className="PostPlanTable__action-icon update" icon="pen" />,
    read: <FontAwesomeIcon className="PostPlanTable__action-icon read" icon="check-double" />,
    "no-op": <FontAwesomeIcon className="PostPlanTable__action-icon no-op" icon="ban" />,
    import: <FontAwesomeIcon className="PostPlanTable__action-icon import" icon="download" />,
    createdelete: <CreateDeleteIcon className="PostPlanTable__action-icon-createdelete" />,
    deletecreate: <DeleteCreateIcon className="PostPlanTable__action-icon-deletecreate" />,
}

const getGuardrailsTableColumnsByType = (type, tColumns, onClickCodeChangedLink, handleRemediateClick, apiRef, tagEnforcementMode) => {
    const disabledFields = {
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
    }

    switch (type) {
        case GUARDRAILS_TYPES.resource:
            return [
                {
                    headerName: tColumns("action"),
                    field: "action",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        const action = params.row?.assetInfo?.action?.[0] || params.row?.criteria?.resource?.action || "";
                        return <div className="row g8">
                            {actionsIcons[action]}
                            <span className="GuardrailsBlockTable__item-table-actionName">{WORKFLOWS_ACTIONS_MAP[action]}</span>
                        </div>
                    }
                },
                {
                    headerName: tColumns("resourceAddress"),
                    field: "resourceAddress",
                    flex: 1, 
                    ...disabledFields,
                    renderCell: (params) => params.row?.assetInfo?.address || params.row?.criteria?.resource?.address || ""
                },
                {
                    headerName: tColumns("region"),
                    field: "region",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => params.row?.assetInfo?.region || ""
                },
                {
                    headerName: tColumns("assetType"),
                    field: "assetType",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        const type = params.row?.assetInfo?.type || params.row?.criteria?.resource?.assetType || "";
                        return (
                           <div className="row g8">
                            <AssetTypeImage assetType={type} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>
                            <span>{formatAwsStringWithUnderscore(type)}</span>
                           </div>
                        );
                      },
                },
                {
                    headerName: "",
                    field: "overridden",
                    width: 20,
                    ...disabledFields,
                    renderCell: (params) => {
                        const severity = params.row?.violationSeverity;
                        if (severity !== 0) return null;
                        return (
                            <Tooltip title={i18next.t("ci-pipeline:guardrails.override.iconTooltip")}>
                                <div className="table row g5">
                                    <FontAwesomeIcon icon="eye-slash" />
                                </div>
                            </Tooltip>)
                    }
                }
            ]
            case GUARDRAILS_TYPES.policy: 
            return [
                {
                    headerName: tColumns("violatedPolicy"),
                    field: "violatedPolicy",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => params.row?.violationPolicyInfo?.name || ""

                },
                {
                    headerName: tColumns("address"),
                    field: "resourceAddress",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        apiRef.current = params.api;
                        const address = params.row?.assetInfo?.address ||  params.row?.violationPolicyInfo?.address || "";
                        const fileName = address.split(":")[0];
                        const line = address.split(":")[1];
                        return <div className="" onClick={(e) => {
                            e.stopPropagation();
                            if (!fileName || !line) return;
                            onClickCodeChangedLink(fileName, line);
                        }}>{address}</div>
                    } 
                        
                },
                {
                    headerName: tColumns("expectedValue"),
                    field: "expectedValue",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        const { expectedValue = '', description = '' } = params.row?.violationPolicyInfo || {};
                        const displayValue = expectedValue || description;
                        
                        return (
                            <Tooltip title={displayValue}>
                                {displayValue}
                            </Tooltip>
                        );
                    }
                },
                {
                    headerName: tColumns("policySeverity"),
                    field: "policySeverity",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        const severity = params.row?.violationPolicyInfo?.severity || "";
                        const highestLower = severity.toLowerCase();
                        return  (
                        <span className={`Diagnostic__severityBtn ${highestLower} GuardrailsBlockTable__item-table-severity`}>
                            {capitalizeFirst(severity)}
                        </span>)
                    }
                },
                {
                    headerName: "",
                    field: "remediation",
                    width: 120,
                    ...disabledFields,
                    renderCell: (params) => {
                        const violationId = params.row?.violationId;
                        if (!violationId) return null;
                        return (
                        <Tooltip title={i18next.t("ci-pipeline:remediation.thinker2Tooltip")}>
                            <div className="CiCode__content-collapse-content-policyViolations-item-remediateIcon table row g5" onClick={(e) => {
                                e.stopPropagation();
                                handleRemediateClick(params.row);
                            }}>
                               <RemediationIcon />
                                <span>Remediate</span>
                            </div>
                        </Tooltip>)
                    }
                },
                {
                    headerName: "",
                    field: "ignored",
                    width: 20,
                    ...disabledFields,
                    renderCell: (params) => {
                        const severity = params.row?.violationSeverity;
                        if (severity !== 0) return null;
                        return (
                            <Tooltip title={i18next.t("ci-pipeline:guardrails.override.iconTooltip")}>
                                <div className="table row g5">
                                    <FontAwesomeIcon icon="eye-slash" />
                                </div>
                            </Tooltip>)
                    }
                }
            ]
            case GUARDRAILS_TYPES.cost:
                return [
                    {
                        headerName: tColumns("costLimit"),
                        field: "costLimit",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => {
                            const thresholdAmount = params.row?.criteria?.cost?.thresholdAmount;
                            const thresholdPercentage = params.row?.criteria?.cost?.thresholdPercentage;
                            const costLimit = thresholdAmount ? formatCostAmount(thresholdAmount)
                            : thresholdPercentage ? `${thresholdPercentage}%` : "";
                            return <span>{costLimit}</span>
                        }
                    },
                    {
                        headerName: tColumns("expectedCostChange"),
                        field: "expectedCostChange",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => {
                            const thresholdAmount = params.row?.criteria?.cost?.thresholdAmount;
                            const thresholdPercentage = params.row?.criteria?.cost?.thresholdPercentage;
                            const change = params?.row?.assetInfo?.expectedCostChange || "";
                            const expectedCostChange = parseFloat(change).toFixed(2);
                            const costChange = thresholdAmount ? formatCostAmount(expectedCostChange) : thresholdPercentage ? `${expectedCostChange}%` : "";
                            const component = expectedCostChange > 0 ? <IncreaseCostIcon />
                            : expectedCostChange < 0 ? <DecreaseCostIcon /> : null;
                            return <div className="row g5">
                                <span>{costChange}</span>
                                {component}
                            </div>
                        }
                    },
                    {
                        headerName: "",
                        field: "ignored",
                        width: 20,
                        ...disabledFields,
                        renderCell: (params) => {
                            const severity = params.row?.violationSeverity;
                            if (severity !== 0) return null;
                            return (
                                <Tooltip title={i18next.t("ci-pipeline:guardrails.override.iconTooltip")}>
                                    <div className="table row g5">
                                        <FontAwesomeIcon icon="eye-slash" />
                                    </div>
                                </Tooltip>)
                        }
                    }
                ]
            case GUARDRAILS_TYPES.tag:
                const leftGeneralColumns = [
                    {
                        headerName: tColumns("resourceAddress"),
                        field: "resourceAddress",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => params.row?.assetInfo?.address || ""
                    },
                    {
                        headerName: tColumns("tagsEnforcementMode"),
                        field: "tagEnforcementMode",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => {
                            apiRef.current = params.api;
                            const tagEnforcementMode = params?.row?.criteria?.tag?.tagEnforcementMode || "";
                            return <span>{TAG_ENFORCEMENTS_MODES[tagEnforcementMode] || ""}</span>
                        }
                    }
                ]

                let specificColumns = [];
                switch (tagEnforcementMode) {
                    case REQUIRED_TAGS:
                        specificColumns = [
                            {
                                headerName: tColumns("requiredTags"),
                                field: "missingTag",
                                flex: 1,
                                ...disabledFields,
                                renderCell: (params) => {
                                    const missingTag = params?.row?.criteria?.tag?.missingTags || [];
                                    const missingTagString = missingTag.join(", ");
                                    return (
                                        <div className="row g5">
                                            <TagsIcon className="img16"/>
                                            <span>{missingTagString}</span>
                                        </div>
                                    )
                                }
                            },
                        ]
                        break;
                    case REQUIRED_VALUES:
                        specificColumns = [
                            {
                                headerName: tColumns("currentTagsValues"),
                                field: "currentTagsValues",
                                flex: 1,
                                ...disabledFields,
                                renderCell: (params) => {
                                    const currentTags = params?.row?.criteria?.tag?.invalidTagValue || {};
                                    return (
                                        <Tooltip title={<div className="col g8">
                                            <span className="CiStepsCollapse__table-cost-tooltip-header-title">{tColumns("currentTagsValues")}</span>
                                                {Object.entries(currentTags).map(([key, value]) => {
                                                    return <div key={key} className="row g5">
                                                    <TagsIcon className="img16"/>
                                                    <span>{key}:</span>
                                                    <span>{value}</span>
                                                </div>
                                            })}
                                        </div>}>
                                        {Object.keys(currentTags).length <=1 ? 
                                            (<div className="col g5">
                                                {Object.entries(currentTags).map(([key, value]) => {
                                                    return <div key={key} className="row g5">
                                                        <TagsIcon className="img16"/>
                                                        <span>{key}:</span>
                                                        <span>{value}</span>
                                                    </div>
                                                })}
                                            </div>) : (
                                            <div className="row g5">
                                                <TagsIcon className="img16" />
                                                <span>{Object.keys(currentTags).length} tags</span>
                                            </div>)}
                                        </Tooltip>
                                    )
                                }
                            },
                            {
                                headerName: tColumns("desiredTagsValues"),
                                field: "desiredTagsValues",
                                flex: 1,
                                ...disabledFields,
                                renderCell: (params) => {
                                    const desiredTags = params?.row?.criteria?.tag?.allowedTagValues || {};
                                    return (
                                        <Tooltip title={<div className="col g8">
                                            <span className="CiStepsCollapse__table-cost-tooltip-header-title">{tColumns("desiredTagsValues")}</span>
                                                {Object.entries(desiredTags).map(([key, values]) => {
                                                    return <div key={key} className="row g5">
                                                        <TagsIcon className="img16"/>
                                                        <span>{key}:</span>
                                                        <span>{Array.isArray(values) ? values.join(' / ') : values}</span>
                                                    </div>
                                                })}
                                            </div>}>
                                            {Object.keys(desiredTags).length <=1 ? 
                                            (<div className="col g5">
                                            {Object.entries(desiredTags).map(([key, values]) => (
                                                <div key={key} className="row g5">
                                                    <TagsIcon className="img16"/>
                                                    <span>{key}:</span>
                                                    <span>{Array.isArray(values) ? values.join(' / ') : values}</span>
                                                </div>
                                            ))}
                                            </div>) : (
                                            <div className="row g5">
                                                <TagsIcon className="img16" />
                                                <span>{Object.keys(desiredTags).length} tags</span>
                                            </div>)}
                                        </Tooltip>
                                    )
                                }   
                            }
                        ]
                        break;
                }

                return [
                    ...leftGeneralColumns,
                    ...specificColumns,
                    {
                        headerName: tColumns("tagsCoverage"),
                        field: "tagsCoverage",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => {
                            const tags = Object.values(params?.row?.assetInfo?.tags || {});
                            const isTags = tags?.length > 0;
                            return (
                            <Tooltip title={isTags ? <div className="col g8">
                                {<span className="CiStepsCollapse__table-cost-tooltip-header-title">{tColumns("tagsCoverage")}</span>}
                                {Object.entries(params?.row?.assetInfo?.tags || {}).map(([key, value]) => {
                                    return <div className="row g5" key={key}>
                                        <TagsIcon className="img16"/>
                                        <span>{key}:</span>
                                        <span>{value}</span>
                                    </div>
                                })}
                            </div> : ""}>
                                <div className="row g5">
                                    {isTags ? <TagsIcon className="img16" /> : <NoTagsIcon className="img16" />}
                                    <span>{isTags ? tags.length : "No"} tags</span>
                                </div>
                            </Tooltip>)
                        }
                    },
                    {
                        headerName: "",
                        field: "remediation",
                        width: 120,
                        ...disabledFields,
                        renderCell: (params) => {
                            const violationId = params.row?.violationId;
                            if (!violationId) return null;
                            return (
                            <Tooltip title={i18next.t("ci-pipeline:remediation.thinker2Tooltip")}>
                             <div className="CiCode__content-collapse-content-policyViolations-item-remediateIcon table row g5" onClick={(e) => {
                                e.stopPropagation();
                                handleRemediateClick(params.row);
                            }}>
                               <RemediationIcon />
                                <span>Remediate </span>
                            </div>
                            </Tooltip>)
                        }
                    },
                    {
                        headerName: "",
                        field: "ignored",
                        width: 20,
                        ...disabledFields,
                        renderCell: (params) => {
                            const severity = params.row?.violationSeverity;
                            if (severity !== 0) return null;
                            return (
                                <Tooltip title={i18next.t("ci-pipeline:guardrails.override.iconTooltip")}>
                                    <div className="table row g5">
                                        <FontAwesomeIcon icon="eye-slash" />
                                    </div>
                                </Tooltip>)
                        }
                    }
                ]
            default: return [];
    }
}
const getStatusesCount = (status = []) => {
    let passed = 0;
    let hardFailed = 0;
    let softFailed = 0;
    if (!status.length) return {};
    status.forEach((item) => {
        if (item.isPassed) {
            passed++;
            return
        }
        if (item.severity === 1) {
            softFailed++;
            return
        }
        if (item.severity === 2) {
            hardFailed++;
        }
    });
    return { passedCount: passed, failedCount: hardFailed, softFailedCount: softFailed };
};

const getViolationIdsFromGuardrailsBlock = (failedCount, softFailedCount, statusesByType) => {
    if (failedCount < 1 && softFailedCount < 1) return [];
    const { policy = [], tag = [] } = statusesByType;
    if (!policy.length && !tag.length ) return [];
    const policyTagsArray = [...policy, ...tag];
    const releventViolations = [];
    (policyTagsArray).forEach((item) => {
        const { violations = [], isPassed } = item;
        const shouldViolate = !isPassed && violations.length > 0;
        if (!shouldViolate) return;
        releventViolations.push(...violations);
    });
    const violationsIds = compact(releventViolations.map((item) => item?.violationId));
    return violationsIds;
};

export {
    getGuardrailsTableColumnsByType,
    getStatusesCount,
    getViolationIdsFromGuardrailsBlock,
}