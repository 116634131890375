import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_DRIFTS_EXCLUSIONS = "GET_DRIFTS_EXCLUSIONS";
export const CREATE_DRIFT_EXCLUSION = "CREATE_DRIFT_EXCLUSION";
export const DELETE_DRIFT_EXCLUSION = "DELETE_DRIFT_EXCLUSION";
export const UPDATE_DRIFT_EXCLUSION = "UPDATE_DRIFT_EXCLUSION";

const getExclusions = (showError) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/drift-exclusions`,
      "GET",
      {},
      undefined,
      undefined,
      showError
    );
    const rules = await req.json();
    dispatch({
      type: GET_DRIFTS_EXCLUSIONS,
      payload: { rules },
    });
  });
};

const createExclusion = (scope, properties, type, providers, arn, globalScope, comment) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let payload = {
      properties,
      type: scope === "global" ? globalScope : type,
      providers,
      comment
    };

    if (scope === "arn") {
      payload.arn = arn;
    }

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/drift-exclusions/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_DRIFT_EXCLUSION,
      payload: {},
    });
    return req;
  });
};

const deleteExclusion = (id, payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/drift-exclusions/${id}`,
      "DELETE", payload
    );

    dispatch({
      type: DELETE_DRIFT_EXCLUSION,
      payload: { ok: req.ok },
    });
  });
};

const updateExclusion = (id, payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/drift-exclusions/${id}`,
      "PUT", payload
    );
    return req;
  });
};

export {
  getExclusions,
  createExclusion,
  deleteExclusion,
  updateExclusion
};
