import TableWrapperV2 from "../../shared/tableWrapperV2/tableWrapperV2";
import EventTypes from "./filters/eventTypes/eventTypes";
import "./eventCenter.scss";
import HeaderSearchBox from "../../shared/headerSearchBox/headerSearchBox";
import DatePicker from "./filters/datePicker/datePicker";
import FacetPanel from "./filters/facets/facetPanel/facetPanel";
import Mutation from "./table/mutation/mutation";
import ClickOps from "./table/clickOps/clickOps";
import CliSdk from "./table/cliSdk/cliSdk";
import { useEffect, useState } from "react";
import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    clearEventCenterData,
    getEvents,
    getEventsWithoutFacets,
    getFacets,
    getFacetsSearch,
    setFacetsContinuationToken,
} from "../../redux/actions/eventCenterActions";
import EventCenterColumns from "./table/eventCenterColumns/eventCenterColumns";
import { useEventCenterFilters } from "../../hooks/useEventCenterFilters";
import { getAllClouds } from "../../redux/actions/globalAppActions";
import FiltersRowBadges from "../../components/inventory/filtersRowBadges/filtersRowBadges";
import { eventCenterEvents } from "../../utils/amplitudeEvents";
import { sendEvent } from "../../utils/amplitude";
import { setFilters } from "../../redux/actions/inventoryv3Actions";
import { emptyInventoryScreenFilters } from "../../consts/inventory";
import { ReactComponent as NoDataDarkImage } from "../../Images/eventCenter/noDataDark.svg";
import { ReactComponent as NoDataLightImage } from "../../Images/eventCenter/noDataLight.svg";

const EventCenter = () => {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(50);
    const [loading, setLoading] = useState(false);
    const [facetLoading, setFacetLoading] = useState(false);
    const [sort, setSort] = useState([]);
    const [customPage, setCustomPage] = useState(1);
    const [isNoEvents, setIsNoEvents] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const isDarkTheme = useSelector((state) => state.userPreferencesReducer.themeDark);
    const [loadMorefacets, setLoadMoreFacets] = useState({ loadMore: true, field: "" });
    const [searchFacets, setSearchFacets] = useState({ field: "", value: "", searchFacet: false });

    const { filters, updateFilter, resetFilters } = useEventCenterFilters();

    const handleFetchClouds = async () => {
        await dispatch(getAllClouds());
    };

    const events = useSelector((state) => state.eventCenterReducer?.events || []);
    const facets = useSelector((state) => state.eventCenterReducer?.facets || []);
    const continuationTokenFacets = useSelector(
        (state) => state.eventCenterReducer?.continuationTokenFacets || [],
    );

    const continuationToken = useSelector(
        (state) => state.eventCenterReducer?.continuationToken || [],
    );

    const totalEvents = useSelector((state) => state.eventCenterReducer?.totalEvents || 0);

    const fetchData = async (
        skip = "",
        sortModel = [],
        newPageSize = pageSize,
        newFilters = filters,
    ) => {
        setLoading(true);
        try {
            const response = await dispatch(getEvents(skip, newPageSize, sortModel, newFilters));
            if (response?.abort) {
                return;
            }
            const { facets = [] } = response || {};

            const desiredFields = [
                "location",
                "assetType",
                "owner",
                "providerType",
                "integrationId",
            ];

            const continuationTokenFacetsInit = facets
                .filter((item) => desiredFields.includes(item.field))
                .map((item) => ({
                    [item.field]: [item.continuationToken],
                }));

            dispatch(setFacetsContinuationToken(continuationTokenFacetsInit));
            return response;
        } finally {
            setLoading(false);
        }
    };

    const fetchFacets = async (field = "", skip = "", size = 10) => {
        setFacetLoading(true);
        try {
            const response = await dispatch(
                getFacets(field, skip, size, filters, searchFacets.value),
            );
            if (response?.abort) return;

            const { field: fieldName = "", continuationToken = "" } = response[0] || {};

            const updateContinuationTokenFacets = continuationTokenFacets.map((item) => {
                const key = Object.keys(item)[0];
                if (key === fieldName) {
                    return { [field]: [...item[fieldName], continuationToken] };
                } else {
                    return { ...item };
                }
            });

            dispatch(setFacetsContinuationToken(updateContinuationTokenFacets));
        } finally {
            setFacetLoading(false);
        }
    };

    const fetchFacetsSearch = async (field = "", skip = "", size = 10) => {
        setFacetLoading(true);
        try {
            const response = await dispatch(
                getFacetsSearch(field, skip, size, filters, searchFacets.value),
            );
            if (response?.abort) return;

            const { field: fieldName = "", continuationToken = "" } = response[0] || {};

            const updateContinuationTokenFacets = continuationTokenFacets.map((item) => {
                const key = Object.keys(item)[0];
                if (key === fieldName) {
                    return { [field]: [...item[fieldName], continuationToken] };
                } else {
                    return { ...item };
                }
            });

            dispatch(setFacetsContinuationToken(updateContinuationTokenFacets));
        } finally {
            setFacetLoading(false);
        }
    };

    const fetchDataWitoutFacets = async (
        skip = "",
        sortModel = [],
        newPageSize = pageSize,
        newFilters = filters,
    ) => {
        setLoading(true);
        try {
            const response = await dispatch(
                getEventsWithoutFacets(skip, newPageSize, sortModel, newFilters),
            );
            if (response?.abort) return;
        } finally {
            setLoading(false);
        }
    };

    const onLoadMore = async (fieldName) => {
        const facet = facets.find((item) => item.field === fieldName);

        if (facet.Values.length >= facet.size) {
            return setLoadMoreFacets({ loadMore: false, field: fieldName });
        }

        return setLoadMoreFacets({ loadMore: true, field: fieldName });
    };

    const handleRemoveBadgeFilter = async (key, filter) => {
        switch (key) {
            case "resource_type":
                filters["assetType"] = filter
                    ? filters["assetType"].filter((item) => item !== filter)
                    : [];
                updateFilter("assetType", filters["assetType"]);
                break;
            case "owners":
                filters["owner"] = filter ? filters["owner"].filter((item) => item !== filter) : [];
                updateFilter("owner", filters["owner"]);
                break;
            case "regions":
                filters["location"] = filter
                    ? filters["location"].filter((item) => item !== filter)
                    : [];
                updateFilter("location", filters["location"]);
                break;
            case "providerType":
                filters["dataSource"] = filter
                    ? filters["dataSource"].filter((item) => item !== filter)
                    : [];
                updateFilter("dataSource", filters["dataSource"]);
                break;

            default:
                break;
        }
    };

    const onSearchFacets = async (field, search = "") => {
        if (search.length > 2 || search.length === 0)
            setSearchFacets({ field, value: search, searchFacet: true });
    };

    useEffect(() => {
        handleFetchClouds();
        fetchDataUntilEmpty();
        dispatch(
            setFilters({
                ...emptyInventoryScreenFilters,
            }),
        );
        return () => resetFilters();
    }, []);

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        fetchData(0, sort);
    }, [filters]);

    useEffect(() => {
        if (!(loadMorefacets.loadMore && loadMorefacets.field)) return;

        const continuationTokenObject = continuationTokenFacets.find((item) => {
            const key = Object.keys(item)[0];
            return loadMorefacets.field === key;
        });

        const continuationTokenArray = continuationTokenObject[loadMorefacets.field];
        const continuationToken = continuationTokenArray[continuationTokenArray.length - 1];
        fetchFacets(loadMorefacets.field, continuationToken);
    }, [loadMorefacets]);

    useEffect(() => {
        if (!searchFacets.searchFacet) return;

        const updateContinuationTokenFacets = continuationTokenFacets.map((item) => {
            const key = Object.keys(item)[0];
            if (key === searchFacets.field) {
                return { [key]: [] };
            } else {
                return { ...item };
            }
        });

        dispatch(setFacetsContinuationToken(updateContinuationTokenFacets));

        fetchFacetsSearch(searchFacets.field);
    }, [searchFacets]);

    const fetchDataUntilEmpty = async () => {
        const filtersToTry = [
            { selectedTime: "week", eventType: ["click_ops"] },
            { selectedTime: "month", eventType: ["click_ops"] },
            { selectedTime: "week", eventType: ["mutation"] },
            { selectedTime: "month", eventType: ["mutation"] },
        ];

        for (const currFilter of filtersToTry) {
            const result = await fetchData(0, sort, 50, {
                eventType: currFilter.eventType,
                selectedTime: currFilter.selectedTime,
            });
            if (result?.size !== 0) {
                updateFilter("eventType", currFilter.eventType);
                updateFilter("selectedTime", currFilter.selectedTime);
                setIsInitialized(true);
                return;
            }
        }
        setIsNoEvents(true);
    };

    const getContinuationToken = (newPage) => {
        return newPage > 1 ? continuationToken[newPage - 2] : "";
    };

    const handlePageChange = async (newPage) => {
        setCustomPage(newPage);
        const skip = getContinuationToken(newPage);

        await fetchDataWitoutFacets(skip, sort);
    };

    const onPageSizeChange = async (newPageSize) => {
        setPageSize(newPageSize);
        setCustomPage(1);
        dispatch(clearEventCenterData());
        const skip = "";

        await fetchData(skip, sort, newPageSize);
    };

    const handleSortChange = async (newSortModel) => {
        setSort(newSortModel);
        const skip = getContinuationToken(customPage);
        await fetchData(skip, newSortModel);
    };

    const detailComponentsMap = {
        mutation: (payload) => <Mutation mutations={payload} />,
        click_ops: (payload) => <ClickOps clickOps={payload} />,
        cli_sdk: (payload) => <CliSdk cliSdk={payload} />,
    };

    const detailHeights = {
        mutation: 200,
        click_ops: 415,
    };

    const getDetailPanelHeight = ({ row }) => {
        const eventType = row?.eventType;
        return detailHeights[eventType] ?? 350;
    };

    const getDetailPanelContent = ({ row }) => {
        const ComponentFn = detailComponentsMap[row?.eventType];
        const height = getDetailPanelHeight({ row });

        return (
            <div style={{ height, overflowY: "auto", paddingBottom: 30 }}>
                <div>{ComponentFn ? ComponentFn(row) : null}</div>
            </div>
        );
    };

    const handleSearch = async (value) => {
        updateFilter("searchText", value);
    };

    const handleClearAllFilters = async () => {
        sendEvent(eventCenterEvents.eventClearAll, { title: "Clicked clear all button" });
        resetFilters();
    };

    return (
        <div className="EventCenter">
            <div className="EventCenter__menu">
                <EventTypes updateFilter={updateFilter} setCustomPage={setCustomPage} />
                <div className="EventCenter__date">
                    <DatePicker updateFilter={updateFilter} setCustomPage={setCustomPage} />
                </div>
                <FacetPanel
                    facets={facets}
                    updateFilter={updateFilter}
                    selectedFacets={filters}
                    onLoadMore={onLoadMore}
                    loadMorefacets={loadMorefacets.loadMore}
                    facetLoading={facetLoading}
                    onSearchFacets={onSearchFacets}
                />
                <div className="EventCenter__menu__clear">
                    <Divider type="vertical" />
                    <div className="EventCenter__menu__clear__text" onClick={handleClearAllFilters}>
                        Clear all
                    </div>
                </div>
            </div>
            {isNoEvents ? (
                <div className="EventCenter__no-data">
                    {isDarkTheme ? <NoDataDarkImage /> : <NoDataLightImage />}
                </div>
            ) : (
                <div className="EventCenter__table-wrapper">
                    <div className="EventCenter__header row g10">
                        <HeaderSearchBox
                            placeholder={"Search"}
                            value={handleSearch}
                            currentValue={filters.searchText}
                            resetSearch={handleSearch}
                            width="320px"
                        />
                        <FiltersRowBadges
                            filterTypes={filters.assetType}
                            filterOwners={filters.owner}
                            filterRegions={filters.location}
                            filterProviderTypes={{ integrationId: filters.dataSource }}
                            handleRemoveFilter={handleRemoveBadgeFilter}
                        />
                    </div>

                    <div className="EventCenter__table">
                        <TableWrapperV2
                            rowKey="id"
                            rowBuffer={100}
                            height={"calc(100vh - 282px)"}
                            columns={EventCenterColumns}
                            tableData={events}
                            pageSize={pageSize}
                            rowCount={totalEvents}
                            externalPage={customPage - 1}
                            loading={loading}
                            handlePageChange={handlePageChange}
                            handleSortChange={handleSortChange}
                            onPageSizeChange={onPageSizeChange}
                            getDetailPanelContent={getDetailPanelContent}
                            getDetailPanelHeight={getDetailPanelHeight}
                            serverSide
                            handleSelectedRowsArr={() => {
                                return;
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventCenter;
