import {
    GET_EVENTS,
    GET_FACETS,
    GET_EVENTS_WITHOUT_FACETS,
    CLEAR_DATA,
    SET_FILTER,
    CLEAR_ALL_FILTERS,
    SET_FACETS_CONTINUATION_TOKEN,
    GET_FACETS_SEARCH,
} from "../actions/eventCenterActions";

const initialState = {
    events: [],
    facets: [],
    continuationToken: [],
    continuationTokenFacets: [],
    totalEvents: 0,
    searchFacet: "",
    lastFilteredKey: "",
    filters: {
        selectedTime: "week",
        dateRange: [],
        eventType: ["click_ops"],
        location: [],
        dataSource: [],
        assetType: [],
        owner: [],
    },
};

const getFacetValues = (state, payload) => {
    const facets = payload?.facets;
    for (const facet of facets) {
        if (facet?.field === state?.lastFilteredKey) {
            for (const stateFacet of state?.facets) {
                if (stateFacet?.field === state?.lastFilteredKey) {
                    facet.Values = stateFacet?.Values;
                    facet.size = stateFacet?.size;
                    break;
                }
            }
            break;
        }
    }
    return facets;
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_EVENTS:
            const facets = getFacetValues(state, payload);
            return {
                ...state,
                events: payload?.events,
                facets: facets,
                continuationToken: [...state.continuationToken, payload.continuation_token],
                totalEvents: payload?.size || 0,
            };
        case GET_EVENTS_WITHOUT_FACETS:
            return {
                ...state,
                events: payload?.events,
                continuationToken: [...state.continuationToken, payload.continuation_token],
                totalEvents: payload?.size || 0,
            };
        case CLEAR_DATA:
            return { ...state, continuationToken: initialState.continuationToken };
        case SET_FILTER:
            if (payload.key === "selectedTime" && payload.value === "custom") {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        selectedTime: payload.value,
                        dateRange: payload.dateRange,
                    },
                    lastFilteredKey: payload.key,
                };
            }
            let key = payload.key;
            if (key === "dataSource") {
                key = "integrationId";
            }
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.key]: payload.value,
                },
                lastFilteredKey: key,
            };
        case CLEAR_ALL_FILTERS:
            return {
                ...state,
                filters: initialState.filters,
                lastFilteredKey: "ClearAll",
            };
        case GET_FACETS:
            const { field = "", Values = [] } = payload[0];
            const updateFacets = state.facets.map((item) => {
                if (item.field === field) {
                    return { ...item, Values: [...item.Values, ...Values] };
                } else {
                    return { ...item };
                }
            });

            return {
                ...state,
                facets: updateFacets,
            };
        case GET_FACETS_SEARCH:
            const { field: f = "" } = payload[0];
            const updateFacet = state.facets.map((item) => {
                if (item.field === f) {
                    return payload[0];
                } else {
                    return { ...item };
                }
            });

            return {
                ...state,
                facets: updateFacet,
            };
        case SET_FACETS_CONTINUATION_TOKEN:
            return {
                ...state,
                continuationTokenFacets: payload,
            };

        default:
            return state;
    }
};
