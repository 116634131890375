import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getRepositoriesWithModules,
  getModulesByRepo,
  moduleCall,
  clearModuleData,
  setPullRequestParameters,
  setSelectedModuleCallFormat,
  setSelectedModuleCallFilePath,
  changeCodifyDrawerProperty
} from "../../../../redux/actions/iacImportActions";
import { setTerraformCmds } from "../../../../redux/actions/inventoryv3Actions";
import { v4 as uuidv4 } from "uuid";
import Select from "antd/lib/select";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import find from "lodash/find";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import { clouds } from "../../../../utils/icons";
import AppToggle from "../../../../shared/appToggle/appToggle";

import { Divider, Input, Radio, Tabs, Tooltip } from "antd";
import ModulesResources from "./modulesResources";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { filterSearchComperasion } from "../../../composing/moduleCall/moduleCallHelper";
import TypingDots from "../../../../shared/typingDots/typingDots";
import LongLoader from "../../../../shared/longLoader/longLoader";
import { getFileType, getUniqueModuleFileName, MODULE_CALL_FORMAT_TYPES } from "../../codifyDrawer/codifyHelpers";

import { sendEvent } from "../../../../utils/amplitude";
import { codifyEvents } from "../../../../utils/amplitudeEvents";
import { CODIFY_TYPES } from "../../../../consts/general";
import { ACTIVE_CODIFICATION_TYPES } from "../../codifyDrawer/codifyHelpers";
import { formatJSONstring } from "../../../../utils/formatting";
import CodeSyntaxEditor from "../codeSyntaxEditor";
import "./modulesTab.scss";

const NON_TOGGLE_VCS_SUPOORT = ['github', 'bitbucket'];
const MAX_STRING_LENGTH_TO_RENDER = 50000;

const ModulesTab = ({ description, prefix, selectedResources, isImportBlocksClicked, importBlocksComment, handleOverweightData, OverweightDataDisplay, codifySessionId }) => {
  const dispatch = useDispatch();
  const [loadingInit, setLoadinInit] = useState(true);
  const [loadingModule, setLoadingModule] = useState(false);
  const [loadingCreateModule, setLoadingCreateModule] = useState(false);
  const [seperateToggle, setSeperateToggle] = useState(true);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [showModuleProtocolRadio, setShowModuleProtocolRadio] = useState(false)

  const { t } = useTranslation("compose", { keyPrefix: "moduleCall" });

  const moduleRepos = useSelector((state) => state.iacImportReducer.moduleRepos);
  const selectedFilePath = useSelector((state) => state.iacImportReducer.selectedModuleCallFilePath);
  const selectedFormat = useSelector((state) => state.iacImportReducer.selectedModuleCallFormat);
  
  const moduleProtocol = useSelector((state) => state.iacImportReducer.moduleProtocol);
  const dependenciesToggle = useSelector((state) => state.iacImportReducer.dependenciesToggle);

  const moduleData = useSelector((state) => state.iacImportReducer.moduleData);
  const modules = useSelector((state) => state.iacImportReducer.modules);
  const pullReqParams = useSelector((state) => state.iacImportReducer.pullReqParams);
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  useEffect(() => {
    getInitData();
    return () => {
      dispatch(clearModuleData());
    };
  }, []);
  
  useEffect(() => {
    if (selectedFilePath) {
    const { TerraformImportBlocks: importBlocks } = moduleData || {};
    const selectedFile = moduleCallFiles[selectedFilePath] || "";
      let code = [MODULE_CALL_FORMAT_TYPES.JSON, MODULE_CALL_FORMAT_TYPES.TFVARS_JSON].includes(selectedFormat) ? formatJSONstring(selectedFile) :
      selectedFilePath ===  "main.tf" ? `${prefix}${isImportBlocksClicked && !isEmpty(importBlocks) ? `${importBlocksComment}${importBlocks?.join("\n")}\n`: ''} ${selectedFile}` : selectedFile;
      if (code.length > MAX_STRING_LENGTH_TO_RENDER) {
        handleOverweightData(true);
      }
    }
  }, [selectedFilePath])

  const setModuleProtocol = async(newMoudleProtocol) => await dispatch(changeCodifyDrawerProperty("moduleProtocol", newMoudleProtocol));
  const setDependenciesToggle = async(newDependenciesToggle) => await dispatch(changeCodifyDrawerProperty("dependenciesToggle", newDependenciesToggle));

  const getResourceTypes = (isSameTypeResourcesCheck = false) => {
    let resourcesTypes = [];
    for (let i = 0; i < selectedResources?.length; i++) {
      const resource = selectedResources[i];
      resourcesTypes.push(resource?.assetType);
    }
    const uniqueResourcesTypes = _.uniq(resourcesTypes);
    if (isSameTypeResourcesCheck) {
      return uniqueResourcesTypes.length === 1
    }
    return uniqueResourcesTypes
  }

  const allowSeperateModules = selectedResources?.length > 1 && getResourceTypes(true)

  const getInitData = async () => {
    setLoadinInit(true);
    let resourcesTypes = getResourceTypes();
    await Promise.all([
      dispatch(getRepositoriesWithModules(resourcesTypes)),
    ]);
    setLoadinInit(false);
  };

  const getModules = async (repoName) => {
    if (repoName) {
      setLoadingModule(true);
      setSelectedModule(null);
      let resourcesTypes = getResourceTypes();
      await dispatch(getModulesByRepo(repoName, resourcesTypes));
      setLoadingModule(false);
    }
  };

  const handleCreateModule = async (md5, useDependencies, protocol, useSeperate, moduleFormat) => {
    setLoadingCreateModule(true);

    let assetType = "";
    let assetId = "";
    let provider = "";
    let providerId = "";
    let resources = [];

    for (let i = 0; i < selectedResources?.length; i++) {
      const resource = selectedResources[i];

      if (i === 0) {
        assetType = resource.assetType;
        assetId = resource.assetId;
        provider = resource.provider;
        providerId = resource.integrationId;
      } else {
        resources.push({
          terraformAssetType: resource.assetType,
          providerId: resource.integrationId,
          assetId: resource.assetId,
        });
      }
    }

    const payload = {
      moduleMD5: md5,
      sourceType: find(modules || [] , mod => mod?.moduleFullPathMD5 === md5)?.moduleSourceType,
      assetType,
      assetId,
      providerId,
      provider,
      resources,
      codificationMode: useDependencies ? "CALL_MODULE_AI" : "CALL_MODULE_WITHOUT_DEPENDENCIES_AI",
      moduleProtocol: protocol || moduleProtocol,
      separateModuleCall: allowSeperateModules && useSeperate,
      format: moduleFormat || selectedFormat,
    };

    let req = await dispatch(moduleCall(payload));
    if (req?.abort) {
      return;
    }
    await dispatch(setTerraformCmds(req?.TerraformCMDs));
    setLoadingCreateModule(false);
  };

  const renderRepos = () => {
    if (!isEmpty(moduleRepos)) {
      return map(moduleRepos || [], ({ repoFullName = "", vcsType= "", isPublic = false } = {}) => {
        return (
          <Select.Option value={[repoFullName, vcsType]} key={repoFullName}>
            <span className={`ModulesTab__repo row between`}>
              <div className="row g15">
                <img src={clouds(vcsType, themeDark)} alt="integration" />
                {repoFullName}
              </div>
              {isPublic && <span className="purple-flag ModulesTab__repo-public">Public</span>}
            </span>
          </Select.Option>
        );
      });
    }
  };

  const renderModules = () => {
    if (!isEmpty(modules) && selectedRepo) {
      return map(modules || [], (mod) => {
        return (
          <Select.Option value={mod?.moduleFullPathMD5} key={uuidv4()}>
            <div className="row g8">
            {mod?.missingAssetTypes && 
              <Tooltip title={t("tooltips.moduleWarning")} placement="top">
                  <FontAwesomeIcon icon="info" className="Dashboard__center-total-info pointer" />
              </Tooltip>}
              {mod?.moduleName}
            </div>
          </Select.Option>
        );
      });
    }
  };
  const moduleCallFiles = moduleData?.Files || {};
  const needToRenderCode = !isEmpty(moduleData) && !isEmpty(moduleCallFiles);
  const needToRenderNoData = !loadingCreateModule && isEmpty(moduleCallFiles) && !isEmpty(selectedRepo) && !isEmpty(selectedModule);
  const noHeaderBottomBorder = needToRenderCode && !isEmpty(selectedModule) && !isEmpty(selectedRepo) && !loadingCreateModule;
  const isFiles =  needToRenderCode && !isEmpty(moduleCallFiles) && !loadingCreateModule;
  const onSaveNewCode = async (newCode) => {
    await dispatch(changeCodifyDrawerProperty("moduleData", { ...moduleData, Files: { ...moduleCallFiles, [selectedFilePath]: newCode } }));
  };
  const renderCode = () => {
    if (loadingCreateModule) {
      return (
        <div className="empty-code center">
        <LongLoader customLoader={<Loading />} duration={10000} 
          customClassName="col g10 center font-18 bold" loading={loadingCreateModule}
          msg1={<span className="row g8">{t("modulesTabLoading")} <TypingDots isInText/></span>}
          msg2={<span className="row g8">Hold on, it is almost ready <TypingDots isInText/></span>}/>
      </div>
      );
    }

    if (isEmpty(selectedRepo) || isEmpty(selectedModule)) {
      return null;
    }

    if (needToRenderNoData) {
      return (
        <div className="empty-code center">
          <AppEmpty text="No data" customStyle="code" />
        </div>
      );
    }

    if (needToRenderCode) {
      const { TerraformImportBlocks: importBlocks } = moduleData || {};
      const selectedFile = moduleCallFiles[selectedFilePath] || "";

      let code = [MODULE_CALL_FORMAT_TYPES.JSON, MODULE_CALL_FORMAT_TYPES.TFVARS_JSON].includes(selectedFormat) ? formatJSONstring(selectedFile) :
      selectedFilePath ===  "main.tf" ? `${prefix}${isImportBlocksClicked && !isEmpty(importBlocks) ? `${importBlocksComment}${importBlocks?.join("\n")}\n`: ''} ${selectedFile}` : selectedFile;
      if (code.length > MAX_STRING_LENGTH_TO_RENDER) {
        return (
          <div className="empty-code center">
            {OverweightDataDisplay}
          </div>
        );
      }
      const langauge = [MODULE_CALL_FORMAT_TYPES.HCL, MODULE_CALL_FORMAT_TYPES.JSON].includes(selectedFormat) ? selectedFormat
      : selectedFormat === MODULE_CALL_FORMAT_TYPES.TFVARS_JSON ? "json" : selectedFilePath === "main.tf" || selectedFilePath === "terragrunt.hcl" ? MODULE_CALL_FORMAT_TYPES.HCL : MODULE_CALL_FORMAT_TYPES.YAML
      return (
        <div className="ModulesTab__code-container col">
          {/* <SyntaxHighlighter
              style={atomDark}
              showLineNumbers={true}
              language={langauge?.toLowerCase()}
              lineProps={{
                style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
              }}
            >
              {code}
          </SyntaxHighlighter> */}
          <CodeSyntaxEditor code={code} onSave={onSaveNewCode} language={langauge?.toLowerCase()} />
          <ModulesResources moduleData={moduleData} tModuleCall={t}/>
        </div>
      );
    }
  };

  const handleOnChangeModule = (module, useDependencies, protocol, useSeperate, moduleFormat) => {
    setSelectedModule(module);
    const moduleMatch = find(modules || [] , mod => mod?.moduleFullPathMD5 === module) || {};
    dispatch(
      setPullRequestParameters({
        ...pullReqParams,
        moduleMD5: module,
        sourceType: moduleMatch.moduleSourceType,
        repo: selectedRepo,
        moduleProtocol: protocol || moduleProtocol,
      })
    );
    handleCreateModule(module, useDependencies, protocol, useSeperate, moduleFormat);
    sendEvent(codifyEvents.generatedIac, { iacUUID: codifySessionId, iacType: CODIFY_TYPES.terraform, codificationType: ACTIVE_CODIFICATION_TYPES.modules, 
      isImportBlocksEnabled: isImportBlocksClicked, assetType: (selectedResources || []).map((res) => res?.assetType),
      modulePath: `${selectedRepo}_${moduleMatch.moduleName}`, dependencies: useDependencies, moduleCallFormat: moduleFormat })
  };
  
  const onChangeModuleProtocol = (e) => {
    const newMoudleProtocol = e.target.value;
    if (selectedModule) {
      handleOnChangeModule(selectedModule, dependenciesToggle, newMoudleProtocol, seperateToggle, selectedFormat)
    }
    setModuleProtocol(newMoudleProtocol)
  };
  const onChangeFormat = (e) => {
    const newFormat = e.target.value;
    if (selectedModule && selectedRepo) {
      handleOnChangeModule(selectedModule, dependenciesToggle, moduleProtocol, seperateToggle, newFormat)
    }
    dispatch(setSelectedModuleCallFormat(newFormat));
  }
  
  const handleOnAddFile = async() => {
    const fileName = getUniqueModuleFileName(Object.keys(moduleCallFiles));
    const extensionFile = getFileType({ isModuleCallActiveCodificationTab: true, selectedModuleCallFilePath: selectedFilePath, activeTab: CODIFY_TYPES.terraform })
    const newFileName = `${fileName}.${extensionFile}`;
    await dispatch(changeCodifyDrawerProperty("moduleData", { ...moduleData, Files: { ...moduleCallFiles, [newFileName]: "" } }));
    await dispatch(changeCodifyDrawerProperty("editMode", true));
    sendEvent(codifyEvents.clickedCodificationAddNewFile);
  }
  const onEditFileName = async(newName, oldName) => {
    const newFiles = { ...moduleCallFiles, [newName]: moduleCallFiles[oldName] };
    if (newName !== oldName) {
      delete newFiles[oldName];
    }
    await dispatch(changeCodifyDrawerProperty("moduleData", { ...moduleData, Files: newFiles }));
    await dispatch(setSelectedModuleCallFilePath(newName));
    sendEvent(codifyEvents.clickedCodificationRenameFile);
  };
  const onDeleteFile = async(filePath) => {
    const newFiles = { ...moduleCallFiles };
    delete newFiles[filePath];
    await dispatch(changeCodifyDrawerProperty("moduleData", { ...moduleData, Files: newFiles }));
    await dispatch(setSelectedModuleCallFilePath(Object.keys(newFiles)[0]));
    sendEvent(codifyEvents.clickedCodificationDeleteFile);
  };

  if (loadingInit) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="ModulesTab col">
      <div className={`ModulesTab__header ${noHeaderBottomBorder ? 'noBottom' : ''} col`}>
        <span className="ModulesTab__header-description row g15">
          <div className="ModulesTab__header-description-mark">
                <FontAwesomeIcon icon="info-circle" className="ModulesTab__header-description-mark-dot"/>
                <div className="ModulesTab__header-description-mark-line"/>
          </div>
          {description}
          </span>
        <div className="ModulesTab__header-select">
          <Select
            placeholder={loadingInit ? "loading..." : "Choose Repo"}
            showSearch
            filterOption={(input, option) => {
              const [repoName] = option?.value || []
              return repoName?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            }
            style={{ width: "100%" }}
            disabled={loadingInit || loadingModule}
            loading={loadingInit}
            className="ModulesTab__header-select-drop"
            onChange={(item) => {
              const [repoName, vcsType] = item || []
              getModules(repoName);
              setSelectedRepo(repoName)
              if (!NON_TOGGLE_VCS_SUPOORT.includes(vcsType)) {
                return setShowModuleProtocolRadio(true)
              }
              setShowModuleProtocolRadio(false)
            }}
          >
            {renderRepos()}
          </Select>

          <Select
            placeholder={loadingModule ? "loading..." : "Choose Module"}
            showSearch
            filterOption={filterSearchComperasion}
            style={{ width: "100%" }}
            disabled={loadingModule}
            loading={loadingModule}
            value={selectedModule}
            className="ModulesTab__header-select-drop"
            onSelect={(module) => handleOnChangeModule(module, dependenciesToggle, moduleProtocol, seperateToggle, selectedFormat)}
          >
            {!loadingModule && renderModules()}
          </Select>
        </div>
        <div className="row ModulesTab__header-radio format">
              <span className={`ModulesTab__header-radio-text`}>Format</span>
              <Radio.Group onChange={onChangeFormat} value={selectedFormat} >
                <Radio value={MODULE_CALL_FORMAT_TYPES.HCL} key={MODULE_CALL_FORMAT_TYPES.HCL}>{MODULE_CALL_FORMAT_TYPES.HCL}</Radio>
                <Radio value={MODULE_CALL_FORMAT_TYPES.YAML} key={MODULE_CALL_FORMAT_TYPES.YAML}>{`${MODULE_CALL_FORMAT_TYPES.HCL} with ${MODULE_CALL_FORMAT_TYPES.YAML}`}</Radio>
                <Radio value={MODULE_CALL_FORMAT_TYPES.JSON} key={MODULE_CALL_FORMAT_TYPES.JSON}>{MODULE_CALL_FORMAT_TYPES.JSON}</Radio>
                <Radio value={MODULE_CALL_FORMAT_TYPES.TFVARS} key={MODULE_CALL_FORMAT_TYPES.TFVARS}>tfvars</Radio>
                <Radio value={MODULE_CALL_FORMAT_TYPES.TFVARS_JSON} key={MODULE_CALL_FORMAT_TYPES.TFVARS_JSON}>JSON tfvars</Radio>
                <Radio value={MODULE_CALL_FORMAT_TYPES.TERRAGRUNT} key={MODULE_CALL_FORMAT_TYPES.TERRAGRUNT}>Terragrunt</Radio>
              </Radio.Group>
        </div>
        <div className={`ModulesTab__header-checkbox ${isFiles ? "noBottom" : ""} row`}>
          <div className="ModulesTab__header-checkbox-item">
            <AppToggle
              text="Codify with dependencies"
              checked={dependenciesToggle}
              toggleChecked={() => {
                let useDependencies = !dependenciesToggle
                setDependenciesToggle(!dependenciesToggle);
                if (selectedModule !== null) {
                  handleOnChangeModule(selectedModule, useDependencies, moduleProtocol, seperateToggle, selectedFormat)
                }
              }}
            >
            </AppToggle>
          </div>
        {allowSeperateModules && <div className="ModulesTab__header-checkbox-item">
          <AppToggle
              text="Seperate module call for each resource"
              checked={seperateToggle}
              toggleChecked={() => {
                let useSeperate = !seperateToggle
                setSeperateToggle(!seperateToggle);
                if (selectedModule !== null) {
                  handleOnChangeModule(selectedModule, dependenciesToggle, moduleProtocol, useSeperate, selectedFormat)
                }
              }}
            >
            </AppToggle>

          </div>
        }
        
          <div className="row">
            <Divider type="vertical" />
            <div className="row ModulesTab__header-radio">
              <span className={`ModulesTab__header-radio-text ${!showModuleProtocolRadio ? 'disabledText':  ''}`}>Git Connection Protocol</span>
              <Radio.Group onChange={onChangeModuleProtocol} value={moduleProtocol} disabled={!showModuleProtocolRadio}>
              <Radio value="ssh" key="ssh">ssh</Radio>
              <Radio value="https" key="https">https</Radio>
            </Radio.Group>
            </div>
          </div>

        </div>
            {isFiles &&
            <div className="row g10">
              <Tabs activeKey={selectedFilePath} defaultActiveKey={selectedFilePath} onChange={(key) => dispatch(setSelectedModuleCallFilePath(key))}>
                {Object.keys(moduleCallFiles).map((filePath) => <Tabs.TabPane className="ModulesTab__header-tab" tab={
                  selectedFilePath === filePath ? <Input className="ModulesTab__header-tabs-inputFile" id={`inputs-${selectedFilePath}`} onBlur={(e) => onEditFileName(e.target.value, filePath)}
            onPressEnter={(e) => onEditFileName(e.target.value, filePath)} defaultValue={filePath} /> : 
            <div className="ModulesTab__header-tabs-file row g8">
              <span>{filePath}</span>
              <FontAwesomeIcon icon="times" onClick={() => onDeleteFile(filePath)} />
            </div>} key={filePath}>
                  </Tabs.TabPane>)}
              </Tabs>
              <div className="ModulesTab__header-tabs-addFile row g8" onClick={() => handleOnAddFile()}>
                <FontAwesomeIcon icon="plus" />
                <span>{t("addFile")}</span>
              </div>
            </div>
            }
      </div>

      <div className="ModulesTab__code">{renderCode()}</div>
  
    </div>
  );
};

export default ModulesTab;
