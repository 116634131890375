import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import AppBtn from "../../../shared/appBtn/appBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useIntercom } from "react-use-intercom";
import { ReactComponent as UnauthorizedDarkImage } from "../../../Images/backup/unauthorized-dark.svg";
import { ReactComponent as UnauthorizedLightImage } from "../../../Images/backup/unauthorized-light.svg";
import "./unauthorized.scss";

const BackupDRUnauthorized = () => {
    const history = useHistory();
    const { showNewMessages, show } = useIntercom();
    const [isVisible, setIsVisible] = useState(true);
    const isDarkTheme = useSelector((state) => state.userPreferencesReducer.themeDark);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth < 1800 ? "1100px" : "1400px");

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth < 1800 ? "1100px" : "1400px");
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleOnClose = () => {
        setIsVisible(false);
        history.push("/inventory");
    };

    const handleOnClick = () => {
        show();
        showNewMessages(
            "Hello Firefly. I would like to learn more about your Backup & DR capabilities.",
        );
    };

    const generateCloseButton = () => {
        return (
            <div className="unauthorized-backup__close-button" onClick={handleOnClose}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
        );
    };

    const generateContactSalesButton = () => {
        return (
            <div className="unauthorized-backup__contact-button">
                <AppBtn
                    onClick={handleOnClick}
                    text="Contact Sales"
                    tooltipText={
                        "Upgrade to Backup & DR for advanced configuration backup and disaster recovery."
                    }
                />
            </div>
        );
    };

    return (
        <NewAppModal
            visible={isVisible}
            destroyOnClose
            centered
            bodyClassName="unauthorized-backup-modal"
            width={screenWidth}
            iconSrc={null}
            footer={null}
        >
            <div className="unauthorized-backup">
                {isDarkTheme ? (
                    <UnauthorizedDarkImage className="unauthorized-backup__image-style" />
                ) : (
                    <UnauthorizedLightImage className="unauthorized-backup__image-style" />
                )}
                {generateCloseButton()}
                {generateContactSalesButton()}
            </div>
        </NewAppModal>
    );
};

export default BackupDRUnauthorized;
