import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { inventoryEvents } from "../../utils/amplitudeEvents";
import { sendEvent } from "../../utils/amplitude";
import { capitalizeFirst } from "../../utils/formatting";
import { appToast } from "../../shared/appToast/appToast";
import { getCSVData, getJSONData } from "../../utils/helpers";
/**
 * INVENTORY V2
 */
export const GET_INVENTORY_FIREFLY_AGGREGATIONS_V3 =
  "GET_INVENTORY_FIREFLY_AGGREGATIONS_V3";
export const GET_INVENTORY_BASE_AGGREGATIONS_V3 =
  "GET_INVENTORY_BASE_AGGREGATIONS_V3";
export const GET_INVENTORY_YEARS_AGGREGATIONS_V3 = "GET_INVENTORY_YEARS_AGGREGATIONS_V3";
export const GET_INVENTORY_HITS_V3 = "GET_INVENTORY_HITS_V3";
export const SET_FILTERS = "SET_FILTERS";
export const SET_TERRAFORM_COMMANDS = "SET_TERRAFORM_COMMANDS";
export const SET_TF_CMDS_REDACTED = "SET_TF_CMDS_REDACTED";
export const SET_CFT_COMMANDS = "SET_CFT_COMMANDS";
export const GET_ASSET_CODE_TRACE = "GET_ASSET_CODE_TRACE";
export const SET_SELECTED_SERVICE_VIEW = "SET_SELECTED_SERVICE_VIEW";
export const GET_INVENTORY_FLAGS_AGGS = "GET_INVENTORY_FLAGS_AGGS";
export const GET_INVENTORY_DELETED_AGGS = "GET_INVENTORY_DELETED_AGGS";
export const GET_INVENTORY_MASTER_TYPES = "GET_INVENTORY_MASTER_TYPES";
export const GET_INVENTORY_DYNAMIC_AGSS = "GET_INVENTORY_DYNAMIC_AGSS";
export const SET_TOGGLE_SWITCH = "SET_TOGGLE_SWITCH";
export const SET_DEFAULT_INVENTORY_SORTING = "SET_DEFAULT_INVENTORY_SORTING";
let hitsAbortController;

const getFireflyAggregations = (screenFilters, searchVal, showError=false) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/firefly-aggs`,
      "POST",
      { screenFilters, freeTextSearch: searchVal },
      undefined,
      undefined,
      false
    );
    if (req?.ok) {
      const data = await req.json();
      dispatch({
        type: GET_INVENTORY_FIREFLY_AGGREGATIONS_V3,
        payload: data,
      });
    }
    return;
  });
};

const getBaseAggregations = (screenFilters, freeTextSearch, shouldIgnorePriority, showError=true) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/base-aggs`,
      "POST",
      { screenFilters, freeTextSearch, shouldIgnorePriority },
      undefined,
      undefined,
      showError
    );

    if (req?.ok) {
      const data = await req.json();
      dispatch({
        type: GET_INVENTORY_BASE_AGGREGATIONS_V3,
        payload: data,
      });
    }
    return;
  });
};

const getYearsAggregations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/years-aggs`,
      "GET",
      undefined,
      undefined,
      undefined,
      false
    );
    if (req?.ok) {
      const data = await req.json();
      dispatch({
        type: GET_INVENTORY_YEARS_AGGREGATIONS_V3,
        payload: data,
      });
    }
    return;
  });
};

const getInventoryHits = (
  screenFilters,
  freeTextSearch,
  pageNumber,
  sorting,
  tablePageSize,
  showError
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    if (hitsAbortController) {
      hitsAbortController.abort();
    }
    hitsAbortController = new AbortController();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/hits`,
      "POST",
      { screenFilters, freeTextSearch, pageNumber, sorting, tablePageSize },
      undefined,
      undefined,
      showError,
      undefined,
      hitsAbortController.signal
    );

    if (req?.ok) {
      const data = await req.json();
      // send search event
      if(!_.isEmpty(freeTextSearch)) {
        sendEvent(inventoryEvents.search, { searchString: freeTextSearch, assetNumber: data?.totalObjects })
      }
      dispatch({
        type: GET_INVENTORY_HITS_V3,
        payload: data,
      });
    }
    return;
  });
};

const searchInventory = async (
  filters,
  pageNumber,
  pageSize
) => {
      const requestWrapper = new RequestWrapper();
      const req = await requestWrapper.sendRequest(
          `${BACKEND_URL}/inventoryV3/hits`,
          "POST",
          { screenFilters: filters, freeTextSearch:"", pageNumber: pageNumber, sorting:{}, tablePageSize:pageSize },
          undefined,
          undefined,
          false,
          undefined,
          hitsAbortController.signal
      );

      if (req?.ok) {
          const data = await req.json();
        return data;
      }
      return {};
};

const  fetchAssetDataById = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/hits/${id}`,
      "GET",
    );

    if (req?.ok) {
      const data = await req.json();
     return data;
    }
    return;
  });
};

const exportInventory = async (screenFilters, freeTextSearch, format) => {
    try{
        const requestWrapper = new RequestWrapper();
        const response = await requestWrapper.sendRequest(
          `${BACKEND_URL}/inventoryV3/export-table`,
          "POST",
          { screenFilters, freeTextSearch, format }
        );
    
        const reader = response?.body?.getReader();
        const decoder = new TextDecoder();
        let blob ="";
        if (format === "csv") {
            blob = await getCSVData(reader, decoder);
          } else {
            blob = await getJSONData(reader, decoder);
        }
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `inventory.${format}`;
        link.click();
    }catch(error){
        appToast("error", "Error", "Failed to export inventory", undefined, undefined, { autoClose: false });
    }
};

const setFilters = (filters) => {
  let payload = filters;
  const isLiveMode = !payload?.deleted
  if (isLiveMode) {
    payload = { ...payload, deletedRange: [] };
  }
  return action(async (dispatch) => {
    dispatch({
      type: SET_FILTERS,
      payload,
    });
  });
};

const setTerraformCmds = (terraformCmds, isCft = false) => {
  return action(async (dispatch) => {
    dispatch({
      type: isCft ? SET_CFT_COMMANDS : SET_TERRAFORM_COMMANDS,
      payload: terraformCmds,
    });
  });
};

const setTerraformCmdsRedacted = (isRedacted = false) => { 
  return action(async (dispatch) => {
    dispatch({
      type: SET_TF_CMDS_REDACTED,
      payload: isRedacted
    });
  });
};

const getAssetCodeTrace = (referenceIds) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/vcs-code/references`,
      "POST",
      { referenceIds },
      undefined,
      undefined,
      false
    );
    let data = [];
    if (req?.ok) {
      data = await req.json();
      dispatch({
        type: GET_ASSET_CODE_TRACE,
        payload: data,
      });
    }
    return data;
  });
};

const setSelectedServiceView = (type) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_SELECTED_SERVICE_VIEW,
      payload: type,
    });
  });
};

const getFlagsAggregations = (screenFilters, searchVal, showError=false) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/flags-aggs`,
      "POST",
      { screenFilters, freeTextSearch: searchVal },
      undefined,
      undefined,
      false
    );
    if (req?.ok) {
      const data = await req.json();
      dispatch({
        type: GET_INVENTORY_FLAGS_AGGS,
        payload: data,
      });
    }
    return;
  });
};

const getDeletedAggregations = (screenFilters, searchVal) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/deleted-aggs`,
      "POST",
      { screenFilters, freeTextSearch: searchVal },
      undefined,
      undefined,
      false
    );
    if (req?.ok) {
      const data = await req.json();
      dispatch({
        type: GET_INVENTORY_DELETED_AGGS,
        payload: data,
      });
    }
    return;
  });
};

const getMasterTypesAggregations = (screenFilters, freeTextSearch, showError=false) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/master-type-aggs`,
      "POST",
      { screenFilters, freeTextSearch },
      undefined,
      undefined,
      showError
    );

    if (req?.ok) {
      const data = await req.json();
      dispatch({
        type: GET_INVENTORY_MASTER_TYPES,
        payload: data,
      });
    }
    return;
  });
};

const getAggregationByDynamicSearch = (inventorySearch, screenFilters, aggSearch, aggregationField) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/dynamic-aggs`,
      "POST",
      { freeTextSearch: inventorySearch, aggSearch, screenFilters, aggregationField },
      undefined,
      undefined,
      false
    );
    let data = {};
    if (req?.ok) {
      data = await req.json();
    }
    const exactAggregationField = `aggregations${capitalizeFirst(aggregationField)}`;
    dispatch({
      type: GET_INVENTORY_DYNAMIC_AGSS,
      payload: { data, aggregationField: exactAggregationField, aggSearch },
    });
  });
}

const setInventoryFiltersByPrompt = (query) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV3/ai-search-filters`,
      "POST",
      { 
        query,
      },
      undefined,
      undefined,
      false
    );
 
    let data = {};
    if (req?.ok) { 
      data = await req.json();
    }
    const filterState = data?.filterState;
    const success = _.isObject(filterState);

    if (success) {
      dispatch({
        type: SET_FILTERS,
        payload: filterState,
      });
    }
    return success;
  });
}

const setIsToggleSwitch = (toggleSwitch) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_TOGGLE_SWITCH,
      payload: toggleSwitch,
    });
  });
};
const setDefaultInventorySorting = (sorting) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_DEFAULT_INVENTORY_SORTING,
      payload: sorting,
    });
  });
}

export {
  getFireflyAggregations,
  getBaseAggregations,
  getYearsAggregations,
  getInventoryHits,
  exportInventory,
  setFilters,
  setTerraformCmds,
  getAssetCodeTrace,
  setSelectedServiceView,
  getFlagsAggregations,
  getDeletedAggregations,
  fetchAssetDataById,
  getMasterTypesAggregations,
  setTerraformCmdsRedacted,
  getAggregationByDynamicSearch,
  searchInventory,
  setInventoryFiltersByPrompt,
  setIsToggleSwitch,
  setDefaultInventorySorting,
};
