import action from "../middleware";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";
import { buildEventCenterQueryBody, buildEventCenterFacetsQueryBody } from "./eventCenter.utils";

export const GET_EVENTS_DATA = "GET_EVENTS_DATA";
export const GET_EVENT_CENTER_AGGS = "GET_EVENT_CENTER_AGGS";
export const SET_EVENT_CENTER_FILTERS = "SET_EVENT_CENTER_FILTERS";
export const GET_EVENTS = "GET_EVENTS";
export const GET_FACETS = "GET_FACETS";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_FILTER = "SET_FILTER";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const GET_EVENTS_WITHOUT_FACETS = "GET_EVENTS_WITHOUT_FACETS";
export const SET_FACETS_CONTINUATION_TOKEN = "SET_FACETS_CONTINUATION_TOKEN";
export const GET_FACETS_SEARCH = "GET_FACETS_SEARCH";

let abortController;

const getEvents = (continuationToken = "", size = 50, sortModel, filters) => {
    abortFetch();
    return action(async (dispatch) => {
        try {
            const data = await fetchEventsData(size, continuationToken, filters);

            dispatch({
                type: GET_EVENTS,
                payload: data,
            });
            return data;
        } catch (err) {
            if (err.name === "AbortError") {
                return { abort: true };
            }
            return {};
        }
    });
};

const getEventsWithoutFacets = (continuationToken = "", size = 50, sortModel, filters) => {
    abortFetch();
    return action(async (dispatch) => {
        try {
            const data = await fetchEventsData(size, continuationToken, filters);
            dispatch({
                type: GET_EVENTS_WITHOUT_FACETS,
                payload: data,
            });
            return data;
        } catch (err) {
            if (err.name === "AbortError") {
                return { abort: true };
            }
            return {};
        }
    });
};

const getFacets = (field = "", skip = "", size = 10, filters, searchFacets) => {
    abortFetch();
    return action(async (dispatch) => {
        try {
            const data = await fetchEventsFacetData(field, skip, size, filters, searchFacets);

            dispatch({
                type: GET_FACETS,
                payload: data,
            });
            return data;
        } catch (err) {
            if (err.name === "AbortError") {
                return { abort: true };
            }
            return {};
        }
    });
};

const getFacetsSearch = (field = "", skip = "", size = 10, filters, searchFacets) => {
    abortFetch();
    return action(async (dispatch) => {
        try {
            const data = await fetchEventsFacetData(field, skip, size, filters, searchFacets);

            dispatch({
                type: GET_FACETS_SEARCH,
                payload: data,
            });
            return data;
        } catch (err) {
            if (err.name === "AbortError") {
                return { abort: true };
            }
            return {};
        }
    });
};

const fetchEventsData = async (size, continuationToken, filters) => {
    const requestWrapper = new RequestWrapper();

    const body = buildEventCenterQueryBody(size, continuationToken, filters);

    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/event-center/`,
        "POST",
        body,
        true,
        null,
        false,
        null,
        abortController.signal,
    );

    let data = null;
    if (req?.ok) {
        data = await req.json();
        data?.events?.forEach((item, index) => {
            item.id = item.id + index;
        });
    }
    return data;
};

const fetchEventsFacetData = async (field, skip, size, filters, searchFacets) => {
    const requestWrapper = new RequestWrapper();

    const body = buildEventCenterFacetsQueryBody(field, skip, size, filters, searchFacets);

    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/event-center/facets`,
        "POST",
        body,
        true,
        null,
        false,
        null,
        abortController.signal,
    );

    let data = null;
    if (req?.ok) {
        data = await req.json();
    }
    return data;
};

const clearEventCenterData = () => action(async (dispatch) => dispatch({ type: CLEAR_DATA }));

const setFilter = (key, value, dateRange) => {
    return action(async (dispatch) =>
        dispatch({ type: SET_FILTER, payload: { key, value, dateRange } }),
    );
};

const clearAllFilters = () => action(async (dispatch) => dispatch({ type: CLEAR_ALL_FILTERS }));

const setFacetsContinuationToken = (facetsContinuationToken) => {
    return action(async (dispatch) =>
        dispatch({ type: SET_FACETS_CONTINUATION_TOKEN, payload: facetsContinuationToken }),
    );
};

const abortFetch = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

export {
    getEvents,
    getEventsWithoutFacets,
    getFacets,
    setFilter,
    clearEventCenterData,
    clearAllFilters,
    setFacetsContinuationToken,
    getFacetsSearch,
};
