export const AZURE_REGIONS = [
    { value: "eastus", label: "(US) East US" },
    { value: "southcentralus", label: "(US) South Central US" },
    { value: "westus2", label: "(US) West US 2" },
    { value: "westus3", label: "(US) West US 3" },
    { value: "australiaeast", label: "(Asia Pacific) Australia East" },
    { value: "southeastasia", label: "(Asia Pacific) Southeast Asia" },
    { value: "northeurope", label: "(Europe) North Europe" },
    { value: "swedencentral", label: "(Europe) Sweden Central" },
    { value: "uksouth", label: "(Europe) UK South" },
    { value: "westeurope", label: "(Europe) West Europe" },
    { value: "centralus", label: "(US) Central US" },
    { value: "southafricanorth", label: "(Africa) South Africa North" },
    { value: "centralindia", label: "(Asia Pacific) Central India" },
    { value: "eastasia", label: "(Asia Pacific) East Asia" },
    { value: "japaneast", label: "(Asia Pacific) Japan East" },
    { value: "koreacentral", label: "(Asia Pacific) Korea Central" },
    { value: "newzealandnorth", label: "(Asia Pacific) New Zealand North" },
    { value: "canadacentral", label: "(Canada) Canada Central" },
    { value: "francecentral", label: "(Europe) France Central" },
    { value: "germanywestcentral", label: "(Europe) Germany West Central" },
    { value: "italynorth", label: "(Europe) Italy North" },
    { value: "norwayeast", label: "(Europe) Norway East" },
    { value: "polandcentral", label: "(Europe) Poland Central" },
    { value: "spaincentral", label: "(Europe) Spain Central" },
    { value: "switzerlandnorth", label: "(Europe) Switzerland North" },
    { value: "mexicocentral", label: "(Mexico) Mexico Central" },
    { value: "uaenorth", label: "(Middle East) UAE North" },
    { value: "brazilsouth", label: "(South America) Brazil South" },
    { value: "israelcentral", label: "(Middle East) Israel Central" },
    { value: "qatarcentral", label: "(Middle East) Qatar Central" },
    { value: "centralusstage", label: "(US) Central US (Stage)" },
    { value: "eastusstage", label: "(US) East US (Stage)" },
    { value: "eastus2stage", label: "(US) East US 2 (Stage)" },
    { value: "northcentralusstage", label: "(US) North Central US (Stage)" },
    { value: "southcentralusstage", label: "(US) South Central US (Stage)" },
    { value: "westusstage", label: "(US) West US (Stage)" },
    { value: "westus2stage", label: "(US) West US 2 (Stage)" },
    { value: "asia", label: "Asia" },
    { value: "asiapacific", label: "Asia Pacific" },
    { value: "australia", label: "Australia" },
    { value: "brazil", label: "Brazil" },
    { value: "canada", label: "Canada" },
    { value: "europe", label: "Europe" },
    { value: "france", label: "France" },
    { value: "germany", label: "Germany" },
    { value: "global", label: "Global" },
    { value: "india", label: "India" },
    { value: "israel", label: "Israel" },
    { value: "italy", label: "Italy" },
    { value: "japan", label: "Japan" },
    { value: "korea", label: "Korea" },
    { value: "newzealand", label: "New Zealand" },
    { value: "norway", label: "Norway" },
    { value: "poland", label: "Poland" },
    { value: "qatar", label: "Qatar" },
    { value: "singapore", label: "Singapore" },
    { value: "southafrica", label: "South Africa" },
    { value: "sweden", label: "Sweden" },
    { value: "switzerland", label: "Switzerland" },
    { value: "uae", label: "United Arab Emirates" },
    { value: "uk", label: "United Kingdom" },
    { value: "unitedstates", label: "United States" },
    { value: "unitedstateseuap", label: "United States EUAP" },
    { value: "eastasiastage", label: "(Asia Pacific) East Asia (Stage)" },
    { value: "southeastasiastage", label: "(Asia Pacific) Southeast Asia (Stage)" },
    { value: "brazilus", label: "(South America) Brazil US" },
    { value: "eastus2", label: "(US) East US 2" },
    { value: "eastusstg", label: "(US) East US STG" },
    { value: "northcentralus", label: "(US) North Central US" },
    { value: "westus", label: "(US) West US" },
    { value: "japanwest", label: "(Asia Pacific) Japan West" },
    { value: "jioindiawest", label: "(Asia Pacific) Jio India West" },
    { value: "centraluseuap", label: "(US) Central US EUAP" },
    { value: "eastus2euap", label: "(US) East US 2 EUAP" },
    { value: "southcentralusstg", label: "(US) South Central US STG" },
    { value: "westcentralus", label: "(US) West Central US" },
    { value: "southafricawest", label: "(Africa) South Africa West" },
    { value: "australiacentral", label: "(Asia Pacific) Australia Central" },
    { value: "australiacentral2", label: "(Asia Pacific) Australia Central 2" },
    { value: "australiasoutheast", label: "(Asia Pacific) Australia Southeast" },
    { value: "jioindiacentral", label: "(Asia Pacific) Jio India Central" },
    { value: "koreasouth", label: "(Asia Pacific) Korea South" },
    { value: "southindia", label: "(Asia Pacific) South India" },
    { value: "westindia", label: "(Asia Pacific) West India" },
    { value: "canadaeast", label: "(Canada) Canada East" },
    { value: "francesouth", label: "(Europe) France South" },
    { value: "germanynorth", label: "(Europe) Germany North" },
    { value: "norwaywest", label: "(Europe) Norway West" },
    { value: "switzerlandwest", label: "(Europe) Switzerland West" },
    { value: "ukwest", label: "(Europe) UK West" },
    { value: "uaecentral", label: "(Middle East) UAE Central" },
    { value: "brazilsoutheast", label: "(South America) Brazil Southeast" }
  ];