import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { clouds } from "../../../../utils/icons";
import AppWizard from "../../../../shared/appWizard/appWizard";
import AwsIntegrationConfiguration from "./awsIntegrationConfiguration";
import AwsFinalInstructions from "./awsFinalInstructions";
import { getInstructionsCode } from "../../../../redux/actions/keyPairsActions";
import regionsOptions from "../../../../consts/regions";
import {
    getExternalId as getAccountExternalId,
  } from "../../../../redux/actions/integrationsActions";

import "./awsIntegration.scss";

const AwsIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isProd, setIsProd] = useState(false);
  const [isEventDriven, setIsEventDriven] = useState(true);
  const [isMultipleAccount, setIsMultipleAccount] = useState(false);
  const [regions, setRegions] = useState(_.keys(regionsOptions));
  const [disableSubmit, setDisableSubmit] = useState(false);

  const externalId = useSelector((state) => state.integrationsReducer.aws.externalId);

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  useEffect(() => {
    dispatch(getAccountExternalId());
  }, []);

  useEffect(() => {
    if (isEventDriven && _.isEmpty(regions)) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [regions, isEventDriven]);

  const configWizard = [
    {
      step_title: "Configuration",
      step_description: "Modify default configuration values",
      content_title: "Terraform Provider Configuration",
      content: (
        <AwsIntegrationConfiguration
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          isProd={isProd}
          handelSetIsProd={() => setIsProd(!isProd)}
          isEventDriven={isEventDriven}
          isMultipleAccount={isMultipleAccount}
          handelSetIsMultipleAccount={() => setIsMultipleAccount(!isMultipleAccount)}
          handelSetIsEventDriven={() => setIsEventDriven(!isEventDriven)}
          setLabels={(regions) => setRegions(regions)}
          regions={regions}
          disableSubmit={disableSubmit}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const data = await dispatch(getInstructionsCode("aws", { name: wizardState?.name, isProd, externalId, isEventDriven, regions: isEventDriven ? regions: [], isMultipleAccount }));
        setWizardState({ ...wizardState, code: data?.code });
        setLoadingSubmit(false);
        if (_.isEmpty(data?.code)) {
          throw new Error("Failed to get instructions code. Please try again");
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "Final Instructions",
      content_title: "Terraform Provider Configuration",
      content: (
        <AwsFinalInstructions
          wizardState={wizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="AwsIntegration">
      <AppWizard
        iconSrc={clouds("aws", themeDark)}
        iconStyle={{ height: "35px", transform: "translateY(2px)" }}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        enableLastStepPrevious={true}
      />
    </div>
  );
};

export default AwsIntegration;
