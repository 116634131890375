import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ReactComponent as ExpectedValueIcon } from "../../../../../Images/ci/scan-expected.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as RemediationIcon } from "../../../../../Images/general_icons/remediation.svg";

import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RemediationBlock from "../../remediationBlock/remediationBlock";
import "./policyScaning.scss";

const PolicyScaningItem = ({
    severLower,
    severityTitle,
    actualValue,
    expectedValue,
    codeStartLine,
    code,
    scanId,
    resourceId,
    fetchRemediateScan,
    description,
    ruleUrl,
    ruleName,
    openRemediationsByDefault,
    allLoading,
    shouldOpenSpecificRemediation = false,
}) => {
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "remediation" });
    const [isRemediationOpen, setIsRemediateOpen] = useState(false);
    const [remedationLoading, setRemedationLoading] = useState(false);
    const remediationPlanByScanId =
        useSelector((state) => state.ciWorkflowsReducer.remediationPlanByScanId) || {};
    const violationRemediation = remediationPlanByScanId[scanId] || {};
    const isRemediation = !isEmpty(violationRemediation);

    useEffect(() => {
        if (openRemediationsByDefault && isRemediation) {
            setIsRemediateOpen(true);
        }
    }, [openRemediationsByDefault]);

    useEffect(() => {
        if (shouldOpenSpecificRemediation && !isRemediationOpen) {
            setIsRemediateOpen(true);
            handleFetch();
            const element = document.getElementById(`item-${scanId}`);
            if (element) {
                setTimeout(
                    () => element.scrollIntoView({ behavior: "smooth", block: "center" }),
                    700,
                );
            }
        }
    }, [shouldOpenSpecificRemediation]);

    const handleFetch = async () => {
        if (isRemediation) return;
        setRemedationLoading(true);
        await fetchRemediateScan({ [scanId]: resourceId });
        setRemedationLoading(false);
    };

    const onRemediateClick = () => {
        const showRemediation = !isRemediationOpen;
        setIsRemediateOpen(showRemediation);
        if (showRemediation) {
            handleFetch();
        }
    };

    return (
        <div className="PolicyScaning__container g8" id={`item-${scanId}`}>
            <div className="PolicyScaning col g5">
                <div className="row g8">
                    <span className={`PolicyScaning__title ${severLower}`}>
                        <span className="bold">{severityTitle}</span> Severity
                    </span>
                    <Tooltip title={t("thinker2Tooltip")}>
                        <div
                            className="CiCode__content-collapse-content-policyViolations-item-remediateIcon row g5"
                            onClick={() => onRemediateClick(scanId, resourceId)}
                        >
                            <RemediationIcon />
                            <span>Remediate</span>
                        </div>
                    </Tooltip>
                </div>
                {!expectedValue ? <span>{actualValue}</span> : null}
                <div className="col">
                    {code && (
                        <div className="PolicyScaning__code">
                            <SyntaxHighlighter
                                style={atomDark}
                                showLineNumbers={true}
                                language="hcl"
                                lineProps={{
                                    style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                                }}
                                startingLineNumber={codeStartLine}
                            >
                                {code}
                            </SyntaxHighlighter>
                        </div>
                    )}
                    <div className="PolicyScaning__patch col g5">
                        <div className="row g8">
                            <ExpectedValueIcon className="PolicyScaning__patch-icon" />
                            <span className="PolicyScaning__patch-title bold">{ruleName}</span>
                        </div>
                        <span className="PolicyScaning__patch-description">{description}</span>
                        <div className="row g8">
                            {expectedValue && (
                                <span className="PolicyScaning__patch-value">{expectedValue}</span>
                            )}
                            {ruleUrl && (
                                <FontAwesomeIcon
                                    className="ModuleCall__select-link"
                                    icon="external-link-alt"
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(ruleUrl, "_blank");
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isRemediationOpen && (
                <RemediationBlock
                    data={violationRemediation}
                    remediationLoading={remedationLoading || allLoading}
                    isRemediation
                    remediationType="violation"
                    setIsRemediateOpen={setIsRemediateOpen}
                    isFromExpandedBox
                />
            )}
        </div>
    );
};

export default PolicyScaningItem;
