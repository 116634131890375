import { IAC_TYPES, PROVIDERS, VCS_TYPES } from "../../consts/general";
import { clouds, iacProviders, integrationIcons, projects, remoteTypes } from "../../utils/icons";

export const cloud_integrations = (dark) => {
    let arr = [
        {
            name: "aws",
            title: "Amazon Web Services",
            subtitle:
                "Amazon Web Services (AWS), is a collection of web services that together make up a cloud computing platform.",
            icon: clouds("aws", dark),
            comingSoon: false,
            link: "/integrations/aws-integration",
            server_name: "aws",
            category: "cloud",
        },
        {
            name: "gcp",
            title: "Google Cloud",
            subtitle:
                "Google Cloud is a collection of web services that together make up a cloud computing platform.",
            icon: clouds("gcp", dark),
            ccomingSoon: false,
            link: "/integrations/gcp-provider-integration",
            server_name: "gcp",
        },
        {
            name: "azure",
            title: "Azure",
            subtitle: "Microsoft Azure is an open and flexible cloud platform.",
            icon: clouds("azure", dark),
            link: "/integrations/azure-integration",
            server_name: "azurerm",
        },
        {
            name: "k8s",
            title: "Kubernetes",
            subtitle:
                "Kubernetes (K8s), is an open-source system for automating deployment, scaling, and management of containerized applications.",
            icon: clouds("k8s", dark),
            comingSoon: false,
            link: "/integrations/k8s-integration",
            server_name: "k8s",
        },
        {
            name: "akamai",
            title: "Akamai",
            subtitle:
                "Akamai's Intelligent Edge Platform is one of the world's largest distributed computing platforms.",
            icon: clouds("akamai", dark),
            comingSoon: false,
            link: "/integrations/akamai-integration",
            server_name: "akamai",
        },
        {
            name: "datadog",
            title: "Datadog",
            subtitle:
                "Datadog is an observability service for cloud-scale applications, providing monitoring of servers, databases, tools, and services, through a SaaS-based data analytics platform.",
            icon: clouds("datadog", dark),
            comingSoon: false,
            link: "/integrations/datadog-integration",
            server_name: "datadog",
        },
        {
            name: "newrelic",
            title: "New Relic",
            subtitle:
                "New Relic is a Software as a Service offering that focuses on performance and availability monitoring. ",
            icon: clouds("newrelic", dark),
            link: "/integrations/newrelic-integration",
            comingSoon: false,
            server_name: "newrelic",
        },
        {
            name: "okta",
            title: "Okta",
            subtitle:
                "Okta is one trusted platform to secure every identity, from customers to your workforce with Single Sign-On, Multi-factor Authentication and Lifecycle Management.",
            icon: clouds("okta", dark),
            link: "/integrations/okta-integration",
            comingSoon: false,
            server_name: "okta",
        },
        {
            name: "github",
            title: "GitHub",
            subtitle:
                "GitHub is a web-based hosting service for software development projects that use the Git revision control system.",
            icon: clouds("github", dark),
            comingSoon: false,
            link: "/integrations/github-provider-integration",
            server_name: "github",
        },
        {
            name: "cloudFlare",
            title: "Cloudflare",
            subtitle:
                "Cloudflare provides web performance and security services including CDN, Argo Smart Routing, DDoS and Bot mitigation, WAF, and distributed DNS services.",
            icon: clouds("cloudflare", dark),
            comingSoon: false,
            link: "/integrations/cloudflare-integration",
            server_name: "cloudflare",
        },
        {
            name: PROVIDERS.ns1,
            title: "NS1",
            subtitle: "",
            icon: clouds(PROVIDERS.ns1, dark),
            comingSoon: false,
            link: "/integrations/ns1-integration",
            server_name: "ns1",
        },
        {
            name: PROVIDERS.pagerduty,
            title: "PagerDuty",
            subtitle: "",
            icon: clouds(PROVIDERS.pagerduty, dark),
            comingSoon: false,
            link: "/integrations/pagerduty-provider-integration",
            server_name: "pagerduty",
        },
        {
            name: PROVIDERS.mongodbatlas,
            title: "MongoDB Atlas",
            subtitle: "",
            icon: clouds(PROVIDERS.mongodbatlas, dark),
            comingSoon: false,
            link: "/integrations/mongodbatlas-integration",
            server_name: "mongodbatlas",
        },
        {
            name: PROVIDERS.vault,
            title: "Vault",
            subtitle: "",
            icon: clouds(PROVIDERS.vault, dark),
            comingSoon: false,
            link: "/integrations/vault-integration",
            server_name: "vault",
        },
        {
            name: "oracle",
            title: "Oracle Cloud",
            subtitle:
                "Oracle Cloud Infrastructure (OCI) is an IaaS platform that delivers high-performance computing and simple migrations for your on-premise applications.",
            icon: clouds("oracle", dark),
            comingSoon: true,
            server_name: "oracle",
        },
        {
            name: "alibaba",
            title: "Alibaba Cloud",
            subtitle:
                "Alibaba Cloud, a subsidiary of Alibaba Group, provides cloud computing services.",
            icon: clouds("alibaba", dark),
            comingSoon: true,
            server_name: "alibaba",
        },
    ];
    return arr;
};

export const notification_integrations = (dark) => {
    let arr = [
        {
            name: "slack",
            title: "Slack",
            subtitle:
                "Slack is a hosted, fully-searchable communication platform that brings all of your team's communication into one place.",
            icon: integrationIcons["slack"],
            comingSoon: false,
            link: "/integrations/slack-integration",
            server_name: "slack",
        },
        {
            name: "teams",
            title: "Teams",
            subtitle:
                "Microsoft Teams is the chat-based workspace in Office 365 that integrates people, content, and tools.",
            icon: integrationIcons["teams"],
            comingSoon: false,
            link: "/integrations/teams-integration",
            server_name: "teams",
        },
        {
            name: "webhook",
            title: "Webhooks",
            subtitle: "Interact with your own services via Webhooks",
            icon: integrationIcons["webhook"],
            comingSoon: false,
            link: "/integrations/webhook-integration",
            server_name: "webhook",
        },
        {
            name: "opsgenie",
            title: "Opsgenie",
            subtitle:
                "Opsgenie is a modern incident management platform that ensures critical incidents are never missed, and actions are taken by the right people in the shortest possible time.",
            comingSoon: false,
            icon: integrationIcons["opsgenie"],
            link: "/integrations/opsgenie-integration",
            server_name: "opsgenie",
        },
        {
            name: "torq",
            title: "Torq",
            subtitle:
                "Torq helps front line teams and CISOs by delivering lightweight, modern security automation that is easily integrated with their existing tools set.",
            comingSoon: false,
            icon: integrationIcons["torq"],
            link: "/integrations/torq-integration",
            server_name: "torq",
        },
        {
            name: "pagerduty",
            title: "PagerDuty",
            subtitle: "",
            comingSoon: false,
            icon: integrationIcons["pagerduty"],
            link: "/integrations/pagerduty-integration",
            server_name: "pagerduty-notification",
        },
        {
            name: "Google Chat",
            title: "Google Chat",
            subtitle: "",
            comingSoon: false,
            icon: integrationIcons["googleChat"],
            link: "/integrations/google-chat-integration",
            server_name: "googleChat",
        },
        {
            name: "Webex",
            title: "Webex",
            subtitle: "",
            comingSoon: false,
            icon: integrationIcons["webex"],
            link: "/integrations/webex-integration",
            server_name: "webex",
        },
    ];
    return arr;
};

export const git_integrations = (dark) => {
    let arr = [
        {
            name: "github",
            title: "GitHub",
            subtitle:
                "GitHub is a web-based hosting service for software development projects that use the Git revision control system.",
            icon: clouds("github", dark),
            comingSoon: false,
            link: "/integrations/github-integration",
            server_name: "githubvcs",
        },
        {
            name: "gitlab",
            title: "GitLab",
            subtitle:
                "GitLab is a web-based DevOps lifecycle tool that provides a Git repository manager.",
            icon: integrationIcons["gitlab"],
            comingSoon: false,
            link: "/integrations/gitlab-integration",
            server_name: "gitlab",
        },
        {
            name: "bitbucket",
            title: "Bitbucket",
            subtitle:
                "Bitbucket is a Git-based source code repository hosting service owned by Atlassian.",
            icon: integrationIcons["bitbucket"],
            comingSoon: false,
            link: "/integrations/bitbucket-integration",
            server_name: "bitbucket",
        },
        {
            name: "codecommit",
            title: "AWS CodeCommit",
            subtitle: "",
            icon: integrationIcons["codecommit"],
            comingSoon: false,
            link: "/integrations/codecommit-integration",
            server_name: "codecommit",
        },
        {
            name: VCS_TYPES.azuredevops,
            title: "Azure DevOps",
            subtitle: "",
            icon: integrationIcons[VCS_TYPES.azuredevops],
            comingSoon: false,
            link: "/integrations/azuredevops-integration",
            server_name: "azuredevops",
        },
    ];
    return arr;
};

export const remote_state_integrations = (dark) => {
    let arr = [
        {
            name: IAC_TYPES.terraform,
            title: "Terraform Cloud",
            subtitle:
                "Terraform Cloud is a platform that performs Terraform runs to provision infrastructure, either on-demand or in response to various events.",
            icon: iacProviders("terraform"),
            comingSoon: false,
            link: "/integrations/tfc-integration",
            server_name: IAC_TYPES.terraform,
        },
        {
            name: "consul",
            title: "HashiCorp Consul",
            subtitle:
                "Consul is a service networking solution to automate network configurations, discover services, and enable secure connectivity across any cloud or runtime.\n",
            icon: integrationIcons["consul"],
            comingSoon: false,
            link: "/integrations/consul-integration",
            server_name: "consul",
        },
        {
            name: "gcs",
            title: "Google Cloud Storage",
            subtitle:
                "Google Cloud Storage is a RESTful online file storage web service for storing and accessing data on Google Cloud Platform infrastructure.",
            icon: integrationIcons["gcs"],
            comingSoon: false,
            link: "/integrations/gcs-integration",
            server_name: "gcs",
        },
        {
            name: PROVIDERS.env0,
            title: PROVIDERS.env0,
            subtitle: "",
            icon: remoteTypes(PROVIDERS.env0),
            comingSoon: false,
            link: "/integrations/env0-integration",
            server_name: PROVIDERS.env0,
        },
        {
            name: IAC_TYPES.pulumi,
            title: "Pulumi Cloud",
            subtitle:
                "The Cloud Framework for Pulumi lets you program infrastructure and application logic, side by side, using simple, high-level, cloud-agnostic building blocks.",
            icon: iacProviders("pulumi"),
            comingSoon: true,
            link: "/integrations/pulumi-integration",
            server_name: IAC_TYPES.pulumi,
        },
    ];
    return arr;
};

export const project_integrations = (dark) => {
    let arr = [
        {
            name: "jira",
            title: "Jira",
            subtitle:
                "Jira is a proprietary issue tracking product developed by Atlassian that allows bug tracking and agile project management.",
            icon: projects["jira"],
            comingSoon: false,
            link: "/integrations/jira-integration",
            server_name: "jira",
        },
        {
            name: "servicenow",
            title: "Service Now",
            subtitle: "",
            icon: projects["servicenow"],
            comingSoon: false,
            link: "/integrations/servicenow-integration",
            server_name: "servicenow",
        },
    ];
    return arr;
};

export const AllActiveIntegrationsCatalog = (dark) => {
    const output = {
        cloud: cloud_integrations(dark),
        vcs: git_integrations(dark),
        notification: notification_integrations(dark),
        state: remote_state_integrations(dark),
        project: project_integrations(dark),
    };
    return output;
};
