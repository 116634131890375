import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as IacIgnoredIcon } from "../../../Images/states/iacIgnored.svg";
import { ReactComponent as ChildIcon } from "../../../Images/states/child.svg";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import { numberWithCommas } from "../../../utils/formatting";
import { useTranslation } from "react-i18next";
import "./otherTypes.scss";

const OtherTypes = ({ otherTypesData = {} }) => {
    const { pending = {}, child = {}, iacIgnored = {}, undetermined = {} } = otherTypesData;
    const { t } = useTranslation("dashboard", { keyPrefix: "assetsCube.otherTypes" });
    const dispatch = useDispatch();
    const history = useHistory();

    const handleOnStateClick = async (state) => {
        dispatch(
            setFilters({
                ...emptyInventoryScreenFilters,
                masterType: [],
                state: [state],
                ignoreStates: [],
            }),
        );
        history.push({
            pathname: `/inventory`,
            search: `?state=${state}`,
        });
    };
    return (
        <div className="OtherStateTypes">
            <div className={`OtherStateTypes-item pending ${pending.value ? "pointer" : ""}`} onClick={() => pending.value && handleOnStateClick("pending")}>
                <div className="OtherStateTypes__icon-number-style">
                    <FontAwesomeIcon icon="hourglass" className="OtherStateTypes-item-icon" />
                    <span className="OtherStateTypes-item-count">{numberWithCommas(pending.value || 0)}</span>
                </div>
                <span className="OtherStateTypes-item-title">{t("pending")}</span>
            </div>
            <div className="OtherStateTypes-divider ver" />
            <div className={`OtherStateTypes-item child ${child.value ? "pointer" : ""}`} onClick={() => child.value && handleOnStateClick("child")}>
                <div className="OtherStateTypes__icon-number-style">
                    <ChildIcon className="OtherStateTypes-item-icon" />
                    <span className="OtherStateTypes-item-count">{numberWithCommas(child.value || 0)}</span>
                </div>
                <span className="OtherStateTypes-item-title">{child.key || t("child")}</span>
            </div>
            <div className="OtherStateTypes-divider ver" />
            <div
                className={`OtherStateTypes-item iacIgnored ${iacIgnored.value ? "pointer" : ""}`}
                onClick={() => iacIgnored.value && handleOnStateClick("iacIgnored")}
            >
                <div className="OtherStateTypes__icon-number-style">
                    <IacIgnoredIcon className="OtherStateTypes-item-icon" />
                    <span className="OtherStateTypes-item-count">{numberWithCommas(iacIgnored.value || 0)}</span>
                </div>
                <span className="OtherStateTypes-item-title">{iacIgnored.key || t("iacIgnored")}</span>
            </div>
            <div className="OtherStateTypes-divider ver" />
            <div
                className={`OtherStateTypes-item undetermined ${undetermined.value ? "pointer" : ""}`}
                onClick={() => undetermined.value && handleOnStateClick("undetermined")}
            >
                <div className="OtherStateTypes__icon-number-style">
                    <FontAwesomeIcon icon="question" className="OtherStateTypes-item-icon" />
                    <span className="OtherStateTypes-item-count">{numberWithCommas(undetermined.value || 0)}</span>
                </div>
                <span className="OtherStateTypes-item-title">{undetermined.key || t("undetermined")}</span>
            </div>
        </div>
    );
};

export default OtherTypes;
