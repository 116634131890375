import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ClickOpsIcon } from "../../../../Images/general_icons/event_center/clickOps.svg";
import { ReactComponent as MutationsIcon } from "../../../../Images/general_icons/event_center/mutations.svg";
import { ReactComponent as CliIcon } from "../../../../Images/general_icons/event_center/cli.svg";

import { clearEventCenterData } from "../../../../redux/actions/eventCenterActions";
import { eventCenterEvents } from "../../../../utils/amplitudeEvents";
import { sendEvent } from "../../../../utils/amplitude";
import "./eventTypes.scss";

const EventTypes = ({ updateFilter, setCustomPage }) => {
    const dispatch = useDispatch();
    const lastFilteredKey = useSelector((state) => state.eventCenterReducer?.lastFilteredKey || "");
    const filters = useSelector((state) => state.eventCenterReducer?.filters || {});
    const [selectedEventTypes, setSelectedEventTypes] = useState(["click_ops"]);

    const [items, setItems] = useState([
        {
            title: "ClickOps",
            icon: <ClickOpsIcon />,
            isSelected: true,
            key: "click_ops",
        },
        {
            title: "CLI/SDK",
            icon: <CliIcon />,
            isSelected: false,
            key: "cli_sdk",
        },
        {
            title: "Mutations",
            icon: <MutationsIcon />,
            isSelected: false,
            key: "mutation",
        },
    ]);

    const handleSetFilter = (row) => {
        sendEvent(eventCenterEvents.eventTypeFilter, { title: row.title });
        const updatedItems = items.map((item) =>
            item?.key === row?.key ? { ...item, isSelected: !item.isSelected } : item,
        );
        setItems(updatedItems);
        const selectedTypes = updatedItems
            .filter((item) => item?.isSelected)
            .map((item) => item?.key);
        setCustomPage(1);
        dispatch(clearEventCenterData());
        return updateFilter("eventType", selectedTypes);
    };

    useEffect(() => {
        if (lastFilteredKey !== "ClearAll") return;
        items[0].isSelected = true;
        items[1].isSelected = false;
        items[2].isSelected = false;
        setItems(items);
        setSelectedEventTypes(["click_ops"]);
        updateFilter("eventType", ["click_ops"]);
    }, [lastFilteredKey]);

    useEffect(() => {
        if (filters?.eventType.length !== 1 || selectedEventTypes.length !== 1) {
            return;
        }

        if (filters?.eventType[0] === selectedEventTypes[0]) {
            return;
        }
        for (const item of items) {
            if (item.key === filters?.eventType[0]) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        }
        setItems(items);
        setSelectedEventTypes(filters?.eventType);
    }, [filters?.eventType]);

    return (
        <div className="EventTypes">
            {items.map((item) => (
                <div
                    className="EventTypes__item"
                    key={item.key}
                    onClick={() => handleSetFilter(item)}
                >
                    <div
                        className={`EventTypes__item-inner row g5 ${
                            item.isSelected ? "active-custom" : ""
                        }`}
                        style={{
                            padding: "0 10px",
                            justifyContent: "start",
                            transition: "padding 0.5s, justify-content 0.5s",
                        }}
                    >
                        {item.icon}
                        {item.title}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EventTypes;
