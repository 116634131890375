import React, { useState, useCallback } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import "./searchBox.scss";

const SearchBox = ({
  placeholder,
  value,
  resetSearch,
  rightSide,
  grayMode,
  darkMode,
  width,
  noBorder,
  withBackground,
  grayModeBorder,
  hideReset,
  className
}) => {
  const [val, setVal] = useState("");
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);

  const handleChange = (e) => {
    setVal(e.target.value);
    debounce(e.target.value);
  };

  const debounce = useCallback(
    _.debounce((val) => {
      value(val);
    }, 350),
    []
  );

  return (
    <div
      className={`SearchBox ${rightSide ? "rightSide" : ""} ${
        grayMode ? "grayMode" : ""
      } ${noBorder ? "noBorder" : ""} ${
        withBackground ? "withBackground" : ""
      } ${grayModeBorder ? "grayModeBorder" : ""}
        ${darkMode ? "darkMode" : ""}
      ${className}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: width ? width : rightSide ? "250px" : "100%",
      }}
    >
      <FontAwesomeIcon
        icon={faSearch}
        className="SearchBox__magnify"
        style={{ opacity: active || val.length > 0 ? 0 : 1 }}
      />
      <input
        placeholder={active || val.length > 0 ? null : placeholder}
        onChange={handleChange}
        value={val}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        style={{
          paddingLeft: active || val.length > 0 ? "10px" : "38px",
          paddingRight: active || val.length > 0 ? "38px" : "10px",
          borderColor:
            (active && !noBorder) || (val.length > 0 && !noBorder)
              ? "rgba(133, 89, 254, 0.5)"
              : "transparent",
        }}
      />
      {!hideReset && (
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="SearchBox__clear"
          style={{ visibility: val.length > 0 ? "visible" : "hidden" }}
          onClick={() => {
            setVal("");
            resetSearch("");
          }}
        />
      )}
    </div>
  );
};

export default SearchBox;
