import React from "react";
import { Collapse } from "antd";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import "./collapsePanel.scss";
import { atomDark } from "react-syntax-highlighter/dist/cjs/styles/prism";

const CollapsePanel = ({
    header = "",
    headerKey,
    data = {},
    defaultActiveKey = ["1"],
    showLineNumbers = true,
}) => {
    const { Panel } = Collapse;

    const customStyle = {
        ...atomDark,
        "hljs-attr": { color: "#ecc48d" },
    };

    const jsonString = typeof data === "string" ? data : JSON.stringify(data, null, 2);

    return (
        <Collapse defaultActiveKey={defaultActiveKey} expandIconPosition="left">
            <Panel header={header} key={headerKey}>
                <div className={`ClickOps__code`}>
                    <SyntaxHighlighter
                        style={customStyle}
                        wrapLines={true}
                        showLineNumbers={showLineNumbers}
                        language="json"
                        lineProps={{
                            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                        }}
                        customStyle={{
                            backgroundColor: "#212133 !important",
                            margin: 0,
                            padding: "1rem",
                            borderRadius: "4px",
                            color: "#fffff",
                        }}
                    >
                        {jsonString}
                    </SyntaxHighlighter>
                </div>
            </Panel>
        </Collapse>
    );
};

export default CollapsePanel;
