import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import "./serviceNowEditForm.scss";

const ServiceNowEditForm = () => {
    const { t } = useTranslation("integrations", { keyPrefix: "servicenow" });

    return (
        <div className="serviceNowEditForm">
            <Form.Item
                label={t("form.username")}
                name="username"
                rules={[
                    {
                        required: true,
                        message: `${t("form.username")} ${t("form.isRequired")}`,
                    },
                ]}
                className="form-item"
            >
                <Input placeholder={`Please fill ${t("form.username")}`} />
            </Form.Item>
            <Form.Item
                label={t("form.password")}
                name="password"
                rules={[
                    {
                        required: true,
                        message: `${t("form.password")} ${t("form.isRequired")}`,
                    },
                ]}
                className="form-item"
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label={t("form.url")}
                name="url"
                rules={[
                    {
                        required: true,
                        message: `${t("form.url")} ${t("form.isRequired")}`,
                    },
                ]}
                className="form-item"
            >
                <Input placeholder={`Please fill ${t("form.url")}`} />
            </Form.Item>
        </div>
    );
};

export default ServiceNowEditForm;
