import React, { useState, useCallback, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "antd/lib/collapse";
import ProvidersFilter from "./providersFilter";
import ScopesFilter from "./scopesFilter";
import SeverityFilter from "./severityFilter";
import DataSourcesFilter from "./dataSourcesFilter";
import FrameworksFilterV2 from "./frameworksFilterV2";
import CategoriesFilter from "./categoriesFilter";
import { ReactComponent as Chevron } from "../../../../Images/general_icons/chevron.svg";
import { ReactComponent as ScopeIcon } from "../../../../Images/general_icons/insights-filters/scope-icon.svg";
import { ReactComponent as CloudIcon } from "../../../../Images/general_icons/provider_cloud.svg";
import { ReactComponent as FrameworkIcon } from "../../../../Images/general_icons/frameworks-icon.svg";
import { ReactComponent as CategoryIcon } from "../../../../Images/general_icons/insights-filters/category-icon.svg";
import { ReactComponent as SeverityIcon } from "../../../../Images/general_icons/insights-filters/severity.svg";
import { ReactComponent as AccountsIcon } from "../../../../Images/general_icons/insights-filters/accounts-icon.svg";
import map from "lodash/map";
import "./policyCollapseFilters.scss";
import FrameworksFilter from "./frameworksFilter";
import { isEqual } from "lodash";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import { insightsFilterTemplate } from "../../../../consts/insights";
import IconBtn from "../../../../shared/iconBtn/iconBtn";
import { sendEvent } from "../../../../utils/amplitude";

const PolicyCollapseFilters = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("insights");
    const { Panel } = Collapse;
    const [activePanel, setActivePanel] = useState(null);

    const filtersOpen = useSelector((state) => state.insightsV2Reducer.filtersOpen);

    const insightsReducer = useSelector((state) => state.insightsV2Reducer);

    useEffect(() => {
        setActivePanel("Frameworks");
    }, []);

    const panels = [];

    panels.push(
        {
            title: t("filters.frameworks"),
            component: <FrameworksFilterV2 />,
            icon: <FrameworkIcon />,
        },
        {
            title: t("filters.categories"),
            component: <CategoriesFilter />,
            icon: <CategoryIcon />,
        },
        {
            title: t("filters.providers"),
            component: <ProvidersFilter />,
            icon: <CloudIcon />,
        },
        {
            title: t("filters.dataSource"),
            component: <DataSourcesFilter />,
            icon: <AccountsIcon />,
        },
        {
            title: t("filters.scopes"),
            component: <ScopesFilter />,
            icon: <ScopeIcon />,
        },
        {
            title: t("filters.severity"),
            component: <SeverityFilter />,
            icon: <SeverityIcon />,
        },
    );

    const handleCutsomArrow = useCallback(
        (e) => {
            if (!filtersOpen) {
                return null;
            }
            return (
                <Chevron
                    style={{
                        transform: e.isActive
                            ? "translateY(2px)"
                            : "rotate(-90deg) translateY(2px)",
                        transition: "all .2s ease",
                        width: "10px",
                    }}
                />
            );
        },
        [activePanel, filtersOpen],
    );

    const handlePanelHeader = useCallback(
        (obj) => {
            if (!filtersOpen) {
                return (
                    <span className="PolicyCollapseFilters__panel-header center">{obj?.icon}</span>
                );
            }
            return (
                <div className="PolicyCollapseFilters__panel-header row g10">
                    {obj?.icon}
                    {obj?.title}
                </div>
            );
        },
        [activePanel, filtersOpen],
    );

    const handleClearFilters = () => {
        sendEvent("clicked governance clear all");
        return dispatch(setInsightsFilters(insightsFilterTemplate));
    };

    return (
        <div className="PolicyCollapseFilters col">
            <span className="PolicyCollapseFilters__title bold">
                {t("filters.filters-sub-title")}
                {!isEqual(insightsReducer.filters, insightsFilterTemplate) && (
                    <span className="clear-all" onClick={handleClearFilters}>
                        {insightsReducer.filtersOpen && (
                            <span className="InsightsFilters-clear-btn purple-text underline">
                                {t("filters.clear-btn")}
                            </span>
                        )}
                    </span>
                )}
            </span>
            <Collapse
                expandIconPosition="right"
                expandIcon={handleCutsomArrow}
                activeKey={activePanel}
                onChange={setActivePanel}
                accordion
            >
                {map(panels || [], (i) => {
                    return (
                        <Panel
                            key={i?.title}
                            header={handlePanelHeader(i)}
                            className="PolicyCollapseFilters__panel"
                        >
                            {i.component}
                        </Panel>
                    );
                })}
            </Collapse>
        </div>
    );
};

export default memo(PolicyCollapseFilters);
