import { useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getAllClassificationsNames } from '../../../redux/actions/classificationsActions';

export const useClassifications = () => {
    const dispatch = useDispatch();
    const [isLoadingClassificationNames, setIsLoadingClassificationNames] = useState(false);
    const terraformPolicies = useSelector((state) => state.classificationsReducer.terraformClassificationsNames) || [];
    const regoPolicies = useSelector((state) => state.classificationsReducer.regoClassificationsNames) || [];

    const fetchPolicies = useCallback(async () => {
        if (isEmpty(terraformPolicies) || isEmpty(regoPolicies)) {
            setIsLoadingClassificationNames(true);
            await dispatch(getAllClassificationsNames());
            setIsLoadingClassificationNames(false);
        }
    }, [terraformPolicies, regoPolicies]);

    // For TreeSelect in GuardrailsModal
    const allPolicies = useMemo(() => [
        ...terraformPolicies.map((policy = {}) => ({ 
            title: policy.name, 
            value: policy.id, 
            key: policy.id 
        })),
        ...regoPolicies.map((policy = {}) => ({ 
            title: policy.name, 
            value: policy.id, 
            key: policy.id 
        }))
    ], [terraformPolicies, regoPolicies]);

    // For GuardrailCriteriaPopover
    const policiesNamesMap = useMemo(() => {
        return [...terraformPolicies, ...regoPolicies].reduce((acc, policy = {}) => {
            acc[policy.id] = policy.name;
            return acc;
        }, { All: 'All' });
    }, [terraformPolicies, regoPolicies]);

    return {
        terraformPolicies,
        regoPolicies,
        allPolicies,
        policiesNamesMap,
        isLoadingClassificationNames,
        fetchPolicies
    };
};
