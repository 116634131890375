import "./assetNameCell.scss";
import { useHistory } from "react-router-dom";

const AssetNameCell = ({ assetName, assetKey }) => {
    const history = useHistory();

    const handleClick = () => {
        return history.push(`/inventory?asset=${assetKey}`);
    };

    return (
        <div
            onClick={assetKey ? handleClick : undefined}
            className={`AssetNameCell ${assetKey ? "AssetNameCell-clickable" : ""}`}
        >
            {assetName}
        </div>
    );
};

export default AssetNameCell;
