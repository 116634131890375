import React, { memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import InventorySingelRadioFilter from "../../../inventory/inventoryFilters/inventorySingelRadioFilter";
import { sendEvent } from "../../../../utils/amplitude";
import { governanceEvents } from "../../../../utils/amplitudeEvents";
import { insights } from "../../../../utils/icons";

const CategoriesFilter = () => {
    const prioritizedCategories = ["Best Practices", "Access Control", "Optimization"];

    const dispatch = useDispatch();
    const categorysAggs = useSelector((state) => state.insightsV2Reducer.aggs?.category);
    const insightFilters = useSelector((state) => state.insightsV2Reducer.filters);

    const handleSetSelectedCategory = useMemo(
        () => (selectedCategory) => {
            const selectedCategoryFormted = selectedCategory[0] ? selectedCategory[0] : "";
            const payload = { ...insightFilters, category: selectedCategoryFormted };
            sendEvent(governanceEvents.categories, {
                selectedFilter: selectedCategoryFormted,
            });
            return dispatch(setInsightsFilters(payload));
        },
        [insightFilters],
    );

    const categories = useMemo(() => {
        const mappedCategories = (categorysAggs || []).map((category) => ({
            name: category?.value,
            formattedName: category?.value,
            element: <img src={insights(category?.value)} alt={category?.value} />,
        }));

        mappedCategories.sort((a, b) => {
            const aIndex = prioritizedCategories.indexOf(a.name);
            const bIndex = prioritizedCategories.indexOf(b.name);

            if (aIndex === -1 && bIndex === -1) return 0;
            if (aIndex === -1) return 1;
            if (bIndex === -1) return -1;

            return aIndex - bIndex;
        });

        return mappedCategories;
    }, [categorysAggs]);

    return (
        <InventorySingelRadioFilter
            allTypes={categories}
            selectedTypes={handleSetSelectedCategory}
            checkedList={insightFilters?.category || []}
            title="Search"
            onSearch={(val) =>
                sendEvent(governanceEvents.textSearched, {
                    searchedText: val,
                    filterName: "categories",
                })
            }
        />
    );
};

export default memo(CategoriesFilter);
