import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as ExpectedValueIcon } from "../../../../Images/ci/scan-expected.svg";
import { ReactComponent as RemediationIcon } from "../../../../Images/general_icons/remediation.svg";


import { capitalizeFirst } from '../../../../utils/formatting';
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCodeOrPlanScanRemediation } from "../../../../redux/actions/ciWorkflowsActions";
import RemediationBlock from "../remediationBlock/remediationBlock";
import { sendEvent } from "../../../../utils/amplitude";
import { aiRemediationEvents } from "../../../../utils/amplitudeEvents";

const CodeScanResult = ({ codeScan, ind, onClickPolicyViolation, codeDivId, taskId = "", openRemediationByDefault = false, specificExpectedValue }) => {
    const key = uuidv4();
    const { severity = "", actualValue, expectedValue = "", line, id: scanId = "", ruleName } = codeScan;
    const [isRemediationOpen, setIsRemediateOpen] = useState(false);
    const [remediationLoading, setRemedationLoading] = useState(false);
    const toLowerSeverity = severity.toLowerCase();
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "remediation" })
    const dispatch = useDispatch();
    const remediationCodeByScanId = useSelector((state) => state.ciWorkflowsReducer.remediationCodeByScanId) || {};
    const violationRemediation = remediationCodeByScanId[scanId] || {};
    const isRemediation = !isEmpty(violationRemediation);

    useEffect(() => {
        if (openRemediationByDefault && isRemediation) {
            setIsRemediateOpen(true)
        }
    }, [openRemediationByDefault]);

    useEffect(() => {
        const shouldFetchRemediation = !isRemediation && (specificExpectedValue === expectedValue);
        if (shouldFetchRemediation) {
            fetchRemediateScan();
            setIsRemediateOpen(true);
        }
    }, [specificExpectedValue]);

    const fetchRemediateScan = async() => {
        if (isRemediationOpen || isRemediation) return;
        setRemedationLoading(true);
        const payload = { codeScans: [scanId] };
        await dispatch(getCodeOrPlanScanRemediation(payload, taskId));
        setRemedationLoading(false);
    }
    const onRemediateClick = () => {
        const showRemediation = !isRemediationOpen;
        setIsRemediateOpen(showRemediation);
        if (showRemediation) {
            fetchRemediateScan();
            sendEvent(aiRemediationEvents.clickedWorkflowPolicyViolations, { origin: "code", ruleName });
        }
    }

    return (
        <div className="CiCode__content-collapse-content-policyViolations-container col g8" id={`item-${expectedValue}`}>
            <div className={`CiCode__content-collapse-content-policyViolations-item ${toLowerSeverity} col g5`} key={key}>
                <div className="CiCode__content-collapse-content-policyViolations-item-header row between">
                    <div className="row g8">
                        <span className={`CiCode__content-collapse-content-policyViolations-item-title ${toLowerSeverity} bold`}>{capitalizeFirst(toLowerSeverity)}</span>
                        <span className="CiCode__content-collapse-content-policyViolations-item-desc row g8">
                            <span>{actualValue}</span>
                            <span className="purple-text pointer underline" onClick={(e) => onClickPolicyViolation(e, line, codeDivId)}>Line {line}</span>
                        </span>
                    </div>
                </div>
                <div className="CiCode__content-collapse-content-policyViolations-item-patch row between">
                    <div className="row g8">
                        <ExpectedValueIcon className={`CiCode__content-collapse-content-policyViolations-item-patch-icon ${toLowerSeverity}`} />
                        <span className="PolicyScaning__patch-title">{expectedValue}</span>
                    </div>
                    <Tooltip title={t("thinker2Tooltip")}>
                        <div className="CiCode__content-collapse-content-policyViolations-item-remediateIcon row g5" onClick={onRemediateClick}>
                            <RemediationIcon  />
                            <span>Remediate</span>
                        </div>
                        
                    </Tooltip>
                </div>
            </div>
            {isRemediationOpen && <RemediationBlock data={violationRemediation} remediationType="violation" remediationLoading={remediationLoading} isRemediation setIsRemediateOpen={setIsRemediateOpen} isFromExpandedBox />}
        </div>
        )
};

export default CodeScanResult;