import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuItem, Divider } from "@mui/material";
import { setFilters } from "../../../../redux/actions/inventoryv3Actions";
import { formatAwsStringWithUnderscore } from "../../../../utils/formatting";
import { sendEvent } from "../../../../utils/amplitude";
import { tableContextMenuEvents } from "../../../../utils/amplitudeEvents";

import { ReactComponent as CopyIdIcon } from "../../../../Images/general_icons/copy_id.svg";
import { ReactComponent as CopyIcon } from "../../../../Images/general_icons/copy_icon.svg";
import { ReactComponent as CloudIcon } from "../../../../Images/clouds/cloud_icon.svg";
import { ReactComponent as AssetTypeIcon } from "../../../../Images/general_icons/layers_outline.svg";
import { ReactComponent as LocationIcon } from "../../../../Images/general_icons/location_outline.svg";
import { ReactComponent as FilterIcon } from "../../../../Images/general_icons/filter_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TableContextMenu.scss";

const TableContextMenu = ({ contextMenu, handleCloseContextMenu, isDarkTheme }) => {
    const dispatch = useDispatch();
    const screenFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);
    const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);

    const isMenuOpen = Boolean(contextMenu?.row);
    const isFilterMenuOpen = Boolean(filterMenuAnchor);

    useEffect(() => {
        if (!contextMenu) return;
        sendEvent(tableContextMenuEvents.rightClick);
    }, [contextMenu]);

    const menuPosition = useMemo(() => {
        if (!contextMenu) return undefined;
        return { top: contextMenu.mouseY, left: contextMenu.mouseX };
    }, [contextMenu]);

    const handleClose = () => {
        setFilterMenuAnchor(null);
        handleCloseContextMenu();
    };

    const handleCopyAssetId = () => {
        if (!contextMenu?.row?.assetId) return;
        navigator.clipboard.writeText(contextMenu.row.assetId);
        sendEvent(tableContextMenuEvents.copyAssetId);
        handleClose();
    };

    const handleCopyAssetLink = () => {
        if (!contextMenu?.row) return;
        const { integrationId, assetType, frn } = contextMenu.row;
        if (!integrationId || !assetType || !frn) return;

        const assetId = `${integrationId}-${assetType}-${frn}`;
        navigator.clipboard.writeText(`${window.location.origin}/inventory?asset=${assetId}`);
        sendEvent(tableContextMenuEvents.copyAssetLink);
        handleClose();
    };

    const handleFilter = (filterType, filterValue) => {
        const filterMap = {
            integration: { providerTypes: { integrationId: [filterValue] } },
            assetType: { types: [filterValue] },
            region: { regions: [filterValue] },
            state: { state: [filterValue] },
        };

        if (!filterMap[filterType]) return;

        dispatch(setFilters({ ...screenFilters, ...filterMap[filterType] }));
        sendEvent(tableContextMenuEvents.filterBy, { filterType });
        handleClose();
    };

    const handleFilterMenuOpen = (event) => {
        setFilterMenuAnchor(event.currentTarget);
    };

    const handleJumpToConsole = () => {
        if (!contextMenu?.row?.consoleURL) return;
        window.open(contextMenu?.row?.consoleURL, "_blank");
        sendEvent(tableContextMenuEvents.jumpToConsole);
        handleClose();
    };

    const renderMenu = () => {
        return (
            <Menu
                open={isMenuOpen}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
                className={`TableContextMenu ${isDarkTheme ? "dark" : "light"}`}
                MenuListProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <MenuItem onClick={handleCopyAssetId} disabled={!contextMenu?.row?.assetId}>
                    <CopyIdIcon />
                    Copy ID
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={handleCopyAssetLink}
                    disabled={
                        !contextMenu?.row?.integrationId ||
                        !contextMenu?.row?.assetType ||
                        !contextMenu?.row?.frn
                    }
                >
                    <CopyIcon />
                    Copy link to asset
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleJumpToConsole} disabled={!contextMenu?.row?.consoleURL}>
                    <FontAwesomeIcon icon="external-link-alt" />
                    Jump to console
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleFilterMenuOpen} className="filter-menu-trigger">
                    <FilterIcon />
                    Filter by
                </MenuItem>
            </Menu>
        );
    };

    const renderFilterMenu = () => {
        return (
            <Menu
                open={isFilterMenuOpen}
                onClose={handleClose}
                anchorEl={filterMenuAnchor}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                className={`TableContextMenu ${isDarkTheme ? "dark" : "light"}`}
                MenuListProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <MenuItem
                    onClick={() => handleFilter("integration", contextMenu?.row?.integrationId)}
                    disabled={!contextMenu?.row?.integrationId}
                >
                    <CloudIcon />
                    Integration:{" "}
                    <span className="filter-name">{contextMenu?.row?.integrationName}</span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleFilter("assetType", contextMenu?.row?.assetType)}
                    disabled={!contextMenu?.row?.assetType}
                >
                    <AssetTypeIcon className="asset-type-icon" />
                    Type:{" "}
                    <span className="filter-name">
                        {formatAwsStringWithUnderscore(contextMenu?.row?.assetType)}
                    </span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleFilter("region", contextMenu?.row?.region)}
                    disabled={!contextMenu?.row?.region}
                >
                    <LocationIcon className="location-icon" />
                    Region: <span className="filter-name">{contextMenu?.row?.region}</span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleFilter("state", contextMenu?.row?.state)}
                    disabled={!contextMenu?.row?.state}
                >
                    <FilterIcon />
                    State: <span className="filter-name">{contextMenu?.row?.state}</span>
                </MenuItem>
            </Menu>
        );
    };

    return (
        <>
            {renderMenu()}
            {renderFilterMenu()}
        </>
    );
};

export default React.memo(TableContextMenu);
