import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../../utils/formatting";
import { useHistory } from "react-router-dom";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import UnavailableIconText from "../../../shared/unavailableIconText/unavailableIconText";
import { governanceEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";

const MatchingAssetCell = ({ row = {}, closeDrawer, handleClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );

  const integrations = useSelector(
    (state) => state.insightsV2Reducer.filters?.integrations
  );

  const { excluded_assets, total_assets,  total_assets_by_types = 0 } = row;
  const count = showExclusion ? excluded_assets : total_assets;
  
  const handleViewAssets = (e) => {
    e.stopPropagation();

    sendEvent(governanceEvents.violatingAssets, {
        policyName: row?.name, 
        severity: row?.severity, 
        assetCount: count, 
        provider: row?.providers?.length ? row.providers[0] : ""
    });

    const ignoreStates = showExclusion ? ["iacIgnored"] : [];

    dispatch(
      setFilters({
        classifications: [row?._id],
        ignoreStates,
        providerTypes: {
          integrationId: integrations,
        },
        masterType: []
      })
    );
    if(closeDrawer && handleClose){
      handleClose(false);
      closeDrawer(false);
    }
     return history.push({
      pathname: "/inventory" ,
      search: "?defaultView=false",
    });
  };


  if (row?.isScanning) {
    return (
      <span className="row g8">
        Scanning
        <FontAwesomeIcon icon="spinner" className="purple-text" spin />
      </span>
    );
  }

  if (!row?.isEnabled) {
    return null;
  }

  if (count === 0 && total_assets_by_types === 0) {
    return (
        <UnavailableIconText toolTipTitle={"No assets found to scan"} />
    );
  }

  if (!count && row?.isEnabled) {
    return (
      <span
        style={{ fontSize: "13px" }}
        className="purple-flag row g5"
      >
        <FontAwesomeIcon icon="layer-group" className="purple-text" />
        0
      </span>
    );
  }

    return (
        <a
            href={`/inventory?defaultView=false&classifications=${row?._id}`}
            className="matching-assets-cell purple-flag row g5"
            onClick={(e) => {
                e.preventDefault();
                handleViewAssets(e);
            }}
        >
            <FontAwesomeIcon icon="layer-group" className="purple-text" />
            {numberWithCommas(count)}
            <FontAwesomeIcon
                icon="external-link-alt"
                style={{ fontSize: "11px", marginLeft: "5px" }}
            />
        </a>
    );
};

export default MatchingAssetCell;
