import React from "react";
import _ from "lodash";
import { Checkbox, Radio, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { numberWithCommas } from "../../../../../utils/formatting";
import ProviderIcon from "../../../../../shared/providerIcon/providerIcon";

const FacetContent = ({
    name,
    count,
    isProdFlag,
    element,
    formattedName,
    checkedList = [],
    provider,
    tooltip = "",
}) => {
    return (
        <Checkbox
            value={name}
            className={`SingelFilter__types-item row ${
                !_.isEmpty(checkedList) && checkedList?.includes(name) && "active"
            }`}
        >
            <div className={`SingelFilter__types-item-wrapper`}>
                <div className="SingelFilter__types-item-wrapper-inner">
                    <div className="SingelFilter__types-item-wrapper-inner-content row between">
                        {!_.isEmpty(provider) && (
                            <ProviderIcon provider={provider} customStyle="inventory" />
                        )}
                        {!_.isEmpty(element) && <span className="element">{element}</span>}
                        <Tooltip title={tooltip}>
                            <span className="SingelFilter__types-item-wrapper-inner-text">{`${formattedName}`}</span>
                        </Tooltip>
                        {count && (
                            <span className="SingelFilter__types-item-wrapper-inner-count">{`${numberWithCommas(
                                count,
                            )}`}</span>
                        )}
                        {isProdFlag && (
                            <span className="SingelFilter__types-item-wrapper-inner-count">
                                <FontAwesomeIcon
                                    icon={faFlag}
                                    style={{ opacity: ".7", marginLeft: "1px" }}
                                />
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </Checkbox>
    );
};

export default FacetContent;
