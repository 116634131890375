import React from "react";
import { useTranslation } from "react-i18next";
import DarkCopyBox from "../../../../shared/darkCopyBox/darkCopyBox";
import TypingDots from "../../../../shared/typingDots/typingDots";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as ThinkerbellIcon } from "../../../../Images/general_icons/thinkerbell/thinkerbell_logo.svg";

import "./remediationBlock.scss";

const RemediationBlock = ({ data = {}, remediationLoading, isRemediation, setIsRemediateOpen, isFromExpandedBox, remediationType }) => {
  const { t } = useTranslation("ci-pipeline", { keyPrefix: "remediation" });
  const { explanation = "", suggestion = "", suggestedCode = "", suggestedCommandLine = "" } = data;
  
  return (
    <div className={`RemediationBlock ${isFromExpandedBox ? 'expandedBlock' : ''} col`}>
        <div className="RemediationBlock-header row between">
            <div className="row g10">
                <ThinkerbellIcon className="RemediationBlock-header-icon fadeIn-animation-oneSec"/>
                <span className="RemediationBlock-header-title font-16 row g5">
                    <span className="bold">{t("thinker")}</span>
                    <span>{t("assistant")}</span>
                </span>
            </div>
            {isFromExpandedBox && <FontAwesomeIcon icon="times" className="RemediationBlock-closeIcon pointer" onClick={() => setIsRemediateOpen(false)} />}
        </div>
        <div className="RemediationBlock-body col g5">
        {remediationLoading ? (
            <span className="RemediationBlock-body-loading row g8 font-13">
            {remediationType === "diagnostic" ? t("loadingText.diagnosticLoading") : t("loadingText.violationLoading")} <TypingDots isInText />
            </span>
            ) : isRemediation ? (
            <div className="RemediationBlock-body-content col g5">
            <span className="bold underline">{remediationType === "diagnostic" ? t("subtitles.explanation.errorDetails") : t("subtitles.explanation.violationDetails")} </span>
            <span className="RemediationBlock-text font-13">
                {explanation}
            </span>
            <span className="bold underline">
                {t("subtitles.suggestedResoultion")}
            </span>
            <span className="RemediationBlock-text font-13">
                {suggestion}
            </span>
            {suggestedCode && (
                <div className="col g5">
                    <span className="bold underline">
                        {t("subtitles.codeUpdate")}
                    </span>
                    <span className="font-13">{t("descriptions.codeUpdate")}</span>
                    <DarkCopyBox text={suggestedCode} />
                </div>
            )}
            {suggestedCommandLine && (
                <div className="col g5">
                    <span className="bold underline">
                        {t("subtitles.suggestedCommandLine")}
                    </span>
                    <span className="font-13">
                        {t("descriptions.suggestedCommandLine")}
                    </span>
                    <DarkCopyBox text={suggestedCommandLine} />
                </div>
            )}
            </div>
        ) : null}
        </div>
    </div>
  );
};
export default RemediationBlock;