import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from "../../consts/config";
import _ from "lodash";
import { GET_INTEGRATIONS_BY_TYPE } from "./integrationsActions";

export const GET_VCS_GITHUB = "GET_VCS_GITHUB";
export const GET_VCS_GITLAB = "GET_VCS_GITLAB";
export const DELETE_SPECIFIC_VCS_APP = "DELETE_SPECIFIC_VCS_APP";
export const EDIT_SPECIFIC_VCS_APP = "EDIT_SPECIFIC_VCS_APP";
export const CREATE_GITHUB_APP = "CREATE_GITHUB_APP";
export const GET_ALL_VSC_BY_ACCOUNT = "GET_ALL_VSC_BY_ACCOUNT";
export const GET_BITBUCKET_WORKSPACES = "GET_BITBUCKET_WORKSPACES";
export const GET_VCS_REPOS = "GET_VCS_REPOS";
export const GET_VCS_BRANCHES = "GET_VCS_BRANCHES";
export const BROWSE_REPO_PATHS = "BROWSE_REPO_PATHS";

const createVcsIntegration = (type = "", payload = {}) => {
  return action(async dispatch => {
    const requestWrapper = new RequestWrapper();
    const res = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/${type}`, "POST", payload);
    return res;
  });
}

const getAllGithubApps = () => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github`, "GET");

    const githubApps = await vcsReq.json();
    dispatch({
      type: GET_VCS_GITHUB,
      payload: { githubApps }
    });
    return vcsReq.ok;

  });
}

const getAllGitlabApps = () => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/gitlab`, "GET");

    const gitlabApps = await vcsReq.json();
    dispatch({
      type: GET_VCS_GITLAB,
      payload: { gitlabApps: gitlabApps }
    });
    return vcsReq.ok;

  });
}

const deleteVcsIntegration = (id, type = "github") => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/${type}/${id}`, "DELETE");

    dispatch({
      type: DELETE_SPECIFIC_VCS_APP,
      payload: {}
    });
    return { ok: vcsReq.ok };
  });
}

const editVcsIntegration = (id, name, type = "github", token="", username="") => {
  return action(async dispatch => {
    const requestWrapper = new RequestWrapper();
    const payload = { name, token, username };
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/${type}/${id}`, "PUT", payload);

    dispatch({
      type: GET_INTEGRATIONS_BY_TYPE,
      payload: { id, name, isVcsEdit: true }
    });
    return { ok: vcsReq.ok };
  });
}

const createGithubApp = (code, installation_id, nickname) => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/github/app`, "POST", {
      code, installation_id, nickname
    });
    await vcsReq.json();
    return vcsReq;
  });
}

const getAllVcsByAccount = () => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/all`, "GET");
    let output = [];
    if (vcsReq?.ok) {
      output = await vcsReq.json();
      return dispatch({
        type: GET_ALL_VSC_BY_ACCOUNT,
        payload: output
      });
    }
    return output;
  });
}

const createGitLabTokenIntegration = (name, token, domain = "") => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/gitlab`, "POST", {
      name, token, domain
    });
    return req;
  });
}

const createBitBucketIntegration = (name = "", userName, workspace, token, domainUrl = "") => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/bitbucket`, "POST", {
      name, userName, workspace, token, domainUrl
    });
    return req;
  });
}

const getBitbucketWorkspaces = (username, token, domainUrl = "") => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/bitbucket/workspaces`, "POST",
      { username, token, domainUrl });
    let output = [];
    if (vcsReq?.ok) {
      output = await vcsReq.json();
      dispatch({
        type: GET_BITBUCKET_WORKSPACES,
        payload: output
      });
    }
    return output;
  });
}

const getVcsBranches = (type, vcsId, repo) => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/${type}/branch`, "POST",
      { id: vcsId, repoFullName: repo });
    let output = [];
    if (vcsReq?.ok) {
      output = await vcsReq.json();
      dispatch({
        type: GET_VCS_BRANCHES,
        payload: output
      });
    }
    return output;
  });
}

const searchRepoPaths = (type, vcsId, repo, searchString) => {
  return action(async dispatch => {

    const requestWrapper = new RequestWrapper();
    const vcsReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/${type}/search`, "POST",
      { id: vcsId, repoFullName: repo, searchName: searchString });
    let output = [];
    if (vcsReq?.ok) {
      output = await vcsReq.json();
      dispatch({
        type: BROWSE_REPO_PATHS,
        payload: output
      });
    }
    return output;
  });
}

const createCodeCommmitIntegration = (name = '', accountNumber = '', externalId = '') => {
  return action(async dispatch => {
    const requestWrapper = new RequestWrapper();
    const codeCommitReq = await requestWrapper.sendRequest(`${BACKEND_URL}/vcs/codecommit`, "POST", {
      name, accountNumber, externalId,
    });

    let data = {};

    if (codeCommitReq?.ok) {
      data = await codeCommitReq.json() || {}
    }
    return { req: codeCommitReq, data }
  });
}
const getReposByVcsSelection = (vcsId, type, repoName = "", publicRepo) => {
  return action(async dispatch => {
    const requestWrapper = new RequestWrapper();
    const vcsReposReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/vcs/${type}/${vcsId}/repos?public=${publicRepo}&repoName=${repoName}`,
      "GET"
    );

    let repos = [];
    if (vcsReposReq?.ok) {
      repos = await vcsReposReq.json();
    }
    dispatch({
      type: GET_VCS_REPOS,
      payload: repos
    });
  });
};

export {
  getAllGithubApps,
  getAllGitlabApps,
  createVcsIntegration,
  deleteVcsIntegration,
  editVcsIntegration,
  createGithubApp,
  getAllVcsByAccount,
  createGitLabTokenIntegration,
  createBitBucketIntegration,
  createCodeCommmitIntegration,
  getBitbucketWorkspaces,
  getVcsBranches,
  searchRepoPaths,
  getReposByVcsSelection,
}
