import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateAzureIntegration } from "../../../redux/actions/azureIntegrationActions";
import { iacProviders } from "../../../utils/icons";
import isEmpty from "lodash/isEmpty";
import AzurePreferances from "./azurePreferances";
import ControlledAppWizard from "../../../shared/appWizard/controlledAppWizard";
import "./azureIntegration.scss";
import AzureTerraformModule from "./azureTerraformModule";

const AzureIntegration = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation("azure-integration");
    const clientSecretEditDefault = "** Encrypted **";

    const [wizardState, setWizardState] = useState({
        isProd: false,
        isAutoDiscover: true,
        directoryDomain: "",
        location: "",
        prefix: "",
        subscriptionId: "",
        tenantId: "",
    });
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [current, setCurrent] = useState(0);

    const isEditMode = useMemo(() => {
        return !isEmpty(history?.location?.state?._id);
    }, [history?.location?.state]);

    useEffect(() => {
        if (!isEmpty(history?.location?.state?._id)) {
            const integObj = history?.location?.state;
            setWizardState({
                ...integObj,
                clientSecret: clientSecretEditDefault,
                subscriptionId: integObj?.accountNumber,
            });
        }
    }, [history?.location?.state]);

    const handleSubmitWizard = async () => {
        if (!isEditMode) return;

        setLoadingSubmit(true);

        const editPayload = { ...wizardState };

        const req = await dispatch(updateAzureIntegration(editPayload));

        setLoadingSubmit(false);

        if (!req?.ok) {
            throw new Error(`Failed to update Azure integration.`);
        }
    };

    const configWizard = [
        {
            step_title: isEditMode ? t("preferences.nav-title-update") : t("preferences.nav-title"),
            step_description: isEditMode ? t("preferences.nav-sub-update") : t("preferences.nav-sub"),
            content_title: t("preferences.screen-title"),
            content: (
                <AzurePreferances isEditMode={isEditMode} wizardState={wizardState} setWizardState={setWizardState} setSubmitDisabled={setSubmitDisabled} />
            ),
            beforeNextStep: handleSubmitWizard,
        },
        !isEditMode && {
            step_title: t("setup.nav-title"),
            step_description: t("setup.nav-sub"),
            content_title: t("setup.screen-title"),
            content: <AzureTerraformModule wizardState={wizardState} />,
            valid: true,
        },
    ];

    return (
        <div className="AzureIntegration">
            <ControlledAppWizard
                iconSrc={iacProviders("terraform")}
                loadingSubmit={loadingSubmit}
                configWizard={configWizard}
                handleClose={() => history.goBack()}
                submitDisabled={submitDisabled}
                setSubmitDisabled={setSubmitDisabled}
                handleSubmit={() => history.push("/integrations")}
                current={current}
                setCurrent={setCurrent}
                isEditMode={isEditMode}
            />
        </div>
    );
};

export default AzureIntegration;
