import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Collapse, TreeSelect } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Split from "react-split-it";
import "./split.css";

import ReactJson from "react-json-view";
import { findLast, split, map, last, isEmpty, keys } from "lodash";
import Editor from "@monaco-editor/react";
import { getAvailableScopes } from "../../redux/actions/classificationsActions";
import InventoryMiniTable from "../../components/inventory/inventoryMiniTable/inventoryMiniTable";
import { FIREFLY_REGO_PREFIX } from "../../utils/regoHelper";
import { providers } from "../../utils/providers";
import {
    formatAwsStringWithUnderscore,
    providerAssetImagesFormatter,
    providerNameFormatter,
} from "../../utils/formatting";
import { useTranslation } from "react-i18next";
import ProviderIcon from "../../shared/providerIcon/providerIcon";
import EvaluateIcon from "../../Images/general_icons/evaluate_Icon.svg";
import AssetTypeImage from "../../shared/assetTypeImage/assetTypeImage";

const PolicyCode = ({
    wizardState,
    setWizardState,
    setSubmitDisabled,
    tableLoading,
    editorLoading,
    setFilterPage,
    setSearchVal,
    setAssetTypes,
    assetTypes,
    setScopeAfterFetch,
    testRegoPolicy,
    disableScopeSelection,
    onPageSizeChange,
    tablePageSize,
}) => {
    const { t } = useTranslation("excluded-assets");
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const [testAsset, setTestAsset] = useState({});
    const [activePanel, setActivePanel] = useState(["1"]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loadingScope, setLoadingScope] = useState(true);

    const responseObjects = useSelector((state) => state.inventoryReducer.responseObjects);
    const totalObjects = useSelector((state) => state.inventoryReducer.totalObjects);

    const scopes = useSelector((state) => state.classificationsReducer.scopes);
    const testPassed = useSelector((state) => state.exclusionsReducer.testPassed);

    useEffect(() => {
        setSubmitDisabled(false);
        setActivePanel(["1"]);
        fetchScopes();
    }, []);

    useEffect(() => {
        if (testPassed) {
            setActivePanel(["3"]);
        }
    }, [testPassed]);

    useEffect(() => {
        return setSubmitDisabled(isEmpty(wizardState?.code));
    }, [wizardState]);

    useEffect(() => {
        setWizardState({
            ...wizardState,
            code: isEmpty(wizardState.code)
                ? FIREFLY_REGO_PREFIX
                : FIREFLY_REGO_PREFIX + wizardState.code,
            initialCode: FIREFLY_REGO_PREFIX,
        });
    }, []);

    const fetchScopes = async () => {
        if (isEmpty(scopes)) {
            await dispatch(getAvailableScopes());
        }
        setActivePanel(["1"]);
        setLoadingScope(false);
        setScopeAfterFetch();
    };

    const handleCutsomArrow = (e) => {
        return (
            <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                    opacity: 0.7,
                    transform: e.isActive ? "rotate(180deg)" : "none",
                    transition: "all .2s ease",
                }}
            />
        );
    };

    const renderExcludedList = (array) => {
        return map(array, (item) => {
            return <span key={item}>{item}</span>;
        });
    };

    const formatScopeOptions = () => {
        if (!loadingScope) {
            const x = map(keys(providers), (providerKey) => {
                let filterChildrenByProviderName = providerKey;
                if (providerKey === "gcp") {
                    filterChildrenByProviderName = "google";
                } else if (providerKey === "k8s") {
                    filterChildrenByProviderName = "kubernetes";
                }
                let children = scopes?.filter((scope) => {
                    return split(scope.key, "_")[0] === filterChildrenByProviderName;
                });
                if (!isEmpty(children)) {
                    children = children.map((ch) => ({
                        title: (
                            <span className="row g10">
                                <AssetTypeImage
                                    assetType={ch.key}
                                    provider={providerAssetImagesFormatter(providerKey)}
                                    customStyle={{ height: "15px" }}
                                />{" "}
                                {formatAwsStringWithUnderscore(ch.key)}{" "}
                            </span>
                        ),
                        value: ch.key,
                        key: ch.key,
                    }));
                }
                return {
                    title: (
                        <span className="row g10">
                            <ProviderIcon provider={providerKey} customStyle="notification" />
                            {providerNameFormatter(providerKey)}
                        </span>
                    ),
                    key: providerKey,
                    value: providerKey + "objects",
                    ...(!assetTypes?.includes(providerKey + "objects") &&
                        !isEmpty(children) && { children }),
                };
            });
            return x;
        }
    };
    const handleScopeChange = (selectedScopes, extra) => {
        let newArr = [...selectedScopes];
        if (
            assetTypes?.some(
                (s) => s.includes("objects") && selectedScopes.some((s) => s.includes("objects")),
            ) &&
            !selectedScopes.some((s) => s.includes("_"))
        ) {
            newArr = [findLast(newArr, (s) => s?.includes("objects"))];
        } else if (selectedScopes.some((s) => s.includes("_"))) {
            if (selectedScopes.some((s) => s.includes("objects"))) {
                newArr = newArr.filter((s) => !s.includes("objects"));
            }
            if (!assetTypes?.some((s) => s.includes(split(extra.triggerValue, "_")[0]))) {
                newArr = [extra.triggerValue];
            }
        }
        setAssetTypes(newArr);
    };

    return (
        <div className="PolicyCode">
            <Split minSize={200}>
                <div className="PolicyCode__left">
                    <Editor
                        defaultLanguage="hcl"
                        defaultValue={wizardState.code}
                        theme="vs-dark"
                        onChange={(value, event) => {
                            setWizardState({ ...wizardState, code: value });
                        }}
                        height="63vh"
                    />

                    <Button
                        type="primary"
                        loading={editorLoading}
                        onClick={() => !editorLoading && testRegoPolicy(false, true)}
                        icon={
                            <img
                                src={EvaluateIcon}
                                alt=""
                                style={{ marginRight: "8px", transform: "translateY(-1px)" }}
                            />
                        }
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "40px",
                            height: "30px",
                            paddingTop: "3px",
                            zIndex: 1000,
                        }}
                    >
                        Evaluate
                    </Button>
                </div>
                <div className="PolicyCode__right">
                    <div className="PolicyCode__right-scope col">
                        <TreeSelect
                            multiple={false}
                            treeCheckable
                            treeData={formatScopeOptions()}
                            loading={loadingScope}
                            value={assetTypes}
                            onChange={(selectedScopes, _, extra) => {
                                handleScopeChange(selectedScopes, extra);
                            }}
                            showCheckedStrategy={"SHOW_PARENT"}
                            placeholder={t(
                                "exclusion-creation.steps.coding.content.scope-selection",
                            )}
                            showArrow
                            showSearch
                        />
                    </div>
                    <Collapse
                        expandIconPosition="right"
                        expandIcon={handleCutsomArrow}
                        activeKey={activePanel}
                        onChange={(panel) => setActivePanel(panel)}
                    >
                        <Panel
                            header={t(
                                "exclusion-creation.steps.coding.content.panel.inventory.header",
                            )}
                            key="1"
                        >
                            <div className="PolicyCode__right-table">
                                {isEmpty(assetTypes) ? (
                                    <div className="small-dark-blue center PolicyCode__right-table-empty">
                                        {t(
                                            "exclusion-creation.steps.coding.content.panel.inventory.hint",
                                        )}
                                    </div>
                                ) : (
                                    <InventoryMiniTable
                                        handleSelectedRows={(items) => {
                                            setSelectedRows(items);
                                            const testItems = JSON.parse(
                                                last(items || [])?.inventoryItem || null,
                                            );
                                            setTestAsset(testItems);
                                        }}
                                        selectedRowsArr={selectedRows}
                                        clearSelected={() => {
                                            setSelectedRows([]);
                                            setTestAsset(null);
                                        }}
                                        searchValue={(val) => {
                                            setFilterPage(1);
                                            setSearchVal(val);
                                        }}
                                        tableData={responseObjects}
                                        fetchingData={tableLoading}
                                        totalObjects={totalObjects}
                                        handlePageChange={(page) => {
                                            setFilterPage(page);
                                        }}
                                        columns={[
                                            {
                                                headerName: t(
                                                    "exclusion-creation.steps.coding.content.panel.inventory.table-columns.name",
                                                ),
                                                field: "assetId",
                                                flex: 1,
                                                filterable: false,
                                                sortable: false,
                                                resizable: false,
                                                disableColumnMenu: true,
                                            },
                                        ]}
                                        customHeight={"300px"}
                                        onPageSizeChange={onPageSizeChange}
                                        tablePageSize={tablePageSize}
                                    />
                                )}
                            </div>
                        </Panel>
                        <Panel
                            header={t(
                                "exclusion-creation.steps.coding.content.panel.viewer.header",
                            )}
                            key="2"
                        >
                            <ReactJson
                                src={testAsset || {}}
                                theme="monokai"
                                style={{ fontSize: "12px" }}
                            />
                        </Panel>
                        <Panel
                            header={t(
                                "exclusion-creation.steps.coding.content.panel.assets.header",
                                {
                                    count: !isEmpty(wizardState?.excludedArns)
                                        ? "(" + wizardState?.excludedArns?.length + ")"
                                        : "",
                                },
                            )}
                            key="3"
                            className="excluded-assets"
                        >
                            {!isEmpty(wizardState?.excludedArns) ? (
                                <div className="excluded-assets-list col">
                                    {renderExcludedList(wizardState?.excludedArns)}
                                </div>
                            ) : (
                                <span className="small-dark-blue center explanation">
                                    {t("exclusion-creation.steps.coding.content.panel.assets.hint")}
                                </span>
                            )}
                        </Panel>
                    </Collapse>
                </div>
            </Split>
        </div>
    );
};

export default PolicyCode;
