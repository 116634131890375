import _ from "lodash";
import React from "react";
import IacStatusFlag from "../../../../shared/iacStatusFlag/iacStatusFlag";
import OwnerIndication from "../../ownerIndication/ownerIndication";
import AssetInfoCard from "./assetInfoCard";
import AssetClickableChips from "./assetTags/assetTags";
import CustomPropertiesTags from "../../../../shared/customPropertiesTags/customPropertiesTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatUnixDate } from "../../../../utils/formatting";
import moment from 'moment';
import AssetDataSourceCell from '../../inventoryTable/assetDataSourceCell';
import { RESOURCE_STATE_TYPES } from "../../../../consts/general";
import { useTranslation } from "react-i18next";
import { formatAwsStringWithUnderscore } from "../../../../utils/formatting";



const BasicInfo = ({ asset, handleOpenAnalysis, parentData, childParentData, onClickTag, onParentClick, onClickResourceGroup }) => {

  const { t } = useTranslation("inventory");

  const deleteMode = asset?.state === "deleted";

  const handleRenderId = () => {
    let assetId = asset?.assetId;

    if (asset?.state === "ghost" || asset?.state === "pending") {
      assetId = asset?.frn;
    }
    return (
      <div className="row" style={{ gap: "10px", alignItems: 'flex-start' }}>
        <span>{assetId}</span>
        {asset?.consoleURL && !_.isEmpty(asset?.consoleURL) && (
          <span
            onClick={() => window.open(asset?.consoleURL, "_blank")}
            className="console-icon-link"
          >
            <FontAwesomeIcon icon="external-link-alt" />
          </span>
        )}
      </div>
    );
  };

  const renderProperties = () => {
    return <div className="row" style={{ gap: "10px" }}>
    {!_.isEmpty(asset?.resourceStatus) && (
      <div className="purple-flag">
        {asset?.resourceStatus}
      </div>
    )}
    <CustomPropertiesTags
      data={asset?.customColumns}
      disableClick
    />
  </div>
  }

  const renderIacStatus = (asset, parentData) => {
    return {
      label: t('asset-popup.card.iacStatus'),
      content: (
        <IacStatusFlag
          state={!_.isEmpty(parentData) && asset?.isChild ? parentData?.state : asset?.state}
          iacType={!_.isEmpty(parentData) && asset?.isChild ? parentData?.iacType : asset?.iacType}
          hasControllerSources={!_.isEmpty(parentData) && asset?.hasControllerSources ? parentData?.hasControllerSources : asset?.hasControllerSources}
          handleOpenAnalysis={handleOpenAnalysis}
          rowData={asset}
          isAssetDataModal={true}
          handleOpenMulti={() => {
            return false;
          }}
          isLocked={false}
        />
      ),
      display: true,
    }
  }

  const renderParentName = () => {
    return {
        label: t('asset-popup.card.parentName'),
        content: childParentData?.map((childParent) => {
            if(childParent?.hasOwnerTargets) {
                return <div key={childParent?.key} className="parentChildText"> <AssetClickableChips key={childParent?.key} chips={[childParent]} onClickChip={onParentClick} isObject={true} tooltip={formatAwsStringWithUnderscore(childParent?.assetType, false)}/></div>
            }
        }),
        display: true,
    }
  }

  return (
    <AssetInfoCard
      contentArr={[
        asset?.state !== RESOURCE_STATE_TYPES.child ? renderIacStatus(asset, parentData) : renderParentName(),
        { label: t('asset-popup.card.id'), content: handleRenderId(), display: true },
        { label: t('asset-popup.card.dataSource'), content: <AssetDataSourceCell row={asset} />, display: true },
        {
          label: t('asset-popup.card.resourceGroup'),
          content: <AssetClickableChips chips={[asset?.providerGroupId]} onClickChip={onClickResourceGroup} />,
          display: !_.isEmpty(asset?.providerGroupId),
        },
        {
          label: t('asset-popup.card.owner'),
          content: (
            <OwnerIndication
              data={asset?.ownerData}
              drawerView
              handleClickOwner={() => {
                return false;
              }}
            />
          ),
          display: !_.isEmpty(asset?.ownerData?.userIdentity?.displayName),
        },
        {
          label: t('asset-popup.card.location'),
          content: asset?.region,
          display: !_.isEmpty(asset?.region),
        },
        {
          label: t('asset-popup.card.properties'),
          content: renderProperties(),
          display: !_.isEmpty(asset?.resourceStatus) || (!_.isEmpty(asset?.customColumns) && asset?.customColumns?.length > 2),
        },
        {
          label: t('asset-popup.card.tags'),
          content: <AssetClickableChips chips={asset?.tagsList} onClickChip={onClickTag} />,
          display: !_.isEmpty(asset?.tagsList),
        },
        {
          label: deleteMode ? t('asset-popup.card.deletionDetectionDate') : t('asset-popup.card.createdOn'),
          content: deleteMode? moment(asset?.deletedAt).format("MM/DD/YYYY") : formatUnixDate(asset?.resourceCreationDate),
          display: deleteMode ? _.has(asset, 'deletedAt') && asset.deletedAt : _.has(asset, 'resourceCreationDate') && asset.resourceCreationDate,
        },
      ]}
      // grid
    />
  );
};

export default BasicInfo;
