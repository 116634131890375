import React, {useEffect, useState} from "react";
import {Tooltip} from "antd";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendEvent } from "../../../utils/amplitude";
import { CiEvents } from "../../../utils/amplitudeEvents";

import AppBtn from "../../../shared/appBtn/appBtn";
import { appToast } from "../../../shared/appToast/appToast";

import './workspaceMenu.scss';
import {
    deleteGuardrailViolationIgnore,
    listWorkspaceGuardrailsViolationsIgnores
} from "../../../redux/actions/workflowsGuardrailsActions";
import isEmpty from "lodash/isEmpty";
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import {capitalizeFirst} from "../../../utils/formatting";

const DATE_FORMAT = "MMM DD YYYY, HH:mm";

const GuardrailIgnoresModal = ({ onClickIgnores, data = {}, showLabelsModal }) => {
    const [loading, setLoading] = useState(false);
    const guardrailsIgnores = useSelector((state) => state.ciWorkflowsReducer.workspaceGuardrailsIgnores) || [];
    const { workspaceId, workspaceName } = data;
    const { t } = useTranslation(["ci-pipeline", "common"]);
    const { t: tColumns } = useTranslation("ci-pipeline", { keyPrefix: "columns"});
    const dispatch = useDispatch();
    const shouldShowTable = !isEmpty(guardrailsIgnores);
    const dataLength = guardrailsIgnores?.length;
    const [pageSize, setPageSize] = useState(5);
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    useEffect(() => {
        fetchGuardrailsIgnores();
    }, []);

    const fetchGuardrailsIgnores = async() => {
        setLoading(true);
        await dispatch(listWorkspaceGuardrailsViolationsIgnores(workspaceId));
        setLoading(false);
    }
    
    const handleOnDeleteClick = async(ignoreId) => {
        const res = await dispatch(deleteGuardrailViolationIgnore(ignoreId));
        if (!res) {
            appToast("error", "", t("guardrails.override.modal.deleteError"))
        }
        sendEvent(CiEvents.ciWorkspaceIgnoreDelete, {})
        fetchGuardrailsIgnores();
    };

    const getIgnoresTableColumns = (tColumns, handleOnDeleteClick) => {
        return [
            {
                headerName: tColumns("guardrailRule"),
                field: "guardrailRuleName",
                flex: 1,
                disableColumnMenu: true,
                sortable: true,
                filterable: true,
                renderCell: (params) => params.row?.guardrailRuleName || ""
            },
            {
                headerName: tColumns("ruleType"),
                field: "guardrailRuleType",
                flex: 0.5,
                disableColumnMenu: true,
                sortable: true,
                filterable: true,
                renderCell: (params) => capitalizeFirst(params.row?.guardrailRuleType) || ""
            },
            {
                headerName: tColumns("resourceAddress"),
                field: "resourceAddress",
                flex: 1,
                disableColumnMenu: true,
                sortable: true,
                filterable: true,
                renderCell: (params) => params.row?.resourceIdentifier?.address || params.row?.resourceIdentifier?.fileName || ""
            },
            {
                headerName: tColumns("resourceName"),
                field: "resourceName",
                flex: 1,
                disableColumnMenu: true,
                sortable: true,
                filterable: true,
                renderCell: (params) => params.row?.resourceIdentifier?.resourceName || ""
            },
            {
                headerName: tColumns("resourceType"),
                field: "resourceType",
                flex: 1,
                disableColumnMenu: true,
                sortable: true,
                filterable: true,
                renderCell: (params) => params.row?.resourceIdentifier?.resourceType || ""
            },
            {
                headerName: tColumns("ttl"),
                field: "ttl",
                flex: 0.5,
                sortable: true,
                filterable: true,
                disableColumnMenu: true,
                renderCell: (params) => params.row?.ttl ? params.row?.ttl === 1 ? "One-time" : params.row?.ttl : "Permanent"
            },
            {
                headerName: tColumns("approvedBy"),
                field: "approvedBy",
                flex: 1,
                sortable: true,
                filterable: true,
                disableColumnMenu: true,
                renderCell: (params) => params.row?.createdBy || "Unknown"
            },
            {
                headerName: tColumns("createdAt"),
                field: "createdAt",
                flex: 1,
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                valueGetter: ({ row }) => moment(row.createdAt).format(DATE_FORMAT)
            },
            {
                headerName: "",
                field: "delete",
                width: 20,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                    const ignoreId = params.row?.id;
                    if (!ignoreId) return null;
                    return (
                        <Tooltip title={t("guardrails.override.modal.deleteTooltip")}>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                if (isViewer) return;
                                handleOnDeleteClick(ignoreId);
                            }}>
                                <FontAwesomeIcon icon="trash-alt" className={"WorkspaceMenu__deleteIgnore"} />
                            </div>
                        </Tooltip>)
                }
            }
        ]
    }


    return (
    <NewAppModal
        visible={showLabelsModal}
        handleClose={() => onClickIgnores(false)}
        destroyOnClose
        centered
        width="60vw"
        title={`${workspaceName} ${t("ci-pipeline:guardrails.override.modal.title")}`}
        subtitle=""
        iconSrc={null}
        footer={<div className="row g8 flex-end">
            <AppBtn onClick={() => onClickIgnores(false)} text={t("common:close")}/>
        </div>}
    >
        {shouldShowTable || loading ?
            <TableWrapper
                autoHeight={dataLength < 5 ? "100%" : "auto"}
                height={dataLength < 5 ? "100%" : "auto"}
                rowKey="id"
                tableData={guardrailsIgnores}
                columns={getIgnoresTableColumns(tColumns, handleOnDeleteClick)}
                disableSelectionOnClick
                pageSize={pageSize}
                loading={loading}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 100]}
            /> : <div className="centered">{t("ci-pipeline:guardrails.override.modal.noViolations")}</div>}
    </NewAppModal>)
};

export default GuardrailIgnoresModal;