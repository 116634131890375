import React, { useState, useEffect } from "react";
import { Form, Input, Tooltip, Checkbox, Tag } from "antd";
import _ from "lodash";
import regionsOptions from "../../../../consts/regions";
import DarkCopyBox from "../../../../shared/darkCopyBox/darkCopyBox";
import MarkAsProduction from "../../../../shared/markAsProduction/markAsProduction";
import { DESCRIPTIONS } from "../../../../consts/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventDrivenTooltipBox from "../eventDrivenTooltipBox";
import AppToggle from "../../../../shared/appToggle/appToggle";

const AwsIntegrationConfiguration = ({
    wizardState,
    setWizardState,
    setSubmitDisabled,
    isProd,
    handelSetIsProd,
    isEventDriven,
    handelSetIsEventDriven,
    isMultipleAccount,
    handelSetIsMultipleAccount,
    setLabels,
    regions,
    disableSubmit,
}) => {
    const [form] = Form.useForm();
    const layout = {};
    const { CheckableTag } = Tag;

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setSubmitDisabled(false);
        setWizardState({
            ...wizardState,
            ...{
                name: "AWS",
                accessKeyType: "new",
                selectedKey: null,
            },
        });
    }, []);

    useEffect(() => {
        checkValidation(form.getFieldsValue());
    }, [disableSubmit]);

    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked ? [...regions, tag] : regions?.filter((t) => t !== tag);
        setLabels(nextSelectedTags);
        checkValidation(form.getFieldsValue());
    };

    const checkValidation = (formValues) => {
        let formValid = true;
        const { name } = formValues;
        if (_.isEmpty(name) || disableSubmit) {
            formValid = false;
        }
        return setSubmitDisabled(!formValid);
    };

    return (
        <div className="AwsIntegrationConfiguration col">
            <div>
                <span className="dark-blue bold">Before you begin</span>
                <ul className="dark-blue AwsIntegrationConfiguration__introList">
                    <li key="1">
                        <span className="dark-blue" style={{ lineHeight: "1.5em" }}>
                            Use{" "}
                            <a
                                href="https://www.terraform.io/cli/commands/0.13upgrade"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                            >
                                Terraform v0.13
                            </a>{" "}
                            or later. To check your version of Terraform, run:{" "}
                            <span className="purple-flag" style={{ fontSize: "12px" }}>
                                terraform --version
                            </span>
                        </span>
                    </li>
                    <li key="2">
                        <span className="dark-blue">
                            AWS CLI must be installed on your workstation {"> "}
                            <a
                                href="https://docs.aws.amazon.com/cli/latest/userguide/getting-started-install.html#getting-started-install-instructions"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                            >
                                How to Install AWS CLI on your workstation.
                            </a>
                        </span>
                    </li>
                    <li key="3">
                        <span className="dark-blue" style={{ lineHeight: "1.5em" }}>
                            Configure{" "}
                            <a
                                href="https://docs.aws.amazon.com/cli/latest/userguide/cli-configure-quickstart.html#cli-configure-quickstart-config"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                            >
                                AWS Credentials
                            </a>{" "}
                            on your workstation. To configure AWS CLI installation, run:
                            <br />
                            <span className="purple-flag" style={{ fontSize: "12px" }}>
                                aws configure
                            </span>
                        </span>
                    </li>
                    <li key="4">
                        <span className="dark-blue">
                            Create IAM user with the roles needed to run AWS
                        </span>
                    </li>
                    <li key="5">
                        <span className="dark-blue">
                            Select your AWS region or select all regions
                        </span>
                    </li>
                    <li key="6">
                        <span className="dark-blue">
                            To verify you fulfilled these prerequisites, at your terminal, run the
                            command:
                            <div style={{ marginTop: "7px" }}>
                                <DarkCopyBox
                                    text={`cat ~/.aws/credentials && terraform init && terraform --version`}
                                />
                            </div>
                        </span>
                    </li>
                </ul>
            </div>

            <Form
                {...layout}
                name="aws-wizard-form"
                form={form}
                initialValues={{
                    name: wizardState.name || "AWS",
                    accessKeyType: wizardState.accessKeyType || "new",
                    selectedKey: wizardState?.selectedKey || null,
                    selectAllRegions: { checked: true },
                }}
                className="AppWizard__content-body-form"
                onValuesChange={(
                    changedValues,
                    allValues, // this is how you can get the form values
                ) => {
                    if (!_.isUndefined(changedValues.selectAllRegions)) {
                        if (changedValues.selectAllRegions) {
                            setLabels(_.keys(regionsOptions));
                        } else {
                            setLabels([]);
                        }
                    }
                    allValues.name = allValues.name || "AWS";
                    delete allValues["region"];
                    delete allValues["bucketNames"];
                    setWizardState({
                        ...wizardState,
                        ...allValues,
                        ...{ fetchSecrets: checked },
                    });
                    checkValidation(allValues);
                }}
            >
                <Form.Item
                    label="Integration Name"
                    name="name"
                    style={{ marginBottom: "1.5rem", flexDirection: "column" }}
                >
                    <Input placeholder="AWS Account Nickname" />
                </Form.Item>
                <div className="AwsIntegrationConfiguration__multipleAccount">
                    <AppToggle
                        text="Multi-Account"
                        checked={isMultipleAccount}
                        toggleChecked={handelSetIsMultipleAccount}
                    />
                    <Tooltip placement="topRight" title={DESCRIPTIONS.awsMultipleAccount}>
                        <FontAwesomeIcon icon="question-circle" />
                    </Tooltip>
                </div>
                <div className="AwsIntegrationConfiguration__eventDriven">
                    <div className="AwsIntegrationConfiguration__eventDriven-text">
                        Event-Driven
                    </div>
                    {DESCRIPTIONS.awsEventDrivenNote}
                    <Tooltip
                        placement="topRight"
                        title={<EventDrivenTooltipBox />}
                        overlayInnerStyle={{
                            width: "350px",
                        }}
                    >
                        <FontAwesomeIcon
                            icon="question-circle"
                            style={{
                                marginLeft: "5px",
                            }}
                        />
                    </Tooltip>
                </div>
                <div className="col event-driven-block">
                    <Form.Item label="" style={{ marginBottom: "0", flexDirection: "column" }}>
                        <div className="AwsIntegrationConfiguration__list">
                            {_.map(_.keys(regionsOptions), (tag) => (
                                <CheckableTag
                                    key={tag}
                                    checked={regions?.indexOf(tag) > -1}
                                    onChange={(checked) => handleChange(tag, checked)}
                                >
                                    {tag}
                                </CheckableTag>
                            ))}
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="selectAllRegions"
                        valuePropName="checked"
                        style={{
                            marginBottom: "0.6rem",
                            marginTop: "1.2rem",
                        }}
                    >
                        <Checkbox checked={true}>
                            <span className="form-label">Select all regions</span>
                        </Checkbox>
                    </Form.Item>
                </div>
                <div className="secrets-selection" style={{ marginTop: "25px" }}>
                    <Checkbox checked={isProd} onChange={handelSetIsProd}>
                        <MarkAsProduction />
                    </Checkbox>
                </div>
            </Form>
        </div>
    );
};

export default AwsIntegrationConfiguration;
