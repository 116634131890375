import React from "react";
import { useSelector } from "react-redux";
import AppLogo from "../../appSidebar/appLogo/appLogo";
import UserConnected from "../../../components/appHeader/userConnected/userConnected";
import ThemeToggle from "../../../components/appHeader/themeToggle/themeToggle";
import ProgressBar from "../../../shared/progressBar/progressBar";
import OrganizationSelect from "../../appHeader/organizationSelect/organizationSelect";
import "./stepsSidebar.scss";

const StepsSidebar = () => {
    const currentStep = useSelector((state) => state.onbordingReducer.status);
    const onbordingSteps = [
        {
            text: "Welcome",
        },
        {
            text: "Choose Experience",
        },
        {
            text: "Data Sources",
        },
        {
            text: "Workflows",
        },
        {
            text: "GitOps",
        },
        {
            text: "ChatOps",
        },
    ];
    const customWidth = 100 / (onbordingSteps.length - 1);
    return (
        <div className="StepsSidebar row between">
            <AppLogo />
            <ProgressBar steps={onbordingSteps} currentStep={currentStep} onBoarding customMargin="0 10%" customWidth={customWidth} centered />
            <div className="StepsSidebar__user row">
                <ThemeToggle />
                <OrganizationSelect />
                <UserConnected />
            </div>
        </div>
    );
};

export default StepsSidebar;
