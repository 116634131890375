import { guidRegex, prefixRegex } from "../../../utils/validations";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";

const terraformModuleVersion = "v1.5.0";
const azurermProviderVersion = "4.19.0";

const getFormItems = (isEditMode) => {
    const commonStyle = { marginBottom: "1rem", marginTop: "0.5rem", flexDirection: "column" };
    const { t } = useTranslation("azure-integration");

    return [
        {
            name: "subscriptionId",
            tooltip: t("form.subscription-tooltip"),
            label: t("form.subscription-label"),
            rules: [
                {
                    required: true,
                    message: t("form.subscription-error"),
                },
                {
                    pattern: guidRegex.regex,
                    message: `Subscription ID ${guidRegex.msg}`,
                },
            ],
            style: commonStyle,
            placeholder: "Subscription ID",
            disabled: isEditMode,
        },
        {
            name: "directoryDomain",
            tooltip: t("form.directory-domain-tooltip"),
            label: t("form.directory-domain-label"),
            rules: [
                {
                    required: true,
                    message: t("form.directory-error"),
                },
            ],
            style: commonStyle,
            placeholder: "Directory Domain",
            disabled: isEditMode,
        },
        {
            name: "tenantId",
            tooltip: t("form.tenant-id-tooltip"),
            label: t("form.tenant-id-label"),
            rules: [
                {
                    required: true,
                    message: t("form.tenant-id-error"),
                },
                {
                    pattern: guidRegex.regex,
                    message: `Tenant ID ${guidRegex.msg}`,
                },
            ],
            style: commonStyle,
            placeholder: "Tenant ID",
            disabled: isEditMode,
        },
        {
            name: "location",
            tooltip: t("form.location-tooltip"),
            label: t("form.location-label"),
            rules: [
                {
                    required: true,
                    message: t("form.location-error"),
                },
            ],
            style: commonStyle,
            placeholder: "Location",
            disabled: isEditMode,
        },
        {
            name: "prefix",
            tooltip: t("form.prefix-tooltip"),
            label: t("form.prefix-label"),
            rules: [
                {
                    pattern: prefixRegex.regex,
                    message: `${prefixRegex.msg}`,
                },
            ],
            style: commonStyle,
            placeholder: "Prefix",
            disabled: isEditMode,
        },
    ];
};

const getItems = (wizardState = {}, isEditMode) => {
    const { t } = useTranslation("azure-integration");
    return [
        {
            title: t("preferences.items.auto-discover-title"),
            description: t("preferences.items.auto-discover-sub"),
            icon: <FontAwesomeIcon icon="search" style={{ height: "16px" }} />,
            toogleChecked: wizardState?.isAutoDiscover,
            key: "isAutoDiscover",
            disabled: isEditMode,
        },
        {
            title: t("preferences.items.prod-title"),
            description: t("preferences.items.prod-sub"),
            icon: <FontAwesomeIcon icon="flag" opacity={0.7} />,
            toogleChecked: wizardState?.isProd,
            key: "isProd",
        },
    ];
};

const getTerraformModule = async (wizardState, keyPair) => {
    if (wizardState.isAutoDiscover) {
        return getIsAutoDiscoverTerraformModule(wizardState, keyPair);
    }
    return getIsNotAutoDiscoverTerraformModule(wizardState, keyPair);
};

const getIsAutoDiscoverTerraformModule = async (wizardState, keyPair) => {
    let discoveredSubscriptions = `
terraform {
    required_providers {
        azurerm = {
            source  = "hashicorp/azurerm"
            version = "${azurermProviderVersion}"
        }
        azuread = {
            source  = "hashicorp/azuread"
            version = "2.53.1"
        }
    }
}

provider "azurerm" {
    features {}
    alias           = "deployment_subscription"
    subscription_id = "${wizardState.subscriptionId}"
    tenant_id       = "${wizardState.tenantId}"
}

module "firefly_azure" {
    source = "github.com/gofireflyio/terraform-firefly-azure-onboarding?ref=${terraformModuleVersion}"

    providers = {
        azurerm.deployment_subscription = azurerm.deployment_subscription
    }

    management_group_name = var.management_group_name

    directory_domain = "${wizardState.directoryDomain}"
    tenant_id = "${wizardState.tenantId}"
    
    // firefly's landing subscription: eventgrid, storage_account and resource_group will be created here
    subscription_id = "${wizardState.subscriptionId}"

    // firefly credentials
    firefly_access_key = "${keyPair.accessKey}"
    firefly_secret_key = "${keyPair.secretKey}"

    // switch to false if resource_provider_registration exists
    create_resource_provider_registration = true

    // enable on all subscriptions that can be discovered
    auto_discover_enabled = ${wizardState.isAutoDiscover}

    // set the location
    location = "${wizardState.location}"

    // custom settings
    is_prod = ${wizardState.isProd}
`;

    if (wizardState.prefix) {
        discoveredSubscriptions += `
    prefix = "${wizardState.prefix}"
`;
    }

    discoveredSubscriptions += `
}`;

    return discoveredSubscriptions;
};

const getIsNotAutoDiscoverTerraformModule = async (wizardState, keyPair) => {
    let discoveredSubscriptions = `
terraform {
    required_providers {
        azurerm = {
            source  = "hashicorp/azurerm"
            version = "${azurermProviderVersion}"
        }
        azuread = {
            source  = "hashicorp/azuread"
            version = "2.53.1"
        }
    }
}

provider "azurerm" {
    features {}
    alias           = "deployment_subscription"
    subscription_id = "${wizardState.subscriptionId}"
    tenant_id       = "${wizardState.tenantId}"
}

module "firefly_azure" {
    source = "github.com/gofireflyio/terraform-firefly-azure-onboarding?ref=${terraformModuleVersion}/modules/single_integration"

    providers = {
      azurerm.deployment_subscription = azurerm.deployment_subscription
    }

    directory_domain = "${wizardState.directoryDomain}"
    tenant_id = "${wizardState.tenantId}"
    
    // firefly's landing subscription: eventgrid, storage_account and resource_group will be created here
    subscription_id = "${wizardState.subscriptionId}"

    // firefly credentials
    firefly_access_key = "${keyPair.accessKey}"
    firefly_secret_key = "${keyPair.secretKey}"

    // switch to false if resource_provider_registration exists
    create_resource_provider_registration = true

    // enable on all subscriptions that can be discovered
    auto_discover_enabled = ${wizardState.isAutoDiscover}

    // set the location
    location = "${wizardState.location}"

    // custom settings
    is_prod = ${wizardState.isProd}
    tags = var.tags
`;

    if (wizardState.prefix) {
        discoveredSubscriptions += `
    prefix = "${wizardState.prefix}"`;
    }

    discoveredSubscriptions += `
}`;

    return discoveredSubscriptions;
};

const hasEmptyKeys = (obj) => {
    for (const key in obj) {
        if (isEmpty(obj[key])) {
            return true;
        }
    }
    return false;
};

export { getFormItems, getItems, getTerraformModule, hasEmptyKeys };
