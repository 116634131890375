import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import ServiceNowCreation from "./serviceNowCreation";
import { useTranslation } from "react-i18next";
import { projects } from "../../../utils/icons";
import { createProviderIntegration } from "../../../redux/actions/integrationsActions";
import "./serviceNowIntegration.scss";
import { PROVIDERS } from "../../../consts/general";

const ServiceNowIntegration = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation("integrations", { keyPrefix: "servicenow" });
    const [wizardState, setWizardState] = useState({});
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const configWizard = [
        {
            step_title: t("first-step.title"),
            step_description: t("first-step.description"),
            content_title: t("first-step.content"),
            content: (
                <ServiceNowCreation
                    wizardState={wizardState}
                    setWizardState={setWizardState}
                    setSubmitDisabled={setSubmitDisabled}
                />
            ),
            valid: true,
            beforeNextStep: async () => {
                setLoadingSubmit(true);
                const payload = {
                    name: wizardState.name,
                    username: wizardState.username,
                    password: wizardState.password,
                    url: wizardState.url,
                };
                const req = await dispatch(
                    createProviderIntegration(PROVIDERS.servicenow, payload),
                );
                setLoadingSubmit(false);
                if (!req?.ok) {
                    throw new Error(`Failed to create Service Now integration.`);
                }
            },
        },
        {
            step_title: t("complete-step.title"),
            step_description: t("complete-step.description"),
            content_title: t("complete-step.content"),
            content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
            valid: true,
        },
    ];

    return (
        <div className="serviceNowIntegration">
            <AppWizard
                iconSrc={projects["serviceNow"]}
                loadingSubmit={loadingSubmit}
                configWizard={configWizard}
                handleClose={() => history.goBack()}
                submitDisabled={submitDisabled}
                setSubmitDisabled={setSubmitDisabled}
                handleSubmit={() => history.push("/integrations")}
            />
        </div>
    );
};

export default ServiceNowIntegration;
