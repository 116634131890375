import "../clickOps/clickOps.scss";
import { isEmpty } from "lodash";
import CollapsePanel from "../collapsePanel/collapsePanel";

const CliSdk = ({ cliSdk = {} }) => {
    const { eventPayload } = cliSdk;
    const isResponseElementsExist = !isEmpty(eventPayload?.responseElements) || false;

    const tls = {
        tlsVersion: eventPayload?.tlsDetails?.tlsVersion,
        cipherSuite: eventPayload?.tlsDetails?.tlsVersion?.cipherSuite,
        clientProvidedHostHeader: eventPayload?.tlsDetails?.clientProvidedHostHeader,
    };

    const requestParameters = JSON.stringify(eventPayload.requestParameters, null, 2);
    const responseElement =
        isResponseElementsExist ?? JSON.stringify(eventPayload?.responseElements, null, 2);

    return (
        <>
            <div className="ClickOps">
                <div className="ClickOps__column">
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Time</span>
                        <span>{cliSdk["@timestamp"]}</span>
                    </div>
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Event ID</span>
                        <span>{eventPayload.eventId}</span>
                    </div>
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Event Name</span>
                        <span>{eventPayload.eventName}</span>
                    </div>
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Region</span>
                        <span>{cliSdk.location}</span>
                    </div>
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Service</span>
                        <span>{eventPayload.service}</span>
                    </div>
                </div>

                <div className="ClickOps__column">
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Owner</span>
                        <span>{cliSdk.owner}</span>
                    </div>
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Source IP Address</span>
                        <span>{eventPayload.sourceIpAddress}</span>
                    </div>
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Agent</span>
                        <span>{eventPayload.agent}</span>
                    </div>
                    <div className="ClickOps__column__item">
                        <span className="ClickOps__column__text">Request Parameters</span>
                        <span>{requestParameters}</span>
                    </div>
                </div>
            </div>

            <div className={`ClickOps__Collapse ${isResponseElementsExist ? "sparate" : ""}`}>
                {isResponseElementsExist && (
                    <CollapsePanel
                        header="Response elements"
                        data={responseElement}
                        defaultActiveKey={["Response"]}
                        showLineNumbers={true}
                        headerKey="Response"
                    />
                )}

                <CollapsePanel
                    header="TLS details"
                    data={tls}
                    defaultActiveKey={["TLS"]}
                    showLineNumbers={true}
                    headerKey="TLS"
                />
            </div>
        </>
    );
};

export default CliSdk;
