import React, { useMemo, useState, useEffect } from "react";
import _ from "lodash";
import { Tooltip } from "antd";
import { numberWithCommas } from "../../utils/formatting";
import "./assetIacLine.scss";

const AssetIacLine = ({ data }) => {
  const [chart, setChart] = useState({
    total: 0,
    columns: [
      {
        status: "Codified",
        key: "managed",
        sum: 0,
        percent: 0,
      },
      {
        status: "Child",
        key: "child",
        sum: 0,
        percent: 0,
      },
      {
        status: "Drifted",
        key: "modified", // Include both modified and ghost keys
        sum: 0,
        percent: 0,
      },
      {
        status: "Unmanaged",
        key: "unmanaged",
        sum: 0,
        percent: 0,
      },
      {
        status: "IaC-Ignored",
        key: "iacIgnored",
        sum: 0,
        percent: 0,
      }
    ],
  });

  useEffect(() => {
    handleSetChartData();
  }, [data]);

  const handleSetChartData = () => {
    if (!_.isEmpty(data)) {
      const total = data.reduce((acc, obj) => {
        if (obj?.key !== "pending") {
          return acc + obj?.doc_count;
        }
        return acc;
      }, 0);

      const columns = _.map(chart?.columns || [], (item) => {
        let sum = 0;
        if (item.key === "modified") {
          const modifiedCount = _.find(data, i => i?.key === "modified")?.doc_count || 0;
          const ghostCount = _.find(data, i => i?.key === "ghost")?.doc_count || 0;
          sum = modifiedCount + ghostCount;
        } else {
          sum = _.find(data, i => i?.key === item?.key)?.doc_count || 0;
        }
        
        let percent = sum > 0 ? (sum / total) * 100 : 0;
        return { ...item, sum, percent };
      });

      setChart({ total, columns });
    }
  };

  const renderChart = useMemo(() => {
    if (_.isEmpty(data)) {
      return "-";
    }
    return _.map(chart?.columns || [], (x) => {
      return (
        <div
          key={x?.key}
          className={`AssetIacLine__block ${x?.key}`}
          style={{ width: `${x?.percent}%` }}
        />
      );
    });
  }, [chart]);

  const renderTooltip = useMemo(() => {
    if (_.isEmpty(data)) {
      return null;
    }
    return (
      <div className="AssetIacLine__tooltip col">
        {_.map(chart?.columns || [], (item) => {
          if (item?.sum > 0) {
            return (
              <div
                key={item?.key}
                className={`AssetIacLine__tooltip-item row between ${item?.key}`}
              >
                <div className="row" style={{ gap: "5px" }}>
                  <span
                    className={`AssetIacLine__tooltip-item-dot ${item?.key}`}
                  />
                  <span>{item?.status}</span>
                </div>
                <span>{numberWithCommas(item?.sum)}</span>
                {/* <span>{formatIfDecimal(item?.precent)}%</span> */}
              </div>
            );
          }
        })}
      </div>
    );
  }, [chart]);

  return (
    <Tooltip title={_.isEmpty(data) ? null : renderTooltip}>
      <div className="AssetIacLine row">{renderChart}</div>
    </Tooltip>
  );
};

export default AssetIacLine;
