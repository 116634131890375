import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _, { isEmpty } from "lodash";
import { Checkbox, Spin } from "antd";
import { Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SearchBox from "../../../shared/searchBox/searchBox";
import { v4 as uuidv4 } from "uuid";
import FilterContent from "./filterContent";
import "./inventorySingelFilter.scss";
import AppToggle from "../../../shared/appToggle/appToggle";

const InventorySingelFilter = ({
    allTypes = [],
    selectedTypes,
    title,
    reset,
    singleSelection,
    active,
    noAnimation,
    checkedList = [],
    hideText,
    onDynamicFilterSearch,
    onRegularFilterSearch,
    setFilters,
}) => {
    const dispatch = useDispatch();

    const CheckboxGroup = Checkbox.Group;
    const isDynmaicSearch = !!onDynamicFilterSearch;
    const [searchVal, setSearchVal] = useState(isDynmaicSearch ? null : "");
    const [clickedCheckbox, setClickedCheckbox] = useState(null);
    const [isPinnedClicked, setIsPinnedClicked] = useState(false);
    const [dynamicSearchLoading, setDynamicSearchLoading] = useState(false);
    const [hideIacIgnored, setHideIacIgnored] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const screenFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);
    const isPinnedItem = allTypes[0]?.isPinnedItem;

    useEffect(() => {
        if (screenFilters?.ignoreStates?.length > 0) {
            setHideIacIgnored(true);
        }
    }, []);

    useEffect(() => {
        if (_.isEmpty(allTypes) || reset) {
            setClickedCheckbox(null);
        }
    }, [allTypes, reset]);

    useEffect(() => {
        if (isDynmaicSearch && searchVal !== null) {
            handleDynamicSearch();
        }
        if (onRegularFilterSearch && !isEmpty(searchVal)) {
            onRegularFilterSearch(searchVal);
        }
    }, [searchVal, reset]);

    useEffect(() => {
        if (screenFilters?.exclusions?.length > 0 && hideIacIgnored) {
            setHideIacIgnored(false);
            return;
        }
    }, [screenFilters?.exclusions]);

    const handleDynamicSearch = async () => {
        setDynamicSearchLoading(true);
        await onDynamicFilterSearch(searchVal);
        setDynamicSearchLoading(false);
    };

    const filteredTypes = useMemo(() => {
        if (!_.isEmpty(allTypes)) {
            let filteredTypes = isPinnedItem ? _.slice(allTypes, 1) : allTypes;
            if (searchVal && !isDynmaicSearch) {
                filteredTypes = _.filter(
                    filteredTypes,
                    (item) =>
                        (item.formattedName || "")
                            .toLowerCase()
                            .includes(searchVal.toLowerCase()) || checkedList?.includes(item?.name),
                );
            }
            return filteredTypes;
        }
    }, [searchVal, selectedTypes, allTypes, reset]);

    const renderTypes = () => {
        if (!_.isEmpty(allTypes)) {
            return _.map(filteredTypes, (item, idx) => {
                return (
                    <FilterContent
                        key={uuidv4()}
                        name={item?.name}
                        icon={item?.icon}
                        count={item?.count}
                        isProdFlag={item?.isProdFlag}
                        element={item?.element}
                        formattedName={item?.formattedName}
                        checkedList={checkedList}
                        setClickedCheckbox={(val) => setClickedCheckbox(val)}
                        active
                        noAnimation={noAnimation}
                        provider={item?.provider}
                        tooltip={item?.tooltip}
                        hideText={hideText}
                    />
                );
            });
        }
    };

    const onChange = (list) => {
        if (isDynmaicSearch) return;

        if (singleSelection && list?.length > 1) {
            const singleArr = _.filter(list, (item) => item !== clickedCheckbox);
            selectedTypes(singleArr);
        } else {
            selectedTypes(list);
        }
    };

    const onClick = (event) => {
        if (!isDynmaicSearch) return;
        if (event?.target?.checked) {
            checkedList.push(event?.target?.value);
        } else {
            checkedList = checkedList.filter((item) => item !== event?.target?.value);
        }
        selectedTypes(checkedList);
    };

    const handleClearAll = () => {
        selectedTypes([]);
        setClickedCheckbox(null);
        setSearchVal("");
        setIsPinnedClicked(false);
        if (selectedTypes?.name === "excludedAssetSelected") {
            setHideIacIgnored(false);
        }
    };

    const handlePinnedClick = () => {
        const toggle = !isPinnedClicked;
        setIsPinnedClicked(toggle);
        const subItems = allTypes[0].subItems || [];
        const updatedArray = toggle
            ? [...checkedList, ...subItems]
            : _.filter(checkedList, (item) => !subItems.find((subItem) => item === subItem));
        selectedTypes(updatedArray);
    };

    useEffect(() => {
        if (selectedTypes?.name !== "excludedAssetSelected") {
            return;
        }
        if (!isInitialized) {
            setIsInitialized(true);
            return;
        }
        if (hideIacIgnored) {
            const filteredStates = screenFilters?.state?.filter((item) => item !== "iacIgnored");
            dispatch(
                setFilters({
                    ...screenFilters,
                    ignoreStates: ["iacIgnored"],
                    exclusions: [],
                    state: filteredStates,
                }),
            );
        } else {
            dispatch(
                setFilters({
                    ...screenFilters,
                    ignoreStates: [],
                }),
            );
        }
    }, [hideIacIgnored]);

    const addIacIgnoredToggle = () => {
        if (selectedTypes?.name === "excludedAssetSelected") {
            return (
                <div className="InventorySingelFilter__showIacIgnored">
                    <div className="InventorySingelFilter__showIacIgnored-text">
                        Hide IaC-Ignored from inventory
                    </div>
                    <AppToggle
                        checked={hideIacIgnored}
                        toggleChecked={() => setHideIacIgnored(!hideIacIgnored)}
                        loading={false}
                    />
                </div>
            );
        }
    };

    if (_.isEmpty(allTypes) && !isDynmaicSearch) {
        return (
            <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
            </div>
        );
    }
    return (
        <div className="InventorySingelFilter">
            <div className="col">
                {addIacIgnoredToggle()}
                <div className="InventorySingelFilter__title row between">
                    <SearchBox
                        placeholder={title}
                        value={(val) => setSearchVal(val)}
                        resetSearch={(val) => setSearchVal(val)}
                        grayMode
                        noBorder
                        hideReset
                    />
                    {!_.isEmpty(checkedList) && (
                        <button
                            onClick={handleClearAll}
                            className="InventorySingelFilter__title-actions-clear"
                        >
                            Clear
                        </button>
                    )}
                </div>
            </div>
            <div className="InventorySingelFilter__types col">
                {_.isEmpty(filteredTypes) && !_.isEmpty(searchVal) ? (
                    <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Results" />
                    </div>
                ) : (
                    <div className="col g5">
                        {isPinnedItem && (
                            <div
                                className={`InventorySingelFilter__types-item pinned ${
                                    isPinnedClicked ? "active" : ""
                                } row g5`}
                                onClick={handlePinnedClick}
                            >
                                <span className="InventorySingelFilter__types-item-pinnedText">
                                    {allTypes[0].formattedName}
                                </span>
                                <div className="InventorySingelFilter__types-item-pinnedText">
                                    {allTypes[0].count}
                                </div>
                            </div>
                        )}
                        {isDynmaicSearch && dynamicSearchLoading ? (
                            <div className="InventorySingelFilter-dynamicSearchLoading center">
                                <Spin indicator={<LoadingOutlined />} />
                            </div>
                        ) : (
                            <CheckboxGroup
                                value={checkedList}
                                onChange={onChange}
                                onClick={onClick}
                            >
                                {renderTypes()}
                            </CheckboxGroup>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default InventorySingelFilter;
