import React, { useEffect, useState, useMemo } from "react";
import groupBy from "lodash/groupBy";
import {getGuardrailsViolationsByTaskId, ignoreTaskGuardrailViolations} from "../../../../redux/actions/workflowsGuardrailsActions";
import { getCodeOrPlanScanRemediation as getCodeOrPlanOrGuardrailsRemediation } from "../../../../redux/actions/ciWorkflowsActions";
import Loading from "../../../../shared/loading/loading";
import { ReactComponent as TagIcon } from '../../../../Images/ci/guardrails/tag.svg';
import { ReactComponent as CostIcon } from '../../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourceIcon } from '../../../../Images/ci/guardrails/resources.svg';
import { ReactComponent as WarningIcon} from '../../../../Images/general_icons/Warning icon.svg';
import RemediationAllBtn from "../../remediationAllBtn/remediationAllBtn";
import {useDispatch, useSelector} from "react-redux";
import { sendEvent } from '../../../../utils/amplitude';

import {Checkbox, Radio, Tooltip} from "antd";
import { capitalizeFirst } from "../../../../utils/formatting";
import GuardrailsBlockTable from "./guradrailsBlockTable";

import { getStatusesCount, getViolationIdsFromGuardrailsBlock } from "./guardrailsTableHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./guardrailBlock.scss";
import {useAuth0} from "@auth0/auth0-react";
import AppBtn from "../../../../shared/appBtn/appBtn";
import {CiEvents} from "../../../../utils/amplitudeEvents";

const TYPE_ICONS = {
    cost: CostIcon,
    policy: PolicyIcon,
    resource: ResourceIcon,
    tag: TagIcon,
}
const GuardrailBlock = ({ taskId, handleOpenConfig, onClickCodeChangedLink, taskData }) => {
    const [data, setData] = useState({});
    const { user } = useAuth0();
    const [ignoreData, setIgnoreData] = useState({
        ignoreAlways: false,
        rerun: false,
        createdBy: "",
        taskId: taskId,
    });
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);
    const [ignoreLoading, setIgnoreLoading] = useState(false);
    const [ignoreResponseMessage, setIgnoreResponseMessage] = useState("");
    const [ignoreResponseSuccess, setIgnoreResponseSuccess] = useState(true);
    const [ignoreResponseRunUrl, setIgnoreResponseRunUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const [allRemediationsState, setAllRemedationsState] = useState({});
    const [statusFilter, setStatusFilter] = useState({});
    const { t } = useTranslation("ci-pipeline");
    
    const dispatch = useDispatch();

    const isNewDesign = data?.newDesign || false;
    const status = isNewDesign ? data?.status : [];
    const filterredStatus = (!statusFilter.passed && !statusFilter.softFailed && !statusFilter.failed) || (statusFilter.passed && statusFilter.softFailed && statusFilter.failed) ? status
    : status.filter((item) => {
        if (statusFilter.passed && item.isPassed) return true;
        if (statusFilter.softFailed && item.severity === 1 && !item.isPassed) return true;
        if (statusFilter.failed && item.severity === 2 && !item.isPassed) return true;
    });
    const statusesByType = useMemo(() => groupBy(filterredStatus, "ruleType"), [data, filterredStatus, isNewDesign]);
    const { passedCount = 0, failedCount = 0, softFailedCount = 0 } = getStatusesCount(status);
    const violationsIds = useMemo(() => getViolationIdsFromGuardrailsBlock(failedCount, softFailedCount, statusesByType), [failedCount, softFailedCount, statusesByType]);

    useEffect(() => {
        fetchGuardrailsViolations()
    }, []);

    const onIgnoreChange = (changedValues = {}) => setIgnoreData({ ...ignoreData, ...changedValues });

    const handleOnSubmitIgnore = async() => {
        const createdBy = user.name || user.nickname;
        ignoreData.createdBy = createdBy;
        sendEvent(CiEvents.ciTaskGuardrailsViolationsIgnore, {
            rerun: ignoreData.rerun,
            ignoreAlways: ignoreData.ignoreAlways,
        });
        onIgnoreChange({ createdBy });
        setIgnoreLoading(true);
        const response = await dispatch(ignoreTaskGuardrailViolations(ignoreData));
        if (response?.success) {
            if (ignoreData.ignoreAlways) {
                if (ignoreData.rerun) {
                    setIgnoreResponseMessage(t("guardrails.override.successMessages.overriddenAlwaysAndRerun"));
                } else {
                    setIgnoreResponseMessage(t("guardrails.override.successMessages.overriddenAlwaysOnly"));
                }
            } else {
                if (ignoreData.rerun) {
                    setIgnoreResponseMessage(t("guardrails.override.successMessages.overriddenAndRerun"));
                } else {
                    setIgnoreResponseMessage(t("guardrails.override.successMessages.overriddenOnly"));
                }
            }
            setIgnoreResponseRunUrl(response?.runUrl);
            setIgnoreResponseSuccess(true);
        } else {
            setIgnoreResponseMessage(t("guardrails.override.failureMessage"));
            setIgnoreResponseSuccess(false);
        }
        setIgnoreLoading(false);
    };
    
    const fetchGuardrailsViolations = async () => {
        setLoading(true);
        const response = await dispatch(getGuardrailsViolationsByTaskId(taskId));
        setData(response);
        setLoading(false);
    };

    if (loading){
        return <div className="basic-loading"><Loading /></div>
    }
    const handleStatusClick = (e, field) => {
        e.stopPropagation();
        const toggle = !statusFilter[field];
        setStatusFilter({...statusFilter,  [field]: toggle });
    }

    const fetchGuardrailsViolationsRemediations = async (guardrailsViolations) => {
        const payload = { guardrailsViolations };
        await dispatch(getCodeOrPlanOrGuardrailsRemediation(payload, taskId, false, true));
    };
    
    const handleRemediationAll = async() => {
        setAllRemedationsState({...allRemediationsState, loading: true, defaultOpen: true});
        await fetchGuardrailsViolationsRemediations(violationsIds);
        setAllRemedationsState({...allRemediationsState, loading: false});
    };

    const includesOnlyCostGuardrailsViolationsFlexibleBlock = (rules) => {
        for (const rule of rules) {
            if (rule.severity !== 1) {
                continue;
            }
            if (rule.ruleType !== "cost") {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="GuardrailBlock col g15">
          {isNewDesign ?
              <>
                  <div className="row between">
                      <div className="GuardrailBlock-header row g10">
                          {failedCount > 0 &&
                              <div className={`GuardrailBlock-status ${statusFilter.failed ? "selected" : ""} row g5`}
                                   onClick={(e) => handleStatusClick(e, "failed")}>
                                  <FontAwesomeIcon icon="times" className="GuardrailBlock-icon-status failed"/>
                                  <span className="GuardrailBlock-title unbold">{failedCount} Failed (Strict Block)</span>
                              </div>}
                          {softFailedCount > 0 && <div
                              className={`GuardrailBlock-status ${statusFilter.softFailed ? "selected" : ""} row g5`}
                              onClick={(e) => handleStatusClick(e, "softFailed")}>
                              <WarningIcon className="GuardrailBlock-icon-status softFailed"/>
                              <span className="GuardrailBlock-title unbold">{softFailedCount} Failed (Flexible Block)</span>
                          </div>}
                          {passedCount > 0 && failedCount > 0 && <div className="GuardrailBlock-divider horizontal"/>}
                          {passedCount > 0 &&
                              <div className={`GuardrailBlock-status ${statusFilter.passed ? "selected" : ""} row g5`}
                                   onClick={(e) => handleStatusClick(e, "passed")}>
                                  <FontAwesomeIcon icon="check" className="GuardrailBlock-icon-status passed"/>
                                  <span className="GuardrailBlock-title unbold">{passedCount} Passed</span>
                              </div>
                          }
                      </div>
                      {violationsIds?.length > 0 &&
                          <RemediationAllBtn t={t} handleRemidiationAllDiagnostics={handleRemediationAll}
                                             loading={allRemediationsState.loading}
                                             customBtnTitle="Remediate all violations"/>}
                  </div>
                  <div className="GuardrailBlock-divider"/>
                  {Object.entries(statusesByType).map(([ruleType, ruleArray = []]) => {
                      const Icon = TYPE_ICONS[ruleType];
                      ruleArray.sort((a, b) => a.severity - b.severity);
                      return (
                          <div className="col g10" key={ruleType}>
                              <div className="row g10">
                                  {Icon && <Icon className="GuardrailBlock-icon pointer"/>}
                                  <span className="unbold">{capitalizeFirst(ruleType)}</span>
                              </div>
                              {ruleArray.map((item = {}, index) => (
                                  <GuardrailsBlockTable ind={index} key={item.ruleId} data={item} type={ruleType}
                                                        taskId={taskId}
                                                        handleOpenConfig={handleOpenConfig}
                                                        onClickCodeChangedLink={onClickCodeChangedLink}
                                                        openRemediationsByDefault={allRemediationsState.defaultOpen}
                                                        allRemediationsLoading={allRemediationsState.loading}/>))}
                          </div>)
                  })}
                  {failedCount === 0 && softFailedCount !== 0 &&
                      <div className="col g10">
                          <div className="row g5">
                              <div className="GuardrailBlock-divider"/>
                          </div>
                          <div className="row g10">
                              <FontAwesomeIcon icon="eye-slash" className="WorkflowManagement__header-icon"/>
                              <span className="bold">{t("guardrails.override.overrideTitle")}</span>
                          </div>
                          <div className="row g5">
                              <span>{t("guardrails.override.overrideDescription")}</span>
                          </div>
                          {!includesOnlyCostGuardrailsViolationsFlexibleBlock(data?.status) &&
                              <div className="row g5">
                                  <Radio.Group onChange={(e) => onIgnoreChange({ignoreAlways: e.target.value})}
                                               value={ignoreData.ignoreAlways || false}>
                                      <div className="col g5">
                                          <Radio value={false} key={t("guardrails.override.overrideOnceTitle")}>
                                              <div className="row g5">
                                                  <span>{t("guardrails.override.overrideOnceTitle")}</span>
                                                  <Tooltip
                                                      placement="topRight"
                                                      title={t("guardrails.override.overrideOnceTooltip")}
                                                  >
                                                      <FontAwesomeIcon
                                                          icon="question-circle"
                                                          style={{
                                                              cursor: 'pointer'
                                                          }}
                                                      />
                                                  </Tooltip>
                                              </div>
                                          </Radio>
                                          <Radio value={true} key={t("guardrails.override.overrideAlwaysTitle")}>
                                              <div className="row g5">
                                                  <span>{t("guardrails.override.overrideAlwaysTitle")}</span>
                                                  <Tooltip
                                                      placement="topRight"
                                                      title={t("guardrails.override.overrideAlwaysTooltip")}
                                                  >
                                                      <FontAwesomeIcon
                                                          icon="question-circle"
                                                          style={{
                                                              cursor: 'pointer'
                                                          }}
                                                      />
                                                  </Tooltip>
                                              </div>
                                          </Radio>
                                      </div>
                                  </Radio.Group>
                              </div>
                          }
                          { taskData?.vcsId && ["github", "gitlab"].includes(taskData?.vcsType) &&
                              <div className="row">
                                  <Checkbox checked={ignoreData.rerun} onChange={(e) => onIgnoreChange({ rerun: e.target.checked })}>
                                      <span>{t("guardrails.override.rerunTitle")}</span>
                                  </Checkbox>
                                  <Tooltip
                                      placement="topRight"
                                      title={t("guardrails.override.rerunTooltip")}
                                  >
                                      <FontAwesomeIcon
                                          icon="question-circle"
                                          style={{
                                              cursor: 'pointer'
                                          }}
                                      />
                                  </Tooltip>
                              </div>
                          }
                          <div className="row">
                              <AppBtn disabled={isViewer} onClick={handleOnSubmitIgnore} text={t("guardrails.override.buttonTitle")} loading={ignoreLoading}/>
                          </div>
                            {ignoreResponseMessage && <div className="row">
                                {ignoreResponseSuccess ?
                                    <>
                                        <span className="form-label">{ignoreResponseMessage}</span>
                                        {ignoreResponseRunUrl &&
                                            <span
                                                onClick={() => window.open(ignoreResponseRunUrl, "_blank")}
                                                className="form-label console-icon-link"
                                                style={{ fontSize: "11px", marginLeft: "5px" }}
                                            >
                                                <FontAwesomeIcon icon="external-link-alt"/>
                                            </span>
                                        }
                                    </> :
                                    <span className="error">{ignoreResponseMessage}</span>
                            }
                                </div>}
                      </div>
                  }
              </>
              : data.violations?.map((item = {}) => {
                  const { ruleId, description = "", ruleType = "", ruleName = ""} = item;
            const Icon = TYPE_ICONS[ruleType];
            return (
                <div key={ruleId} className="GuardrailBlockItem">
                        <div className="Diagnostic__header guardrail row g8">
                        {Icon && <Tooltip title={capitalizeFirst(ruleType)}><Icon className="Diagnostic__icon-guardrail pointer"/></Tooltip>}
                            <div className="row g5">
                                <span className="Diagnostic__header-title unbold">{ruleName}</span>
                            </div>
                        </div>
                        <div className="Diagnostic__body col g5">
                            <span className="Diagnostic__text guardrails">{description}</span>
                        </div>
                </div>
            )
          })}
        </div>
    );
};

export default GuardrailBlock;