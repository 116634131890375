import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import AppBtn from "../../../shared/appBtn/appBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useIntercom } from "react-use-intercom";

import RocketFly from "../../../Images/governance/rocket_fly.svg";
import Dot from "../../../Images/governance/dot.svg";
import "./unauthorized.scss";

const Unauthorized = ({ screen, handleClose }) => {
    const history = useHistory();
    const { showNewMessages, show } = useIntercom();
    const [isVisible, setIsVisible] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth < 1800 ? "1100px" : "1300px");

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth < 1800 ? "1100px" : "1300px");
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const key_benefits = [
        {
            title: "Comprehensive Compliance Coverage",
            list: [
                "Instant assessment of your cloud infrastructure against industry-standard frameworks including SOC2, HIPAA, PCI, and NIST",
                "Pre-built policies that identify cloud waste and enforce tagging standards",
                "Real-time visibility into compliance status across all your cloud accounts",
            ],
        },
        {
            title: "Flexible Policy Management",
            list: [
                "Leverage Open Policy Agent (OPA) framework for custom policy creation",
                "Define and enforce organization-specific governance rules",
                "Implement standardized policies across multi-cloud environments",
            ],
        },
        {
            title: "Automated Remediation",
            list: [
                "Auto-generate Infrastructure-as-Code fixes for policy violations",
                "Direct CLI commands for immediate cloud configuration updates",
                "Streamline compliance maintenance with automated patch suggestions",
            ],
        },
    ];

    const key_capabilities = [
        {
            title: "Compliance Dashboard",
            description: "Single-pane visibility into your compliance status",
        },
        {
            title: "Remediation Engine",
            description: "Automated fixes and improvement suggestions",
        },
        {
            title: "Multi-Cloud Support",
            description: "Consistent governance across AWS, Azure, and GCP",
        },
        {
            title: "Policy Library",
            description: "Access to pre-built compliance frameworks and best practices",
        },
        {
            title: "Custom Policy Builder",
            description: "Create and manage organization-specific rules",
        },
    ];

    const generateKeyBenefits = () => {
        return (
            <div className="unauthorized__key-benefits">
                <div className="unauthorized__content__title">Key Benefits</div>
                <div className="unauthorized__content__container unauthorized__content__key_benefits">
                    {key_benefits.map((item, index) => (
                        <div className="unauthorized__content-item" key={index}>
                            <div className="unauthorized__content-item-title">
                                <img src={Dot} alt="" className="unauthorized__content-item-dot" />
                                <div>{item.title}</div>
                            </div>
                            <div className="unauthorized__content-item-list">
                                <ul>
                                    {item.list.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const generateKeyCapabilities = () => {
        return (
            <div className="unauthorized__key-capabilities">
                <div className="unauthorized__content__title">Key Capabilities</div>
                <div className="unauthorized__content__container unauthorized__content__key_capabilities">
                    {key_capabilities.map((item, index) => (
                        <div className="unauthorized__content-item" key={index}>
                            <div className="unauthorized__content-item-title">
                                <img src={Dot} alt="" className="unauthorized__content-item-dot" />
                                <div>{item.title}</div>
                            </div>
                            <div className="unauthorized__content-item-description">
                                {item.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const handleOnClose = () => {
        setIsVisible(false);

        if (screen === "insights") {
            history.push("/inventory");
            return;
        }
        if (handleClose) {
            handleClose();
        }
    };

    const handleOnClick = () => {
        show();
        showNewMessages(
            "Hello Firefly. I would like to learn more about your Cloud Governance capabilities.",
        );
    };

    const generateHeader = () => {
        return (
            <div className="unauthorized__header">
                <div className="unauthorized__header__close-button" onClick={handleOnClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <img src={RocketFly} alt="" className="__header-image" />
                <div className="unauthorized__header">
                    <div className="unauthorized__header__title">
                        Enhance Your Cloud Management with{" "}
                        <span className="unauthorized__header__title-highlight">
                            Firefly Governance
                        </span>
                    </div>
                    <div className="unauthorized__header-sub-title">
                        Streamline Compliance, Eliminate Waste, Automate Remediation
                    </div>
                </div>
            </div>
        );
    };

    const generateContent = () => {
        return (
            <div className="unauthorized__content">
                {generateKeyBenefits()}
                <div className="unauthorized__seperator"></div>
                {generateKeyCapabilities()}
            </div>
        );
    };

    const generateFooter = () => {
        return (
            <div className="unauthorized__footer">
                <div className="unauthorized__footer__button">
                    <AppBtn
                        onClick={handleOnClick}
                        text="Contact Sales"
                        tooltipText={"Upgrade to Governance for advanced policy and compliance."}
                    />
                </div>
                <div className="unauthorized__footer__description">
                    <div className="unauthorized__footer__description-a">
                        Ready to strengthen your cloud governance? Our team will help you understand
                        how Firefly Governance can enhance your cloud operations.
                    </div>
                    <div className="unauthorized__footer__description-b">
                        Firefly Governance is available as an add-on to your existing Firefly
                        subscription.
                    </div>
                </div>
            </div>
        );
    };

    return (
        <NewAppModal
            visible={isVisible}
            destroyOnClose
            centered
            width={screenWidth}
            bodyClassName="unauthorized-modal"
            iconSrc={null}
            footer={null}
        >
            <div className="unauthorized">
                {generateHeader()}
                {generateContent()}
                {generateFooter()}
            </div>
        </NewAppModal>
    );
};

export default Unauthorized;
