import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import { useTranslation } from "react-i18next";
import { Collapse, Drawer, Tooltip } from "antd";
import { ReactComponent as TaggingImage } from "../../../../../Images/ci/tagging-img.svg"
import { ReactComponent as DollarImage} from "../../../../../Images/ci/dollar-img.svg"
import { ReactComponent as SecurityImage} from "../../../../../Images/ci/security-img.svg"
import { ReactComponent as RemediationIcon } from "../../../../../Images/general_icons/remediation.svg";

import { capitalizeFirst, formatAwsStringWithUnderscore, renderEllipsis } from "../../../../../utils/formatting";
import AssetTypeImage from "../../../../../shared/assetTypeImage/assetTypeImage";
import CostAnalysis from "../postPlanConfig/costAnalysis";
import { useDispatch } from "react-redux";
import { getCostPolicyData } from "../../../../../redux/actions/ciWorkflowsActions";
import Loading from "../../../../../shared/loading/loading";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";

import TableWrapper from "../../../../../shared/tableWrapper/tableWrapper";
import tagsSummaryColumns from "./tagsSummaryColumns";
import { clouds } from "../../../../../utils/icons";
import { IAC_TYPES } from "../../../../../consts/general";
import "./generalSummaryConfig.scss";
import { sendEvent } from "../../../../../utils/amplitude";
import { aiRemediationEvents } from "../../../../../utils/amplitudeEvents";

const { Panel } = Collapse;
const GeneralSummaryConfig = ({ visible, onClose, generalData = {}, runId, handleOpenConfig, handleOpenCodeScan }) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tCi } = useTranslation("ci-pipeline");
  const { planSeverities = {}, costSummary = {}, tagsSummary = {} } = generalData;
  const [loading, setLoading] = useState(false);
  const [policyData, setPolicyData] = useState({});
  const [costData, setCostData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [scanCodeChangedResults, setScanCodeChangedResults] = useState([]);
  const [tagsPageSize, setTagsPageSize] = useState(5);
  const dispatch = useDispatch();
  
  useEffect(() => {
    fetchCostPolicyData();
  }, []);

  const fetchCostPolicyData = async () => {
    setLoading(true);
    const response = await dispatch(getCostPolicyData(runId));
    if (response) {
      const { policyData: policy = {}, costData: cost = [], tagsData: tags = [], scanCodeChangedResults: scanCode = []} = response;
      setPolicyData(policy);
      setCostData(cost);
      setTagsData(tags);
      setScanCodeChangedResults(scanCode);
    }
    return setLoading(false);
  }
  const handleOpenConfigDrawer = (resource, triggerTab) => {
    onClose();
    handleOpenConfig(resource, triggerTab);
  };
  
  const onRemediatePlanClick = (e, policy) => {
    e.stopPropagation();
    const resourceWithDefaultRemediationOpen = { ...(policy?.resource || {}), defaultRemediationOpen: true, specificReason: policy?.reason };
    handleOpenConfigDrawer(resourceWithDefaultRemediationOpen, "policy");
    sendEvent(aiRemediationEvents.clickedWorkflowsSummaryPolicyViolationRemediation, { source: "plan" });
  };

  const isScanCodeChangedEmpty = isEmpty(scanCodeChangedResults);
  const isPolicyBySevertiesEmpty = isEmpty(policyData?.HIGH) && isEmpty(policyData?.MEDIUM) && isEmpty(policyData?.LOW);
  const isPolicyDataEmpty = isPolicyBySevertiesEmpty && isScanCodeChangedEmpty;
  const scanCodeChangedBySeverity = groupBy(scanCodeChangedResults, "severity");
  const isCostDataEmpty = costSummary?.was == 0 && costSummary?.now == 0;
  const isCostDataRemains = (costSummary?.was > 0 || costSummary?.now > 0) && costSummary?.was === costSummary?.now;
  const { totalTaggingPercenetage = 0, a = 0, b = 0 } = tagsSummary;
  const showTagsSummary = b > 0;
  const tagsTitle = showTagsSummary ? 
  (a === b ? tCi(`tooltips.tagsCoverageFull${b === 1 ? "Single" : ""}`, { x: b })
  : a > 0 ? tCi("tooltips.tagsCoveragePartial", { x: b - a, y: b })
  : tCi(`tooltips.tagsCoverageEmpty${b === 1 ? "Single" : ""}`, { x: b }))
  : "";
  
  return (
        <Drawer
            title={
            <div className="GeneralSummaryConfig__mask">
              <div className="GeneralSummaryConfig__header row g10">
                <span className="GeneralSummaryConfig__header-title">{tCommon("summary")}</span>
              </div>
            </div>}
            placement="right"
            onClose={onClose}
            visible={visible}
            getContainer={false}
            style={{ position: "absolute" }}
            className="GeneralSummaryConfig"
            width="75%"
        >
            {loading ? <div className="GeneralSummaryConfig__body center"><Loading/></div>
            : 
            <div className="GeneralSummaryConfig__body col">
              {showTagsSummary && 
              <div className="GeneralSummaryConfig__body-tags col g5">
                <TaggingImage className="GeneralSummaryConfig__header-taggingImg" /> 
                <span className={`GeneralSummaryConfig__body-policy-title`}>Tags Coverage</span>
                <div className="col g10">
                    <span className="disabledText font-12">{tagsTitle}</span>
                    <span className="font-16 bold purple-text">{+totalTaggingPercenetage.toFixed(1)}%</span>
                    <div className={`CiPolicyCostSummary__tagBar summary`}>
                        <div className="CiPolicyCostSummary__tagBar-fill" style={{ width: `${totalTaggingPercenetage}%`, ...(totalTaggingPercenetage === 100 ? { borderRadius: '10px' } : {})}}></div>
                    </div>
                    <TableWrapper
                          autoHeight
                          height="100%"
                          loading={loading}
                          rowKey="id"
                          tableData={tagsData || []}
                          columns={tagsSummaryColumns}
                          disableSelectionOnClick
                          pageSize={tagsPageSize}
                          onPageSizeChange={(newSize) => setTagsPageSize(newSize)}
                          rowCount={tagsData?.length}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          onRowClick={(params) => handleOpenConfigDrawer(params.row?.resource, params.row?.showNoTags ? "plan" : "tag")}
                      />
                </div>
              </div>}
            {!isPolicyDataEmpty ? 
              <div className="GeneralSummaryConfig__body-policy col g10" style={!(!isCostDataEmpty || (!isPolicyDataEmpty || showTagsSummary)) ? { borderBottom: '0px', height: "100%" } : {} }>
                <SecurityImage className="GeneralSummaryConfig__header-securityImg" />
                <span className="GeneralSummaryConfig__body-policy-title">{tCi("policyViolations")}</span>
                <div className="GeneralSummaryConfig__body-policy-content col g10">
                  {!isScanCodeChangedEmpty &&
                  <div className="col g10">
                    <span className="font-15">Code</span>
                    {["HIGH", "MEDIUM", "LOW"].map((severityLevel = "", ind) => {
                      const severityData = scanCodeChangedBySeverity[severityLevel] || [];
                      const severityTitle = capitalizeFirst(severityLevel?.toLowerCase());
                      const severityCount = severityData?.length || 0;
                      if (!severityCount) return null;
                      const panelKey = `${severityLevel}-code-changed`;
                      return (
                        <Collapse
                          key={panelKey}
                          className="GeneralSummaryConfig__body-policy-content"
                          defaultActiveKey={ind === 0 && severityCount ? severityLevel : ''}
                          expandIconPosition="right"
                        >
                          <Panel className={`GeneralSummaryConfig__body-policy-content-panel ${severityLevel}`} key={panelKey} 
                            header={
                            <div className="GeneralSummaryConfig__body-policy-content-header row between">
                              <span className="GeneralSummaryConfig__body-policy-content-header-title">{severityTitle}</span>
                              <span className={`GeneralSummaryConfig__body-policy-content-header-count ${severityLevel}`}>{severityCount}</span>
                            </div>}>
                              {severityData.map((scanCode = {}, ind) => {
                                const { line, expectedValue = "", fileName = "", description = ""} = scanCode;
                                const tooltipText = expectedValue || description;
                                return (
                                <div className={`GeneralSummaryConfig__body-policy-content-header-item ${ind === severityData?.length -1 ? 'last': ''} ${severityLevel} row between`} key={uuidv4()} >
                                  <div className="row g8" onClick={() => handleOpenCodeScan(fileName, line)}>
                                    <img src={clouds(IAC_TYPES.terraform)} alt="cloud" style={{ height: "20px", width: "20px", borderRadius: "3px" }}/>
                                    <div className="col">
                                      <span className="bold">{fileName}</span>
                                      <span className="sub-title">Line {line}</span>
                                    </div>
                                  </div>
                                  <div className="row g8">
                                    <Tooltip title={tooltipText}>
                                      <span style={{ maxWidth: '315px' }}>{renderEllipsis(tooltipText, 120)}</span>
                                    </Tooltip>
                                    <Tooltip title={tCi("remediation.thinkerTooltip")}>
                                      <div className={`CiCode__content-collapse-content-policyViolations-item-remediateIcon row g5`} onClick={(e) => handleOpenCodeScan(fileName, line, expectedValue)}>
                                        <RemediationIcon />
                                        <span>Remediate</span>
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>)
                                })
                              }
                          </Panel>
                        </Collapse>
                      )
                    })}
                  </div>}
                  {!isPolicyBySevertiesEmpty &&
                  <div className="col g10">
                    <span className="font-15">Plan</span>
                    {Object.keys(policyData).map((severityLevel = "", ind) => {
                      const severityData = policyData[severityLevel] || [];
                      const severityTitle = capitalizeFirst(severityLevel?.toLowerCase());
                      const severityCount = planSeverities[severityLevel] || 0;
                      if (!severityCount) return null;
                      return (
                      <Collapse
                          key={severityLevel}
                          className="GeneralSummaryConfig__body-policy-content"
                          expandIconPosition="right"
                          defaultActiveKey={ind === 0 && severityCount ? severityLevel : ''}
                          >
                          <Panel className={`GeneralSummaryConfig__body-policy-content-panel ${severityLevel}`} key={severityLevel} header={<div className="GeneralSummaryConfig__body-policy-content-header row between">
                            <span className="GeneralSummaryConfig__body-policy-content-header-title">{severityTitle}</span>
                            <span className={`GeneralSummaryConfig__body-policy-content-header-count ${severityLevel}`}>{severityCount}</span>
                          </div>}>
                            <div className="col">
                              {severityData.map((policy = {}, ind) => {
                                return (
                                <div className={`GeneralSummaryConfig__body-policy-content-header-item ${ind === severityData?.length -1 ? 'last': ''} ${severityLevel} row between`} key={uuidv4()} >
                                    <div className="row g8" onClick={() => handleOpenConfigDrawer(policy.resource, "policy")}>
                                      <AssetTypeImage assetType={policy.assetType} customStyle={{ height: "20px", width: "20px", borderRadius: "3px" }}/>
                                      <div className="col">
                                        <span className="bold">{formatAwsStringWithUnderscore(policy.assetType)}</span>
                                        <span className="sub-title">{policy.resource?.name}</span>
                                      </div>
                                    </div>
                                    <div className="row g8">
                                            <Tooltip title={policy?.reason?.length > 120 ? policy?.reason : ""}>
                                               <span style={{ maxWidth: '315px' }}>{renderEllipsis(policy?.reason, 120)}</span>
                                            </Tooltip>
                                            <Tooltip title={tCi("remediation.thinkerTooltip")}>
                                              <div className={`CiCode__content-collapse-content-policyViolations-item-remediateIcon row g5`} onClick={(e) => onRemediatePlanClick(e, policy)}>
                                                  <RemediationIcon />
                                                  <span>Remediate</span>
                                              </div>
                                            </Tooltip>
                                    </div>
                                </div>
                                );
                              })}
                            </div>
                          </Panel>
                        </Collapse>);
                    })}
                  </div>}
                </div>
              </div> : null}
            {!isCostDataEmpty || (!isPolicyDataEmpty || showTagsSummary) ? 
                <div className="GeneralSummaryConfig__body-cost col g10">
                <DollarImage className="GeneralSummaryConfig__header-dollarImg" />
                  <div className="row between">
                    <span className="GeneralSummaryConfig__body-policy-title">{tCi("costEstimation")}</span>
                    <div className="GeneralSummaryConfig__body-cost-title row g8">
                        {(!isCostDataRemains || isPolicyDataEmpty) && 
                        <div className="GeneralSummaryConfig__body-cost-title-item row g5">
                          <span className="GeneralSummaryConfig__body-cost-title-label">Was</span>
                          <span className="GeneralSummaryConfig__body-cost-title-value">${costSummary?.was || 0}</span>
                        </div>}
                        <div className="GeneralSummaryConfig__body-cost-title-item row g5">
                          <span className="GeneralSummaryConfig__body-cost-title-label purple-text nowTitle">{isCostDataRemains || isCostDataEmpty ? "Remains" : "Now"}</span>
                          <span className="GeneralSummaryConfig__body-cost-title-item-nowValue purple-text">${costSummary?.now || 0}</span>
                        </div>
                    </div>
                  </div>
                  <div className="GeneralSummaryConfig__body-cost-content col g10">
                      {!isEmpty(costData) && <CostAnalysis data={{ generalCostResults: costData }} isGeneralSummary onGeneralNameClick={(resource) => handleOpenConfigDrawer(resource, "cost")}/>}
                  </div>
                </div> 
                  : (isPolicyDataEmpty && !showTagsSummary) ? 
                    <div className="pad20 centered">
                      <AppEmpty customStyle="ciResourcesNotFound" text={tCi("tooltips.summaryTabPolicyCostEmpty")} />
                    </div>
            : null}
            </div>}

        </Drawer>
  );
};

export default GeneralSummaryConfig;
