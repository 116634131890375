import { isEmpty, map, uniq, keys, filter } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { Link } from "react-router-dom";
import { Tabs, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { getStackStateFilePreview, getStackStateFileDownload } from "../../../redux/actions/iacStacksActions";
import { setFilters as setInventoryFilters } from "../../../redux/actions/inventoryv3Actions";
import ActionBtn from "../../../shared/actionBtn/actionBtn";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import AppBtn from "../../../shared/appBtn/appBtn";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import Loading from "../../../shared/loading/loading";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { providers } from "../../../utils/providers";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";
import {
    capitalizeFirst,
    renderCrawlerIconByType,
    renderCrawlerTitleByType,
} from "../../../utils/formatting";
import "./iacStackDrawer.scss";

const IacStackDrawer = ({
    visible,
    closeDrawer,
    rowData,
    setDiagramModalRowData,
    setDiagramModalOpen,
    showStackContents,
    providerUrls,
    iacType,
}) => {
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    const history = useHistory();
    const { showNewMessages, show } = useIntercom();

    const backends = useSelector((state) => state.iacStacksReducer.backends.hits);

    const emptyState = "Empty State";

    let fileType = "tfstate";
    let fileName = "terraform";
    if (iacType === "cloudformation") {
        fileType = "yml";
        fileName = "stack";
    }

    const providerIntegrations = useSelector((state) => state.globalAppReducer.clouds);
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    const [activeTab, setActiveTab] = useState("stateFile");
    const [stateFile, setStateFile] = useState("");
    const [code, setCode] = useState("");
    const [loadingPreviewData, setLoadingPreviewData] = useState(false);
    const [loadingFileDownload, setLoadingFileDownload] = useState(false);
    const [isCodeTruncated, setIsCodeTruncated] = useState(false);

    const mapStatuses = ["outOfSync", "partialSync", "synced", "active"];
    const darkIcons = ["aws", "kafka", "helm", "vault"];
    const notSupportedAssetTypes = useMemo(
        () =>
            (rowData?.notSupportedSlice || []).reduce(
                (acc, obj = {}) =>
                    !obj.isFullNotSupported && obj?.notSupportedAssetTypes?.length
                        ? [...acc, ...obj?.notSupportedAssetTypes]
                        : acc,
                [],
            ),
        [rowData?.notSupportedSlice],
    );

    useEffect(() => {
        if (visible) {
            if (showStackContents && !isEmpty(rowData)) {
                return getStackStatePreview();
            }
            return setActiveTab("properties");
        }
        setActiveTab("stateFile");
        setStateFile("");
        setCode("");
        setIsCodeTruncated(false);
    }, [visible]);

    const getStackStatePreview = async () => {
        setLoadingPreviewData(true);
        const { content, isTruncated = false } =
            (await dispatch(getStackStateFilePreview(rowData?.stateLocalPath)));
        if (!isEmpty(content)) {
            try {
                const defaultJson = JSON.parse(content);
                const formattedJsonString = JSON.stringify(defaultJson, null, 2);
                setCode(content);
                setIsCodeTruncated(isTruncated);
                setStateFile(formattedJsonString);
            } catch (error) {
                setCode(content);
                setStateFile(content);
                setIsCodeTruncated(isTruncated);
            }
        } else {
            setCode("");
            setStateFile("");
        }
        setLoadingPreviewData(false);
    };

    const handleGetStackStateFileDownload = async () => {
        setLoadingFileDownload(true);
        try {
            await dispatch(getStackStateFileDownload(rowData?.stateLocalPath));
        } finally {
            setLoadingFileDownload(false);
        }
    };

    const handleClick = (provider, assetType) => {
        if (!isEmpty(provider)) {
            show();
            showNewMessages(
                `Dear Firefly Team, I would like to ask to extend the product support to include ${provider} provider`,
            );
            return;
        }
        if (!isEmpty(assetType)) {
            show();
            showNewMessages(
                `Dear Firefly Team, I would like to ask you to extend the product coverage to include ${assetType} asset type.`,
            );
        }
    };

    const renderCodeTab = () => {
        if (loadingPreviewData) {
            return <Loading />;
        }
        if (isEmpty(stateFile)) {
            return <AppEmpty text={emptyState} customStyle="code" />;
        }

        return (
            <div style={{ position: "relative", zIndex: 1 }}>
                <SyntaxHighlighter
                    style={atomDark}
                    showLineNumbers={true}
                    language="hcl"
                    lineProps={{
                        style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                    }}
                >
                    {stateFile}
                </SyntaxHighlighter>
                {isCodeTruncated && (
                    <div className="maskCode col">
                        <div className="title maskCode__title">
                            Download file for additonal information
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderProperties = () => {
        let crawler = null;
        let integrations = {};
        if (!isEmpty(backends)) {
            crawler = backends?.find((backend) => backend?.id === rowData?.crawlerId);
        }
        if (!isEmpty(rowData?.stackProviders)) {
            map(rowData?.stackProviders, (provider) => {
                if (!isEmpty(provider?.providerIntegrationId) && !isEmpty(providerIntegrations)) {
                    let type =
                        provider?.providerType === "kubernetes" ||
                        provider?.providerType === "kubectl"
                            ? "k8s"
                            : provider?.providerType;
                    const integMatch = providerIntegrations[type]?.find(
                        (integ) => integ?.id === provider?.providerIntegrationId,
                    );
                    integrations[provider?.providerIntegrationId] = integMatch?.name;
                }
            });
        }
        return (
            <div className="IacStackDrawer__tabs-body-properties col">
                {(!isEmpty(
                    filter(rowData?.stackProviders, (provider) => provider?.isProviderSupported),
                ) ||
                    !isEmpty(rowData?.notSupportedProviders)) && (
                    <div className="IacStackDrawer__tabs-body-properties-scope col">
                        <div className="title">Providers</div>
                        {!isEmpty(
                            filter(
                                rowData?.stackProviders,
                                (provider) => provider?.isProviderSupported,
                            ),
                        ) && (
                            <div className="IacStackDrawer__tabs-body-properties-scope-sub col">
                                <div className="small-title bold">Supported</div>
                                <div className="IacStackDrawer__tabs-body-properties-scope-sub-list col">
                                    {map(
                                        filter(
                                            rowData?.stackProviders,
                                            (provider) => provider?.isProviderSupported,
                                        ),
                                        (provider) => {
                                            return (
                                                <div
                                                    className="IacStackDrawer__tabs-body-properties-scope-sub-list-provider row"
                                                    key={uuidv4()}
                                                >
                                                    <ProviderIcon
                                                        provider={provider?.providerType}
                                                        dark={darkIcons?.includes(
                                                            provider?.providerType,
                                                        )}
                                                        customStyle="drawer"
                                                    />
                                                    {isEmpty(provider?.providerIntegrationId) ? (
                                                        <div className="IacStackDrawer__tabs-body-properties-scope-sub-list-provider-integ row">
                                                            {!isEmpty(
                                                                provider?.providerAccountId,
                                                            ) && (
                                                                <span>{`${provider?.providerAccountId} -`}</span>
                                                            )}
                                                            <span>Not Integrated</span>
                                                            <Link
                                                                to={`/integrations/${
                                                                    providers[
                                                                        provider?.providerType
                                                                    ]?.integrationPath ||
                                                                    (provider?.providerType ===
                                                                    "kubernetes"
                                                                        ? "k8s"
                                                                        : provider?.providerType)
                                                                }-integration`}
                                                            >
                                                                + Add Integration
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        <span>
                                                            {!isEmpty(
                                                                integrations[
                                                                    provider?.providerIntegrationId
                                                                ],
                                                            )
                                                                ? `Integrated - ${
                                                                      integrations[
                                                                          provider
                                                                              ?.providerIntegrationId
                                                                      ]
                                                                  }`
                                                                : "Integrated"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        )}
                        {!isEmpty(rowData?.notSupportedProviders) && (
                            <div className="IacStackDrawer__tabs-body-properties-scope-sub col">
                                <div className="small-title bold">Not Supported</div>
                                <div className="IacStackDrawer__tabs-body-properties-scope-sub-list col">
                                    {map(
                                        uniq(rowData?.notSupportedProviders),
                                        (notSupportedProvider) => {
                                            return (
                                                <div
                                                    className="IacStackDrawer__tabs-body-properties-scope-sub-list-notSupportedProvider row"
                                                    key={uuidv4()}
                                                >
                                                    <span
                                                        onClick={() =>
                                                            handleClick(notSupportedProvider)
                                                        }
                                                        className="IacStackDrawer__tabs-body-properties-scope-sub-list-notSupportedProvider-icon"
                                                    >
                                                        <ProviderIcon
                                                            provider={notSupportedProvider}
                                                            dark={darkIcons?.includes(
                                                                notSupportedProvider,
                                                            )}
                                                            customStyle="drawer"
                                                        />
                                                    </span>

                                                    <span>{`${capitalizeFirst(
                                                        notSupportedProvider,
                                                    )} - `}</span>
                                                    <a
                                                        href={`https://${
                                                            keys(providerUrls)?.includes(
                                                                notSupportedProvider,
                                                            )
                                                                ? providerUrls[notSupportedProvider]
                                                                : `registry.terraform.io/providers/hashicorp/${notSupportedProvider}`
                                                        }`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Docs
                                                    </a>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {((!isEmpty(rowData?.managedSplit) &&
                    !keys(rowData?.managedSplit)?.includes("unknown")) ||
                    !isEmpty(notSupportedAssetTypes)) && (
                    <div className="IacStackDrawer__tabs-body-properties-scope col">
                        <div className="title">Assets</div>
                        {!isEmpty(rowData?.managedSplit) &&
                            !keys(rowData?.managedSplit)?.includes("unknown") && (
                                <div className="IacStackDrawer__tabs-body-properties-scope-sub col">
                                    <div className="small-title bold">Supported</div>
                                    <div
                                        className="row IacStackDrawer__tabs-body-properties-scope-sub-view-asstes-btn-flag"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(
                                                setInventoryFilters({
                                                    ...emptyInventoryScreenFilters,
                                                    stacks: [
                                                        {
                                                            id: rowData?.stackId,
                                                            name: rowData?.stackName,
                                                            iacType: rowData?.stateType,
                                                        },
                                                    ],
                                                }),
                                            );
                                            history.push({
                                                pathname: `/inventory`,
                                                search: `?stack=${rowData?.stackId}`,
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon="layer-group" />
                                        {rowData?.managedSplit?.total || ""}
                                    </div>
                                </div>
                            )}
                        {!isEmpty(notSupportedAssetTypes) && (
                            <div className="IacStackDrawer__tabs-body-properties-scope-sub col">
                                <div className="small-title bold">Cloud Scanning Not Supported</div>
                                <div className="IacStackDrawer__tabs-body-properties-scope-sub-list col">
                                    {map(notSupportedAssetTypes, (assetType) => {
                                        return (
                                            <div
                                                className="IacStackDrawer__tabs-body-properties-scope-sub-list-notSupportedProvider row"
                                                key={uuidv4()}
                                            >
                                                <span
                                                    onClick={() => handleClick(null, assetType)}
                                                    className="IacStackDrawer__tabs-body-properties-scope-sub-list-notSupportedProvider-icon"
                                                >
                                                    <ProviderIcon
                                                        provider="none"
                                                        customStyle="drawer"
                                                    />
                                                </span>
                                                {assetType}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {!isEmpty(crawler?.remoteIntegrationName) && (
                    <div className="IacStackDrawer__tabs-body-properties-subject col">
                        <div className="title">Backend</div>
                        <div className="IacStackDrawer__tabs-body-properties-subject-list col">
                            <div className="IacStackDrawer__tabs-body-properties-subject-list-backend row">
                                <Tooltip
                                    placement="topLeft"
                                    title={renderCrawlerTitleByType(crawler?.type)}
                                    overlayInnerStyle={{
                                        width: "120px",
                                    }}
                                >
                                    <img
                                        src={renderCrawlerIconByType(crawler?.type, themeDark)}
                                        alt="type"
                                        className="IacStackDrawer__tabs-body-properties-subject-list-backend-icon"
                                    />
                                </Tooltip>
                                <span>{crawler?.remoteIntegrationName}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderFooter = () => {
        let footer = [];
        if (mapStatuses?.find((status) => status === rowData?.status)) {
            footer?.push(
                <AppBtn
                    text="Diagram"
                    onClick={() => {
                        setDiagramModalRowData(rowData);
                        setDiagramModalOpen(true);
                    }}
                    disabled={isEmpty(rowData)}
                    icon={<FontAwesomeIcon icon="sitemap" />}
                />,
            );
        }
        if (showStackContents && !isCodeTruncated) {
            footer?.push(
                <ActionBtn
                    text="Copy"
                    icon="copy"
                    disabled={isEmpty(code)}
                    action="copy"
                    stringToAction={code}
                    refresh={visible}
                />,
            );
        }
        if (showStackContents) {
            footer?.push(
                <ActionBtn
                    text="Export"
                    icon="download"
                    action="download"
                    fileType={fileType}
                    fileName={fileName}
                    stringToAction={isCodeTruncated ? null : code}
                    loading={loadingFileDownload}
                    disabled={loadingFileDownload || isEmpty(code)}
                    onClick={isCodeTruncated ? handleGetStackStateFileDownload : null}
                    refresh={visible}
                />
            );
        }
        return footer;
    };

    return (
        <AppDrawer
            title="Stack Data"
            closeDrawer={closeDrawer}
            visible={visible}
            hideClose
            width="700px"
            footer={renderFooter()}
        >
            {loadingPreviewData ? (
                <Loading />
            ) : isEmpty(stateFile) ? (
                <AppEmpty text={emptyState} customStyle="code" />
            ) : (
                <div className="IacStackDrawer col">
                    <Tabs
                        className="IacStackDrawer__tabs"
                        defaultActiveKey={showStackContents ? "stateFile" : "properties"}
                        onChange={(tab) => setActiveTab(tab)}
                        activeKey={activeTab}
                    >
                        {showStackContents && (
                            <TabPane tab="Stack Contents" key="stateFile">
                                <div className="IacStackDrawer__tabs-body col">
                                    {renderCodeTab()}
                                </div>
                            </TabPane>
                        )}
                        <TabPane tab="Properties" key="properties">
                            <div className="IacStackDrawer__tabs-body">{renderProperties()}</div>
                        </TabPane>
                    </Tabs>
                </div>
            )}
        </AppDrawer>
    );
};

export default IacStackDrawer;
