import OwnerIndication from "../../../../components/inventory/ownerIndication/ownerIndication";
import "./ownerCell.scss";

export const extractNameFromOwner = (owner) => {
    if (owner.includes("@")) {
        return owner;
    } else {
        const parts = owner.split("/");
        return parts[parts.length - 1];
    }
};

export const generateInitials = (owner) => {
    if (!owner || owner === "") {
        return "";
    }
    const namePart = owner.split("@")[0];
    const nameSegments = namePart.split(/[-.]/);
    if (nameSegments.length === 0) {
        return "";
    }
    const initials = nameSegments?.map((segment) => segment[0].toUpperCase()).join("");
    return initials;
};

export const generateRandomColor = (initials) => {
    const colors = ["#e94141", "#4544e9", "#41e94a", "#ffa500", "#ff69b4", "#ffd700", "#808080"];
    const hash = initials.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const randomIndex = hash % colors.length;
    return colors[randomIndex];
};

export const extractDetails = (owner) => {
    if (!owner || owner === "") {
        return {
            customColor: "#424268",
            displayName: owner,
            initials: "-",
        };
    }
    owner = extractNameFromOwner(owner);
    const initials = generateInitials(owner);
    const color = generateRandomColor(initials);
    return {
        customColor: color,
        displayName: owner,
        initials: initials,
    };
};

const OwnerCell = ({ row }) => {
    const extractedDetails = extractDetails(row.owner);
    const ownerObject = {
        ...row.owner,
        userIdentity: {
            ...extractedDetails,
        },
    };
    return (
        <div className="OwnerCell">
            <OwnerIndication
                data={ownerObject}
                handleClickOwner={() => {
                    return false;
                }}
                drawerView={true}
            />
        </div>
    );
};

export default OwnerCell;
