import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "react-calendar";
import moment from "moment";
import { Button } from "antd";

import { clearEventCenterData } from "../../../../redux/actions/eventCenterActions";
import { eventCenterEvents } from "../../../../utils/amplitudeEvents";
import { sendEvent } from "../../../../utils/amplitude";
import "./datePicker.scss";

const DatePicker = ({ updateFilter, setCustomPage }) => {
    const dispatch = useDispatch();
    const lastFilteredKey = useSelector((state) => state.eventCenterReducer?.lastFilteredKey || "");
    const filters = useSelector((state) => state.eventCenterReducer?.filters || {});

    const [openCalendar, setOpenCalendar] = useState(false);
    const [dateRange, setDateRange] = useState(null);
    const [selectedDate, setSelectedDate] = useState("week");

    const [dates, setDates] = useState({
        day: {
            key: "day",
            title: "24h",
            isSelected: false,
        },

        week: {
            key: "week",
            title: "7d",
            isSelected: true,
        },

        month: {
            key: "month",
            title: "30d",
            isSelected: false,
        },

        custom: {
            key: "custom",
            title: "Custom",
            isSelected: false,
        },
    });

    useEffect(() => {
        if (lastFilteredKey !== "ClearAll") return;

        for (const date in dates) {
            dates[date].isSelected = false;
        }
        dates.week.isSelected = true;
        setDates(dates);
        setSelectedDate("week");
        updateFilter("selectedTime", "week");
    }, [lastFilteredKey]);

    useEffect(() => {
        if (filters?.selectedTime !== "custom" && selectedDate === filters?.selectedTime) {
            return;
        }
        dates[selectedDate].isSelected = false;
        dates[filters?.selectedTime].isSelected = true;
        setSelectedDate(filters?.selectedTime);
    }, [filters?.selectedTime]);

    const handleCancel = () => {
        setDateRange(null);
        setOpenCalendar(false);
    };

    const handleOk = () => {
        sendEvent(eventCenterEvents.eventDatesFilter, { title: "filter date custom" });
        setOpenCalendar(false);
        setCustomPage(1);
        dispatch(clearEventCenterData());
        updateFilter("selectedTime", "custom", dateRange);
    };

    const handleSetFilter = (date) => {
        setDates((oldDates) => {
            const newDates = Object.keys(oldDates).reduce((acc, key) => {
                acc[key] = { ...oldDates[key], isSelected: false };
                return acc;
            }, {});
            newDates[date].isSelected = true;
            return newDates;
        });

        if (date === "custom") {
            setOpenCalendar(true);
        } else {
            sendEvent(eventCenterEvents.eventDatesFilter, { title: `filter date: ${date}` });
            setDateRange(null);
            setOpenCalendar(false);
            setCustomPage(1);
            dispatch(clearEventCenterData());
            updateFilter("selectedTime", date);
        }
    };

    const handleCalendarChange = (date) => {
        setDateRange(date);
    };

    const renderType = (date) => {
        const { title, key, isSelected } = dates[date] || {};

        return (
            <div
                className={`DatePicker__inner-btn ${key} center g5 ${isSelected ? "active" : ""}`}
                onClick={() => handleSetFilter(key)}
                style={{ cursor: "pointer" }}
            >
                {title}
            </div>
        );
    };

    return (
        <div className="DatePicker">
            <div className="DatePicker__inner">
                {renderType("day")}
                <div className="DatePicker__inner-divider first" />
                {renderType("week")}
                <div className="DatePicker__inner-divider second" />
                {renderType("month")}
                <div className="DatePicker__inner-divider third" />
                {renderType("custom")}
                {openCalendar && (
                    <div className="calendar-container">
                        <Calendar
                            selectRange={true}
                            onChange={handleCalendarChange}
                            value={dateRange}
                            calendarType="ISO 8601"
                            minDate={moment().subtract(1, "year").toDate()}
                            maxDate={new Date()}
                            className="SmallCalendar"
                            showNeighboringMonth={true}
                        />
                        <div className="calendar-actions">
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button onClick={handleOk}>Ok</Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DatePicker;
