import React, { useState ,useEffect, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { getCiWorkspaces, handleSetCiFilters, handleSetCiSorting } from "../../redux/actions/ciWorkflowsActions";
import { updateUserPreference } from "../../redux/actions/userPreferencesActions";
import { ReactComponent as ArrowIcon } from '../../Images/general_icons/chevron.svg';
import CiStepsCollapse from "../../components/ciSteps/ciStepsCollapse";
import Loading from "../../shared/loading/loading";
import HeaderSearchBox from "../../shared/headerSearchBox/headerSearchBox";
import { useTranslation } from "react-i18next";
import { ReactComponent as CiPipelineIcon } from '../../Images/general_icons/sidebar/ci-pipeline.svg';
import CiFilters from "./ciFilters";
import AppBtn from "../../shared/appBtn/appBtn";
import { ReactComponent as CollapseIcon } from '../../Images/general_icons/collapse.svg'
import { sendEvent } from "../../utils/amplitude";
import { CiEvents } from "../../utils/amplitudeEvents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkflowManagement from "../../components/ciSteps/workflowManagement/workflowManagement";
import { TablePagination } from "@mui/material";
import EmptyWorkflows from "./emptyWorkflows";
import AppEmpty from "../../shared/appEmpty/appEmpty";
import CiFiltersBadges from "./ciFiltersBadges";
import CiModal from "../../components/ciSteps/ciModal/ciModal";
import { EMPTY_CI_WORKFLOWS_AGGS_FILTERS } from "../../consts/general";
import DeleteWorkspace from "../../components/ciSteps/workspaceMenu/deleteWorkspace";
import LabelsModal from "../../components/ciSteps/workspaceMenu/labelsModal";
import GuardrailIgnoresModal from "../../components/ciSteps/workspaceMenu/guardrailIgnoresModal";

import './ciCustomTheme.scss';
import './ciSteps.scss';

const CI_WORKFLOWS_INTERVAL_TIME = 60000;
const DEFAULT_PAGE_SIZE = 25;

const getArrowClassNameAddition = (sort) => sort === 1 ? "up" : sort === -1 ? "down" : "";

const CiSteps = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [ignoreDefaultParams, setIgnoreDefaultParams] = useState(false);
    const [showCreateWorkflowModal, setShowCreateWorkflowModal] = useState(false);
    const [isEditModeWorkflowModal, setIsEditModeWorkflowModal] = useState(false);
    const [showDeleteWorkflowModal, setShowDeleteWorkflowModal] = useState(false);
    const [showLabelsModal, setShowLabelsModal] = useState(false);
    const [showGuardrailIgnoresModal, setShowGuardrailIgnoresModal] = useState(false);

    const [runModalVisible, setRunModalVisible] = useState(false);
    const [selectedRun, setSelectedRun] = useState({});
    const [openDefaultPolicyCostDrawer, setOpenDefaultPolicyCostDrawer] = useState(false);

    const [workflowPage, setWorkflowPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
    const [editWorkflowId, setEditWorkflowId] = useState("");
    const [temporaryWorkspaceData, setTemporaryWorkspaceData] = useState("");

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const workflowsFiltersOpen = useSelector((state) => state.userPreferencesReducer.workflowsFiltersOpen);
    const ciFilters = useSelector((state) => state.ciWorkflowsReducer.ciFilters);
    const totalWorkflows = useSelector((state) => state.ciWorkflowsReducer.total);
    const ciSorting = useSelector((state) => state.ciWorkflowsReducer.ciSorting);
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    const params = new URL(window.location).searchParams || {};
    const defaultWorkflowId = params.get("workflow");

    const dispatch = useDispatch();
    const { t } = useTranslation("ci-pipeline");
    
    const isDefaultWorkspace = defaultWorkflowId && !ignoreDefaultParams;
    const ciFiltersIsEmpty = isEqual(ciFilters, EMPTY_CI_WORKFLOWS_AGGS_FILTERS);

    const fetchCiWorkflow = useCallback(async () => {
        const response = await dispatch(getCiWorkspaces(ciFilters, searchInput, ciSorting, workflowPage, rowsPerPage))
        if (response?.abort) return response;
        return setData(response);
    });
    
    useEffect(() => {
        if (isDefaultWorkspace) {
            dispatch(handleSetCiFilters({...ciFilters, workspaceId: [defaultWorkflowId] }));
        }
    }, []);

    useEffect(() => {
         if (themeDark) {
            document.documentElement.classList.remove("ciLight");
            document.documentElement.classList.add("ciDark");
         } else {
            document.documentElement.classList.remove("ciDark");
            document.documentElement.classList.add("ciLight");
        }
    }, [themeDark]);

    useEffect(async () => {
        if (showCreateWorkflowModal || showDeleteWorkflowModal || showLabelsModal || showGuardrailIgnoresModal) return;
        setLoading(true);
        const res = await fetchCiWorkflow();
        if (res?.abort) return;
        setLoading(false);
    }, [ciFilters, searchInput, showCreateWorkflowModal, showDeleteWorkflowModal, showLabelsModal,
        showGuardrailIgnoresModal, ciSorting, workflowPage, rowsPerPage]);

    useEffect(() => {
        let interval = setInterval(fetchCiWorkflow, CI_WORKFLOWS_INTERVAL_TIME);
        return () => clearInterval(interval);
    }, [fetchCiWorkflow]);


    const handleOpenWorkflowManagement = (isEditMode = false, workflowId) => {
       if (workflowId){
        setEditWorkflowId(workflowId);
       }
        setIsEditModeWorkflowModal(!!isEditMode);
        setShowCreateWorkflowModal(true);
        if (!isEditMode) {
            sendEvent(CiEvents.ciClickAddNewWorkflow,{ });
        }
    }
    
    const handleOnDeleteWorkflowClick = (showModal = false, workspaceData = {}) => {
        setShowDeleteWorkflowModal(showModal);
        setTemporaryWorkspaceData(workspaceData);
    }
    const handleOnLabelsClick = (showModal = false, workspaceData) => {
        setShowLabelsModal(showModal);
        setTemporaryWorkspaceData(workspaceData);
    }
    const handleOnGuardrailIgnoresClick = (showModal = false, workspaceData) => {
        setShowGuardrailIgnoresModal(showModal);
        setTemporaryWorkspaceData(workspaceData);
    }

    const handleFiltersCollapse = () => {
        const toggle = !workflowsFiltersOpen;
        dispatch(updateUserPreference("workflowsFiltersOpen", toggle));
        sendEvent(CiEvents.ciClickedFilterToggle, { actionType: toggle ? "opened" : "closed" });
    };
    const handleSetRowsPerPage = (e) => {
        const newSize = e.target.value;
        if (newSize > totalWorkflows) {
            setWorkflowPage(0);
        }
        setRowsPerPage(newSize);
    }

    if (isEmpty(data) && !totalWorkflows && ciFiltersIsEmpty && !searchInput && !loading) {
        return <EmptyWorkflows showCreateWorkflowModal={showCreateWorkflowModal} setShowCreateWorkflowModal={setShowCreateWorkflowModal} handleOpenWorkflowManagement={handleOpenWorkflowManagement}/>
    }

    if (isEmpty(data) && loading) return <div className="basic-loading"><Loading /></div>

    return (
        <div className="CiSteps col g20">
            <div className={`CiSteps__container ${!workflowsFiltersOpen ? 'closed' : ''}`}>
                <div className="col g8">
                    <div className={`CiSteps__header-container row g8 ${!workflowsFiltersOpen ? 'center' : ''}`}>
                        <CiPipelineIcon className="CiSteps__header-icon"/>
                        {workflowsFiltersOpen && <span className="CiSteps__header-title">{t("title")}</span>}
                    </div>
                    <CiFilters searchInput={searchInput} handleFiltersCollapse={handleFiltersCollapse} setWorkflowPage={setWorkflowPage} workflowsFiltersOpen={workflowsFiltersOpen} 
                    modalsClosed={!showCreateWorkflowModal && !showDeleteWorkflowModal && !showLabelsModal && !showGuardrailIgnoresModal }/>
                </div>
                <div className="col g15">
                    <div className="basic-card row between">
                        <HeaderSearchBox
                            placeholder="Search..."
                            value={(val) => {
                                setSearchInput(val)
                                sendEvent(CiEvents.ciSearchText, { searchedText: val })
                              }
                            }
                            resetSearch={(val) => {
                                setSearchInput(val)
                            }}
                            currentValue={searchInput}
                        />
                        <AppBtn
                            text={t("add-workflow-btn")}
                            onClick={() => handleOpenWorkflowManagement()}
                            icon={<FontAwesomeIcon icon="plus" />}
                            disabled={isViewer}
                        />
                    </div>
                    <CiFiltersBadges workspaceData={data} />
                    <div className="CiSteps__workflows basic-card col">
                        <div className="CiStepsCollapse__header titles">
                            <div className={`CiStepsCollapse__header-title sort row g5`} id="">
                                {t("columns.workspace")}
                                <ArrowIcon className={`CiSteps__workflows-sortArrow ${getArrowClassNameAddition(ciSorting.workspaceName)}`} onClick={() => dispatch(handleSetCiSorting({ workspaceName: ciSorting.workspaceName === -1 ? 1 : -1 }))}/>
                            </div>
                            <div className={`CiStepsCollapse__header-title sort row g5`}>
                                {t("columns.lastRun")}
                                <ArrowIcon className={`CiSteps__workflows-sortArrow ${getArrowClassNameAddition(ciSorting.lastRunTime)}`} onClick={() => dispatch(handleSetCiSorting({ lastRunTime: ciSorting.lastRunTime === -1 ? 1 : -1 }))}/>
                            </div>
                            <div className={`CiStepsCollapse__header-title sort row g5`}>
                                {t("columns.repository")}
                                <ArrowIcon className={`CiSteps__workflows-sortArrow ${getArrowClassNameAddition(ciSorting.repo)}`} onClick={() => dispatch(handleSetCiSorting({ repo: ciSorting.repo === -1 ? 1 : -1 }))}/>
                            </div>
                            <div className={`CiStepsCollapse__header-title sort marg row g5`}>
                                <span className="CiStepsCollapse__header-tfVersion">{t("columns.iacVersion")}</span>
                                <ArrowIcon className={`CiSteps__workflows-sortArrow ${getArrowClassNameAddition(ciSorting.terraformVersion)}`} onClick={() => dispatch(handleSetCiSorting({ terraformVersion: ciSorting.terraformVersion === -1 ? 1 : -1 }))}/>
                            </div>
                            <div className={`CiStepsCollapse__header-title sort row g5`}>
                                <span className="">{t("columns.lastStatus")}</span>
                                <ArrowIcon className={`CiSteps__workflows-sortArrow ${getArrowClassNameAddition(ciSorting.lastRunStatus)}`} onClick={() => dispatch(handleSetCiSorting({ lastRunStatus: ciSorting.lastRunStatus === -1 ? 1 : -1 }))}/>
                            </div>
                        </div>
                    {loading ? <Loading /> 
                        : 
                        <div className="col between basic-100"> 
                           {data?.length ? <div className="col g20">
                                {data.map((collapseData) => {
                                    return (
                                        <CiStepsCollapse data={collapseData} key={collapseData?.workspaceId} ignoreDefaultParams={ignoreDefaultParams}
                                        setIgnoreDefaultParams={setIgnoreDefaultParams} handleOpenWorkflowManagement={handleOpenWorkflowManagement}
                                        params={params} modalVisible={runModalVisible} setModalVisible={setRunModalVisible} 
                                        setSelectedRun={setSelectedRun} setOpenDefaultPolicyCostDrawer={setOpenDefaultPolicyCostDrawer}
                                        handleOnDeleteWorkflowClick={handleOnDeleteWorkflowClick} handleOnLabelsClick={handleOnLabelsClick}
                                        handleOnGuardrailIgnoresClick={handleOnGuardrailIgnoresClick}/>
                                    )
                                })}
                            </div> : <AppEmpty text="No data" customStyle="code" />}
                            <TablePagination 
                                component="div"
                                className="CiStepsCollapse__pagination" 
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[10, 25, 50]}
                                onRowsPerPageChange={handleSetRowsPerPage}
                                count={isDefaultWorkspace ? 1 : totalWorkflows}
                                page={workflowPage}
                                onPageChange={(e, page) => setWorkflowPage(page)} />
                        </div>
                    }
                    </div>
                    <div className={`CiSteps__workflows__collapseToggle ${workflowsFiltersOpen ? 'collapsed' : ''}`} onClick={handleFiltersCollapse}>
                        <CollapseIcon />
                    </div>
                </div>
            </div>
            {showCreateWorkflowModal && <WorkflowManagement modalVisible={showCreateWorkflowModal} setModalVisible={setShowCreateWorkflowModal} isEditMode={isEditModeWorkflowModal} workflowId={editWorkflowId}/>}
            {runModalVisible && <CiModal runData={selectedRun} modalVisible={runModalVisible} setModalVisible={setRunModalVisible} openDefaultPolicyCostDrawer={openDefaultPolicyCostDrawer} />}
            {showDeleteWorkflowModal && <DeleteWorkspace handleOnDeleteWorkflowClick={handleOnDeleteWorkflowClick} showDeleteWorkflowModal={showDeleteWorkflowModal} deleteWorkspaceData={temporaryWorkspaceData} />}
            {showLabelsModal && <LabelsModal onClickLabels={handleOnLabelsClick} showLabelsModal={showLabelsModal} data={temporaryWorkspaceData} />}
            {showGuardrailIgnoresModal && <GuardrailIgnoresModal onClickIgnores={handleOnGuardrailIgnoresClick} showLabelsModal={showGuardrailIgnoresModal} data={temporaryWorkspaceData} />}
        </div>
    )
}

export default CiSteps;