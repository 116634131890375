import React, { useState, memo, useCallback } from "react";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { getInsightDataById } from "../../../../../utils/helpers";
import { Tooltip } from "antd";
import AssetInfoCard from "../assetInfoCard";

import "./insights.scss";
import { insights as insightsIcons } from "../../../../../utils/icons";
import GovernanceDrawer from "../../../../Insights/governanceDrawer/governanceDrawer";
import isEqual from 'lodash/isEqual';
import { getInsightsByAccount } from "../../../../../redux/actions/insightsV2Actions";
import IssueModal from "../../../issueModal/issueModal";
import SuggestionsModal from "../../../../Insights/suggestionsModal/suggestionsModal";
import { clearAiRemediationData, setAiRemediationStep } from "../../../../../redux/actions/aiRemediationActions";
import Unauthorized from "../../../../../containers/Insights/unauthorized/unauthorized";


const Insights = ({ classificationIds, disableLink, handleClose }) => {

  const dispatch = useDispatch();
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const account = useSelector((state) => state.onbordingReducer.account);


  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications,
    isEqual
  );
  const insights = useSelector((state) => state.insightsV2Reducer.hits);

  const insightHits = useSelector((state) => state.insightsV2Reducer.hits);

  const insight = (Array.isArray(insightHits) && insightHits.length > 0) ? insightHits[0] : {};

  const [codeDrawerOpen, setCodeDrawerOpen] = useState(false);
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);


  const handleCloseModal = () => {
    setSuggestionsOpen(false);
    dispatch(setAiRemediationStep(0));
    return dispatch(clearAiRemediationData());
  }

  const handleOpenIssue = useCallback(() => {
    setIssueModalOpen(true);
  }, [issueModalOpen]);

  const handleShowSavings = useCallback(
    (e, obj) => {
      if(e) {
        e.stopPropagation();
      }
      setSuggestionsOpen(true);
    },
    [suggestionsOpen]
  );

  const handleCloseCodeDrawer = () => {
    return setCodeDrawerOpen(false);
  }

  const renderIcon = (category = "") => {
    return <img src={insightsIcons(category)} alt={category} />;
  };

  const handleCloseIssueModal = useCallback(() => {
    setIssueModalOpen(false);
  }, [issueModalOpen]);

  const handleCloseEvent = useCallback(() => {
    setIsAuthorized(true);
  }, [isAuthorized]);


  const renderClassificationName = (classificationId) => {
    const classification = getInsightDataById(classificationId, classifications);
    return (
      <>
        <Tooltip
          placement="topLeft"
          title={
            <div className="Insights__insight-content col">
              <div
                className={`row Insights__insight-content-title row ${toLower(
                  classification?.labels?.[0]
                )}`}
              >
                <span
                  className={`row Insights__insight-content-title-flag ${toLower(
                    classification?.labels?.[0]
                  )}-flag`}
                >
                  {renderIcon(classification?.labels?.[0])}
                  {!isEmpty(classification?.labels) &&
                    classification?.labels[0]}
                </span>
              </div>
              <span>
                {!isEmpty(classification?.description) &&
                  classification?.description}
              </span>
            </div>
          }
          className="pointer"
          overlayInnerStyle={{
            fontSize: "13px",
          }}
        >
          <div
            className="Insights__insight row"
            onClick={async (e) => {
              e.stopPropagation();
              if(!account?.skus?.includes("CG")) {
                setIsAuthorized(false);
                return;
              }
              await dispatch(getInsightsByAccount({ id: classificationId }));
              setCodeDrawerOpen(true);
            }}
          >
            <div className="Insights__insight-content col">
              <div
                className={`row Insights__insight-content-title row ${toLower(
                  classification?.labels?.[0]
                )}`}
              >
                <span
                  className={`row Insights__insight-content-title-flag ${toLower(
                    classification?.labels?.[0]
                  )}-flag`}
                >
                  {renderIcon(classification?.labels?.[0])}
                </span>
                <span
                  className={`Insights__insight-content-title-text ${
                    disableLink ? "" : "underline"
                  }`}
                >
                  {!isEmpty(classification?.name) && classification?.name}
                </span>
              </div>
            </div>
          </div>
        </Tooltip>
      </>
    );
  };

  return isAuthorized ? (
    <>
      <AssetInfoCard
        contentArr={[
          {
            label: "",
            content: (
              <div className="Insights col">
                {map(classificationIds, (classificationId) => (
                  <div key={uuidv4()} className="Insights__insight col">
                    {renderClassificationName(classificationId)}
                  </div>
                ))}
              </div>
            ),
            display: !isEmpty(classificationIds),
          },
        ]}
      />
      <GovernanceDrawer
        visible={codeDrawerOpen}
        closeDrawer={handleCloseCodeDrawer}
        handleClose={handleClose}
        data={insight}
        handleShowSavings={handleShowSavings}
        handleOpenIssue={handleOpenIssue}
      />
      <IssueModal
        visible={issueModalOpen}
        handleClose={handleCloseIssueModal}
        insightsTotal={insight?.total_assets}
        insights={insights}
        selectedInsight={insight}
        zIndex={1005}
      />

      <SuggestionsModal
        visible={suggestionsOpen}
        handleClose={handleCloseModal}
        data={insight}
        tableFilters={insight?.filters}
      />
    </>
  ) : <Unauthorized handleClose={handleCloseEvent} />;
};

export default memo(Insights);
