import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppToggle from "../../../shared/appToggle/appToggle";
import map from "lodash/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Select, Tooltip } from "antd";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getFormItems, getItems, hasEmptyKeys } from "./azureFormData";
import { AZURE_REGIONS } from "../../../consts/azureRegions";
import { useDispatch } from "react-redux";
import { getIntegrationSecret } from "../../../redux/actions/integrationsActions";
import { PROVIDERS } from "../../../consts/general";

const AzurePreferances = ({ wizardState = {}, setWizardState, setSubmitDisabled, isEditMode }) => {
    const [form] = Form.useForm();
    const location = useLocation();
    const dispatch = useDispatch();
    const [secret, setSecret] = useState(null);

    const { t } = useTranslation("azure-integration");

    useEffect(() => {
        // this check is for users that doing next-prev, to keep their wizard form state aligned
        if (location.state) {
            setWizardState({ ...wizardState });
        }
        checkValidation(wizardState);
    }, []);

    useEffect(() => {
        if (isEditMode && (wizardState?.subscriptionId || location?.state)) {
            !secret && handleIntegrationSecret();
            form.setFieldsValue({ ...wizardState, subscriptionId: wizardState?.subscriptionId });
            return setSubmitDisabled(false);
        }
    }, [isEditMode, wizardState]);
    
    const handleIntegrationSecret = async () => {
        setSubmitDisabled(true);
        if(!wizardState?._id) {
          return;
        }
        const res = await dispatch(getIntegrationSecret(wizardState?._id, PROVIDERS.azure));
        if (!res) {
          return;
        }
        form.setFieldsValue({ ...wizardState, clientSecret: res.redactedPassword });
        setSecret(res.redactedPassword);
        setSubmitDisabled(false);
      };

    const checkValidation = async (formValues) => {
        let formValid = true;
        const fields = ["subscriptionId", "directoryDomain", "location", "tenantId", "clientId", "clientSecret", "prefix"];

        const { subscriptionId, directoryDomain, location, tenantId } = formValues;
        const requiredFields = { subscriptionId, directoryDomain, location, tenantId };

        if (hasEmptyKeys(requiredFields)) {
            formValid = false;
            return setSubmitDisabled(true);
        }

        try {
            await form.validateFields(fields);
            formValid = true;
        } catch (error) {
            if (error.errorFields.length) formValid = false;
        }

        setSubmitDisabled(!formValid);
    };

    const handleToggleField = (fieldName) => {
        setWizardState({
            ...wizardState,
            [fieldName]: !wizardState[fieldName],
        });
        return;
    };

    const formItems = getFormItems(isEditMode);
    const formToggle = getItems(wizardState, isEditMode);

    return (
        <div className="AzurePreferances col">
            <Form
                form={form}
                initialValues={{
                    ...wizardState,
                }}
                onValuesChange={(changedValues, allValues) => {
                    const updatedWizardState = { ...wizardState, ...allValues };
                    setWizardState(updatedWizardState);
                    checkValidation(updatedWizardState);
                }}
            >
                {formItems.map((item, index) => (
                    <Form.Item
                        key={index}
                        label={
                            <span>
                                {item.label}
                                {item.tooltip && (
                                    <Tooltip title={item.tooltip}>
                                        <FontAwesomeIcon icon="info-circle" style={{ marginLeft: "5px" }} />
                                    </Tooltip>
                                )}
                            </span>
                        }
                        name={item.name}
                        rules={item.rules}
                        style={item.style}
                    >
                        {item.name === "location" ? (
                            <Select
                                showSearch
                                placeholder={item.placeholder}
                                disabled={item.disabled}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {AZURE_REGIONS.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (item.name === "clientSecret" && !isEditMode) ? (
                            <Input.Password placeholder={item.placeholder} disabled={item.disabled} visibilityToggle={true} />
                        ) : (
                            <Input placeholder={item.placeholder} disabled={item.disabled} />
                        )}
                    </Form.Item>
                ))}
                {map(formToggle || [], (i = {}) => {
                    return (
                        <Form.Item key={i.key}>
                            <div className="AzurePreferances__block row g5" key={i.key}>
                                <AppToggle checked={i.toogleChecked} toggleChecked={() => handleToggleField(i.key)} disabled={i?.disabled} />
                                <div className="col g5">
                                    <div className="row g5">
                                        <span className="bold">{i.title}</span>
                                        {i?.icon}
                                    </div>
                                    <span className="text">{i.description}</span>
                                </div>
                            </div>
                        </Form.Item>
                    );
                })}
            </Form>
        </div>
    );
};

export default AzurePreferances;
